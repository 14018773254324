import React, { Component } from 'react';
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';
import firebase from '../../config/FirebaseConfig';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { signInGoogle } from '../../store/auth/AuthActions';

const styles = (theme) => ({
  appleButton: {
    backgroundColor: theme.palette.primary.blackColor,
    color: theme.palette.primary.price,
    direction: 'ltr',
    fontWeight: 500,
    height: 'auto',
    lineHeight: 'normal',
    maxWidth: '220px',
    minHeight: '40px',
    padding: '8px 16px',
    textAlign: 'left',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.blackColor,
      color: theme.palette.primary.price
    }
  }
});

class TestSignInWithApple extends Component {
  async signInWithApple(props) {
    const auth = firebase.auth();
    const provider = new firebase.auth.OAuthProvider('apple.com');
    const result = await auth.signInWithPopup(provider);
    // logged-in Apple user
    if (result.user) {
      // put data of user in updateAuth variable
      var updateAuth = {
        id: result.user.uid,
        name:
          result.user.displayName != null ? result.user.displayName : 'user',
        email: result.user.email != null ? result.user.email : '',
        photoUrl: result.user.photoURL ? result.user.photoURL : '',
        phoneNumber:
          result.user.phoneNumber != null ? result.user.phoneNumber : '',
        createdTimestamp: Date.now()
      };
      // call back signInGoogle function from AuthActions
      props.signInGoogle(updateAuth);
    }
  }
  handleTest = () => {
    var updateAuth = {
      id: '0aUID7we2OY5gIWYINgQrf9Ljbs2',
      name: 'user',
      email: 'maher@test.com',
      photoUrl: '',
      phoneNumber: '',
      createdTimestamp: Date.now()
    };
    // call back signInGoogle function from AuthActions
    this.props.signInGoogle(updateAuth);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button
          variant="contained"
          // color="secondary"
          className={classes.appleButton}
          startIcon={<AppleIcon />}
          onClick={(props) => this.signInWithApple(this.props)}
        >
          Sign in with apple
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    signInGoogle: (newUser) => dispatch(signInGoogle(newUser))
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TestSignInWithApple);
