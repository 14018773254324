// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { forgotPassword } from '../../store/auth/AuthActions';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { injectIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background
  },
  circularProgress: {
    color: theme.palette.primary.icons
  },
  xIcon: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: '10px'
  }
});

class ForgotPassword extends Component {
  state = {
    email: '',
    emailErr: false,
    spinner: false,
    openSnackBar: false
  };
  handleOpenSnackBar = () => {
    this.setState({
      openSnackBar: true
    });
  };
  handleCloseSnackBar = () => {
    this.setState({
      openSnackBar: false
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.email === '') {
      this.setState({
        emailErr: true
      });
    } else {
      this.setState({
        emailErr: false,
        spinner: true
      });
      this.props.forgotPassword(this.state.email);
      this.setState({
        openSnackBar: true,
        spinner: false
      });
      this.handleOpenSnackBar();
      //  this.props.handleCloseForgotPassword();
      //   this.props.handleCloseSignIn();
    }
  };
  render() {
    const { classes, forgotPassword, handleCloseForgotPassword, intl } =
      this.props;
    return (
      <div className={classes.root}>
        <div>
          <CloseIcon
            className={classes.xIcon}
            onClick={handleCloseForgotPassword}
          />
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackBar}
        >
          <Alert severity="success">
            {intl.formatMessage({
              id: 'auth_check_email'
            })}
          </Alert>
        </Snackbar>
        <DialogTitle id="form-dialog-title">
          {intl.formatMessage({
            id: 'reset_password'
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({
              id: 'auth_reset_email'
            })}
          </DialogContentText>
          {this.state.emailErr === false ? (
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label={intl.formatMessage({
                id: 'emails_notifications'
              })}
              onChange={this.handleChange}
              type="email"
              fullWidth
            />
          ) : (
            <TextField
              error
              autoFocus
              margin="dense"
              id="email"
              label={intl.formatMessage({
                id: 'emails_notifications'
              })}
              onChange={this.handleChange}
              type="email"
              fullWidth
            />
          )}
        </DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {forgotPassword}
        </div>
        <DialogActions>
          {this.state.spinner === false ? (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              {intl.formatMessage({
                id: 'reset_password'
              })}
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              <CircularProgress className={classes.circularProgress} />
            </Button>
          )}
        </DialogActions>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotPassword: state.auth.forgotPassword
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (creds) => dispatch(forgotPassword(creds))
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(ForgotPassword)
);
