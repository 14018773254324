// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { NavLink } from "react-router-dom";
import "firebase/compat/storage";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import PropTypes from "prop-types";
// import * as rdd from "react-device-detect";
import { alpha } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Badge from "@mui/material/Badge";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreIcon from "@mui/icons-material/MoreVert";
import MailIcon from "@mui/icons-material/Mail";
import DialogDownload from "./DialogDownload";
import OnBoardingScreens from "./OnBoardingScreens";
import { BoardingScreenAction } from "../../store/onboarding/BoardingScreenAction";
import { injectIntl } from "react-intl";
// import SearchComponent from "../search/SearchComponent";
import { useOnBoardingScreens } from "../../theme/ThemeContext";
import { getThreadIdForSubjectAndSeller } from "../messaging/Messageing";
import {
  SupportIdInProduction,
  AdminIdInDevelopment,
  isProductionBuild,
} from "../../config/FirebaseConfig";
import { clearSearchText } from "../../store/search/SearchAction";
import { createAction } from "../../store/actions/EventsActions";
import MessageComponent from "../messaging/MessageComponent";
import AddProduct from "../products/AddProduct";
import LanguageDialog from "./LanguageDialog";
import { useTheme } from "../../theme/ThemeContext";
import { constantsConfig } from "../../config/ConstantsConfig";
import { signOut, clearAuthError } from "../../store/auth/AuthActions";
import SignUpAndSignIn from "../auth/SignUpAndSignIn";
import NewSearchComponent from "../search/NewSearchComponent";

const label = { inputProps: { "aria-label": "Switch demo" } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    backgroundColor: theme.palette.primary.background,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    maxWidth: "100%",
    backgroundColor: theme.palette.primary.background,
  },
  tabs: {
    color: theme.palette.primary.icons,
    indicatorColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.background,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    ...theme.typography.usernameButton,
    width: "160px",
    fontSize: "0.7rem",
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.primaryText,
  },
  buttonBigName: {
    ...theme.typography.usernameButton,
    width: "165px",
    fontSize: "7px",
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.primaryText,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.background,
  },
  menuButton: {
    marginInlineEnd: theme.spacing(2),
  },
  tab: {
    ...theme.typography.tab,
    color: theme.palette.primary.main,
    padding: 30,
    minWidth: "90px",
    width: "70px",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
  },
  tabMessages: {
    ...theme.typography.tab,
    color: theme.palette.primary.icons,
    backgroundColor: theme.palette.primary.main,
    padding: 30,
    minWidth: "90px",
    width: "110px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    color: theme.palette.primary.primaryText,
    width: "20%",
  },
  containerTabs: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginInlineStart: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginInlineStart: theme.spacing(3),
      width: "auto",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
    marginInlineEnd: theme.spacing(2),
    marginInlineStart: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginInlineStart: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.icons,
  },

  inputRoot: {
    color: theme.palette.primary.icons,
  },
  inputInput: {
    padding: theme.spacing(1.7, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingInlineStart: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(6),
  },
  downloadApp: {
    ...theme.typography.downloadAppButton,
    fontSize: "1rem",
    padding: "10px",
    width: "150px",
    marginInlineEnd: "30px",
  },
  logButtons: {
    ...theme.typography.normalButton,
    color: theme.palette.primary.main,
  },
  offset: {
    marginBottom: 82,
  },
  profile: {
    color: theme.palette.primary.primaryText,
  },
  listItemIcon: { color: theme.palette.primary.primaryText },
  moreVertIcon: { color: theme.palette.primary.main },
  link: {
    textDecoration: "none",
    "&:hover": { textDecoration: "none" },
  },
  moreIcon: { color: theme.palette.primary.main },
  circularProgress: {
    color: theme.palette.primary.main,
  },
  plusLink: {
    textDecoration: "none",
    "&:hover": { textDecoration: "none" },
  },
  menu: {
    "& .MuiList-root": {
      backgroundColor: theme.palette.primary.background,
    },
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.primary.background,
    },
  },
  shareButtonsMenu: {
    "& .MuiList-root": {
      backgroundColor: theme.palette.primary.background,
    },
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.primary.background,
    },
  },
  menuItem: {
    backgroundColor: theme.palette.primary.background,
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: "1px solid",
    borderColor: theme.palette.primary.background,
    backgroundColor: theme.palette.primary.background,
  },
}))((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Navbar = ({ intl, ...props }) => {
  const href = window.location.href;
  var tabValue = 0;
  if (
    (href.includes("buy") || href.includes("product")) &&
    !href.includes("dashboardproductsofmanager")
  ) {
    tabValue = 0;
  } else if (href.includes("sell")) {
    tabValue = 1;
  } else if (href.includes("threads")) {
    tabValue = 2;
  }

  const [isOpenSignIn, setIsOpenSignIn] = React.useState(false);
  const [isOpenSignUp, setIsOpenSignUp] = React.useState(false);
  const [isOpenDialogDownload, setIsOpenDialogDownload] = React.useState(false);
  const [isOpenSignUpAndSignIn, setIsOpenSignUpAndSignIn] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElShareButtons, setAnchorElShareButtons] = React.useState(null);
  const [anchorElPrivacyAndTermsMenu, setAnchorElPrivacyAndTermsMenu] =
    React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [value, setValue] = React.useState(tabValue);
  const [isOpenOnBoardingScreens, setStatus] = useOnBoardingScreens();
  const [openDialogOnBoardingScreens, isOpenDialogOnBoardingScreens] =
    React.useState(isOpenOnBoardingScreens);
  const [isOpenLanguageDialog, setIsOpenLanguageDialog] = React.useState(false);
  const [openChatDialog, setopenChatDialog] = React.useState(false);
  const [openChatLoding, setOpenChatLoding] = React.useState(false);
  const [thread, setThread] = React.useState(null);
  const [isOpenSell, setIsOpenSell] = React.useState(false);
  const [theme, setTheme] = useTheme();
  const [isOpenAboutApp, setIsOpenAboutApp] = React.useState(false);
  const [isMessages, setIsMessages] = React.useState(false);
  const [isNotifications, setIsNotifications] = React.useState(false);
  const updateTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };
  const handleCloseChatDialog = () => {
    setopenChatDialog(false);
  };
  const onCloseThread = () => {
    setThread(null);
    setopenChatDialog(false);
  };
  const handleOpenAboutApp = () => {
    setIsOpenAboutApp(true);
  };
  const handleCloseAboutApp = () => {
    setIsOpenAboutApp(false);
  };
  const handleopenChatDialog = () => {
    setOpenChatLoding(true);
    getThreadIdForSubjectAndSeller({
      receiverId: isProductionBuild
        ? SupportIdInProduction
        : AdminIdInDevelopment,
      receiverName: "Support",
      receiverPhoto: constantsConfig.logoListedNearMe,
      senderId: props.auth.uid,
      senderName: props.displayName,
      senderPhoto: props.profile.photoUrl,
      subjectId: isProductionBuild
        ? SupportIdInProduction
        : AdminIdInDevelopment,
      subjectName: "Support",
      subjectPhoto: constantsConfig.logoListedNearMe,
      isChatSupport: true,
    })
      .then((response) => {
        createAction({
          actionType: "ContactSupport",

          userId: props.auth,
        });
        setOpenChatLoding(false);
        setopenChatDialog(true);
        setThread(response);
      })
      .catch((err) => {
        setOpenChatLoding(false);
      });
  };
  const handleCloseSell = () => {
    setIsOpenSell(false);
  };
  const handleCloseLanguageDialog = () => {
    setIsOpenLanguageDialog(false);
  };
  const handleClearAuthError = () => {
    props.clearAuthError();
  };
  const handleOpenLanguageDialog = () => {
    setIsOpenLanguageDialog(true);
  };
  const handleOpenSignIn = () => {
    setIsOpenSignIn(true);
    setIsOpenSignUp(false);
  };
  const handleOpenSignUp = () => {
    setIsOpenSignUp(true);
    setIsOpenSignIn(false);
  };
  const handleOpenSignUpAndSignIn = () => {
    setIsOpenSignUpAndSignIn(true);
  };
  const handleCloseSignUpAndSignIn = () => {
    setIsOpenSignUpAndSignIn(false);
    setIsOpenSignIn(false);
    setIsOpenSignUp(false);
    handleClearAuthError();
  };
  const handleCloseDialogDownload = () => {
    setIsOpenDialogDownload(false);
  };
  const handleOpenDialogDownload = () => {
    setIsOpenDialogDownload(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickShareButtons = (event) => {
    setAnchorElShareButtons(event.currentTarget);
  };

  const handleCloseShareButtons = () => {
    setAnchorElShareButtons(null);
  };
  const handleClickPrivacyAndTermsMenu = (event) => {
    setAnchorElPrivacyAndTermsMenu(event.currentTarget);
  };

  const handleClosePrivacyAndTermsMenu = () => {
    setAnchorElPrivacyAndTermsMenu(null);
  };
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMobileMenuClose = () => {
    props.clearSearchText();
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (props.signOutSuccess) {
      handleClose();
    }
    if (props.checkMessages) {
      if (props.checkMessages.recentMessagesNotSeenByUserId)
        setIsMessages(
          props.checkMessages.recentMessagesNotSeenByUserId === null ||
            window.location.href.includes("threads")
            ? false
            : true
        );
    }
    if (props.notifications) {
      setIsNotifications(
        props.notifications
          ? props.notifications.length === 0
            ? false
            : window.location.href.includes("threads")
            ? false
            : true
          : false
      );
    }
    // if (props.searchText !== "" && rdd.isMobile) handleMobileMenuOpen();
  }, [props]);
  const { auth, displayName, photoURL } = props;
  var showName;
  if (displayName) showName = displayName.split(" ")[0];

  const mobileMenuId = "primary-search-account-menu-mobile";
  const shareButtons = (
    <Menu
      anchorEl={anchorElShareButtons}
      keepMounted
      open={Boolean(anchorElShareButtons)}
      onClose={handleCloseShareButtons}
      className={classes.shareButtonsMenu}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <div className={classes.flex} style={{ padding: "5px" }}>
        <FacebookShareButton
          url={constantsConfig.shareButtonsLink}
          className={classes.shareIcon}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton
          url={constantsConfig.shareButtonsLink}
          className={classes.shareIcon}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={constantsConfig.shareButtonsLink}
          className={classes.shareIcon}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <TelegramShareButton
          url={constantsConfig.shareButtonsLink}
          className={classes.shareIcon}
        >
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>

        <LinkedinShareButton
          url={constantsConfig.shareButtonsLink}
          className={classes.shareIcon}
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <EmailShareButton
          url={constantsConfig.shareButtonsLink}
          className={classes.shareIcon}
        >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
    </Menu>
  );
  const renderMenuPrivaceAndTerms = (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorElPrivacyAndTermsMenu}
      keepMounted
      open={Boolean(anchorElPrivacyAndTermsMenu)}
      onClose={handleClosePrivacyAndTermsMenu}
      className={classes.menu}
    >
      <StyledMenuItem>
        <ListItemText
          className={classes.profile}
          primary={intl.formatMessage({
            id: "dark_mode",
          })}
        />
        <Switch
          {...label}
          checked={theme === "dark" ? true : null}
          // defaultValue={shop ? shop.isPublished : null}
          onChange={updateTheme}
          name="isPublished"
          color="primary"
        />
      </StyledMenuItem>{" "}
      <a
        href={constantsConfig.privacyLink}
        className={classes.plusLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <ListItemText
              className={classes.profile}
              primary={intl.formatMessage({
                id: "privacy",
              })}
            />
          </ListItemIcon>
        </StyledMenuItem>
      </a>
      <a
        href={constantsConfig.termsLink}
        className={classes.plusLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <ListItemText
              primary={intl.formatMessage({
                id: "terms",
              })}
            />
          </ListItemIcon>
        </StyledMenuItem>
      </a>
      <a
        href={constantsConfig.reportProblemLink}
        className={classes.plusLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <ListItemText
              primary={intl.formatMessage({
                id: "report_a_problem",
              })}
            />
          </ListItemIcon>
        </StyledMenuItem>
      </a>
      <StyledMenuItem
        onClick={() => {
          handleOpenLanguageDialog();
          handleClose();
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ListItemText
            primary={intl.formatMessage({
              id: "language",
            })}
          />
        </ListItemIcon>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleOpenAboutApp}>
        <ListItemIcon className={classes.listItemIcon}>
          <ListItemText
            primary={intl.formatMessage({
              id: "about_the_app",
            })}
          />
        </ListItemIcon>
      </StyledMenuItem>
    </StyledMenu>
  );
  const renderMenu = (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.menu}
    >
      <StyledMenuItem>
        <ListItemText
          className={classes.profile}
          primary={intl.formatMessage({
            id: "dark_mode",
          })}
        />
        <Switch
          {...label}
          checked={theme === "dark" ? true : null}
          // defaultValue={shop ? shop.isPublished : null}
          onChange={updateTheme}
          name="isPublished"
          color="primary"
        />
      </StyledMenuItem>{" "}
      <Link
        to={"/userProfile/" + props.auth.uid}
        style={{ textDecoration: "none" }}
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemText
            className={classes.profile}
            primary={intl.formatMessage({
              id: "my_profile",
            })}
          />
        </StyledMenuItem>
      </Link>
      <Link
        to={"/dashboardfavoriteproducts/" + props.auth.uid}
        style={{ textDecoration: "none" }}
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemText
            className={classes.profile}
            primary={intl.formatMessage({
              id: "favorite_products",
            })}
          />
        </StyledMenuItem>
      </Link>
      {/* <Link to={"/dashboardordershistory/"} style={{ textDecoration: "none" }}>
        <StyledMenuItem onClick={handleClose}>
          <ListItemText
            className={classes.profile}
            primary={intl.formatMessage({
              id: "orders_history",
            })}
          />
        </StyledMenuItem>
          </Link>*/}
      <a
        href={constantsConfig.privacyLink}
        className={classes.plusLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <ListItemText
              className={classes.profile}
              primary={intl.formatMessage({
                id: "privacy",
              })}
            />
          </ListItemIcon>
        </StyledMenuItem>
      </a>
      <a
        href={constantsConfig.termsLink}
        className={classes.plusLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <ListItemText
              primary={intl.formatMessage({
                id: "terms",
              })}
            />
          </ListItemIcon>
        </StyledMenuItem>
      </a>
      <StyledMenuItem
        onClick={() => {
          handleClose();
          handleopenChatDialog();
          handleCloseChatDialog();
        }}
      >
        <ListItemText
          className={classes.profile}
          primary={intl.formatMessage({
            id: "contact_support",
          })}
        />
      </StyledMenuItem>
      <a
        href={constantsConfig.reportProblemLink}
        className={classes.plusLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledMenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <ListItemText
              primary={intl.formatMessage({
                id: "report_a_problem",
              })}
            />
          </ListItemIcon>
        </StyledMenuItem>
      </a>
      <StyledMenuItem onClick={handleClickShareButtons}>
        <ListItemIcon className={classes.listItemIcon}>
          <ListItemText
            primary={intl.formatMessage({
              id: "action_refer",
            })}
          />
        </ListItemIcon>
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          handleOpenLanguageDialog();
          handleClose();
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ListItemText
            primary={intl.formatMessage({
              id: "language",
            })}
          />
        </ListItemIcon>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleOpenAboutApp}>
        <ListItemIcon className={classes.listItemIcon}>
          <ListItemText
            primary={intl.formatMessage({
              id: "about_the_app",
            })}
          />
        </ListItemIcon>
      </StyledMenuItem>
      <StyledMenuItem onClick={props.signOut}>
        <ListItemIcon onClick={handleClose} className={classes.listItemIcon}>
          <ListItemText
            primary={intl.formatMessage({
              id: "action_logout",
            })}
          />
        </ListItemIcon>
      </StyledMenuItem>
    </StyledMenu>
  );
  const renderMobileMenuSignedIn = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.menu}
    >
      <MenuItem className={classes.menuItem}>
        {/* <div style={{ marginInlineStart: "0px", display: "flex" }}> */}
        <NewSearchComponent
          href={window.location.href}
          handleMobileMenuClose={handleMobileMenuClose}
        />
        {/* </div> */}
      </MenuItem>

      <MenuItem style={{ alignItems: "center" }}>
        <Button
          onClick={handleOpenDialogDownload}
          variant="contained"
          color="primary"
          className={classes.downloadApp}
        >
          {intl.formatMessage({
            id: "download_app",
          })}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          variant="contained"
          className={
            showName
              ? showName.length < 10
                ? classes.button
                : classes.buttonBigName
              : null
          }
          onClick={handleClick}
          startIcon={<Avatar alt="Remy Sharp" src={photoURL} />}
          endIcon={<MoreVertIcon className={classes.moreVertIcon} />}
        >
          {showName}
        </Button>
      </MenuItem>
    </Menu>
  );
  const renderMobileMenuSignedOut = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      className={classes.menu}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {/* <div style={{ marginInlineEnd: "30px", display: "flex" }}> */}
        <NewSearchComponent
          href={window.location.href}
          handleMobileMenuClose={handleMobileMenuClose}
        />
        {/* </div>{" "} */}
      </MenuItem>
      <MenuItem onClick={handleOpenDialogDownload}>
        <Button
          variant="contained"
          color="primary"
          className={classes.downloadApp}
        >
          {intl.formatMessage({
            id: "download_app",
          })}
        </Button>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleOpenSignIn();
          handleOpenSignUpAndSignIn();
        }}
      >
        <Button className={classes.logButtons}>
          {intl.formatMessage({
            id: "login",
          })}
        </Button>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleOpenSignUp();
          handleOpenSignUpAndSignIn();
        }}
      >
        <Button className={classes.logButtons}>
          {intl.formatMessage({
            id: "sign_up",
          })}
        </Button>
      </MenuItem>
      <MenuItem onClick={handleClickPrivacyAndTermsMenu}>
        <Button className={classes.logButtons}>
          {intl.formatMessage({
            id: "more",
          })}
          <MoreVertIcon className={classes.moreVertIcon} />
        </Button>
      </MenuItem>
    </Menu>
  );
  const handleCloseOnBoardingScreens = () => {
    setStatus(false);
    isOpenDialogOnBoardingScreens(false);
  };
  return (
    <div className={classes.grow}>
      {!auth.uid ? (
        <Dialog
          onClose={handleCloseOnBoardingScreens}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"xs"}
          open={openDialogOnBoardingScreens}
        >
          <OnBoardingScreens
            handleCloseOnBoardingScreens={handleCloseOnBoardingScreens}
          />
        </Dialog>
      ) : null}
      <Dialog
        onClose={handleCloseDialogDownload}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={"md"}
        open={isOpenDialogDownload}
      >
        <DialogDownload handleClose={handleCloseDialogDownload} />
      </Dialog>
      <Dialog
        onClose={handleCloseAboutApp}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={"xs"}
        open={isOpenAboutApp}
      >
        <OnBoardingScreens handleCloseOnBoardingScreens={handleCloseAboutApp} />
      </Dialog>
      <Dialog
        // onClose={handleCloseSignUpAndSignIn}
        aria-labelledby="simple-dialog-title"
        maxWidth={"sm"}
        open={isOpenSignUpAndSignIn}
      >
        <SignUpAndSignIn
          isOpenSignIn={isOpenSignIn}
          isOpenSignUp={isOpenSignUp}
          handleCloseSignUpAndSignIn={handleCloseSignUpAndSignIn}
        />
      </Dialog>

      <Dialog
        onClose={handleCloseSell}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={"md"}
        open={isOpenSell}
      >
        <AddProduct handleClose={handleCloseSell} />
      </Dialog>

      <Dialog
        onClose={handleCloseLanguageDialog}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={"xs"}
        open={isOpenLanguageDialog}
      >
        <LanguageDialog
          handleCloseLanguageDialog={handleCloseLanguageDialog}
          countryCode={props.countryCode}
        />
      </Dialog>
      <Backdrop className={classes.backdrop} open={openChatLoding}>
        <CircularProgress className={classes.circularProgress} />
      </Backdrop>
      <Dialog open={openChatDialog} onClose={handleCloseChatDialog} fullWidth>
        {thread && (
          <MessageComponent
            thread={thread}
            isDialog
            onCloseThread={onCloseThread}
            isChatWithAdmin={"isChatWithAdmin"}
          />
        )}
      </Dialog>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <NavLink
            className={classes.link}
            to={"/"}
            onClick={() => {
              setValue(0);
            }}
          >
            <img
              src={constantsConfig.logoListedNearMe}
              className={classes.large}
              alt=""
            />
          </NavLink>
          <Typography className={classes.title} variant="h5" noWrap>
            {intl.formatMessage({
              id: "app_name",
            })}
          </Typography>
          {!auth.uid ? (
            <div className={classes.containerTabs}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor={
                  useStyles.tabs ? useStyles.tabs.color : "primary"
                }
                className={classes.tabs}
              >
                <Tab
                  label={intl.formatMessage({
                    id: "title_buy",
                  })}
                  className={classes.tab}
                  to={"/"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setValue(0);
                  }}
                  component={NavLink}
                  {...a11yProps(0)}
                />

                <Tab
                  onClick={() => {
                    handleOpenSignUpAndSignIn();
                    setValue(value);
                  }}
                  label={intl.formatMessage({
                    id: "title_sell",
                  })}
                  className={classes.tab}
                  {...a11yProps(1)}
                />

                <Tab
                  onClick={() => {
                    handleOpenSignUpAndSignIn();
                    setValue(value);
                  }}
                  label={intl.formatMessage({
                    id: "inbox",
                  })}
                  className={classes.tab}
                  {...a11yProps(2)}
                />
              </Tabs>
            </div>
          ) : (
            <div className={classes.containerTabs}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor={
                  useStyles.tabs ? useStyles.tabs.color : "primary"
                }
                className={classes.tabs}
              >
                <Tab
                  label={intl.formatMessage({
                    id: "title_buy",
                  })}
                  className={classes.tab}
                  {...a11yProps(0)}
                  component={NavLink}
                  to={"/"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setValue(0);
                  }}
                />

                {/*  <NavLink
                className={classes.link}
                to={"/shoplists"}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });

                  setValue(1);
                }}
              >
                <Tab
                  style={{ padding: "30px", minWidth: "90px", width: "90px" }}
                  label={intl.formatMessage({
                    id: "tab.shop",
                  })}
                  className={classes.tab}
                  {...a11yProps(1)}
                />
                </NavLink>*/}

                <Tab
                  label={intl.formatMessage({
                    id: "title_sell",
                  })}
                  className={classes.tab}
                  to={"/sellpageproducts/null"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setValue(1);
                  }}
                  component={NavLink}
                  {...a11yProps(2)}
                />

                <Tab
                  label={
                    <div>
                      {isMessages === true || isNotifications === true ? (
                        <div style={{ display: "flex" }}>
                          <div>
                            {intl.formatMessage({
                              id: "inbox",
                            })}
                          </div>
                          <div style={{ marginInlineStart: "0.5em" }}>
                            <Badge
                              badgeContent={
                                props.notificationsNum || props.notifications
                                  ? (props.notifications
                                      ? props.notifications.length
                                      : 0) +
                                    (props.notificationsNum
                                      ? props.notificationsNum
                                      : 0)
                                  : 0
                              }
                              color="secondary"
                            >
                              <MailIcon fontSize="small" />
                            </Badge>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {intl.formatMessage({
                            id: "inbox",
                          })}
                        </div>
                      )}
                    </div>
                  }
                  className={
                    isMessages === true || isNotifications === true
                      ? classes.tabMessages
                      : classes.tab
                  }
                  onClick={() => {
                    if (isMessages === false && isNotifications === false) {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setValue(2);
                    } else {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setValue(2);
                      setIsMessages(false);
                      setIsNotifications(false);
                    }
                  }}
                  to={"/threads"}
                  component={NavLink}
                  {...a11yProps(3)}
                />
              </Tabs>
            </div>
          )}

          <div className={classes.grow} />
          {!auth.uid ? (
            <div className={classes.sectionDesktop}>
              <div
                style={{
                  marginInlineEnd: "15px",
                  marginTop: "0.2em",
                }}
              >
                <NewSearchComponent
                  href={window.location.href}
                  handleMobileMenuClose={handleMobileMenuClose}
                />
              </div>

              <Button
                onClick={handleOpenDialogDownload}
                variant="contained"
                color="primary"
                className={classes.downloadApp}
              >
                {intl.formatMessage({
                  id: "download_app",
                })}
              </Button>

              <Button
                onClick={() => {
                  handleOpenSignIn();
                  handleOpenSignUpAndSignIn();
                }}
                className={classes.logButtons}
              >
                {intl.formatMessage({
                  id: "login",
                })}
              </Button>
              <Button
                onClick={() => {
                  handleOpenSignUp();
                  handleOpenSignUpAndSignIn();
                }}
                className={classes.logButtons}
              >
                {intl.formatMessage({
                  id: "sign_up",
                })}
              </Button>
              <Button
                onClick={handleClickPrivacyAndTermsMenu}
                className={classes.logButtons}
              >
                <MoreVertIcon className={classes.moreVertIcon} />
              </Button>
            </div>
          ) : (
            <div className={classes.sectionDesktop}>
              <div
                style={{
                  marginInlineEnd: "15px",
                  marginTop: "0.2em",
                }}
              >
                {/* <SearchComponent href={window.location.href} /> */}
                <NewSearchComponent
                  handleMobileMenuClose={handleMobileMenuClose}
                />
              </div>

              <Button
                onClick={handleOpenDialogDownload}
                variant="contained"
                color="primary"
                sx={{ marginInlineStart: "30px" }}
                className={classes.downloadApp}
              >
                {intl.formatMessage({
                  id: "download_app",
                })}
              </Button>
              <Button
                variant="contained"
                onClick={handleClick}
                className={
                  showName
                    ? showName.length < 10
                      ? classes.button
                      : classes.buttonBigName
                    : null
                }
                startIcon={<Avatar alt="Remy Sharp" src={photoURL} />}
                endIcon={<MoreVertIcon className={classes.moreVertIcon} />}
              >
                {showName}
              </Button>
            </div>
          )}
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon className={classes.moreIcon} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Offset />
      {!auth.uid ? renderMobileMenuSignedOut : renderMobileMenuSignedIn}
      {renderMenuPrivaceAndTerms}
      {renderMenu}
      {shareButtons}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const uid = state.firebase.auth.uid;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    searchText: state.SearchReducers.searchText,
    BoardingScreenReducer: state.BoardingScreenReducer,
    user: state.firestore.data
      ? state.firestore.data.users
        ? state.firestore.data.users[uid]
        : null
      : null,
    displayName: state.firestore.data
      ? state.firestore.data.users
        ? state.firestore.data.users[uid]
          ? state.firestore.data.users[uid].name
          : null
        : null
      : null,
    photoURL: state.firestore.data
      ? state.firestore.data.users
        ? state.firestore.data.users[uid]
          ? state.firestore.data.users[uid].photoUrl
          : null
        : null
      : null,
    product: state.product,
    countryCode: state.locationReducer
      ? state.locationReducer.bingoDealLocation
        ? state.locationReducer.bingoDealLocation.countryCode
        : state.locationReducer.countryCode
      : null,
    checkMessages: state.firestore.ordered
      ? state.firestore.ordered.checkMessages
        ? state.firestore.ordered.checkMessages[0]
        : null
      : null,
    notificationsNum: state.firestore.ordered
      ? state.firestore.ordered.checkMessages
        ? state.firestore.ordered.checkMessages.length
        : null
      : null,
    notifications: state.firestore.ordered
      ? state.firestore.ordered.notifications
      : null,
    signOutSuccess: state.auth.signOutSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    setSearchText: (searchText) => {
      dispatch({ type: "SEARSH_TEXT", searchText });
    },
    clearSearchText: () => dispatch(clearSearchText()),
    BoardingScreenAction: () => dispatch(BoardingScreenAction()),
    clearAuthError: () => dispatch(clearAuthError()),
  };
};

export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
      return [
        {
          collection: constantsConfig.collectionUsers,
          doc: props.auth.uid,
        },
      ];
    }),
    firestoreConnect((props) => {
      if (props.auth.uid) {
        return [
          {
            collection: constantsConfig.collectionThreads,
            where: ["recentMessagesNotSeenByUserId", "==", props.auth.uid],
            storeAs: "checkMessages",
            // limit: 1,
          },
        ];
      } else return [];
    }),
    firestoreConnect((props) => {
      if (props.auth.uid) {
        return [
          {
            collection: constantsConfig.collectionNotifications,
            where: [
              ["userId", "==", props.auth.uid],
              ["clickTimestamp", "==", 0],
            ],
            storeAs: "notifications",
          },
        ];
      } else return [];
    })
  )(Navbar)
);
