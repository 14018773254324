import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { injectIntl } from "react-intl";
import moment from "moment";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider as LocalizationProviderTime } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import DatePicker from "@mui/lab/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultipleImageUpload from "./images/MultipleImageUpload";
import MapComponent from "../other/MapComponent";
import { createProduct, editProduct } from "../../store/product/ProductActions";
import { constantsConfig } from "../../config/ConstantsConfig";
import {
  isEmptyOrSpaces,
  convertTo24HrsFormat,
} from "../../functions/Functions";

const styles = (theme) => ({
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
  layout: {
    width: "auto",
    marginTop: "35px",
    marginInlineStart: theme.spacing(2),
    marginInlineEnd: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    marginTop: "1em",
  },
  textField: {
    width: "100%",
  },
  errImageMessageTypography: {
    color: theme.palette.primary.errColor,
  },
  buttonProgress: {
    color: theme.palette.primary.icons,
  },
  postButton: {
    ...theme.typography.normalButton,
  },
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
  plusIcon: {
    "&:hover": { cursor: "pointer" },
  },
});

class AddYardSaleEvent extends Component {
  constructor(props) {
    super(props);
    if (props.type === "add") {
      this.state = {
        yardSaleLocation: null,
        testDate1: null,
        defaultDate: Date.now(),
        yardSaleStartTime: null,
        yardSaleEndTimeDefault: Date.now(),
        yardSaleEndTime: null,
        yardSaleDate1: null,
        yardSaleDate2: null,
        yardSaleDate3: null,
        yardSaleEndTimestamp: null,
        price: 0,
        title: "Yard Sale",
        currentImagesURL: [],
        coverImageIndex: -1,
        tags: [],
        onTagsProcess: 0,
        imageUrl1: null,
        imageUrl2: null,
        imageUrl3: null,
        imageUrl4: null,
        imageUrl5: null,
        description: null,
        category: null,
        condition: 2,
        conditionStatus: "Used (normal wear)",
        currencyCode: props.currencyCode,
        disabled: false,
        rating: 3.5,
        isFoodItem: false,
        isBoosted: false,
        isSoldOnBingoDeal: false,
        isImageUrlNull: false,
        impressions: 0,
        clicks: 0,
        boostTimestamp: 0,
        isActive: true,
        isUsedProduct: true,
        platform: "web",
        firmOnPrice: false,
        seller: this.props.profile,
        sellerId: this.props.profile.id,
        value: null,
        countDate: 1,
        isInvalidDate1: false,
        isInvalidDate2: false,
        isFirstTimeNull: false,
        isInvalidEndTime: false,
        isSecondTimeNull: false,
        isDateNull: false,
        spinner: false,
      };
    } else {
      this.state = {
        yardSaleLocation: {
          city: props.productData.city ? props.productData.city : null,
          country: props.productData.country ? props.productData.country : null,
          countryCode: props.productData.countryCode
            ? props.productData.countryCode
            : null,
          currency: props.productData.currencyCode
            ? props.productData.currencyCode
            : null,
          latitude: props.productData.latitude
            ? props.productData.latitude
            : null,
          longitude: props.productData.longitude
            ? props.productData.longitude
            : null,
          readableLocation: props.productData.readableLocation
            ? props.productData.readableLocation
            : null,
          state: props.productData.state ? props.productData.state : null,
          zip: props.productData.zip ? props.productData.zip : null,
        },
        allThisFavoriteProduct: props.allThisFavoriteProduct,
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        defaultDate: Date.now(),
        productId: props.productData.id,
        yardSaleStartTime: props.productData.yardSaleStartTime
          ? props.productData.yardSaleStartTime
          : null,
        yardSaleEndTimeDefault: props.productData.yardSaleEndTimeDefault
          ? props.productData.yardSaleEndTimeDefault
          : null,
        yardSaleEndTime: props.productData.yardSaleEndTime
          ? props.productData.yardSaleEndTime
          : null,
        yardSaleDate1: props.productData.yardSaleDate1
          ? props.productData.yardSaleDate1
          : null,
        yardSaleDate2: props.productData.yardSaleDate2
          ? props.productData.yardSaleDate2
          : null,
        yardSaleDate3: props.productData.yardSaleDate3
          ? props.productData.yardSaleDate3
          : null,
        yardSaleEndTimestamp: props.productData.yardSaleEndTimestamp
          ? props.productData.yardSaleEndTimestamp
          : null,
        price: props.productData.price,
        title: props.productData.title,
        currentImagesURL: props.currentImagesURL,
        coverImageIndex: props.coverImageIndex,
        tags: props.productData.tags ? props.productData.tags : [],
        onTagsProcess: 0,
        imageUrl1: props.productData.imageUrl1
          ? props.productData.imageUrl1
          : null,
        imageUrl2: props.productData.imageUrl2
          ? props.productData.imageUrl2
          : null,
        imageUrl3: props.productData.imageUrl3
          ? props.productData.imageUrl3
          : null,
        imageUrl4: props.productData.imageUrl4
          ? props.productData.imageUrl4
          : null,
        imageUrl5: props.productData.imageUrl5
          ? props.productData.imageUrl5
          : null,
        description: props.productData.description
          ? props.productData.description
          : null,
        category: props.productData.category
          ? props.productData.category
          : null,
        condition: props.productData.condition
          ? props.productData.condition
          : null,
        conditionStatus: props.productData.conditionStatus,
        currencyCode: props.currencyCode,
        disabled: false,
        rating: props.productData.rating,
        isFoodItem: props.productData.isFoodItem,
        isBoosted: props.productData.isBoosted,
        isSoldOnBingoDeal: props.productData.isSoldOnBingoDeal,
        impressions: props.productData.impressions,
        clicks: props.productData.clicks,
        boostTimestamp: props.productData.boostTimestamp,
        isActive: props.productData.isActive,
        isUsedProduct: props.productData.isUsedProduct,
        platform: props.productData.platform,
        firmOnPrice: props.productData.firmOnPrice,
        seller: this.props.profile,
        sellerId: this.props.profile.id,
        value: null,
        countDate: props.productData.yardSaleDate3
          ? 3
          : props.productData.yardSaleDate2
          ? 2
          : 1,
        isInvalidDate1: false,
        isInvalidDate2: false,
        isInvalidEndTime: false,
        isFirstTimeNull: false,
        isSecondTimeNull: false,
        isDateNull: false,
        spinner: false,
      };
    }
  }

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "images":
        switch (value) {
          case 0:
            this.setState({
              imageUrl1: "",
            });
            for (let i = 2; i <= 5; i++) {
              if (i === 2) {
                if (this.state.imageUrl2 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl2,
                    imageUrl2: "",
                  });
                  break;
                }
              } else if (i === 3) {
                if (this.state.imageUrl3 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl3,
                    imageUrl3: "",
                  });
                  break;
                }
              } else if (i === 4) {
                if (this.state.imageUrl4 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl4,
                    imageUrl4: "",
                  });
                  break;
                }
              } else {
                if (this.state.imageUrl5 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl5,
                    imageUrl5: "",
                  });
                  break;
                }
              }
            }
            break;
          case 1:
            this.setState({
              imageUrl2: "",
            });
            break;
          case 2:
            this.setState({
              imageUrl3: "",
            });
            break;
          case 3:
            this.setState({
              imageUrl4: "",
            });
            break;
          case 4:
            this.setState({
              imageUrl5: "",
            });
            break;
          default:
            break;
        }
        this.state.currentImagesURL.splice(value, 1);

        break;
      case "image":
        switch (value.index) {
          case 0:
            this.setState({
              imageUrl1: value.url,
              isImageUrlNull: false,
            });
            break;
          case 1:
            this.setState({
              imageUrl2: value.url,
            });
            break;
          case 2:
            this.setState({
              imageUrl3: value.url,
            });
            break;
          case 3:
            this.setState({
              imageUrl4: value.url,
            });
            break;
          case 4:
            this.setState({
              imageUrl5: value.url,
            });
            break;
          default:
            break;
        }

        break;
      case "coverImageIndex":
        this.changeCoverIndex(value);
        break;
      case "map":
        this.setState({
          yardSaleLocation: value,
        });
        break;
      //default value...
      default:
        break;
    }
  };

  changeCoverIndex = (value) => {
    this.setState({
      coverImageIndex: 0,
    });

    var imageUrl1 = this.state.imageUrl1;
    var currentImage = this.state.currentImagesURL[value];
    var currentImage1 = this.state.currentImagesURL[0];
    var currentImagesURL = this.state.currentImagesURL;

    switch (value) {
      case 0:
        break;
      case 1:
        var imageUrl2 = this.state.imageUrl2;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          imageUrl2: imageUrl1,
          imageUrl1: imageUrl2,
        });

        break;
      case 2:
        var imageUrl3 = this.state.imageUrl3;

        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          imageUrl3: imageUrl1,
          imageUrl1: imageUrl3,
        });
        break;
      case 3:
        var imageUrl4 = this.state.imageUrl4;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          imageUrl4: imageUrl1,
          imageUrl1: imageUrl4,
        });
        break;
      case 4:
        var imageUrl5 = this.state.imageUrl5;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          imageUrl5: imageUrl1,
          imageUrl1: imageUrl5,
        });
        break;
      default:
        break;
    }
  };

  onHandleChangeImage = (type, value) => {
    this.handleChangeValue(type, value);
  };

  handleCountDate = () => {
    if (this.state.countDate < 3)
      this.setState({
        countDate: this.state.countDate + 1,
      });
  };

  handleChange = (event, type) => {
    if (type === "description")
      this.setState({
        description: event.target.value,
        isDescriptionNull: false,
      });
  };

  handleChooseDefaultYardSaleImage = (url) => {
    this.setState({
      imageUrl1: url,
    });
  };

  handleDeleteDefaultYardSaleImage = () => {
    this.setState({
      imageUrl1: null,
    });
  };

  handleChangeDate = (newDate, dateType) => {
    if (newDate !== null) {
      if (dateType === 1) {
        this.setState({
          yardSaleDate1: new Date(new Date(newDate).toDateString()).getTime(),
          isDateNull: false,
          isInvalidDate1: this.state.yardSaleDate2
            ? new Date(new Date(newDate).toDateString()).getTime() >
                new Date(
                  new Date(this.state.yardSaleDate2).toDateString()
                ).getTime() ||
              new Date(new Date(newDate).toDateString()).getTime() >
                new Date(
                  new Date(this.state.yardSaleDate3).toDateString()
                ).getTime()
              ? true
              : false
            : false,
        });
      }
      if (dateType === 2) {
        this.setState({
          yardSaleDate2: new Date(new Date(newDate).toDateString()).getTime(),
          isInvalidDate1:
            new Date(new Date(newDate).toDateString()).getTime() >
            new Date(
              new Date(this.state.yardSaleDate1).toDateString()
            ).getTime()
              ? false
              : true,
          isInvalidDate2: false,
        });
      }
      if (dateType === 3)
        this.setState({
          yardSaleDate3: new Date(new Date(newDate).toDateString()).getTime(),
          isInvalidDate1:
            new Date(new Date(newDate).toDateString()).getTime() >
            new Date(
              new Date(this.state.yardSaleDate1).toDateString()
            ).getTime()
              ? false
              : true,
          isInvalidDate2:
            new Date(new Date(newDate).toDateString()).getTime() >
            new Date(
              new Date(this.state.yardSaleDate2).toDateString()
            ).getTime()
              ? false
              : true,
        });
    }
  };

  handleChangeTime = (newTime, timeType) => {
    if (newTime !== null) {
      if (timeType === 1)
        this.setState({
          yardSaleStartTime: newTime.$d,
          isFirstTimeNull: false,
          isInvalidStartTime: false,
        });
      if (timeType === 2)
        this.setState({
          yardSaleEndTime: newTime.$d,
          isSecondTimeNull: false,
          isInvalidEndTime: false,
        });
    }
  };

  handleSubmit = () => {
    var count = 0;
    this.setState({
      isImageUrlNull: false,
    });
    if (!this.state.imageUrl1) {
      this.setState({
        isImageUrlNull: true,
      });
      count += 1;
    }
    if (!this.state.yardSaleDate1) {
      this.setState({
        isDateNull: true,
      });
      count += 1;
    }

    if (
      this.state.yardSaleDate2 &&
      !this.state.yardSaleDate3 &&
      this.state.yardSaleDate1 >= this.state.yardSaleDate2
    ) {
      this.setState({
        isInvalidDate1: true,
      });
      count += 1;
    }
    if (
      this.state.yardSaleDate2 &&
      this.state.yardSaleDate3 &&
      (this.state.yardSaleDate1 >= this.state.yardSaleDate2 ||
        this.state.yardSaleDate1 >= this.state.yardSaleDate3)
    ) {
      this.setState({
        isInvalidDate1: true,
      });
      count += 1;
    }
    if (
      this.state.yardSaleDate2 &&
      this.state.yardSaleDate3 &&
      this.state.yardSaleDate2 >= this.state.yardSaleDate3
    ) {
      this.setState({
        isInvalidDate2: true,
      });
      count += 1;
    }
    if (!this.state.yardSaleStartTime) {
      this.setState({
        isFirstTimeNull: true,
      });
      count += 1;
    }
    if (!this.state.yardSaleEndTime) {
      this.setState({
        isSecondTimeNull: true,
      });
      count += 1;
    }
    if (
      this.state.yardSaleEndTime &&
      this.state.yardSaleDate1 &&
      this.state.yardSaleDate1 ===
        new Date(new Date().toDateString()).getTime() &&
      (convertTo24HrsFormat(
        new Intl.DateTimeFormat("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(this.state.yardSaleStartTime)
      ) >
        convertTo24HrsFormat(
          new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(this.state.yardSaleEndTime)
        ) ||
        convertTo24HrsFormat(
          new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(this.state.yardSaleStartTime)
        ) <
          convertTo24HrsFormat(
            new Intl.DateTimeFormat("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(Date.now()))
          )) /*|| 
        convertTo24HrsFormat(
          new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(this.state.yardSaleStartTime)
        ) <
          convertTo24HrsFormat(
            new Intl.DateTimeFormat("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(Date.now()))
          )*/
    ) {
      this.setState({
        isInvalidStartTime: true,
      });
      count += 1;
    }
    if (
      this.state.yardSaleEndTime &&
      this.state.yardSaleDate1 &&
      this.state.yardSaleDate1 >
        new Date(new Date().toDateString()).getTime() &&
      convertTo24HrsFormat(
        new Intl.DateTimeFormat("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(this.state.yardSaleEndTime)
      ) <=
        convertTo24HrsFormat(
          new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(this.state.yardSaleStartTime)
        )
    ) {
      this.setState({
        isInvalidEndTime: true,
      });
      count += 1;
    }
    if (count === 0) {
      var date = this.state.yardSaleDate3
        ? new Date(this.state.yardSaleDate3).toLocaleDateString()
        : this.state.yardSaleDate2
        ? new Date(this.state.yardSaleDate2).toLocaleDateString()
        : new Date(this.state.yardSaleDate1).toLocaleDateString();
      const time = new Date(this.state.yardSaleEndTime).toTimeString();
      var timeAndDate = moment(date + " " + time);
      const yardSaleEndTimestamp = new Date(timeAndDate._d).getTime();
      var tags = [];
      tags.push("garage");
      tags.push("yard");
      if (this.props.type === "add") {
        this.setState({
          spinner: true,
        });
        const updateYardSale = {
          ...this.state.yardSaleLocation,
          category: this.props.yardSaleCategory,
          categoryId: this.props.yardSaleCategory.categoryId,
          condition: this.state.condition,
          conditionStatus: this.state.conditionStatus,
          currencyCode: this.state.currencyCode,
          description: isEmptyOrSpaces(this.state.description)
            ? this.state.description
            : null,
          firmOnPrice: this.state.firmOnPrice,
          imageUrl1: this.state.imageUrl1,
          imageUrl2: this.state.imageUrl2,
          imageUrl3: this.state.imageUrl3,
          imageUrl4: this.state.imageUrl4,
          imageUrl5: this.state.imageUrl5,
          isUsedProduct: this.state.isUsedProduct,
          isSoldOnBingoDeal: this.state.isSoldOnBingoDeal,
          impressions: this.state.impressions,
          clicks: this.state.clicks,
          boostTimestamp: this.state.boostTimestamp,
          isFoodItem: this.state.isFoodItem,
          isBoosted: this.state.isBoosted,
          isActive: this.state.isActive,
          price: this.state.price,
          platform: this.state.platform,
          rating: this.state.rating,
          seller: this.state.seller,
          sellerId: this.state.sellerId,
          tags: tags,
          title: this.state.title,
          yardSaleDate1: this.state.yardSaleDate1,
          yardSaleDate2: this.state.yardSaleDate2
            ? this.state.yardSaleDate2
            : null,
          yardSaleDate3: this.state.yardSaleDate3
            ? this.state.yardSaleDate3
            : null,
          yardSaleEndTime: new Date(this.state.yardSaleEndTime).getTime(),
          yardSaleEndTimestamp: yardSaleEndTimestamp,
          yardSaleStartTime: new Date(this.state.yardSaleStartTime).getTime(),
        };
        this.props.createProduct(updateYardSale);
      } else {
        this.setState({
          spinner: true,
        });

        const updateYardSale = {
          ...this.state.yardSaleLocation,
          category: this.props.yardSaleCategory,
          categoryId: this.props.yardSaleCategory.categoryId,
          condition: this.state.condition,
          conditionStatus: this.state.conditionStatus,
          currencyCode: this.state.currencyCode,
          description: isEmptyOrSpaces(this.state.description)
            ? this.state.description
            : null,
          firmOnPrice: this.state.firmOnPrice,
          imageUrl1: this.state.imageUrl1,
          imageUrl2: this.state.imageUrl2,
          imageUrl3: this.state.imageUrl3,
          imageUrl4: this.state.imageUrl4,
          imageUrl5: this.state.imageUrl5,
          isUsedProduct: this.state.isUsedProduct,
          isSoldOnBingoDeal: this.state.isSoldOnBingoDeal,
          impressions: this.state.impressions,
          id: this.state.productId,
          clicks: this.state.clicks,
          boostTimestamp: this.state.boostTimestamp,
          isFoodItem: this.state.isFoodItem,
          isBoosted: this.state.isBoosted,
          isActive: this.state.isActive,
          price: this.state.price,
          platform: this.state.platform,
          rating: this.state.rating,
          seller: this.state.seller,
          sellerId: this.state.sellerId,
          tags: tags,
          title: this.state.title,
          yardSaleDate1: this.state.yardSaleDate1,
          yardSaleDate2: this.state.yardSaleDate2
            ? this.state.yardSaleDate2
            : null,
          yardSaleDate3: this.state.yardSaleDate3
            ? this.state.yardSaleDate3
            : null,
          yardSaleEndTime: new Date(this.state.yardSaleEndTime).getTime(),
          yardSaleEndTimestamp: yardSaleEndTimestamp,
          yardSaleStartTime: new Date(this.state.yardSaleStartTime).getTime(),
        };
        this.props.editProduct(updateYardSale);
      }
    }
  };

  render() {
    const { classes, intl, handleClose, usedProductReducer } = this.props;
    if (usedProductReducer.productState === "CREATE_PRODUCT") {
      if (this.props.type === "add")
        window.location.href =
          constantsConfig.mainUrl +
          "product/" +
          usedProductReducer.productId +
          "/Open/BoostProduct";
      else
        window.location.href =
          constantsConfig.mainUrl + "product/" + this.state.productId;
    }
    return (
      <React.Fragment>
        <div className={classes.dialogsBackground}>
          <CloseIcon className={classes.xIcon} onClick={handleClose} />
        </div>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {this.props.type === "add"
            ? intl.formatMessage({
                id: "add_yard_sale",
              })
            : intl.formatMessage({
                id: "edit_yard_sale",
              })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              <MultipleImageUpload
                isYardSale={true}
                tags={this.state.tags}
                handleRemoveTags={this.handleRemoveTags}
                onHandleChangeImage={this.onHandleChangeImage}
                currentImagesURL={this.state.currentImagesURL}
                coverImageIndex={this.state.coverImageIndex}
                handleChooseDefaultYardSaleImage={
                  this.handleChooseDefaultYardSaleImage
                }
                handleDeleteDefaultYardSaleImage={
                  this.handleDeleteDefaultYardSaleImage
                }
              />
              {this.state.isImageUrlNull ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "upload_a_photo",
                  })}
                </Typography>
              ) : null}
            </Grid>
            <Grid item className={classes.gridItem}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Yard Sale First Date"
                  openTo="day"
                  minDate={Date.now()}
                  views={["year", "month", "day"]}
                  value={this.state.yardSaleDate1}
                  onChange={(newDate) => this.handleChangeDate(newDate, 1)}
                  renderInput={(params) => (
                    <TextField required {...params} style={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
              {this.state.isDateNull ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "invalid_date1",
                  })}
                </Typography>
              ) : null}
              {this.state.isInvalidDate1 ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "invalid_first_date",
                  })}
                </Typography>
              ) : null}
            </Grid>
            {this.state.countDate > 1 && this.state.countDate <= 3 ? (
              <Grid item className={classes.gridItem}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Yard Sale Second Date"
                    openTo="day"
                    minDate={this.state.yardSaleDate1}
                    views={["year", "month", "day"]}
                    value={
                      this.state.yardSaleDate2 ? this.state.yardSaleDate2 : null
                    }
                    onChange={(newDate) => this.handleChangeDate(newDate, 2)}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {this.state.isInvalidDate2 ? (
                  <Typography
                    variant="subtitle1"
                    className={classes.errImageMessageTypography}
                  >
                    {intl.formatMessage({
                      id: "invalid_second_date",
                    })}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
            {this.state.countDate > 2 ? (
              <Grid item className={classes.gridItem}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Yard Sale Third Date"
                    openTo="day"
                    minDate={this.state.yardSaleDate2}
                    views={["year", "month", "day"]}
                    value={
                      this.state.yardSaleDate3 ? this.state.yardSaleDate3 : null
                    }
                    onChange={(newDate) => this.handleChangeDate(newDate, 3)}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}
            {(this.state.countDate === 1 && this.state.yardSaleDate1) ||
            (this.state.countDate === 2 && this.state.yardSaleDate2) ? (
              <Grid item className={classes.gridItem}>
                <Icon
                  color="primary"
                  className={classes.plusIcon}
                  onClick={this.handleCountDate}
                >
                  add_circle
                </Icon>
              </Grid>
            ) : null}
            <Grid item className={classes.gridItem}>
              <LocalizationProviderTime dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Start Time"
                  value={this.state.yardSaleStartTime}
                  onChange={(newTime) => {
                    this.handleChangeTime(newTime, 1);
                  }}
                  minTime={
                    this.state.yardSaleDate1 &&
                    new Date(this.state.yardSaleDate1) > new Date(Date.now())
                      ? null
                      : dayjs(new Date(Date.now()))
                  }
                  renderInput={(params) => (
                    <TextField required {...params} style={{ width: "100%" }} />
                  )}
                />
              </LocalizationProviderTime>
              {this.state.isFirstTimeNull || this.state.isInvalidStartTime ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "invalid_start_time",
                  })}
                </Typography>
              ) : null}
            </Grid>
            <Grid item className={classes.gridItem}>
              <LocalizationProviderTime dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="End Time"
                  value={this.state.yardSaleEndTime}
                  minTime={
                    this.state.yardSaleDate1 &&
                    new Date(this.state.yardSaleDate1) > new Date(Date.now())
                      ? null
                      : dayjs(this.state.yardSaleStartTime)
                      ? dayjs(new Date(this.state.yardSaleStartTime))
                      : dayjs(new Date(Date.now()))
                  }
                  onChange={(newTime) => {
                    this.handleChangeTime(newTime, 2);
                  }}
                  renderInput={(params) => (
                    <TextField
                      error
                      required
                      {...params}
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </LocalizationProviderTime>
              {this.state.isInvalidEndTime ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "invalid_end_time",
                  })}
                </Typography>
              ) : null}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="description"
                label={intl.formatMessage({
                  id: "product_description",
                })}
                multiline
                rows={4}
                className={classes.textField}
                onChange={(e) => this.handleChange(e, "description")}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              <MapComponent
                height="200px"
                zoom={15}
                onHandleChangeValue={this.handleChangeValue}
                addProductData={
                  this.props.type === "add"
                    ? this.props.locationConfig
                    : this.state.yardSaleLocation
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {!this.state.spinner ? (
            <Button
              className={classes.postButton}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              {this.props.type === "add"
                ? intl.formatMessage({
                    id: "product_post",
                  })
                : intl.formatMessage({
                    id: "edit",
                  })}
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    usedProductReducer: state.ProductReducer,
    yardSaleCategory: state.firestore.ordered.yardSaleCategory
      ? state.firestore.ordered.yardSaleCategory[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
    editProduct: (product) => dispatch(editProduct(product)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
      return [
        {
          collection: constantsConfig.collectionCategories,
          where: ["categoryRanking", "==", 1300],
          storeAs: "yardSaleCategory",
        },
      ];
    })
  )(AddYardSaleEvent)
);
