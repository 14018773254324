import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { compose } from "redux";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { deleteUsedProduct } from "../../store/product/DeleteUsedProduct";
import { injectIntl } from "react-intl";
import withRouter from "../hooks/WithRouter";

const styles = (theme) => ({
  containerDialog: {
    backgroundColor: theme.palette.primary.background,
  },
  flexContentBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "100%",
  },
  wrapper: {
    width: "100%",

    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class RemoveAllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      products: props.products,
      numProducts: props.products.length,
    };
  }
  handleRemoveAllProducts = () => {
    this.setState({
      spinner: true,
    });
    for (let i = 0; i < this.state.products.length; i++) {
      deleteUsedProduct({
        product: this.state.products[i],
        isSoldOnBingoDeal: false,
      })
        .then((response) => {
          if (i === this.state.products.length - 1) {
            this.props.router.navigate("/");
          }
        })
        .catch((err) => {});
    }
  };
  render() {
    const { intl, classes, handleCloseRemoveAllProducts } = this.props;
    return (
      <div className={classes.containerDialog}>
        <div className={classes.flexContentBetween}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {intl.formatMessage({
              id: "remove_all",
            })}{" "}
            ({this.state.numProducts}){" "}
            {intl.formatMessage({
              id: "products",
            })}
            {intl.formatMessage({
              id: "symbols.question",
            })}
          </DialogTitle>
        </div>

        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={this.handleRemoveAllProducts}
              className={classes.button}
            >
              {intl.formatMessage({
                id: "yes",
              })}
            </Button>
            {this.state.spinner === true ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : null}
          </div>
          <Button
            onClick={handleCloseRemoveAllProducts}
            className={classes.button}
          >
            {intl.formatMessage({
              id: "no",
            })}
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default withRouter(
  injectIntl(
    compose(
      withStyles(styles),
      connect(mapStateToProps, mapDispatchToProps)
    )(RemoveAllProducts)
  )
);
