// @flow
import React, { Component } from 'react';
import { FormControl } from '@mui/material';
import MultipleImageUpload from './images/MultipleImageUpload';
import TagsComponent from './tags/TagsComponent';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import visionGoogle from './tags/GoogleVision';

class FormUserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.addProductData.productInformation.title,
      tags: props.addProductData.productInformation.tags,
      firstTime: true
    };
    this.onHandleChangeTitle = this.onHandleChangeTitle.bind(this);
    this.onHandleChangeImage = this.onHandleChangeImage.bind(this);
  }
  componentDidMount() {
    if (this.state.firstTime) {
      // analytics.logEvent("view_item", {});
      this.setState({ firstTime: false });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      tags: nextProps.addProductData.productInformation.tags
    });
  }
  onHandleChangeTitle(event) {
    this.props.onHandleChangeValue('title', event.target.value);
  }
  onHandleTags = (resultTags) => {
    var tags = this.state.tags;
    var tagsArray = resultTags[0].labelAnnotations;
    for (let i = 0; i < tagsArray.length; i++) {
      if (!tags.includes(tagsArray[i].description))
        tags.push(tagsArray[i].description);
    }
    this.props.onHandleChangeValue('tags', tags);
    this.setState({ tags: tags });
  };
  onHandleChangeImage(type, value) {
    if (type === 'image') {
      visionGoogle(value.url, this.onHandleTags);
    }

    this.props.onHandleChangeValue(type, value);
  }

  render() {
    const { intl, isTitleNull, handleRemoveTags } = this.props;
    return (
      <div>
        <MultipleImageUpload
          handleRemoveTags={handleRemoveTags}
          onHandleChangeImage={this.onHandleChangeImage}
          currentImagesURL={this.props.addProductData.currentImagesURL}
          coverImageIndex={this.props.addProductData.coverImageIndex}
        />

        <TagsComponent tags={this.state.tags} />

        <FormControl fullWidth>
          {/*<InputLabel htmlFor="standard-adornment-amount">
            {intl.formatMessage({
              id: "product_name",
            })}
          </InputLabel>*/}
          {isTitleNull === false ? (
            <TextField
              label={intl.formatMessage({
                id: 'product_title_hint'
              })}
              margin="normal"
              variant="standard"
              // id="displayName"
              onChange={this.onHandleChangeTitle}
              defaultValue={this.state.title}
              //className={classes.FormControl}
            />
          ) : (
            <TextField
              error
              label={intl.formatMessage({
                id: 'product_title_hint'
              })}
              margin="normal"
              variant="standard"
              // id="displayName"
              onChange={this.onHandleChangeTitle}
              defaultValue={this.state.title}
              //className={classes.FormControl}
            />
          )}
          {/*<Input
            error={true}
            key="title-input"
            placeholder={intl.formatMessage({
              id: "product_title_hint",
            })}
            onChange={this.onHandleChangeTitle}
            defaultValue={this.state.title}
          />*/}
        </FormControl>
      </div>
    );
  }
}

export default injectIntl(FormUserDetails);
