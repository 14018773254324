// @flow
import React, { Component } from 'react';
import { Typography, Slider, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import SimpleDialogDemo from '../other/CategoryDialog';
import { injectIntl } from 'react-intl';
import { constantsConfig } from '../../config/ConstantsConfig';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
  flexContentBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

function translateConditionStatus(condition, intl) {
  if (condition === 0)
    return intl.formatMessage({
      id: 'condition_other'
    });
  if (condition === 1)
    return intl.formatMessage({
      id: 'condition_for_parts'
    });
  if (condition === 2)
    return intl.formatMessage({
      id: 'condition_used'
    });
  if (condition === 3)
    return intl.formatMessage({
      id: 'condition_open_box'
    });
  if (condition === 4)
    return intl.formatMessage({
      id: 'condition_reconditioned'
    });
  if (condition === 5)
    return intl.formatMessage({
      id: 'condition_new'
    });
}

//details step component...
class DetailsForm extends Component {
  /**
   * constructer of component...
   *
   * @param props
   */
  constructor(props) {
    super(props);
    //init state...
    this.state = {
      description: props.addProductData.description,
      condition: props.addProductData.condition,
      conditionBegin: props.addProductData.condition,
      conditionStatus: props.addProductData.conditionStatus,
      categoryTitle: props.addProductData.category.categoryTitle,
      categoryRanking: props.addProductData.category.categoryRanking,
      categoryId: props.addProductData.category.categoryId,
      firstTime: true
    };
    this.onHandleChangeDescription = this.onHandleChangeDescription.bind(this);
    this.onHandleChangeCondition = this.onHandleChangeCondition.bind(this);
    this.onHandleChangeCategory = this.onHandleChangeCategory.bind(this);
  }
  componentDidMount() {
    if (this.state.firstTime) {
      /* analytics.logEvent("view_item", {
        product_name: this.props.addProductData.title,
      });*/
      this.setState({ firstTime: false });
    }
  }

  /**
   * Change Description and update state in main component (add product component)...
   *
   * @param event content the value of description
   */
  onHandleChangeDescription(event) {
    this.props.onHandleChangeValue('description', event.target.value);
  }

  /**
   * Change condition and update state in main component (add product component)...
   *
   * @param event
   * @param value content the value of condition
   */
  onHandleChangeCondition(event, value) {
    const conditionTemp = {
      condition: value,
      conditionStatus: this.conditionsStatus[value]
    };
    this.setState({
      condition: conditionTemp.condition,
      conditionStatus: conditionTemp.conditionStatus
    });
    this.props.onHandleChangeValue('condition', conditionTemp);
  }
  /**
   * Change Category and update state in main component (add product component)...
   *
   * @param tile content the value of Category
   */
  onHandleChangeCategory(tile) {
    this.setState({
      categoryId: tile.categoryId,
      categoryRanking: tile.categoryRanking
    });
    this.props.onHandleChangeValue('category', tile);
  }

  //data of condition
  conditionsStatus = constantsConfig.conditionsStatus;

  //text area style for discription
  textAreaStyle = {
    maxHeight: '75px',
    minHeight: '38px',
    resize: 'none',
    padding: '9px',
    boxSizing: 'border-box',
    fontSize: '15px',
    width: '100%'
  };

  render() {
    //prjects from props from firestore...
    const { projects, intl, classes, isDescriptionNull, isCategoryNull } =
      this.props;
    const selectedCondition = translateConditionStatus(
      this.state.condition,
      intl
    );
    return (
      <React.Fragment>
        <div>
          <SimpleDialogDemo
            projects={projects}
            isCategoryNull={isCategoryNull}
            onHandleChangeCategory={this.onHandleChangeCategory}
            categoryTitle={this.state.categoryTitle}
            categoryRanking={this.state.categoryRanking}
            categoryId={this.state.categoryId}
          />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography id="discrete-slider" gutterBottom variant="subtitle2">
              {intl.formatMessage({
                id: 'product_condition'
              })}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Slider
              defaultValue={this.state.condition}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={5}
              onChange={this.onHandleChangeCondition}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="subtitle2">{selectedCondition}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.flexContentBetween}>
              <Typography variant="subtitle2">
                {intl.formatMessage({
                  id: 'product_description'
                })}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isDescriptionNull === false ? (
              <TextField
                id="outlined-helperText"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'product_description'
                })}
                defaultValue={this.state.description}
                // variant="body1"
                style={this.textAreaStyle}
                onChangeCapture={this.onHandleChangeDescription}
              />
            ) : (
              <TextField
                error
                id="outlined-helperText"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'product_description'
                })}
                defaultValue={this.state.description}
                style={this.textAreaStyle}
                onChangeCapture={this.onHandleChangeDescription}
              />
            )}
            {/* <TextareaAutosize
              placeholder={intl.formatMessage({
                id: "product_description",
              })}
              style={this.textAreaStyle}
              onChangeCapture={this.onHandleChangeDescription}
              defaultValue={this.state.description}
            />*/}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

/**
 * map colletion categories..
 *
 * @param state
 */
const mapStateToProps = (state) => {
  const projects = state.firestore.data.categories;
  return {
    projects: projects
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps),
    firestoreConnect([
      {
        collection: constantsConfig.collectionCategories,
        where: [
          ['isMainCategory', '==', true],
          ['isActive', '==', true]
        ],
        orderBy: ['categoryRanking', 'asc']
      }
    ])
  )(DetailsForm)
);
