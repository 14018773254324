// @flow
import authReducers from '../auth/AuthReducers';
import usedProductReducer from '../product/ProductReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import messagesReducer from '../messages/NewMessagesReducers';
import reportReducer from '../report/ReportReducer';
import locationReducer from '../location/NewLocationReducer';
import EventsReducers from '../actions/EventsReducers';
import SearchReducers from '../search/SearchReducers';
import BoardingScreenReducer from '../onboarding/BoardingScreenReducer';
import ratingReducer from '../rating/RatingReducer';
import ProductReducer from '../product/ProductReducer';
import PaymentsReducer from '../payments/PaymentsReducer';
import notificationsReducer from '../notifications/NotificationsReducer';
const rootReducer = combineReducers({
  locationReducer: locationReducer,
  reports: reportReducer,
  messages: messagesReducer,
  auth: authReducers,
  ProductReducer: ProductReducer,
  usedProductReducer: usedProductReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  events: EventsReducers,
  SearchReducers: SearchReducers,
  BoardingScreenReducer: BoardingScreenReducer,
  ratingReducer: ratingReducer,
  PaymentsReducer: PaymentsReducer,
  NotificationsReducer: notificationsReducer
});

export default rootReducer;
