// @flow
import { useState } from "react";
import React from "react";
// import { FaStar } from "react-icons/fa";
import {
  Button,
  Typography,
  Avatar,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import { injectIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import Rating from "@mui/material/Rating";

const useStyles = makeStyles((theme) => ({
  ratingToUserPhoto: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  ratingQ: {
    // fontWeight: 'bold !important',
    color: theme.palette.primary.main,
    marginBottom: "10px !important",
    marginLeft: "10px !important",
    marginRight: "10px !important",
  },
  allStars: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  star: {
    cursor: "pointer",
    transition: "color 200ms",
  },
  ratingOptions: {
    ...theme.typography.normalButton,
    width: "100%",
    fontSize: "1rem",
    borderColor: theme.palette.primary.main,
    borderWidth: "0.3px !important",
    borderStyle: "solid !important",
    marginBottom: "10px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.icons,
    },
  },

  ratingOptions1: {
    ...theme.typography.normalButton,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.icons,
    fontSize: "1rem",
    borderColor: theme.palette.primary.main,
    borderWidth: "0.3px !important",
    borderStyle: "solid !important",
    marginBottom: "10px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.icons,
    },
  },
  containerRatingButtons: {
    marginBottom: "40px !important",
  },
  submitRatingBefore: {
    width: "100%",
    backgroundColor: theme.palette.primary.secondaryText,
    color: theme.palette.primary.icons,
    textTransform: "none !important",
    height: "40px !important",
    fontWeight: "normal !important",
    marginBottom: "10px !important",
  },
  submitRating: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.icons,
    textTransform: "none !important",
    height: "40px !important",
    fontWeight: "normal !important",
    marginBottom: "10px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.icons,
    },
  },
  flexContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexContentBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexSpaceBetween: {
    display: "flex",
    width: "100%",
    justifySpace: "space-between",
  },
  test: {
    color: "#1976d2",
  },
  test1: {
    color: "#e4e5e9",
  },
}));
const StarRating = (props) => {
  const [rating, setRating] = useState(null);
  // const [hover, setHover] = useState(null);
  const [Other, setOther] = useState(false);
  const [Other1, setOther1] = useState(false);
  const [details, setDetails] = useState("");
  const [reviews, setReviews] = useState([]);
  const [col, setcol] = useState(false);
  const [col1, setcol1] = useState(false);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [coll, setcoll] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col9, setcol9] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const textAreaStyle = {
    maxHeight: "75px",
    minHeight: "38px",
    resize: "none",

    boxSizing: "border-box",
    fontSize: "15px",
    width: "100%",
    marginBottom: "20px",
  };

  const handleOther = () => {
    setOther(true);
  };
  const handleOther1 = () => {
    setOther1(true);
  };
  const handlerating = (e) => {
    props.handleSubmitRating(
      rating,
      rating <= 3 ? false : true,
      details,
      reviews
    );
  };
  const onHandleChangeDetails = (e) => {
    setDetails(e.target.value);
  };
  const handleRatingType = (e) => {
    e.preventDefault();
    // setEnableSubmit(true);
    if (e.currentTarget.value === "Late") {
      setcol(true);
    }
    if (e.currentTarget.value === "Unreliable") {
      setcol1(true);
    }
    if (e.currentTarget.value === "Uncommunicative") {
      setcol2(true);
    }
    if (e.currentTarget.value === "Impolite") {
      setcol3(true);
    }
    if (e.currentTarget.value === "Item not as described") {
      setcol4(true);
    }

    if (e.currentTarget.value === "OnTime") {
      setcoll(true);
    }
    if (e.currentTarget.value === "Reliable") {
      setcol6(true);
    }
    if (e.currentTarget.value === "Communicative") {
      setcol7(true);
    }
    if (e.currentTarget.value === "Friendly") {
      setcol8(true);
    }
    if (e.currentTarget.value === "Item as described") {
      setcol9(true);
    }

    const review = {
      reviewTypeName: e.currentTarget.value,
    };
    reviews.push(review);
    setReviews(reviews);
  };
  const classes = useStyles();
  return (
    <div>
      <div className={classes.flexContentCenter}>
        <Avatar
          src={props.ratingToUserPhoto}
          alt={props.ratingToUserName}
          className={classes.ratingToUserPhoto}
        ></Avatar>
      </div>
      <Typography variant="h6" className={classes.ratingQ}>
        {props.intl.formatMessage({
          id: "rate_review_question",
        })}{" "}
        {props.ratingToUserName}{" "}
        {props.intl.formatMessage({
          id: "again",
        })}
      </Typography>
      <div className={classes.allStars}>
        <Rating
          name="simple-controlled"
          size="large"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
            setEnableSubmit(true);
          }}
          sx={{ color: (theme) => theme.palette.primary.main }}
        />
      </div>
      <br /> <br />
      {rating <= 3 && rating >= 1 ? (
        <div style={{ marginLeft: "0px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={
                  col === false ? classes.ratingOptions : classes.ratingOptions1
                }
                value="Late"
                onClick={handleRatingType}
              >
                {props.intl.formatMessage({
                  id: "ontime_negative",
                })}
              </Button>
            </Grid>{" "}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={
                  col1 === false
                    ? classes.ratingOptions
                    : classes.ratingOptions1
                }
                onClick={handleRatingType}
                value="Unreliable"
              >
                {props.intl.formatMessage({
                  id: "reliable_negative",
                })}
              </Button>
            </Grid>
          </Grid>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                  className={
                    col2 === false
                      ? classes.ratingOptions
                      : classes.ratingOptions1
                  }
                  onClick={handleRatingType}
                  value="Uncommunicative"
                >
                  {props.intl.formatMessage({
                    id: "communicative_negative",
                  })}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                  className={
                    col3 === false
                      ? classes.ratingOptions
                      : classes.ratingOptions1
                  }
                  onClick={handleRatingType}
                  value="Impolite"
                >
                  {props.intl.formatMessage({
                    id: "friendly_negative",
                  })}
                </Button>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container>
              <Button
                className={
                  col4 === false
                    ? classes.ratingOptions
                    : classes.ratingOptions1
                }
                onClick={handleRatingType}
                value="Item not as described"
              >
                {props.intl.formatMessage({
                  id: "itemasdescribed_negative",
                })}
              </Button>
            </Grid>
            <Grid container>
              <Button
                className={
                  Other === false
                    ? classes.ratingOptions
                    : classes.ratingOptions1
                }
                onClick={handleOther}
                value="Other"
              >
                {props.intl.formatMessage({
                  id: "product_other",
                })}
              </Button>
            </Grid>
          </div>
        </div>
      ) : rating > 3 ? (
        <div style={{ marginLeft: "0px" }}>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                  className={
                    coll === false
                      ? classes.ratingOptions
                      : classes.ratingOptions1
                  }
                  onClick={handleRatingType}
                  value="OnTime"
                >
                  {props.intl.formatMessage({
                    id: "ontime_postive",
                  })}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                  className={
                    col6 === false
                      ? classes.ratingOptions
                      : classes.ratingOptions1
                  }
                  onClick={handleRatingType}
                  value="Reliable"
                >
                  {props.intl.formatMessage({
                    id: "reliable_postive",
                  })}
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                  className={
                    col7 === false
                      ? classes.ratingOptions
                      : classes.ratingOptions1
                  }
                  onClick={handleRatingType}
                  value="Communicative"
                >
                  {props.intl.formatMessage({
                    id: "communicative_postive",
                  })}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                  className={
                    col8 === false
                      ? classes.ratingOptions
                      : classes.ratingOptions1
                  }
                  onClick={handleRatingType}
                  value="Friendly"
                >
                  {props.intl.formatMessage({
                    id: "friendly_postive",
                  })}
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Button
                className={
                  col9 === false
                    ? classes.ratingOptions
                    : classes.ratingOptions1
                }
                onClick={handleRatingType}
                value="Item as described"
              >
                {props.intl.formatMessage({
                  id: "itemasdescribed_postive",
                })}
              </Button>
            </Grid>
            <Grid container>
              <Button
                className={
                  Other1 === false
                    ? classes.ratingOptions
                    : classes.ratingOptions1
                }
                onClick={handleOther1}
                value="Other"
              >
                {props.intl.formatMessage({
                  id: "product_other",
                })}
              </Button>
            </Grid>
          </div>
        </div>
      ) : null}
      {Other && rating <= 3 ? (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextareaAutosize
            placeholder={props.intl.formatMessage({
              id: "type_your_review",
            })}
            style={textAreaStyle}
            onChangeCapture={onHandleChangeDetails}
            // defaultValue={this.state.description}
          />
        </Grid>
      ) : null}
      {Other1 && rating > 3 ? (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextareaAutosize
            placeholder={"Type your review"}
            style={textAreaStyle}
            onChangeCapture={onHandleChangeDetails}
            // defaultValue={this.state.description}
          />
        </Grid>
      ) : null}
      <div align="center">
        {" "}
        <Button
          className={
            !enableSubmit ? classes.submitRatingBefore : classes.submitRating
          }
          style={{ fontSize: 18 }}
          color="primary"
          onClick={handlerating}
          disabled={!enableSubmit}
        >
          {props.intl.formatMessage({
            id: "submit",
          })}
        </Button>
      </div>
    </div>
  );
};
export default injectIntl(StarRating);
