// @flow
import React, { Component } from 'react';
import {
  Button,
  Dialog,
  List,
  ListItem,
  ListSubheader,
  TextareaAutosize,
  DialogTitle,
  MenuItem
} from '@mui/material';
import { connect } from 'react-redux';
import { createReport } from '../../../store/report/ReportActions';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
  secondPage: { margin: '5px' },
  textAreaStyle: {
    maxHeight: '300px',
    minHeight: '200px',
    resize: 'none',
    padding: '9px',
    boxSizing: 'border-box',
    fontSize: '15px',
    width: '100%',
    borderRadius: '5px'
  },
  sendButton: {
    width: '100%'
  },
  dialogContainer: {
    backgroundColor: theme.palette.primary.background
  }
});

function translateReportType(reportType, intl) {
  if (reportType === 'They missed our meeting') {
    return intl.formatMessage({
      id: 'missed_meeting'
    });
  }
  if (reportType === 'Made a low offer') {
    return intl.formatMessage({
      id: 'low_offer'
    });
  }
  if (reportType === 'Rude or inappropriate') {
    return intl.formatMessage({
      id: 'rude'
    });
  }
  if (reportType === 'Problem with items') {
    return intl.formatMessage({
      id: 'items'
    });
  }
  if (reportType === 'Trouble at meet up') {
    return intl.formatMessage({
      id: 'trouble_meetup'
    });
  }
  if (reportType === 'Messaging problems') {
    return intl.formatMessage({
      id: 'messaging'
    });
  }
  if (reportType === 'Other') {
    return intl.formatMessage({
      id: 'user_other'
    });
  }
}

class ReportUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstPage: true,
      ReportDialog: false,
      selectItem: null,
      currenttext: ''
    };
  }

  reportType = [
    {
      ReportTypeId: 'u4DMEHtQaKsR5cXXTg7U',
      ReportType: 'They missed our meeting'
    },
    {
      ReportTypeId: '9uHx2O52vaxav3Cis5aO',
      ReportType: 'Made a low offer'
    },
    {
      ReportTypeId: 'SMYYZEHSMCjVJKi4bAsS',
      ReportType: 'Rude or inappropriate'
    },
    { ReportTypeId: 'Wc8FCSSfaeEyPaAcYHVk', ReportType: 'Problem with items' },
    { ReportTypeId: 'TuAFdWUc18n39A5ZZVHf', ReportType: 'Trouble at meet up' },
    { ReportTypeId: 'QchA7OuUDrvfYdXOrPFX', ReportType: 'Messaging problems' },
    { ReportTypeId: 'IaqDl2548rJUIaF1c7kF', ReportType: 'Other' }
  ];
  openReportDialog = () => {
    this.setState({ ReportDialog: true });
  };
  ListItemHandle = (item) => {
    this.setState({ firstPage: false, selectItem: item });
  };

  onChangeText = (text) => {
    this.setState({
      currenttext: text.target.value
    });
  };

  sendHandle = () => {
    const { auth, createReport, thread } = this.props;
    var subjectId, subjectName;
    if (thread.senderId === auth.uid) {
      subjectId = thread.receiverId;
      subjectName = thread.receiverName;
    } else {
      subjectId = thread.senderId;
      subjectName = thread.senderName;
    }
    createReport({
      details: this.state.currenttext,
      reportName: 'User',
      reportType: 2,
      reportTypeId: this.state.selectItem.ReportTypeId,
      reportTypeName: this.state.selectItem.ReportType,
      subjectId: subjectId,
      subjectName: subjectName,
      reporterId: auth.uid,
      threadId: this.props.thread.threadId
    });
    this.setState({
      firstPage: true,
      ReportDialog: false,
      selectItem: null,
      currenttext: ''
    });
  };
  render() {
    const { classes, intl } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.ReportDialog}
          onClose={() =>
            this.setState({
              firstPage: true,
              ReportDialog: false,
              selectItem: null,
              currenttext: ''
            })
          }
        >
          <div className={classes.dialogContainer}>
            <DialogTitle id="simple-dialog-title">
              {intl.formatMessage({
                id: 'report'
              })}
            </DialogTitle>
            {this.state.firstPage ? (
              <List
                component="div"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.primary.background
                    }}
                  >
                    {intl.formatMessage({
                      id: 'why_user'
                    })}
                  </ListSubheader>
                }
                // className={classes.root}
              >
                {this.reportType &&
                  this.reportType.map((item, index) => {
                    const reportType = translateReportType(
                      item.ReportType,
                      intl
                    );
                    return (
                      <ListItem key={index}>
                        <Button onClick={() => this.ListItemHandle(item)}>
                          {reportType}
                        </Button>
                      </ListItem>
                    );
                  })}
              </List>
            ) : (
              <div className={classes.secondPage}>
                <TextareaAutosize
                  className={classes.textAreaStyle}
                  onChange={this.onChangeText}
                />
                <Button
                  className={classes.sendButton}
                  color="primary"
                  onClick={this.sendHandle}
                >
                  {intl.formatMessage({
                    id: 'send_button_label'
                  })}
                </Button>
              </div>
            )}
          </div>
        </Dialog>
        <MenuItem onClick={this.openReportDialog}>
          {' '}
          {intl.formatMessage({
            id: 'report'
          })}
        </MenuItem>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createReport: (report) => dispatch(createReport(report))
  };
};

const ReportRender = injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, { withTheme: true })
  )(ReportUserComponent)
);

export default React.forwardRef((props, ref) => (
  <ReportRender innerRef={ref} {...props} />
));
