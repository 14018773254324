// @flow
import is from "is_js";
import { constantsConfig } from "../../config/ConstantsConfig";
import { getAllInfoByISO } from "iso-country-currency";
import { getMessaging, getToken } from "firebase/messaging";
import { vapidKey } from "../../config/FirebaseConfig";
// import axios from "axios";
const messaging = getMessaging();

export const signIn = (credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch({ type: "LOADING_SIGN_IN" });
    var signInFirebaseFunction = firebase.functions().httpsCallable("signIn");
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(async (resp) => {
        if (!is.ie() && !is.safari()) {
          await getToken(messaging, {
            vapidKey: vapidKey,
          })
            .then((token) => {
              signInFirebaseFunction(
                JSON.stringify({
                  id: resp.user.uid,
                  platform: "web",
                  token: token,
                })
              )
                .then(() => {
                  dispatch({ type: "LOGIN_SUCCESS" });
                  firestore
                    .collection(constantsConfig.collectionUsersStatus)
                    .doc(resp.user.uid)
                    .set({
                      id: resp.user.uid,
                      lastSeenTimestamp: Date.now(),
                    })
                    .then(() => {
                      window.location.reload();
                      dispatch({ type: "LOGIN_SUCCESS" });
                    })
                    .catch((err) => {
                      dispatch({ type: "LOGIN_ERROR", err });
                    });
                })
                .catch((err) => {
                  dispatch(signOut());
                  dispatch({ type: "LOGIN_ERROR", err });
                });
            })
            .catch((err) => {
              dispatch({ type: "LOGIN_ERROR", err });
            });
        }
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const forgotPassword = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .sendPasswordResetEmail(credentials)
      .then(() => {
        dispatch({ type: "RESET_PASSWORD_SUCCESS" });
      })
      .catch((error) => {
        dispatch({ type: "RESET_PASSWORD_ERROR", error });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const clearAuthError = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_AUTH_ERROR" });
  };
};

export const updateMessagingToken = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    await getToken(messaging, {
      vapidKey: vapidKey,
    })
      .then((token) => {
        firestore
          .collection("users")
          .doc(uid)
          .update({
            webMessagingToken: token,
            webMessagingTokenLastUpdate: new Date().getTime(),
            messagingToken: token,
            messagingTokenLastUpdate: new Date().getTime(),
            latestTokenPlatform: "web",
          })
          .then(() => {
            dispatch({ type: "UPDATE_MESSAGE_TOKEN_SUCCESS" });
          })
          .catch((err) => {
            dispatch({ type: "UPDATE_MESSAGE_TOKEN_ERROR" });
          });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_MESSAGE_TOKEN_ERROR" });
      });
    // const messaging = firebase.messaging();
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     return messaging.getToken();
    //   })
    //   .then((token) => {
    //     firestore
    //       .collection('users')
    //       .doc(uid)
    //       .update({
    //         webMessagingToken: token,
    //         webMessagingTokenLastUpdate: new Date().getTime(),
    //         messagingToken: token,
    //         messagingTokenLastUpdate: new Date().getTime(),
    //         latestTokenPlatform: 'web'
    //       })
    //       .then(() => {
    //         dispatch({ type: 'UPDATE_MESSAGE_TOKEN_SUCCESS' });
    //       })
    //       .catch((err) => {
    //         dispatch({ type: 'UPDATE_MESSAGE_TOKEN_ERROR' });
    //       });
    //   })
    //   .catch((err) => {
    //     dispatch({ type: 'UPDATE_MESSAGE_TOKEN_ERROR' });
    //   });
  };
};

export const signUp = (newUser, profile) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    var signUpFirebaseFunction = firebase.functions().httpsCallable("signUp");
    await dispatch({ type: "LOADING_AUTH" });
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(async (resp) => {
        if (!is.ie() && !is.safari()) {
          await getToken(messaging, {
            vapidKey: vapidKey,
          })
            .then((token) => {
              signUpFirebaseFunction(
                JSON.stringify({
                  id: resp.user.uid,
                  platform: "web",
                  token: token,
                  profile: profile,
                })
              )
                .then(() => {
                  firestore
                    .collection(constantsConfig.collectionUsersStatus)
                    .doc(resp.user.uid)
                    .set({
                      id: resp.user.uid,
                      lastSeenTimestamp: Date.now(),
                    })
                    .then(() => {
                      window.location.reload();
                      dispatch({ type: "SIGNUP_SUCCESS" });
                    })
                    .catch((err) => {
                      dispatch({ type: "SIGNUP_ERROR", err });
                    });
                })
                .catch((err) => {
                  dispatch({ type: "SIGNUP_ERROR", err });
                });
            })
            .catch((err) => {});
        } else {
          signUpFirebaseFunction(
            JSON.stringify({
              id: resp.user.uid,
              platform: "web",
              profile: profile,
            })
          )
            .then(() => {
              firestore
                .collection(constantsConfig.collectionUsersStatus)
                .doc(resp.user.uid)
                .set({
                  id: resp.user.uid,
                  lastSeenTimestamp: Date.now(),
                })
                .then(() => {
                  window.location.reload();
                  dispatch({ type: "SIGNUP_SUCCESS" });
                })
                .catch((err) => {
                  dispatch({ type: "SIGNUP_ERROR", err });
                });
            })
            .catch((err) => {
              dispatch({ type: "SIGNUP_ERROR", err });
            });
        }
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};
export const editLocationInfoWhenDisactivePermission = (location) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const auth = getState().firebase.auth;
    const autherId = auth.uid;
    const firestore = getFirestore();
    const response = {
      ...location,
      currency: getAllInfoByISO(location.bingoDealLocation.countryCode)
        .currency,
    };
    if (autherId) {
      firestore
        .collection("users")
        .doc(autherId)
        .get()
        .then((doc) => {
          const userDoc = doc.data();

          firestore
            .collection("users")
            .doc(autherId)
            .set({
              ...userDoc,
              ...location,
            })
            .then(() => {
              dispatch({ type: "LOACTION_ACCEPT", response });
            })
            .catch((err) => {
              dispatch({ type: "LOACTION_ERROR" });
            });
        })
        .catch((err) => {
          dispatch({ type: "LOACTION_ERROR" });
        });
    } else {
      dispatch({ type: "LOACTION_ACCEPT", response });
    }

    //then method execut when befor task complete
  };
};

export const editProfile = (creds) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const uid = getState().firebase.auth.uid;
    dispatch({ type: "LOADING_EDIT_PROFILE" });
    var editProfileFirebaseFunction = firebase
      .functions()
      .httpsCallable("editProfile");
    if (creds.bingoDealLocation)
      Object.keys(creds.bingoDealLocation).forEach((key) =>
        creds.bingoDealLocation[key] === undefined
          ? delete creds.bingoDealLocation[key]
          : {}
      );
    Object.keys(creds).forEach((key) =>
      creds[key] === undefined ? delete creds[key] : {}
    );
    editProfileFirebaseFunction(
      JSON.stringify({
        id: uid,
        platform: "web",
        profile: creds,
      })
    )
      .then(() => {
        let response;
        if (creds.bingoDealLocation)
          response = {
            ...creds.bingoDealLocation,
            currency: getAllInfoByISO(creds.bingoDealLocation.countryCode)
              .currency,
          };
        else
          response = {
            city: creds.city,
            country: creds.country,
            countryCode: creds.countryCode,
            readableLocation: creds.readableLocation,
            state: creds.state,
            zip: creds.zip,
            latitude: creds.latitude,
            longitude: creds.longitude,
            currency: getAllInfoByISO(creds.countryCode).currency,
          };
        dispatch({ type: "LOACTION_ACCEPT", response });
        dispatch({ type: "EDIT_PROFILE_SUCCESS" });
      })
      .catch((err) => {
        // An error happened.
        dispatch({ type: "EDIT_PROFILE_ERROR", err });
      });
  };
};

export const deleteProfile = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const functions = firebase.functions();
    dispatch({ type: "DELETE_PROFILE_LOADING" });
    var deleteAccount = functions.httpsCallable("deleteAccount");
    deleteAccount(
      JSON.stringify({
        id: userId,
      })
    )
      .then(() => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            dispatch({ type: "SIGNOUT_SUCCESS" });
            dispatch({ type: "DELETE_PROFILE_SUCCESS" });
          });
      })
      .catch((e) => {});

    //then method execut when befor task complete
  };
};

export const blockUser = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const functions = firebase.functions();
    dispatch({ type: "DELETE_PROFILE_LOADING" });
    var blockAccount = functions.httpsCallable("blockAccount");
    blockAccount(
      JSON.stringify({
        id: userId,
      })
    )
      .then(() => {
        dispatch({ type: "DELETE_PROFILE_SUCCESS" });
      })
      .catch((e) => {});

    //then method execut when befor task complete
  };
};

export const signInGoogle = (creds) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const functions = firebase.functions();
    var signInWithAccount = functions.httpsCallable("signInWithAccount");
    if (!is.ie() && !is.safari()) {
      getMessaging()
        .firebaseDependencies.installations.getToken()
        .then((token) => {
          dispatch({ type: "OPEN_BACKDROP" });
          signInWithAccount(
            JSON.stringify({
              id: creds.id,
              platform: "web",
              token: token,
              profile: creds,
            })
          )
            .then(() => {
              dispatch({ type: "LOGIN_SUCCESS" });
              firestore
                .collection(constantsConfig.collectionUsersStatus)
                .doc(creds.id)
                .set({
                  id: creds.id,
                  lastSeenTimestamp: Date.now(),
                })
                .then(() => {
                  window.location.reload();
                  dispatch({ type: "LOGIN_SUCCESS" });
                })
                .catch((err) => {
                  dispatch({ type: "LOGIN_ERROR", err });
                });
            })
            .catch((err) => {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  window.location.reload();
                  dispatch({ type: "SIGNOUT_SUCCESS" });
                });
              dispatch({ type: "LOGIN_ERROR", err });
            });
        })
        .catch(() => {
          signInWithAccount(
            JSON.stringify({
              id: creds.id,
              platform: "web",
              profile: creds,
            })
          )
            .then(() => {
              firestore
                .collection(constantsConfig.collectionUsersStatus)
                .doc(creds.id)
                .set({
                  id: creds.id,
                  lastSeenTimestamp: Date.now(),
                })
                .then(() => {
                  window.location.reload();
                  dispatch({ type: "LOGIN_SUCCESS" });
                })
                .catch((err) => {
                  dispatch({ type: "LOGIN_ERROR", err });
                });
            })
            .catch((err) => {
              dispatch({ type: "LOGIN_ERROR", err });
            });
        });
    } else {
      signInWithAccount(
        JSON.stringify({
          id: creds.id,
          platform: "web",
          profile: creds,
        })
      )
        .then(() => {
          firestore
            .collection(constantsConfig.collectionUsersStatus)
            .doc(creds.id)
            .set({
              id: creds.id,
              lastSeenTimestamp: Date.now(),
            })
            .then(() => {
              window.location.reload();
              dispatch({ type: "LOGIN_SUCCESS" });
            })
            .catch((err) => {
              dispatch({ type: "LOGIN_ERROR", err });
            });
        })
        .catch((err) => {
          dispatch({ type: "LOGIN_ERROR", err });
        });
    }
    //window.location.reload();
  };
};

export const deleteUserByAdmin = (uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var deleted = true;
    dispatch({ type: "DELETE_USER_SPINNER" });
    firestore
      .collection(constantsConfig.collectionUsers)
      .doc(uid)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_USER", deleted });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_USER_ERROR", err });
      });
    //then method execute when before task complete
  };
};

export const unblockUserByAdmin = (uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var deleted = true;
    firestore
      .collection("blocked_users")
      .doc(uid)
      .delete()
      .then(() => {
        dispatch({ type: "UNBLOCK_USER", deleted });
      })
      .catch((err) => {
        dispatch({ type: "UNBLOCK_USER_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const createBlockedUsers = (user, uId, blockTimestamp) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("blocked_users")
      .doc(uId)
      .set({
        ...user,
        blockTimestamp,
      })
      .then(() => {
        dispatch({ type: "CREATE_BLOCKED_USER" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_BLOCKED_USER_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const createUnBlockedUsers = (user, uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "UNBLOCK_USER_SPINNER" });
    firestore
      .collection(constantsConfig.collectionUsers)
      .doc(uid)
      .set({
        ...user,
      })
      .then(() => {
        dispatch({ type: "CREATE_UNBLOCKED_USER" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_UNBLOCKED_USER_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const Unsubscribe = (uId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("users_settings")
      .where("id", "==", uId)
      .get()
      .then(function (querySnapshot) {
        if (!querySnapshot.empty) {
          firestore
            .collection("users_settings")
            .doc(uId)
            .update({ isSubscribedToThreadCreationEmail: true, id: uId })
            .then(() => {
              dispatch({ type: "UNSUBSCRIBE_SUCCES" });
            })
            .catch((err) => {
              dispatch({ type: "UNSUBSCRIBE_ERROR", err });
            });
        } else {
          firestore
            .collection("users_settings")
            .doc(uId)
            .set({ isSubscribedToThreadCreationEmail: true, id: uId })
            .then(() => {
              dispatch({ type: "UNSUBSCRIBE_SUCCES" });
            })
            .catch((err) => {
              dispatch({ type: "UNSUBSCRIBE_ERROR", err });
            });
        }
      })
      .catch((err) => {
        dispatch({ type: "UNSUBSCRIBE_ERROR", err });
      });

    //then method execut when befor task complete
  };
};

export const resetReducerNotificationsDialog = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "RESET_EDIT_PROFILE_LOADING" });
  };
};

export const updateUserStatus = (uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionUsersStatus)
      .doc(uid)
      .set({
        id: uid,
        lastSeenTimestamp: Date.now(),
      })
      .then(() => {
        dispatch({ type: "UPDATE_USER_STATUS" });
        //
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_USER_STATUS_ERROR", err });
      });
  };
};

export const makeFollow = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "LOADING_FOLLOW" });
    firestore
      .collection(constantsConfig.collectionFollowers)
      .add({})
      .then((resp) => {
        firestore
          .collection(constantsConfig.collectionFollowers)
          .doc(resp.id)
          .set({
            ...data,
            followTimestamp: Date.now(),
          })
          .then(() => {
            dispatch({ type: "MAKE_FOLLOW" });
          })
          .catch((err) => {
            dispatch({ type: "MAKE_FOLLOW_ERROR", err });
          });
      })
      .catch((err) => {
        dispatch({ type: "MAKE_FOLLOW_ERROR", err });
      });
  };
};

export const makeUnFollow = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionFollowers)
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: "MAKE_UN_FOLLOW" });
      })
      .catch((err) => {
        dispatch({ type: "MAKE_UN_FOLLOW_ERROR", err });
      });
  };
};

export const deleteFollowingsAndFollowers = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionFollowers)
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_FOLLOWINGS_AND_FOLLOWERS" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_FOLLOWINGS_AND_FOLLOWERS_ERROR", err });
      });
  };
};
