import { analytics } from "../../config/FirebaseConfig";

export const purchaseLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 001_Google
    analytics.logEvent("purchase", {
      ITEM_ID: product.id,
      ITEM_NAME: product.title,
      PRICE: product.price,
      CURRENCY: product.currencyCode,
      ITEM_CATEGORY: product.category,
    });
  };
};

export const postLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 002_Google
    analytics.logEvent("product_post", {
      product_name: product.title,
      product_category: product.category.categoryTilte,
    });
  };
};

export const addWishListLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 011_Google
    analytics.logEvent("add_to_wishlist", {
      ITEM_ID: product.id,
      ITEM_NAME: product.title,
      PRICE: product.price,
      CURRENCY: product.currencyCode,
      ITEM_CATEGORY: product.category,
    });
  };
};

export const contactSellerLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 012_Google
    analytics.logEvent("product_contact_seller", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const makeOfferLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 013_Google
    analytics.logEvent("product_make_offer", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const addToCartLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 021_Google
    analytics.logEvent("add_to_cart", {
      ITEM_ID: product.id,
      ITEM_NAME: product.title,
    });
  };
};

export const viewItemLogging = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 031_Google
    analytics.logEvent("view_item", {
      ITEM_ID: product.id,
      ITEM_NAME: product.title,
      PRICE: product.price,
      CURRENCY: product.currencyCode,
      ITEM_CATEGORY: product.category,
    });
  };
};

export const viewItemListLogging = (category) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 041_Google
    analytics.logEvent("view_item_list", {
      ITEM_CATEGORY: category,
    });
  };
};

export const viewSearchResultsLogging = (searchData) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 051_Google
    analytics.logEvent("view_search_results", {
      search_params: searchData.currentRefinement,
      search_total_hits: searchData.total,
    });
  };
};
