// @flow
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export const createUsedProduct = (req) => {
  return new Promise((resolve, reject) => {
    var usedproduct = firebase
      .functions()
      .httpsCallable('createOrUpdateUsedProduct');

    usedproduct(JSON.stringify(req))
      .then(function (result) {
        // Read result of the Cloud Function.
        resolve(JSON.parse(result.data));
      })
      .catch(function (error) {
        reject('Error', error);
      });
  });
};
