// @flow
import Geocode from "react-geocode";
import { firebaseConfig, ipKeyLcation } from "./FirebaseConfig";

//init api key of google map
Geocode.setApiKey(firebaseConfig.apiKey);

export default () => {
  return new Promise((resolve, reject) => {
    var urlReq = "https://api.ipgeolocation.io/ipgeo?apiKey=" + ipKeyLcation;

    fetch(urlReq)
      .then((res) => res.json())
      .then(function (res) {
        // Read result of the Cloud Function.
        try {
          const city = res.city,
            state = res.state_prov,
            country = res.country_name,
            countryCode = res.country_code2,
            zip = res.zipcode;
          const address = (city ? city + ", " : "") + state + ", " + country;

          //update state
          resolve({
            bingoDealLocation: {
              readableLocation: address ? address : "",
              city: city ? city : "",
              state: state ? state : "",
              country: country ? country : "",
              countryCode: countryCode ? countryCode : "",
              zip: zip ? zip : "",
              latitude: parseFloat(res.latitude),
              longitude: parseFloat(res.longitude),
            },
            currency: res.currency.code,
          });
        } catch (err) {}
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
