import React, { Component } from "react";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as rdd from "react-device-detect";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import {
  getSearchProducts,
  clearSearchText,
} from "../../store/search/SearchAction";
import { viewSearchResultsLogging } from "../../store/analyticsLogging/GoogleLogging";
import { viewSearchResultsFacebookPixel } from "../../store/analyticsLogging/FacebookPixel";
import { isEmptyOrSpaces } from "../../functions/Functions";
import withRouter from "../hooks/WithRouter";
import { CircularProgress } from "@mui/material";

const styles = (theme) => ({
  listbox: {
    width: "22.5em",
    margin: 0,
    padding: 0,
    zIndex: "2",
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxHeight: "80%",
    marginInlineStart: "1.4em",
  },
  listboxMobile: {
    width: "16em",
    maxWidth: "16em",
    margin: 0,
    padding: 0,
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxHeight: "80%",
  },
  ListItem: {
    width: "100%",
    backgroundColor: theme.palette.primary.background,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconButton: {
    marginInlineEnd: "0px",
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "0px 5px 5px 0px",
    "&:hover": { backgroundColor: theme.palette.primary.dark },
  },
  grid: {
    padding: theme.spacing(3),
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px 10px 10px 5px",
  backgroundColor: theme.palette.primary.light,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    // borderRadius: "0px 5px 5px 0px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.primary.icons,
  backgroundColor: theme.palette.primary.light,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(0em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

class NewSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      openMenu: false,
      isFirstTime: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reducerSearchText)
      if (isEmptyOrSpaces(nextProps.reducerSearchText) === false) {
        this.setState({
          searchText: nextProps.reducerSearchText,
        });
        var info = {
          countryCode: this.props.locationConfig.countryCode,
          categoryId: null,
          startAfterProductId: null,
          latitude: this.props.locationConfig.latitude,
          longitude: this.props.locationConfig.longitude,
          typeProducts: 0,
          radius: 200,
          searchText: nextProps.reducerSearchText,
        };
        this.setState({
          openMenu: true,
          currentRefinement: this.state.searchText,
        });
        this.props.getSearchProducts(info);
      }
  }

  handleOpenMenu = () => {
    this.setState({
      openMenu: true,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: false,
    });
  };

  handleClickAway = () => {
    // this.handleCloseMenu();
    if (
      !rdd.isMobile &&
      this.props.reducerSearchText !== undefined &&
      isEmptyOrSpaces(this.props.reducerSearchText) === true
    ) {
      this.setState({
        searchText: "",
      });
      this.handleCloseMenu();
      // this.props.clearSearchText();
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleClickListItem = (hit, total) => {
    var searchData = {
      currentRefinement: this.state.currentRefinement,
      total: total,
    };
    this.props.viewSearchResultsLogging(searchData);
    this.props.viewSearchResultsFacebookPixel(searchData);
    //if product push url of this shop page
    if (!hit.shopId) {
      this.props.router.navigate("/product/" + hit.id);
    } else {
      //if shop push url of this shop page
      this.props.router.navigate(
        "/shop/" + hit.shopId + "/dashboardproducts/productdetails/" + hit.id
      );
    }
    //close menu
    this.handleCloseMenu();
    if (rdd.isMobile) this.props.handleMobileMenuClose();
  };

  handleSubmit = () => {
    if (isEmptyOrSpaces(this.state.searchText) === false) {
      var info = {
        countryCode: this.props.locationConfig.countryCode,
        categoryId: null,
        startAfterProductId: null,
        latitude: this.props.locationConfig.latitude,
        longitude: this.props.locationConfig.longitude,
        typeProducts: 0,
        radius: 200,
        searchText: this.state.searchText,
      };
      this.setState({
        openMenu: true,
        currentRefinement: this.state.searchText,
      });
      this.props.getSearchProducts(info);
    }
  };
  render() {
    const { classes, products, searchProductsStatus, intl } = this.props;
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div>
          <Search>
            <StyledInputBase
              id="searchText"
              value={this.state.searchText}
              placeholder="Search…"
              onChange={this.handleChange}
              inputProps={{ "aria-label": "search" }}
            />
            <IconButton
              size="large"
              aria-label="search"
              color="inherit"
              className={classes.iconButton}
              onClick={this.handleSubmit}
            >
              <SearchIcon />
            </IconButton>
          </Search>
          {this.state.openMenu ? (
            <Paper
              className={rdd.isMobile ? classes.listboxMobile : classes.listbox}
            >
              {products &&
              searchProductsStatus === "successful" &&
              products.length > 0 ? (
                <React.Fragment>
                  {/*Render search hits number*/}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    style={{ padding: 5 }}
                  >
                    {products.length > 0 ? products.length : null}{" "}
                    {intl.formatMessage({
                      id: "result",
                    })}
                  </Typography>
                  {/*Render all list item */}
                  {products &&
                    products.map((product, index) => {
                      return (
                        <ListItem
                          key={product.id}
                          className={classes.ListItem}
                          button
                          onClick={() =>
                            this.handleClickListItem(product, products.length)
                          }
                        >
                          <ListItemAvatar>
                            <Avatar src={product.imageUrl1} />
                          </ListItemAvatar>

                          <ListItemText
                            primary={<Typography>{product.title}</Typography>}
                            secondary={
                              product.price + " " + product.currencyCode
                            }
                            style={{
                              width: "%100",
                              whiteSpace: "normal",
                            }}
                          />
                        </ListItem>
                      );
                    })}{" "}
                </React.Fragment>
              ) : products &&
                searchProductsStatus === "successful" &&
                products.length === 0 ? (
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="div"
                    align="center"
                    style={{ padding: 5 }}
                  >
                    {intl.formatMessage({
                      id: "no_result",
                    })}
                  </Typography>
                </React.Fragment>
              ) : searchProductsStatus === "loading" ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.grid}
                >
                  <CircularProgress /*className={classes.linearProgress}*/ />
                </Grid>
              ) : null}
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    locationConfig: state.locationReducer,
    products: state.SearchReducers.products,
    reducerSearchText: state.SearchReducers.searchText,
    searchProductsStatus: state.SearchReducers.searchProductsStatus,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSearchProducts: (info) => dispatch(getSearchProducts(info)),
    clearSearchText: () => dispatch(clearSearchText()),
    viewSearchResultsLogging: (searchData) =>
      dispatch(viewSearchResultsLogging(searchData)),
    viewSearchResultsFacebookPixel: (searchData) =>
      dispatch(viewSearchResultsFacebookPixel(searchData)),
  };
};

export default injectIntl(
  withRouter(
    compose(
      withStyles(styles),
      connect(mapStateToProps, mapDispatchToProps)
    )(NewSearchComponent)
  )
);
