// @flow
import { createUsedProduct } from "./CreateUsedProduct";
import { editUsedProduct } from "./EditUsedProduct";
import { getProducts } from "./GetProductsData";
import { constantsConfig } from "../../config/ConstantsConfig";
import axios from "axios";

export const createProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //when marge with user
    createUsedProduct(product)
      .then((response) => {
        //add doc to products views when add prodcut sucsseful
        var id = response.id;
        dispatch({ type: "CREATE_PRODUCT", id });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_PRODUCT_ERROR", err });
      });
    dispatch({ type: "RESET_PRODUCT" });
    //then method execut when befor task complete
  };
};
export const editProduct = (product) => {
  return (dispatch, getState) => {
    var id = product.id;
    //when marge with user
    const autherId = getState().firebase.auth.uid;
    product.sellerId = autherId;
    product.seller.id = autherId;
    editUsedProduct(product)
      .then((response) => {
        dispatch({ type: "EDIT_PRODUCT", id });
      })
      .catch((err) => {
        dispatch({ type: "EDIT_PRODUCT_ERROR", err });
      });
    dispatch({ type: "RESET_PRODUCT" });
  };
};

export const deleteFavoriteProduct = (productId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionFavorites)
      .doc(productId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_FAVORITE_PRODUCT" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_FAVORITE_PRODUCT_ERROR", err });
      });
  };
};

export const editProductByAdmin = (product) => {
  return (dispatch) => {
    var id = product.id;
    //when marge with user
    var category = product.category.categoryTitle;

    editUsedProduct(product)
      .then((response) => {
        dispatch({ type: "EDIT_PRODUCT_CATEGORY", id, category });
      })
      .catch((err) => {
        dispatch({ type: "EDIT_PRODUCT_CATEGORY_ERROR", err });
      });
    dispatch({ type: "RESET_PRODUCT" });
  };
};

export const deleteProduct = (params) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const product = params.product;
    params.buyer
      ? firestore
          .collection("products")
          .doc(product.id)
          .delete()
          .then(() => {
            firestore
              .collection("archived_products")
              .add({
                ...product,
                isSoldOnBingoDeal: params.isSoldOnBingoDeal,
                buyer: params.buyer,
                buyerId: params.buyerId,
              })
              .then(() => {
                dispatch({ type: "DELETE_PRODUCT", product });
              });
          })
          .catch((err) => {
            dispatch({ type: "DELETE_PRODUCT_ERROR", err });
          })
      : firestore
          .collection("products")
          .doc(product.id)
          .delete()
          .then(() => {
            firestore
              .collection("archived_products")
              .add({
                ...product,

                isSoldOnBingoDeal: params.isSoldOnBingoDeal,
              })
              .then(() => {
                dispatch({ type: "DELETE_PRODUCT", product });
              });
          })
          .catch((err) => {
            dispatch({ type: "DELETE_PRODUCT_ERROR", err });
          });
    dispatch({ type: "RESET_PRODUCT" });
  };
};

export const deleteProductByAdmin = (productId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var deleted = true;
    dispatch({ type: "DELETE_PRODUCT_SPINNER" });
    firestore
      .collection("products")
      .doc(productId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_PRODUCT_BY_ADMIN", deleted });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_PRODUCT_BY_ADMIN_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const DeleteBlockedProductByAdmin = (pId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var deleted = true;
    firestore
      .collection("blocked_products")
      .doc(pId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_BLOCKED_PRODUCT_BY_ADMIN", deleted });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_BLOCKED_PRODUCT_BY_ADMIN_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const createBlockedProducts = (product, productId, blockTimestamp) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("blocked_products")
      .doc(productId)
      .set({
        ...product,
        blockTimestamp,
      })
      .then(() => {
        dispatch({ type: "CREATE_BLOCKED_PRODUCT" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_BLOCKED_PRODUCT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const createUnBlockedProducts = (product, pId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "CREATE_PRODUCT_SPINNER" });
    firestore
      .collection("products")
      .doc(pId)
      .set({
        ...product,
      })
      .then(() => {
        dispatch({ type: "CREATE_UnBLOCKED_PRODUCT" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_UnBLOCKED_PRODUCT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const BoostedProduct = (product, type) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const boost = firebase
      .functions()
      .httpsCallable("boostProductAndSavePayment");
    const productId = product.id;
    boost(JSON.stringify({ ...product, boostDays: type }))
      .then((res) => {
        dispatch({ type: "BOOSTED_PRODUCT", productId });
        dispatch({ type: "SNACKBAR_BOOST_PRODUCT" });
      })
      .catch((Error) => {
        dispatch({ type: "BOOSTED_PRODUCT_ERROR", Error });
      });
  };
};

export const disableSnackBarBoostProduct = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "DISABLE_SNACKBAR_BOOST_PRODUCT" });
  };
};

export const createFavoriteProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    var addFavorite = firebase.functions().httpsCallable("addFavorite");
    dispatch({ type: "LOADING_FAVORITE_PRODUCT" });
    addFavorite(
      JSON.stringify({
        ...product,
      })
    )
      .then((response) => {
        dispatch({ type: "CREATE_FAVORITE_PRODUCT" });
        dispatch({ type: "DISABLE_LOADING_FAVORITE_PRODUCT" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_FAVORITE_PRODUCT_ERROR", err });
        dispatch({ type: "DISABLE_LOADING_FAVORITE_PRODUCT" });
      });
    //then method execut when befor task complete
  };
};
export const removeFavoriteProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    var deleteFavorite = firebase.functions().httpsCallable("deleteFavorite");
    dispatch({ type: "LOADING_FAVORITE_PRODUCT" });
    deleteFavorite(
      JSON.stringify({
        ...product,
      })
    )
      .then(() => {
        dispatch({ type: "DELETE_FAVORITE_PRODUCT" });
        dispatch({ type: "DISABLE_LOADING_FAVORITE_PRODUCT" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_FAVORITE_PRODUCT_ERROR", err });
        dispatch({ type: "DISABLE_LOADING_FAVORITE_PRODUCT" });
      });
    //then method execut when befor task complete
  };
};

export const changeProductsLocation = (info) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "RESET_LOCATION_PRODUCTS",
    });
    var products = [];
    getProducts({
      countryCode: info.countryCode,
      categoryId: info.categoryId,
      startAfterProductId: info.startAfterProductId,
      latitude: info.latitude,
      longitude: info.longitude,
      typeProducts: info.typeProducts,
      radius: info.radius,
    })
      .then((res) => {
        products.push(...res.products);
        var productsInformation = {
          products: products,
          startAtProduct:
            res.products.length !== 0
              ? res.products[res.products.length - 1].id
              : null,
          isLoading: false,
          typeProducts: info.typeProducts,
          categoryId: info.categoryId,
          firstTime: true,
        };
        dispatch({
          type: "GET_LOCATION_PRODUCTS_SUCSESS",
          productsInformation,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getProductsByLocation = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const productsInformationState =
      getState().ProductReducer.productsInformation;
    var products = [];
    if (
      productsInformationState.products &&
      productsInformationState.typeProducts === info.typeProducts &&
      productsInformationState.categoryId === info.categoryId
    ) {
      products = productsInformationState.products;
      if (!info.scrollEnd) {
        var productsInformation = {
          products: products,
          startAtProduct:
            products.length !== 0 ? products[products.length - 1].id : null,
          isLoading: false,
          typeProducts: info.typeProducts,
          categoryId: info.categoryId,
          firstTime: true,
        };
        dispatch({
          type: "GET_LOCATION_PRODUCTS_SUCSESS",
          productsInformation,
        });
        return;
      }
    }
    if (!info.scrollEnd) {
      dispatch({
        type: "LOADING_GET_PRODUCTS",
      });
    } else {
      dispatch({
        type: "LOADING_MORE_GET_PRODUCTS",
      });
    }
    getProducts({
      countryCode: info.countryCode,
      categoryId: info.categoryId,
      startAfterProductId: info.startAfterProductId,
      latitude: info.latitude,
      longitude: info.longitude,
      typeProducts: info.typeProducts,
      radius: info.radius,
    })
      .then((res) => {
        products.push(...res.products);
        var productsInformation = {
          products: products,
          startAtProduct:
            res.products.length !== 0
              ? res.products[res.products.length - 1].id
              : null,
          isLoading: false,
          typeProducts: info.typeProducts,
          categoryId: info.categoryId,
          firstTime: true,
        };
        dispatch({
          type: "GET_LOCATION_PRODUCTS_SUCSESS",
          productsInformation,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const resetProductsInformation = (info) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "RESET_PRODUCTS_INFORMATION",
    });
  };
};

export const addCarModelByAdmin = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "LOADING_CREATE_MODEL" });
    firestore
      .collection(constantsConfig.collectionVehiclesModels)
      .doc(data.docId)
      .collection("models")
      .add({})
      .then((response) => {
        firestore
          .collection(constantsConfig.collectionVehiclesModels)
          .doc(data.docId)
          .collection("models")
          .doc(response.id)
          .update({
            make: data.updateModel.make,
            model: data.updateModel.model,
            modelId: response.id,
          })
          .then(() => {
            dispatch({ type: "CREATE_MODEL" });
          })
          .catch((err) => {
            dispatch({ type: "CREATE_MODEL_ERROR" }, err);
          });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MODEL_ERROR" }, err);
      });
  };
};

export const addMobileModelByAdmin = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "LOADING_CREATE_MODEL" });
    firestore
      .collection(constantsConfig.collectionMobiles)
      .doc(data.docId)
      .collection("models")
      .add({})
      .then((response) => {
        firestore
          .collection(constantsConfig.collectionMobiles)
          .doc(data.docId)
          .collection("models")
          .doc(response.id)
          .update({
            ...data.updateModel,
            modelId: response.id,
          })
          .then(() => {
            dispatch({ type: "CREATE_MODEL" });
          })
          .catch((err) => {
            dispatch({ type: "CREATE_MODEL_ERROR" }, err);
          });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MODEL_ERROR" }, err);
      });
  };
};

export const addTabletModelByAdmin = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "LOADING_CREATE_MODEL" });
    firestore
      .collection(constantsConfig.collectionTablets)
      .doc(data.docId)
      .collection("models")
      .add({})
      .then((response) => {
        firestore
          .collection(constantsConfig.collectionTablets)
          .doc(data.docId)
          .collection("models")
          .doc(response.id)
          .update({
            ...data.updateModel,
            modelId: response.id,
          })
          .then(() => {
            dispatch({ type: "CREATE_MODEL" });
          })
          .catch((err) => {
            dispatch({ type: "CREATE_MODEL_ERROR" }, err);
          });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MODEL_ERROR" }, err);
      });
  };
};

export const updateViewsOrFavorites = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("products_views")
      .doc(data.documentId)
      .update({
        ...data.updateViews,
      })
      .then(() => {
        dispatch({ type: "UPDATE_VIEWS" });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_VIEWS_ERROR", err });
      });
  };
};

export const addNewDocumentToViews = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("products_views")
      .doc(data.documentId)
      .set({
        ...data.updateViews,
      })
      .then(() => {
        dispatch({ type: "ADD_VIEWS_DOCUMENT" });
      })
      .catch((err) => {
        dispatch({ type: "ADD_VIEWS_DOCUMENT_ERROR" });
      });
  };
};

export const getWalmartProducts = () => {
  return (dispatch) => {
    axios
      .get("https://www.walmart.com/search?query=iphone", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log("res", res);
        // dispatch({ type: "GET_ALL_EVENTS_SUCCESS", res });
      })
      .catch((err) => {
        // dispatch({ type: "GET_ALL_EVENTS_ERROR", err });
      });
  };
};
