// @flow
import React, { Component } from 'react';
import {
  Chip,
  Grid,
  Dialog,
  DialogTitle,
  Button,
  TextField
} from '@mui/material';
import { withStyles } from '@mui/styles';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AddIcon from '@mui/icons-material/Add';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  tagsButton: {
    ...theme.typography.normalButton
  }
});
class TagsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      openTags: false,
      addTagText: '',
      openDialogAddTag: false
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      tags: nextProps.tags
    });
  }
  handleCloseTagsDialog = () => {
    this.setState({ openTags: false });
  };

  handleDelete = (tagToDelete, index) => {
    var tags = this.state.tags;
    if (tags.length === 1) {
      tags = [];
    } else {
      tags.splice(index, 1);
    }
    this.setState({
      tags: tags
    });
  };
  handleChangeText = (element) => {
    this.setState({ addTagText: element.target.value });
  };
  handleAddTag = () => {
    if (this.state.addTagText !== '') {
      this.state.tags.push(this.state.addTagText);
      this.handleCloseAddTag();
    }
  };
  handleCloseAddTag = () => {
    this.setState({ openDialogAddTag: false });
  };
  render() {
    const { classes, intl } = this.props;

    return (
      <React.Fragment>
        <Dialog
          onClose={this.handleCloseAddTag}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={this.state.openDialogAddTag}
        >
          <DialogTitle id="simple-dialog-title">
            {intl.formatMessage({
              id: 'add_tag'
            })}
          </DialogTitle>

          <div style={{ display: 'flex', margin: '10px' }}>
            <TextField onChange={this.handleChangeText} fullWidth />
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleAddTag}
              style={{ marginInlineStart: '5px' }}
            >
              {intl.formatMessage({
                id: 'add'
              })}
            </Button>
          </div>
        </Dialog>
        <Dialog
          onClose={this.handleCloseTagsDialog}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={this.state.openTags}
        >
          <DialogTitle id="simple-dialog-title">Products tags</DialogTitle>
          <Grid item xs={12} className={classes.root}>
            {this.state.tags.length !== 0 &&
              this.state.tags.map((data, index) => {
                return (
                  <Chip
                    icon={<LocalOfferIcon />}
                    key={data}
                    label={data}
                    onDelete={() => this.handleDelete(data, index)}
                    className={classes.chip}
                  />
                );
              })}
            <Chip
              icon={<AddIcon />}
              key={'add'}
              className={classes.chip}
              onClick={() => {
                this.setState({ openDialogAddTag: true });
              }}
            />
          </Grid>
        </Dialog>
        {this.state.tags.length !== 0 ? (
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '0.1em' }}
          >
            <Grid item>
              <Button
                variant="contained"
                component="label"
                className={classes.tagsButton}
                onClick={() => {
                  this.setState({ openTags: true });
                }}
              >
                {intl.formatMessage({
                  id: 'product_tags'
                })}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(TagsComponent));
