import { loadStripe } from "@stripe/stripe-js";
import {
  stripeApi,
  isProductionBuild,
  stripeApiDev,
} from "../../config/FirebaseConfig";
const stripePromise = loadStripe(isProductionBuild ? stripeApi : stripeApiDev);
export const checkoutPayment = (checkoutData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const functions = firebase.functions();
    var createCheckoutSession = functions.httpsCallable(
      "createCheckoutSession"
    );
    const stripe = await stripePromise;

    createCheckoutSession(JSON.stringify({ ...checkoutData }))
      .then(async (result) => {
        const resultJSON = JSON.parse(result.data);
        const sessionId = resultJSON.id;
        // When the customer clicks on the button, redirect them to Checkout.
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });
        if (error) {
          console.log(error);
          dispatch({ type: "CREATE_CHECKOUT_SESSION_ERROR", error });
        }
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "CREATE_CHECKOUT_SESSION_ERROR", error });
      });
  };
};
