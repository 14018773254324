//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Navigate } from 'react-router';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import DrawerAdmins from '../layout/DrawerAdmins';
import { withStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import AdminAllReviews from '../admin/AdminAllReviews';
import { constantsConfig } from '../../config/ConstantsConfig';

const drawerWidth = 240;
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.background
  },
  tableCell: {
    width: '10em',
    maxWidth: '10em',
    backgroundColor: theme.palette.primary.background
  },
  spinnerGrid: {
    flexGrow: 1,
    padding: '0px 20px 30px 20px'
  },
  gridCircularProgress: {
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.background
  },
  circularProgress: {
    color: theme.palette.primary.main
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.background,
    width: drawerWidth,
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      backgroundColor: theme.palette.primary.background
    }
  }
});

class DashboardAllReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      reviews: null,
      lastDoc: null,
      mobileOpen: false
    };
  }

  componentDidMount() {
    firebase
      .firestore()
      .collection(constantsConfig.collectionReviews)
      .orderBy('reviewTimestamp', 'desc')
      .limit(20)
      .get()
      .then((collections) => {
        const reviews = collections.docs.map((action) => action.data());
        const lastDoc = collections.docs[collections.docs.length - 1];
        this.setState({
          reviews: reviews,
          lastDoc: lastDoc
        });
      });
    window.addEventListener(
      'scroll',
      _.debounce(() => {
        const windowHeight =
          'innerHeight' in window
            ? window.innerHeight
            : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );
        const windowBottom = Math.round(windowHeight + window.pageYOffset);
        // Small hack for windows. It counts windowBottom in different way
        const difference = docHeight - windowBottom;
        const additional = difference >= 1 && difference <= 2 ? difference : 0;
        if (windowBottom + additional >= docHeight && this.state.lastDoc) {
          this.setState({
            spinner: true
          });
          this.handleFetchMore();
        }
      }, 1000)
    );
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleFetchMore = () => {
    firebase
      .firestore()
      .collection(constantsConfig.collectionReviews)
      .orderBy('reviewTimestamp', 'desc')
      .startAfter(this.state.lastDoc)
      .limit(20)
      .get()
      .then((collections) => {
        const reviews = collections.docs.map((action) => action.data());
        const lastDoc = collections.docs[collections.docs.length - 1];
        this.setState({
          reviews: [...this.state.reviews, ...reviews],
          lastDoc: lastDoc,
          spinner: false
        });
      });
  };

  render() {
    const { classes, profile } = this.props;
    if (profile) {
      if (profile.isAdmin)
        return (
          <div className={classes.root}>
            <Drawer
              variant="temporary"
              anchor={'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <DrawerAdmins />
            </Drawer>
            <Hidden mdDown implementation="css">
              <DrawerAdmins />
            </Hidden>
            <main className={classes.content}>
              <Toolbar />
              <Hidden mdUp implementation="css">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    className={classes.title}
                    onClick={this.handleDrawerToggle}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review Date</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review From User Id</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review To User Id</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Is Positive Review</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review Details</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review Type Id</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review Id</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review Type Name</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6">Review From Type</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.reviews &&
                  this.state.reviews.map((review) => {
                    return <AdminAllReviews review={review} />;
                  })}
              </TableBody>
              {this.state.spinner === true ? (
                <Grid container className={classes.spinnerGrid} spacing={0}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={0}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </main>
          </div>
        );
      else return <Navigate to="/" />;
    } else
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.gridCircularProgress}
        >
          <Grid item xs={3}>
            <CircularProgress className={classes.circularProgress} />
          </Grid>
        </Grid>
      );
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    profile: state.firestore.ordered.users
      ? state.firestore.ordered.users[0]
      : null
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(DashboardAllReviews);
