// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  CircularProgress,
  DialogContent,
  Grid
} from '@mui/material';
import { injectIntl } from 'react-intl';
import { constantsConfig } from '../../config/ConstantsConfig';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background
  },
  deleteButton: {
    width: '140px !important'
  },
  categoryName: {
    textAlign: 'center',
    color: theme.palette.primary.price
  },
  gridListTile: {
    backgroundColor: theme.palette.primary.transparentBackground
  },
  flexContentBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  categoryButton: {
    ...theme.typography.normalButton
  }
});

function translateCategories(categoryId, intl) {
  if (categoryId === constantsConfig.categoriesId.vehicles)
    return intl.formatMessage({
      id: 'category_vehicles'
    });
  if (categoryId === constantsConfig.categoriesId.furniture)
    return intl.formatMessage({
      id: 'category_furniture'
    });
  if (categoryId === constantsConfig.categoriesId.electronics)
    return intl.formatMessage({
      id: 'category_electronics'
    });
  if (categoryId === constantsConfig.categoriesId.homeAndAppliances)
    return intl.formatMessage({
      id: 'category_appliances'
    });
  if (categoryId === constantsConfig.categoriesId.fashionAndAccessories)
    return intl.formatMessage({
      id: 'category_fashion_accessories'
    });
  if (categoryId === constantsConfig.categoriesId.babyAndKids)
    return intl.formatMessage({
      id: 'category_baby_kids'
    });
  if (categoryId === constantsConfig.categoriesId.antiquesAndCollectibles)
    return intl.formatMessage({
      id: 'category_antiques_collectibles'
    });
  if (categoryId === constantsConfig.categoriesId.autoParts)
    return intl.formatMessage({
      id: 'category_auto_parts_tools_machinery'
    });
  if (categoryId === constantsConfig.categoriesId.sportsAndOutdoors)
    return intl.formatMessage({
      id: 'category_sports_outdoors'
    });
  if (categoryId === 1090)
    return intl.formatMessage({
      id: 'category_others'
    });
  if (categoryId === constantsConfig.categoriesId.homeForSaleOrRent)
    return intl.formatMessage({
      id: 'category_home_sale_or_rent'
    });
  if (categoryId === constantsConfig.categoriesId.yardSaleEvent)
    return intl.formatMessage({
      id: 'category_yard_sale_event'
    });
  if (categoryId === constantsConfig.categoriesId.others)
    return intl.formatMessage({
      id: 'category_others'
    });
  if (categoryId === constantsConfig.categoriesId.mobiles)
    return intl.formatMessage({
      id: 'category_mobiles'
    });
  if (categoryId === constantsConfig.categoriesId.tablets)
    return intl.formatMessage({
      id: 'category_tablets'
    });
  else
    return intl.formatMessage({
      id: 'category_others'
    });
}

function SimpleDialog({ intl, ...props }) {
  const { onClose, open, projects, classes } = props;
  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = (value) => {
    props.onHandleChangeCategory(value);
    onClose(value);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      fullWidth
      open={open}
    >
      <div className={classes.root}>
        <div className={classes.flexContentBetween}>
          <DialogTitle
            id="simple-dialog-title"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {intl.formatMessage({
              id: 'set_product_category'
            })}
          </DialogTitle>
        </div>
        <DialogContent>
          {!projects ? (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <ImageList
              cellHeight={130}
              cols={window.innerWidth >= 0 && window.innerWidth < 600 ? 2 : 3}
            >
              {Object.keys(projects).map((tile) => {
                const title = translateCategories(
                  projects[tile].categoryId,
                  intl
                );
                return (
                  <ImageListItem
                    key={projects[tile].categoryId}
                    onClick={() => handleListItemClick(projects[tile])}
                  >
                    <img
                      src={projects[tile].categoryImage}
                      alt={'tile.title'}
                    />

                    <ImageListItemBar
                      title={
                        <div className={classes.flexContentBetween}>
                          <Typography
                            variant="subtitle2"
                            className={classes.categoryName}
                          >
                            {title}
                          </Typography>
                        </div>
                      }
                      className={classes.gridListTile}
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

class SimpleDialogDemo extends Component {
  state = {
    open: false,
    selectedValue: this.props.categoryTitle,
    categoryRanking: this.props.categoryRanking
  };
  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = (value) => {
    if (value) {
      this.setState({
        selectedValue: value.categoryTitle
      });
    }
    this.setState({
      open: false
    });
  };
  render() {
    const { classes, intl } = this.props;
    var selectedValue = translateCategories(this.props.categoryId, intl);
    return (
      <div>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {this.props.isCategoryNull ? (
              <Button
                variant="outlined"
                color="error"
                className={classes.categoryButton}
                onClick={this.handleClickOpen}
              >
                {intl.formatMessage({
                  id: 'product_category'
                })}
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                className={classes.categoryButton}
                onClick={this.handleClickOpen}
              >
                {intl.formatMessage({
                  id: 'product_category'
                })}
              </Button>
            )}
          </Grid>
          <Grid item>
            {this.state.selectedValue && !this.props.edit ? (
              <Typography variant="subtitle2">
                {intl.formatMessage({
                  id: 'selected'
                })}
                : {selectedValue}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <SimpleDialog
          projects={this.props.projects}
          open={this.state.open}
          onClose={this.handleClose}
          onHandleChangeCategory={this.props.onHandleChangeCategory}
          classes={classes}
          intl={this.props.intl}
        />
      </div>
    );
  }
}

export default injectIntl(compose(withStyles(styles))(SimpleDialogDemo));
