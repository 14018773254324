// @flow
import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ListOfBuyingUsers from "./ListOfBuyingUsers";
import StarRatingNew from "./StarRatingNew";

function MarkAsSoldUsersList({ intl, ...props }) {
  const { auth } = props;
  const [myThread, setMyThread] = React.useState(null);

  const handleChooseThread = (thread) => {
    var senderId,
      receiverId,
      senderName,
      receiverName,
      senderPhoto,
      receiverPhoto;
    if (thread.receiverId === auth.uid) {
      senderId = thread.receiverId;
      receiverId = thread.senderId;
      senderName = thread.receiverName;
      receiverName = thread.senderName;
      senderPhoto = thread.receiverPhoto;
      receiverPhoto = thread.senderPhoto;
    } else {
      senderId = thread.senderId;
      receiverId = thread.receiverId;
      senderName = thread = senderName;
      receiverName = thread.receiverName;
      senderPhoto = thread.senderPhoto;
      receiverPhoto = thread.receiverPhoto;
    }
    var myThread = {
      senderId: senderId,
      receiverId: receiverId,
      senderName: senderName,
      receiverName: receiverName,
      subjectId: thread.subjectId,
      subjectName: thread.subjectName,
      senderPhoto: senderPhoto,
      receiverPhoto: receiverPhoto,
    };
    setMyThread(myThread);
  };

  switch (props.step) {
    case 1:
      return (
        <ListOfBuyingUsers
          auth={auth}
          threads={props.threads}
          threadsSpinner={props.threadsSpinner}
          handleNextStep={props.handleNextStep}
          handleChooseThread={handleChooseThread}
          handleNotSoldOnListedNearMe={props.handleNotSoldOnListedNearMe}
        />
      );
    case 2:
      return (
        <StarRatingNew
          myThread={myThread}
          handleSubmitRating={props.handleSubmitRating}
        />
      );
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    deleted: state.usedProductReducer.deleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // deleteProduct: (product) => dispatch(deleteProduct(product)),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MarkAsSoldUsersList)
);
