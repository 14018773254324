// @flow
import React, { Component } from "react";
import BuyScreen from "./onboarding/BuyScreen";
import SellScreen from "./onboarding/SellScreen";
import CreateProductScreen from "./onboarding/CreateProductScreen";

class OnBoardingScreens extends Component {
  state = {
    step: 1,
  };

  //Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  render() {
    const { step } = this.state;
    const { handleCloseOnBoardingScreens } = this.props;
    switch (step) {
      case 1:
        return (
          <BuyScreen
            nextStep={this.nextStep}
            handleCloseOnBoardingScreens={handleCloseOnBoardingScreens}
            onSwipeMove={this.onSwipeMove}
          />
        );
      case 2:
        return (
          <SellScreen
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleCloseOnBoardingScreens={handleCloseOnBoardingScreens}
          />
        );
      /*case 3:
        return (
          <ShopScreen
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleCloseOnBoardingScreens={handleCloseOnBoardingScreens}
          />
        );*/
      case 3:
        return (
          <CreateProductScreen
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleCloseOnBoardingScreens={handleCloseOnBoardingScreens}
          />
        );
      /*case 5:
        return (
          <CreateShopScreen
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleCloseOnBoardingScreens={handleCloseOnBoardingScreens}
          />
        );*/
      default:
        return <div></div>;
    }
  }
}

export default OnBoardingScreens;
