import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '20em'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  container: {
    padding: 35,
    backgroundColor: theme.palette.primary.background
  },
  textField: {
    width: '100%'
  },
  xIcon: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: '10px'
  },
  flexContentCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  gridItem: {
    width: '100%'
  }
}));

function AddOtherModel({ intl, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <CloseIcon
          className={classes.xIcon}
          onClick={props.handleCloseOthersDialog}
        />
        <DialogTitle
          id="form-dialog-title"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          <Typography variant="h5">Add Model</Typography>
        </DialogTitle>
      </div>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.gridItem}>
            <TextField
              id="model"
              variant="standard"
              label={intl.formatMessage({
                id: 'dialog_model_title'
              })}
              className={classes.textField}
              value={props.model}
              onChange={(e) => props.handleChangeOthersModel(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleCloseOthersDialog}
        >
          {intl.formatMessage({
            id: 'done'
          })}
        </Button>
      </DialogActions>
    </div>
  );
}

export default injectIntl(AddOtherModel);
