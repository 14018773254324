// @flow
import firebase from 'firebase/compat/app';
import "firebase/functions";

export const addRateReview = (req) => {
  return new Promise((resolve, reject) => {
    var rate = firebase.functions().httpsCallable("addRateReview");

    rate(JSON.stringify(req))
      .then(function (result) {
        // Read result of the Cloud Function.
        // resolve(JSON.parse(result.data));
      })
      .catch(function (error) {
        reject("Error");
      });
  });
};
