// @flow
import React, { useState } from 'react';
import {
  DialogContent,
  Grid,
  Card,
  CardActionArea,
  Typography,
  DialogActions
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { BoostedProduct } from '../../../store/product/ProductActions';
import { constantsConfig } from '../../../config/ConstantsConfig';
import { injectIntl } from 'react-intl';

import Checkout from '../../payment/checkout';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe(isProductionBuild ? stripeApi : stripeApiDev);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 20px',
    textAlign: 'center'
  },
  CardContainer: {
    maxWidth: '200px'
  },
  Card: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.icons
  },
  SecondCard: {
    backgroundColor: theme.palette.primary.accent,
    color: theme.palette.primary.icons
  },
  Description: {
    padding: '10px'
  },
  BottomItems: {
    marginTop: '3em'
  },
  Terms: {
    color: theme.palette.primary.secondaryText
  },
  button: {
    width: '100%'
  },
  wrapper: {
    width: '100%',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonWatting: {
    backgroundColor: theme.palette.primary.secondaryText,
    width: '100%'
  },
  termsLink: {
    color: theme.palette.primary.secondaryText
  },
  ElementsContainer: {
    width: '100%'
  },
  ElementsContainerHide: {
    display: 'none',
    width: '100%'
  }
}));

function BoostProductDialog({ intl, ...props }) {
  const classes = useStyles();
  const { product } = props;
  const [selected, setselected] = useState({ days: 7, price: 399 });

  //check If product boosted
  /*if (props.product.id === boostedProductId) {
    if (!window.location.href.includes("Open/BoostProduct"))
      window.location.reload();
  }*/

  return (
    <React.Fragment>
      <DialogContent>
        {/*Root Contener*/}
        <div className={classes.root}>
          {/*Cards contener*/}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {constantsConfig.paymentBoost.map((info, index) => (
              <Grid item xs={4} className={classes.CardContainer} key={index}>
                <Card
                  className={
                    selected.days !== info.days
                      ? classes.Card
                      : classes.SecondCard
                  }
                >
                  <CardActionArea
                    onClick={() =>
                      setselected({ days: info.days, price: info.amount })
                    }
                    style={{ padding: 5 }}
                  >
                    <Typography variant="subtitle2">
                      {info.days === 1
                        ? intl.formatMessage({
                            id: 'boost_' + info.days + '_day'
                          })
                        : intl.formatMessage({
                            id: 'boost_' + info.days + '_days'
                          })}
                    </Typography>
                    <br />
                    <Typography variant="h6">${info.amount / 100}</Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/*Description */}
          <Typography variant="body1" className={classes.Description}>
            {intl.formatMessage({
              id: 'boost_product_description'
            })}
          </Typography>

          {/*Bottom items */}
          <div className={classes.BottomItems}>
            {/*Bingo Deal Tearm of services*/}
            <Typography variant="subtitle2" className={classes.Terms}>
              {intl.formatMessage({
                id: 'agree_boost_terms'
              })}
              <a
                href={constantsConfig.termsofservice}
                className={classes.termsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>
                  {' '}
                  {intl.formatMessage({
                    id: 'listed_terms_of_service'
                  })}
                </u>
              </a>
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {constantsConfig.paymentBoost.map((info) => (
          <div
            className={
              info.days === selected.days
                ? classes.ElementsContainer
                : classes.ElementsContainerHide
            }
            key={info.boostProductId}
          >
            <Checkout payInformation={info} product={product} />
          </div>
        ))}
      </DialogActions>
    </React.Fragment>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    BoostedProduct: (pid, type) => dispatch(BoostedProduct(pid, type))
  };
};
const mapStateToProps = (state) => {
  return { boostedProductId: state.usedProductReducer.boostedProductId };
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(BoostProductDialog)
);
