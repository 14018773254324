// @flow
import React from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Typography, Snackbar, CircularProgress, Hidden } from '@mui/material';
import DetailsForm from './DetailsForm';
import PhotoForm from './PhotoForm';
import PriceForm from './PriceForm';
import MapContainer from '../other/MapComponent';
import MuiAlert from '@mui/material/Alert';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { injectIntl } from 'react-intl';
import { isEmptyOrSpaces } from '../../functions/Functions';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginTop: '35px',
    marginInlineStart: theme.spacing(2),
    marginInlineEnd: theme.spacing(2)
  },
  paper: {
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(2)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.primary.background
  },
  buttons: {
    ...theme.typography.normalButton,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    ...theme.typography.normalButton,
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute'
  },
  step: {
    height: '300px',
    overflowX: 'hidden',
    padding: '10px',
    '&::-webkit-scrollbar': {
      width: '0.8em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.30)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.secondaryText,
      outline: '1px solid slategrey'
    }
  },
  typographyAgreeAddProduct: {
    color: theme.palette.primary.secondaryText,
    marginTop: '45px'
  },
  mobileStepper: {
    backgroundColor: theme.palette.primary.background
  },
  stepLableEdit: {
    cursor: 'pointer'
  },
  postAndBackButtons: {
    ...theme.typography.normalButton
  }
}));

function translateSteps(step, intl) {
  if (step === 'Photos') {
    return intl.formatMessage({
      id: 'tab_photo'
    });
  }
  if (step === 'Details') {
    return intl.formatMessage({
      id: 'product_details'
    });
  }
  if (step === 'Price') {
    return intl.formatMessage({
      id: 'tab_price'
    });
  }
  if (step === 'Finish') {
    return intl.formatMessage({
      id: 'tab_finish'
    });
  }
}

const steps = ['Photos', 'Details', 'Price', 'Finish'];

const StepperAddProduct = ({ intl, ...props }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [openErrorCompleteInfo, setOpenErrorCompleteInfo] =
    React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const stepsRender = [
    <div className={classes.step}>
      <PhotoForm
        handleRemoveTags={props.handleRemoveTags}
        onHandleChangeValue={props.onHandleChangeValue}
        addProductData={props.addProductData}
        isTitleNull={props.isTitleNull}
      />
    </div>,
    <div className={classes.step}>
      <DetailsForm
        onHandleChangeValue={props.onHandleChangeValue}
        addProductData={props.addProductData.productInformation}
        isDescriptionNull={props.isDescriptionNull}
        isCategoryNull={props.isCategoryNull}
      />
    </div>,
    <div className={classes.step}>
      <PriceForm
        onHandleChangeValue={props.onHandleChangeValue}
        addProductData={props.addProductData.productInformation}
        isPriceNull={props.isPriceNull}
      />
    </div>,
    <div className={classes.step}>
      <MapContainer
        height="250px"
        zoom={15}
        onHandleChangeValue={props.onHandleChangeValue}
        addProductData={props.addProductData.productInformation}
      />
      <Typography
        variant="subtitle1"
        className={classes.typographyAgreeAddProduct}
      >
        {intl.formatMessage({
          id: 'agree_add_product'
        })}
      </Typography>
    </div>
  ];
  const getStepContent = (step) => {
    return stepsRender[step];
  };

  const handleNext = () => {
    if (activeStep + 1 === steps.length) {
      if (props.checkCompeleteInforamtion()) {
        props.handleSubmit();
        setDisableButton(true);
      } else {
        setOpenErrorCompleteInfo(true);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleCloseErrorCompleteInfo = () => {
    setOpenErrorCompleteInfo(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const digits_only = (string) =>
    [...string].every((c) => '0123456789'.includes(c));

  //Begin process uncomplete product data --------------------------------------------
  var priceError,
    titleError,
    imageError,
    categoryError,
    locationError,
    fullMessageError;
  if (openErrorCompleteInfo) {
    priceError =
      props.addProductData.productInformation.price === '' ||
      props.addProductData.productInformation.price === 0 ||
      !digits_only(props.addProductData.productInformation.price.toString())
        ? intl.formatMessage({
            id: 'tab_price'
          })
        : '';
    titleError = isEmptyOrSpaces(props.addProductData.productInformation.title)
      ? intl.formatMessage({
          id: 'product_name'
        })
      : '';
    locationError =
      props.addProductData.productInformation.countryCode === ''
        ? intl.formatMessage({
            id: 'location'
          })
        : '';
    imageError = isEmptyOrSpaces(
      props.addProductData.productInformation.imageUrl1
    )
      ? intl.formatMessage({
          id: 'image'
        })
      : '';
    categoryError =
      props.addProductData.productInformation.categoryId === ''
        ? intl.formatMessage({
            id: 'product_category'
          })
        : '';
    fullMessageError =
      priceError +
      (priceError &&
      (titleError || imageError || categoryError || locationError)
        ? ', '
        : '') +
      titleError +
      (titleError && (imageError || categoryError || locationError)
        ? ', '
        : '') +
      imageError +
      (imageError && (categoryError || locationError) ? ', ' : '') +
      categoryError +
      (categoryError && locationError ? ', ' : '') +
      locationError;
  }
  //End process uncomplete product data --------------------------------------------
  return (
    <React.Fragment>
      <Snackbar
        open={openErrorCompleteInfo}
        autoHideDuration={6000}
        onClose={handleCloseErrorCompleteInfo}
      >
        <Alert onClose={handleCloseErrorCompleteInfo} severity="error">
          <Typography variant="subtitle2">
            {intl.formatMessage({
              id: 'please_complete'
            }) +
              ' ' +
              fullMessageError}
          </Typography>
        </Alert>
      </Snackbar>
      <main className={classes.layout}>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {props.type === 'add'
              ? intl.formatMessage({
                  id: 'add_product'
                })
              : intl.formatMessage({
                  id: 'edit_product'
                })}
          </Typography>
          <Hidden smUp implementation="css">
            {window.innerWidth >= 0 &&
              window.innerWidth < 600 &&
              getStepContent(activeStep)}

            <MobileStepper
              steps={steps.length}
              position="static"
              variant="text"
              className={classes.mobileStepper}
              activeStep={activeStep}
              nextButton={
                <Button
                  className={classes.postAndBackButtons}
                  size="small"
                  color="primary"
                  onClick={handleNext}
                  disabled={disableButton}
                >
                  {activeStep === steps.length - 1
                    ? intl.formatMessage({
                        id: 'product_post'
                      })
                    : intl.formatMessage({
                        id: 'next'
                      })}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  color="primary"
                  className={classes.postAndBackButtons}
                >
                  <KeyboardArrowLeft />
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              }
            />
          </Hidden>

          <Hidden smDown implementation="css">
            <Stepper
              nonLinear
              activeStep={activeStep}
              className={classes.stepper}
            >
              {steps &&
                steps.map((label, index) => {
                  const step = translateSteps(label, intl);
                  return (
                    <Step key={label}>
                      {props.type === 'add' ? (
                        <StepLabel>
                          <Typography variant="h6">{step}</Typography>
                        </StepLabel>
                      ) : (
                        <StepLabel
                          className={classes.stepLableEdit}
                          onClick={handleStep(index)}
                        >
                          <Typography variant="h6">{step}</Typography>
                        </StepLabel>
                      )}
                    </Step>
                  );
                })}
            </Stepper>
            <React.Fragment>
              {window.innerWidth >= 600 && getStepContent(activeStep)}
              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                )}
                {props.isChoosedCategory === false ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleNext();
                      props.changeCategoryAutomatically();
                    }}
                    className={classes.button}
                    disabled={disableButton}
                  >
                    {activeStep === steps.length - 1
                      ? intl.formatMessage({
                          id: 'product_post'
                        })
                      : intl.formatMessage({
                          id: 'next'
                        })}
                    {disableButton && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleNext();
                    }}
                    className={classes.button}
                    disabled={disableButton}
                  >
                    {activeStep === steps.length - 1
                      ? intl.formatMessage({
                          id: 'product_post'
                        })
                      : intl.formatMessage({
                          id: 'next'
                        })}
                    {disableButton && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </div>
            </React.Fragment>
          </Hidden>
        </div>
      </main>
    </React.Fragment>
  );
};
export default injectIntl(StepperAddProduct);
//||props.addProductData.onTagsProcess > 0
