import React from 'react';
import { Component } from 'react';
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.price
  },
  productImage: {
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  imgContainer: {
    position: 'relative'
  },
  topContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    right: 0,
    height: '15%',
    width: '100%',
    position: 'absolute',
    zIndex: 1
    // borderBottomRightRadius: '25px'
  },
  bottomContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    bottom: 0,
    right: 0,
    height: '25%',
    width: '100%',
    position: 'absolute',
    zIndex: 1
    // borderBottomRightRadius: '25px'
  },
  price: {
    color: theme.palette.primary.price,
    padding: 5,
    textAlign: 'center',
    fontSize: '12px'
  },
  media: {
    width: '50%',
    height: '50%'
  },
  locationIcon: {
    marginTop: '5px',
    color: theme.palette.primary.main
  },
  location: {
    fontFamily: 'Quicksand',
    color: theme.palette.primary.icons
  },
  buttons: {
    fontWeight: 'bold',
    padding: 8,
    borderRadius: '0.5em',
    width: '14em',
    maxWidth: '14em',
    fontFamily: 'Quicksand',
    color: theme.palette.primary.icons,
    backgroundColor: theme.palette.primary.accent,
    textTransform: 'none',
    // [theme.breakpoints.down('md')]: { width: '7em', maxWidth: '7em' },
    [theme.breakpoints.down('sm')]: {
      padding: 3.5,
      borderRadius: '0.2em',
      width: '9em',
      maxWidth: '9em',
      fontFamily: 'Quicksand',
      fontSize: '0.5rem'
    }
  },
  containerTypography: {
    fontWeight: 'bold',
    padding: 8,
    borderRadius: '0.5em',
    width: '14em',
    maxWidth: '14em',
    fontFamily: 'Quicksand'
  },
  typography: {
    fontWeight: 'bold',
    padding: 8,
    borderRadius: '0.5em',
    width: '12.5em',
    maxWidth: '12.5em',
    fontFamily: 'Quicksand',
    backgroundColor: theme.palette.primary.secondaryText,
    color: theme.palette.primary.icons,
    [theme.breakpoints.down('sm')]: {
      padding: 3.5,
      borderRadius: '0.2em',
      width: '9em',
      maxWidth: '9em',
      fontFamily: 'Quicksand',
      fontSize: '0.5rem'
    }
  },
  bottomGrid: {
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5em'
    }
  },
  containerRightArrow: {
    backgroundColor: theme.palette.primary.transparentBackground,
    // top: 0,
    right: 0,
    height: '10%',
    width: '3%',
    position: 'absolute',
    zIndex: 1
  },
  xIcon: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
    color: theme.palette.primary.icons,
    marginInlineEnd: '10px'
  },
  arrows: {
    // position: 'absolute',
    zIndex: 10
  },
  icons: {
    color: theme.palette.primary.main,
    '&:hover': { cursor: 'pointer' }
  }
});

class TestBackdropPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBackdrop: true
    };
  }
  handleCloseBackdrop = () => {
    this.setState({
      openBackdrop: false
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
          <div className={classes.topContainer}>
            <div>
              <CloseIcon
                className={classes.xIcon}
                onClick={this.handleCloseBackdrop}
              />
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              style={{ marginTop: '1em' }}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <VisibilityIcon className={classes.icons} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.viewsTypography}>
                      20
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FavoriteBorderIcon className={classes.favoriteBorderIcon} />
              </Grid>
              <Grid item>
                <ShareIcon className={classes.icons} />
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <ArrowBackIosIcon fontSize="large" className={classes.arrows} />
            </Grid>
            <Grid item>
              <div className={classes.imgContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fa0e22eb5-7146-4734-9a1c-4f5b7927d476?alt=media&token=3727f56d-3c9b-4d74-8dab-2c36dec4c7f2"
                  className={classes.productImage}
                  alt="testImage"
                />
              </div>
            </Grid>
            <Grid item>
              <ArrowForwardIosIcon
                fontSize="large"
                className={classes.arrows}
              />
            </Grid>
          </Grid>
          <div className={classes.bottomContainer}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              className={classes.bottomGrid}
            >
              <Grid item>
                {/* <div className={classes.containerTypography}> */}
                <Typography className={classes.typography} align="center">
                  20miles
                </Typography>
                {/* </div> */}
              </Grid>
              <Grid item>
                {/* <div className={classes.containerTypography}> */}
                <Typography className={classes.typography} align="center">
                  USD 20
                </Typography>
                {/* </div> */}
              </Grid>
              <Grid item>
                {/* <div className={classes.containerTypography}> */}
                <Typography className={classes.typography} align="center">
                  2 months ago
                </Typography>
                {/* </div> */}
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item>BMW</Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    <LocationOnIcon
                      fontSize="small"
                      className={classes.locationIcon}
                    />
                  </Typography>
                  <Typography className={classes.location}>
                    New York, United States of America
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <Button variant="contained" className={classes.buttons}>
                  Contact Seller
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" className={classes.buttons}>
                  Show Details
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" className={classes.buttons}>
                  Make Offer
                </Button>
              </Grid>
            </Grid>
          </div>
        </Backdrop>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TestBackdropPage);
