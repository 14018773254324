//@flow
import React, { Component } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { constantsConfig } from "../../config/ConstantsConfig";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Hidden,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import * as rdd from "react-device-detect";
import Container from "@mui/material/Container";
import withRouter from "../hooks/WithRouter";

const styles = (theme) => ({
  root: {
    marginTop: "20px",
    backgroundColor: theme.palette.primary.background,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.background,
  },
  cardGrid: {
    minHeight: "100vh",
  },
  cardGridMobile: {
    minHeight: "100vh",
  },
  paper: {
    maxWidth: 230,
    minWidth: 200,
    borderRadius: "25px",
  },
  paperMobile: {
    maxWidth: 170,
    minWidth: 170,
    borderRadius: "25px",
  },
  card: {
    maxWidth: 345,
    padding: "10px",
    borderRadius: "25px",
    height: 320,
    backgroundColor: theme.palette.primary.background,
  },
  price: {
    color: theme.palette.primary.price,
    padding: 5,
    textAlign: "center",
  },
  priceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: "40%",
    position: "absolute",
    zIndex: 9,
    borderTopLeftRadius: "25px",
  },
  imgContainer: {
    position: "relative",
  },
  media: {
    height: 225,
    borderRadius: "25px",
  },
  shopName: {
    color: theme.palette.primary.primaryText,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.5em" /* fallback */,
    height: "3em",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  containerGridMobile: {
    flexGrow: 1,
    marginTop: "20px",
    backgroundColor: theme.palette.primary.background,
  },
  imgSell: {
    height: "75%",
    maxHeight: "75%",
    maxWidth: "75%",
  },
  imgSellMobile: {
    marginTop: "10%",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  typographyMyFavoriteProducts: {
    color: theme.palette.primary.main,
    marginTop: "2em",
  },
  typographyMyFavoriteProductsMobile: {
    color: theme.palette.primary.main,
    marginTop: "2em",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  noFavoritesProducts: {
    color: theme.palette.primary.main,
  },
  spinnerGrid: {
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.background,
  },
});

const convertPriceToString = (price) => {
  let strPrice = price.toString();
  let charsPrice = [...strPrice];
  if (charsPrice.length <= 3) return strPrice;
  let result = [];
  let count = 1;
  for (let i = charsPrice.length - 1; i >= 0; i--) {
    result.push(charsPrice[i]);
    if (count % 3 === 0 && count !== charsPrice.length) {
      result.push(",");
    }
    count++;
  }
  return result.reverse().join("");
};

class DashboardMyFavoriteProducts extends Component {
  render() {
    const { intl, classes, favoriteProducts } = this.props;
    return (
      <main className={classes.content}>
        <Container
          className={!rdd.isMobile ? classes.cardGrid : classes.cardGridMobile}
        >
          <div className={classes.flexCenter}>
            <Typography
              className={
                !rdd.isMobile
                  ? classes.typographyMyFavoriteProducts
                  : classes.typographyMyFavoriteProductsMobile
              }
              variant="h4"
              gutterBottom
            >
              {intl.formatMessage({
                id: "favorite_products",
              })}
            </Typography>
          </div>
          <div>
            {favoriteProducts ? (
              favoriteProducts.length !== 0 ? (
                <div>
                  <Hidden smDown implementation="css">
                    <Grid
                      container
                      spacing={1}
                      style={{ padding: "24px" }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {favoriteProducts.map((product, index) => (
                        <React.Fragment key={product.id + index}>
                          <Grid
                            item
                            xs={7}
                            sm={7}
                            md={4}
                            lg={3}
                            xl={3}
                            className={classes.flexContentCenter}
                          >
                            <Link
                              to={"/product/" + product.subjectId}
                              style={{ textDecoration: "none" }}
                            >
                              <div className={classes.root}>
                                <Grid style={{ marginBottom: "20px" }} item>
                                  <Paper
                                    className={
                                      !rdd.isMobile
                                        ? classes.paper
                                        : classes.paperMobile
                                    }
                                  >
                                    <Card className={classes.card}>
                                      <CardActionArea>
                                        <div className={classes.priceContiner}>
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.price}
                                          >
                                            {convertPriceToString(
                                              product.price
                                            )}
                                          </Typography>
                                        </div>
                                        <div className={classes.imgContainer}>
                                          <CardMedia
                                            className={classes.media}
                                            src={product.image}
                                            title={product.title}
                                            component="img"
                                          />
                                        </div>

                                        <CardContent>
                                          <Typography
                                            variant="h5"
                                            className={classes.shopName}
                                          >
                                            {product.title}
                                          </Typography>
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  </Paper>
                                </Grid>
                              </div>
                            </Link>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Hidden>
                  <Hidden smUp implementation="css">
                    <Grid
                      container
                      className={classes.containerGridMobile}
                      spacing={1}
                    >
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                          {favoriteProducts.map((product, index) => (
                            <Grid key={product.id + index} item>
                              <Link
                                to={"/product/" + product.subjectId}
                                style={{ textDecoration: "none" }}
                              >
                                <div className={classes.root}>
                                  <Grid style={{ marginBottom: "20px" }} item>
                                    <Paper
                                      className={
                                        !rdd.isMobile
                                          ? classes.paper
                                          : classes.paperMobile
                                      }
                                    >
                                      <Card className={classes.card}>
                                        <CardActionArea>
                                          <div
                                            className={classes.priceContiner}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.price}
                                            >
                                              {convertPriceToString(
                                                product.price
                                              )}
                                            </Typography>
                                          </div>
                                          <div className={classes.imgContainer}>
                                            <CardMedia
                                              className={classes.media}
                                              src={product.image}
                                              title={product.title}
                                              component="img"
                                            />
                                          </div>

                                          <CardContent>
                                            <Typography
                                              variant="h6"
                                              className={classes.shopName}
                                            >
                                              {product.title}
                                            </Typography>
                                          </CardContent>
                                        </CardActionArea>
                                      </Card>
                                    </Paper>
                                  </Grid>
                                </div>
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Hidden>
                </div>
              ) : (
                <div>
                  <Hidden smDown implementation="css">
                    <Grid style={{ height: "100vh" }}>
                      <div className={classes.flexCenter}>
                        <img
                          src={constantsConfig.imgSell}
                          className={classes.imgSell}
                          alt="productImage"
                        />
                      </div>
                      <div className={classes.flexCenter}>
                        <Typography
                          variant="h3"
                          gutterBottom
                          className={classes.noFavoritesProducts}
                        >
                          {intl.formatMessage({
                            id: "no_favorite_products",
                          })}
                        </Typography>
                      </div>
                    </Grid>
                  </Hidden>
                  <Hidden smUp implementation="css">
                    <Grid style={{ height: "75vh" }}>
                      <div className={classes.flexCenter}>
                        <img
                          src={constantsConfig.imgSell}
                          className={classes.imgSellMobile}
                          alt="productImage"
                        />
                      </div>
                      <div className={classes.flexCenter}>
                        <Typography
                          variant="h4"
                          gutterBottom
                          className={classes.noFavoritesProducts}
                        >
                          {intl.formatMessage({
                            id: "no_favorite_products",
                          })}
                        </Typography>
                      </div>
                    </Grid>
                  </Hidden>
                </div>
              )
            ) : (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.spinnerGrid}
              >
                <Grid item xs={3}>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
          </div>
        </Container>
      </main>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    favoriteProducts: state.firestore.ordered.favorites,
  };
};

export default injectIntl(
  withRouter(
    compose(
      withStyles(styles),
      connect(mapStateToProps),
      firestoreConnect((props) => {
        return [
          {
            collection: constantsConfig.collectionFavorites,
            where: ["userId", "==", props.router.params.id],
            storeAs: constantsConfig.collectionFavorites,
          },
        ];
      })
    )(DashboardMyFavoriteProducts)
  )
);
