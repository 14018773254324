import React, { useState } from 'react';
import { connect } from 'react-redux';
import { checkoutPayment } from '../../store/payments/PaymentActions';
import { Button, CircularProgress } from '@mui/material';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { constantsConfig } from '../../config/ConstantsConfig';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonWatting: {
    backgroundColor: theme.palette.primary.secondaryText,
    width: '100%'
  },
  Description: {
    padding: '10px',
    fontFamily: 'Quicksand',
    fontWeight: 'bold'
  }
}));

function Checkout(props) {
  const classes = useStyles();
  const [loading, setloading] = useState(false);

  const { checkoutPayment, intl, payInformation, product } = props;

  const handleClick = async (event) => {
    setloading(true);

    checkoutPayment({
      priceId: payInformation.boostPriceId,
      successfulData: {
        paymentValue: payInformation.amount / 100,
        currencyCode: 'USD',
        countryCode: 'US',
        userId: product.sellerId,
        productId: product.id,
        timestamp: new Date().getTime(),
        boostProductId: payInformation.boostProductId,
        boostProductName: payInformation.boostProductName,
        platform: 'web',
        days: payInformation.days
      },
      successUlr:
        constantsConfig.mainUrl +
        'product/' +
        product.id +
        constantsConfig.successUlr,
      cancelUlr:
        constantsConfig.mainUrl +
        'product/' +
        product.id +
        constantsConfig.cancelUlr
    });
  };
  return (
    <div className={classes.wrapper}>
      <React.Fragment>
        <Button
          id={'payment-button' + payInformation.amount}
          color="primary"
          variant="contained"
          className={!loading ? classes.button : classes.buttonWatting}
          disabled={loading}
          onClick={handleClick}
        >
          {intl.formatMessage({
            id: 'boost'
          })}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </React.Fragment>
    </div>
  );
}
const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkoutPayment: (checkoutData) => dispatch(checkoutPayment(checkoutData))
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
