// @flow
import React from "react";
import { useLottie } from "lottie-react";
import animationData from "../../animations/spinner_animation.json";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 400,
    width: 400,
  },
}));

const SplashScreen = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const classes = useStyles();
  const { View } = useLottie(defaultOptions);
  return <div className={classes.root}>{View}</div>;
};

export default SplashScreen;
