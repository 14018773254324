// @flow
import React, { Component } from "react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  Input,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Button,
  DialogContent,
  Dialog,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForword from "@mui/icons-material/ArrowForward";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SendIcon from "@mui/icons-material/Send";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import {
  createMessage,
  closeConfirmLegalMessage,
  seenMessage,
  deleteMessage,
} from "../../store/messages/NewMessagesAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import PropTypes from 'prop-types';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/functions";
import ReportUserComponent from "../products/report/ReportUserComponent";
import {
  //createReport,
  blockUser,
  unBlockUser,
  clearBlockVariables,
} from "../../store/report/ReportActions";
import { injectIntl } from "react-intl";
import { constantsConfig } from "../../config/ConstantsConfig";
import { globalLanguage } from "../../theme/ThemeContext";
import StarRating from "../products/StarRating";
import { addRateReview } from "../layout/AddRateReview";
import MultipleImageUpload from "../products/images/MultipleImageUpload";
import { addToCartLogging } from "../../store/analyticsLogging/GoogleLogging";
import { addToCartFacebookPixel } from "../../store/analyticsLogging/FacebookPixel";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import * as rdd from "react-device-detect";
import ReactTimeAgo from "react-time-ago";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import MoreIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import MeetingPlanDialog from "./MeetingPlanDialog";
import PlanNameDialog from "./PlanNameDialog";
import EditMeetingPlanDetails from "./EditMeetingPlanDetails";

let options = {
  weekday: "long",
  month: "long",
  day: "numeric",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// import ReactTimeAgo from "react-time-ago";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    overflowX: "hidden",
    backgroundColor: theme.palette.primary.background,
  },
  flex: {
    alignItems: "center",
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.icons,
  },
  flexChatBox: {
    margin: "10px",
    display: "flex",
    alignItems: "center",
    bottom: 0,
    height: "30px",
  },
  updateContent: { alignItems: "center" },
  typographyRateBuyer: {
    color: theme.palette.primary.icons,
    fontSize: 18,
    marginRight: "100px",
    marginTop: "7px",
  },
  /* topicWindow: {
    width: "30%",
    height: "300px",
    borderRight: "1px solid grey"
  },*/
  messageContainer: {
    color: theme.palette.primary.icons,
    padding: "10px",
    backgroundColor: theme.palette.primary.secondaryText,
    borderRadius: "10px",
    fontSize: "12px",
    maxWidth: "250px",
  },
  flexHeder: {
    height: "60px",
    display: "flex",
    alignItems: "left",
    backgroundColor: theme.palette.primary.main, //BADCF9
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em",
    },
  },
  iconButton: {
    color: theme.palette.primary.icons,
  },
  messageContainerReceiver: {
    color: theme.palette.primary.icons,
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    fontSize: "12px",
    maxWidth: "250px",
  },
  messageTitle: {
    color: theme.palette.primary.icons,
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    marginInlineEnd: "5px",
  },
  messages: {
    justifyContent: "flex-end",
  },
  list: {
    backgroundColor: theme.palette.primary.background,
    overflow: "auto",
    height: window.innerHeight - 220,
    boxSizing: "border-box",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.30)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.secondaryText,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      height: window.innerHeight - 200,
    },
  },
  listWithConfirmMessage: {
    backgroundColor: theme.palette.primary.background,
    overflow: "auto",
    height: window.innerHeight - 300,
    boxSizing: "border-box",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.30)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.secondaryText,
      outline: "1px solid slategrey",
    },
  },
  listDialog: {
    overflowY: "scroll",

    height: "300px",
    boxSizing: "border-box",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.30)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.secondaryText,
      outline: "1px solid slategrey",
    },
  },
  chatWindow: {
    width: "100%",
    backgroundColor: theme.palette.primary.background,
  },
  chatBox: { width: "95%" },
  buttonMobile: { width: "9%" },
  button: { width: "5%" },
  orderDialog: {
    height: "100%",
    padding: "10px",
  },
  moreVertIcon: {
    color: theme.palette.primary.icons,
  },
  arrowBackIcon: { color: theme.palette.primary.icons },
  image: {
    marginLeft: 0,
    marginTop: 0,
  },
  imageDiv: {
    marginTop: "0px",
    width: "90%",
    padding: theme.spacing(0, 2, 0, 2),
  },
  showImage: {
    maxWidth: "200px",
    maxHeight: "200px",
  },
  containerXIcon: { backgroundColor: theme.palette.primary.background },
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "2.5px",
    width: "15px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
  containerConfirmLegalMessage: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  containerIsProductSold: {
    display: "flex",
    justifyContent: "center",
    padding: "15px",
  },
  typographyReactTimeAgo: {
    color: theme.palette.primary.icons,
    fontSize: "10px",
    fontFamily: "Quicksand",
    fontWeight: "bold",
  },
  avatarComponent: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: "none",
    backgroundColor: theme.palette.primary.background,
    // color: theme.palette.text.secondary,
  },
  buttonMessage: {
    ...theme.typography.normalButton,
    borderRadius: "5em",
    fontSize: "0.8rem",
  },
  containerImageUpload: {
    backgroundColor: theme.palette.primary.background,
  },
  normalButton: {
    ...theme.typography.normalButton,
    color: theme.palette.primary.dark,
  },
  moreIcon: {
    "&:hover": { cursor: "pointer" },
  },
  listItemAvatarBedtimeIcon: {
    marginTop: 1,
    minWidth: 0,
  },
  timeZoneTypography: {
    display: "inline",
    color: theme.palette.primary.secondaryText,
    [theme.breakpoints.down("sm")]: {
      fontSize: "6px",
    },
    // fontSize: "6px",
  },
  bedtimeIcon: {
    color: theme.palette.primary.accent,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
});

class MessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMessage: "",
      messagesList: [],
      messagingToken: "",
      anchorEl: null,
      anchorElDeleteMessage: null,
      messagingTokenType: "",
      shouldScroll: false,
      openOrederDialog: false,
      currentOrderId: null,
      ordertime: null,
      openMeetingPlan: false,
      openPlanNameDialog: false,
      openEditMeetingPlan: false,
      message: null,
      block: props.thread ? (props.thread.blockerUserId ? true : false) : false,
      senderConfirmLegalMessage: props.thread
        ? !props.thread.senderConfirmLegalTimestamp
          ? true
          : false
        : false,
      receiverConfirmLegalMessage: props.thread
        ? !props.thread.receiverConfirmLegalTimestamp
          ? true
          : false
        : false,
      rate: false,
      openSnackBar: false,
      photoURL: null,
      currentImagesURL: [],
      coverImageIndex: -1,
      spinner: false,
      imageUrl1: null,
      imageUrl2: null,
      imageUrl3: null,
      imageUrl4: null,
      imageUrl5: null,
      userMustShowSeen: false,
      seenWord: false,
      threadId: null,
      messageId: null,
      timeZone: null,
      buttonMessage1: props.intl.formatMessage({
        id: "product_available_question",
      }),
      buttonMessage2: props.intl.formatMessage({
        id: "buy_question",
      }),
      buttonMessage3: props.intl.formatMessage({
        id: "meet_question",
      }),
    };

    this.onSendHandle = this.onSendHandle.bind(this);
    this.onCloseThread = this.onCloseThread.bind(this);
    this.messagescontainerRef = React.createRef();
  }
  onCloseThread() {
    this.props.onCloseThread();
  }

  handleCloseRating = () => {
    this.setState({ rate: false });
  };
  handleSubmitRating = (
    ratingScore,
    isPositiveReview,
    reviewDetails,
    reviewsNames
  ) => {
    const Rate = {
      ratingScore: ratingScore,
      ratingFromUserId: this.props.auth.uid,
      ratingToUserId:
        this.props.thread.senderId === this.props.auth.uid
          ? this.props.thread.receiverId
          : this.props.thread.senderId,
      ratingFromType: "seller",
      subjectId: this.props.thread.subjectId,
    };
    var reviews = [];
    reviewsNames.map((i) => {
      const rev = {
        reviewTypeName: i.reviewTypeName,

        reviewTypeId: this.getReviewTypeId(i.reviewTypeName),
        isPositiveReview: isPositiveReview,
        reviewDetails: reviewDetails,
        reviewFromUserId: this.props.auth.uid,
        reviewToUserId:
          this.props.thread.senderId === this.props.auth.uid
            ? this.props.thread.receiverId
            : this.props.thread.senderId,
        reviewFromType: "seller",
        subjectId: this.props.thread.subjectId,
      };
      reviews.push(rev);
      return reviews;
    });
    if (reviewDetails !== "") {
      const rev = {
        reviewTypeName: "Other",

        reviewTypeId: this.getReviewTypeId("Other"),
        isPositiveReview: isPositiveReview,
        reviewDetails: reviewDetails,
        reviewFromUserId: this.props.auth.uid,
        reviewToUserId:
          this.props.thread.senderId === this.props.auth.uid
            ? this.props.thread.receiverId
            : this.props.thread.senderId,
        reviewFromType: "seller",
        subjectId: this.props.thread.subjectId,
      };
      reviews.push(rev);
    }

    const dataRateReview = {
      rateData: Rate,
      reviewData: reviews ? (reviews.length !== 0 ? reviews : null) : null,
    };
    addRateReview(dataRateReview);

    this.handleCloseRating();
  };
  getReviewTypeId = (reviewTypeName) => {
    switch (reviewTypeName) {
      case "Late":
        return "RCDEi1XrGpfrFTIEfxpb";
      case "OnTime":
        return "F2Pp5B3pIyiZxEVCwJ8a";
      case "Reliable":
        return "JiI7StWTQdZf6KO0stTZ";
      case "Unreliable":
        return "05DKHx4nxIndTulBHRcT";
      case "Communicative":
        return "sSB1N5DKURaYkUT0FxuY";
      case "Uncommunicative":
        return "f0SSi5j9PlxkVEWXJH8s";
      case "Friendly":
        return "2NYgwZHl7dWsq47Dhg5A";
      case "Impolite":
        return "48c5y1z9mWSatPu6qtfb";
      case "Item as described":
        return "ORYMazy0aOt7Mq1tFLuP";
      case "Item not as described":
        return "RhX8dPiul2rBDUBiyKq2";
      case "Other":
        return "9P1GUDsofUoRa1GXkmLn";
      default:
        return;
    }
  };

  onSendHandle = async (e, buttonMessage) => {
    var thisRef = this;
    if (
      thisRef.state.currentMessage !== "" ||
      thisRef.state.imageUrl1 !== null ||
      buttonMessage !== null
    ) {
      thisRef.setState({
        spinner: true,
        seenWord: false,
      });
      const { createMessage, thread, auth } = this.props;
      var receiverId = thread.receiverId;
      var receiverName = thread.receiverName;
      if (receiverId === auth.uid) {
        receiverName = thread.senderName;
        receiverId = thread.senderId;
      }
      this.setState({
        currentMessage: "",
      });
      try {
        createMessage({
          message: {
            messageType: 1,
            name: receiverName,
            subjectId: thread.subjectId,
            text:
              buttonMessage === undefined || buttonMessage === null
                ? thisRef.state.currentMessage
                : buttonMessage,
            userId: auth.uid,
            imageUrl1: thisRef.state.imageUrl1 ? thisRef.state.imageUrl1 : null,
            imageUrl2: thisRef.state.imageUrl2 ? thisRef.state.imageUrl2 : null,
            imageUrl3: thisRef.state.imageUrl3 ? thisRef.state.imageUrl3 : null,
            imageUrl4: thisRef.state.imageUrl4 ? thisRef.state.imageUrl4 : null,
            imageUrl5: thisRef.state.imageUrl5 ? thisRef.state.imageUrl5 : null,
            timestamp: new Date().getTime(),
          },
          threadId: thisRef.props.thread.threadId,
          receiverId: thread.receiverId,
          senderId: thread.senderId,
          uid: auth.uid,
          thread: thread,
        });

        // Read result of the Cloud Function.
        thisRef.setState({
          spinner: false,
          imageUrl1: null,
          imageUrl2: null,
          imageUrl3: null,
          imageUrl4: null,
          imageUrl5: null,
          currentImagesURL: [],
          coverImageIndex: -1,
          // shouldScroll: true,
        });
        if (
          thisRef.props.buyerMessages.length === 1 ||
          thisRef.props.buyerMessages.length === 2
        ) {
          var product = {
            id: thisRef.props.thread.subjectId,
            title: thisRef.props.thread.subjectName,
          };
          var productPixel = {
            title: thisRef.props.thread.subjectName,
            category: thisRef.props.categoryProduct,
          };
          thisRef.props.addToCartLogging(product);
          thisRef.props.addToCartFacebookPixel(productPixel);
        }
      } catch (err) {}
      this.setState({ shouldScroll: true });
    }
  };

  onSendMeetingPlan = async (currentMessage) => {
    var thisRef = this;
    thisRef.setState({
      spinner: true,
      seenWord: false,
    });
    const { createMessage, thread, auth } = this.props;
    var receiverId = thread.receiverId;
    var receiverName = thread.receiverName;
    if (receiverId === auth.uid) {
      receiverName = thread.senderName;
      receiverId = thread.senderId;
    }
    this.setState({
      currentMessage: "",
    });
    try {
      createMessage({
        message: {
          messageType: 12,
          name: receiverName,
          subjectId: thread.subjectId,
          text: currentMessage.text,
          planDate: currentMessage.planDate,
          planTime: currentMessage.planTime,
          planName: "",
          userId: auth.uid,
          imageUrl1: thisRef.state.imageUrl1 ? thisRef.state.imageUrl1 : null,
          imageUrl2: thisRef.state.imageUrl2 ? thisRef.state.imageUrl2 : null,
          imageUrl3: thisRef.state.imageUrl3 ? thisRef.state.imageUrl3 : null,
          imageUrl4: thisRef.state.imageUrl4 ? thisRef.state.imageUrl4 : null,
          imageUrl5: thisRef.state.imageUrl5 ? thisRef.state.imageUrl5 : null,
          timestamp: new Date().getTime(),
        },
        threadId: thisRef.props.thread.threadId,
        receiverId: thread.receiverId,
        senderId: thread.senderId,
        uid: auth.uid,
        thread: thread,
      });

      this.handleCloseMeetingPlan();
      if (
        thisRef.props.buyerMessages.length === 1 ||
        thisRef.props.buyerMessages.length === 2
      ) {
        var product = {
          id: thisRef.props.thread.subjectId,
          title: thisRef.props.thread.subjectName,
        };
        var productPixel = {
          title: thisRef.props.thread.subjectName,
          category: thisRef.props.categoryProduct,
        };
        thisRef.props.addToCartLogging(product);
        thisRef.props.addToCartFacebookPixel(productPixel);
      }
    } catch (err) {}
    this.setState({ shouldScroll: true });
  };

  completeUpload = (url) => {
    this.setState({
      photoURL: url,
    });
  };

  onChangeText = (message) => {
    this.setState({
      currentMessage: message.target.value,
    });
  };

  handleKeyPress = (target) => {
    //on press enter
    if (target.charCode === 13) {
      this.onSendHandle(target, null);
    }
  };

  scrollDown = () => {
    this.messagescontainerRef.current.scrollTop =
      this.messagescontainerRef.current.scrollHeight;
  };
  UNSAFE_componentWillMount() {
    const { thread, auth } = this.props;
    var receiverId = thread.receiverId;
    if (receiverId === auth.uid) {
      receiverId = thread.senderId;
    }

    firebase
      .firestore()
      .collection("users")
      .doc(receiverId)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) {
          if (data.latestTokenPlatform) {
            switch (data.latestTokenPlatform) {
              case "web":
                this.setState({
                  messagingToken: data.webMessagingToken,
                  messagingTokenType: "web",
                });
                break;
              case "ios":
                this.setState({
                  messagingToken: data.iOSMessagingToken,
                  messagingTokenType: "ios",
                });
                break;
              case "android":
                this.setState({
                  messagingToken: data.androidMessagingToken,
                  messagingTokenType: "android",
                });
                break;
              default:
                this.setState({ messagingToken: data.messagingToken });
                break;
            }
          } else {
            if (data.messagingToken)
              this.setState({ messagingToken: data.messagingToken });
          }
        }
      });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // check if there are messages didn't seen
    if (nextProps.messages !== null && nextProps.messages !== 0) {
      if (
        nextProps.loadingUpdateMessage === "done" ||
        nextProps.loadingUpdatePlanName === "done"
      ) {
        if (this.state.message)
          for (let i = 0; i < nextProps.messages.length; i++) {
            if (nextProps.messages[i].id === this.state.message.id) {
              this.setState({
                message: nextProps.messages[i],
              });
            }
          }
      }
      if (nextProps.thread.recentMessagesNotSeenByUserId !== null) {
        if (
          nextProps.auth.uid === nextProps.thread.recentMessagesNotSeenByUserId
        ) {
          // call back function to update document of thread
          const threadId = nextProps.thread.threadId;
          nextProps.seenMessage({ threadId });
        }
      }
    }
    if (nextProps.thread) {
      this.setState({
        block: nextProps.thread
          ? nextProps.thread.blockerUserId
            ? true
            : false
          : false,
        senderConfirmLegalMessage: nextProps.thread
          ? !nextProps.thread.senderConfirmLegalTimestamp
            ? true
            : false
          : false,
        receiverConfirmLegalMessage: nextProps.thread
          ? !nextProps.thread.receiverConfirmLegalTimestamp
            ? true
            : false
          : false,
      });
      if (nextProps.blockUserStatus === "success") {
        this.setState({ block: true });
        for (let i = 0; i < nextProps.threads.length; i++) {
          if (nextProps.threads[i].threadId === this.props.thread.threadId) {
            this.props.onClickThread(nextProps.threads[i]);
            this.props.clearBlockVariables();
            break;
          }
        }
      }
      if (nextProps.unBlockUserStatus === "success") {
        this.setState({ block: false });
        for (let i = 0; i < nextProps.threads.length; i++) {
          if (nextProps.threads[i].threadId === this.props.thread.threadId) {
            this.props.onClickThread(nextProps.threads[i]);
            this.props.clearBlockVariables();
            break;
          }
        }
      }
      if (nextProps.timeZone) {
        this.setState({
          timeZone: nextProps.timeZone,
        });
      }
      if (!nextProps.timeZone) {
        this.setState({
          timeZone: null,
        });
      }
    }
  }
  componentDidUpdate() {
    if (
      this.messagescontainerRef.current.scrollTop === 0 || //this.state.shouldScroll &&
      this.messagescontainerRef.current.scrollHeight !==
        this.state.oldScrollHeight
    ) {
      this.scrollDown();
      if (this.state.shouldScroll) {
        this.setState({
          shouldScroll: false,
          oldScrollHeight: this.messagescontainerRef.current.scrollHeight,
        });
      }
      if (this.messagescontainerRef.current.scrollTop > 0) {
        this.setState({
          oldScrollHeight: this.messagescontainerRef.current.scrollHeight,
        });
      }
    }
  }

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleClickMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleCloseDeleteMessageMenu = () => {
    this.setState({
      threadId: null,
      messageId: null,
      anchorElDeleteMessage: null,
    });
  };
  handleClickDeleteMessageMenu = (event, threadId, messageId) => {
    this.setState({
      threadId: threadId,
      messageId: messageId,
      anchorElDeleteMessage: event.currentTarget,
    });
  };

  handleDeleteMessage = () => {
    var info = {
      threadId: this.state.threadId,
      messageId: this.state.messageId,
    };
    this.props.deleteMessage(info);
    this.handleCloseDeleteMessageMenu();
  };

  handelCloseOrder = () => {
    this.setState({ openOrederDialog: false });
  };

  handleCloseConfirmLegalMessage = () => {
    const uid = this.props.auth.uid;
    const thread = this.props.thread;
    this.props.closeConfirmLegalMessage({ uid, thread });
  };

  handelOpenOrder = (orderId) => {
    firebase
      .firestore()
      .collection("orders")
      .doc(orderId)
      .get()
      .then((doc) => {
        const order = doc.data();
        var date_ob = new Date(order.lastModifiedTimestamp);
        var dss = date_ob.toLocaleString().split(",");
        this.setState({
          openOrederDialog: true,
          currentOrderId: orderId,
          ordertime: dss[1],
        });
      })
      .catch((err) => {});
  };

  handleOpenRateReview = async (subjectId, message) => {
    const query = firebase
      .firestore()
      .collection("ratings")
      .where("subjectId", "==", subjectId)
      .where("ratingFromType", "==", "seller");

    const snapshot = await query.get();
    const items = snapshot.docs.map((doc) => doc.data());
    const result = items.length;
    if (result === 0 && message.userId !== this.props.auth.uid) {
      this.setState({ rate: true });
    } else this.setState({ openSnackBar: true });
  };
  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false });
  };

  handleOpenMeetingPlan = () => {
    this.setState({
      openMeetingPlan: true,
    });
  };

  handleCloseMeetingPlan = () => {
    this.setState({
      openMeetingPlan: false,
    });
  };

  handleOpenPlanNameDialog = (message) => {
    this.setState({
      message: message,
      openPlanNameDialog: true,
    });
  };

  handleClosePlanNameDialog = () => {
    this.setState({
      // message: null,
      openPlanNameDialog: false,
    });
  };

  handleOpenEditMeetingPlan = (message) => {
    this.setState({
      message: message,
      openEditMeetingPlan: true,
    });
  };

  handleCloseEditMeetingPlan = () => {
    this.setState({
      openEditMeetingPlan: false,
      // message: null,
    });
  };

  handleBlockUser = () => {
    const { auth, /*createReport,*/ thread, blockUser } = this.props;
    var subjectId, subjectName;
    if (thread.senderId === auth.uid) {
      subjectId = thread.receiverId;
      subjectName = thread.receiverName;
    } else {
      subjectId = thread.senderId;
      subjectName = thread.senderName;
    }
    // createReport({
    //   reportName: 'Block User',
    //   reportType: 3,
    //   reportTypeId: 'JMY6hWSDH2OuYbWALGoH',
    //   reportTypeName: 'Block User',
    //   subjectId: subjectId,
    //   subjectName: subjectName,
    //   reporterId: auth.uid,
    //   threadId: this.props.thread.threadId
    // });

    blockUser({
      blockedUserId: subjectId,
      subjectName: subjectName,
      blockerUserId: auth.uid,
      threadId: thread.threadId,
    });
    this.setState({ block: true });
  };

  handleUnBlockUser = () => {
    const { thread, reports } = this.props;
    const threadId = thread.threadId;
    this.props.unBlockUser({ threadId });
    if (reports.blockSpinner !== "loading") this.setState({ block: false });
  };

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "onTagsProcess":
        //  const category=predictCategory()
        this.setState({
          onTagsProcess: value + this.state.onTagsProcess,
        });
        break;
      case "images":
        switch (value) {
          case 0:
            this.setState({
              imageUrl1: "",
            });
            for (let i = 2; i <= 5; i++) {
              if (i === 2) {
                if (this.state.imageUrl2 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl2,
                    imageUrl2: "",
                  });
                  break;
                }
              } else if (i === 3) {
                if (this.state.imageUrl3 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl3,
                    imageUrl3: "",
                  });
                  break;
                }
              } else if (i === 4) {
                if (this.state.imageUrl4 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl4,
                    imageUrl4: "",
                  });
                  break;
                }
              } else {
                if (this.state.imageUrl5 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl5,
                    imageUrl5: "",
                  });
                  break;
                }
              }
            }
            break;
          case 1:
            this.setState({
              imageUrl2: "",
            });
            break;
          case 2:
            this.setState({
              imageUrl3: "",
            });
            break;
          case 3:
            this.setState({
              imageUrl4: "",
            });
            break;
          case 4:
            this.setState({
              imageUrl5: "",
            });
            break;
          default:
            break;
        }
        this.state.currentImagesURL.splice(value, 1);

        break;
      case "image":
        switch (value.index) {
          case 0:
            this.setState({
              imageUrl1: value.url,
            });
            break;
          case 1:
            this.setState({
              imageUrl2: value.url,
            });
            break;
          case 2:
            this.setState({
              imageUrl3: value.url,
            });
            break;
          case 3:
            this.setState({
              imageUrl4: value.url,
            });
            break;
          case 4:
            this.setState({
              imageUrl5: value.url,
            });
            break;
          default:
            break;
        }

        break;
      case "coverImageIndex":
        this.changeCoverIndex(value);

        break;
      case "tags":
        var onTagsProcess = this.state.onTagsProcess - 1;

        this.setState({
          onTagsProcess: onTagsProcess,
          tags: value,
        });
        this.changeCategoryAutomatically();

        break;

      //default value...
      default:
        break;
    }
  };

  onHandleChangeValue(whatChange, value) {
    if (whatChange === "images") {
      this.setState({
        photoURL: value,
      });
    }
  }
  onHandleChangeImage = (type, value) => {
    if (type === "image") {
      //    visionGoogle(value.url, this.onHandleTags);
    }
    this.handleChangeValue(type, value);
  };
  handleOpenSellerProfile = (thread) => {
    this.props.router.navigate("/userProfile/" + thread.receiverId);
  };

  render() {
    const { messages, classes, auth, thread, intl, userStatus } = this.props;
    var blockInfo = {
      threadHaveBlock: false,
      blockerIsMe: false,
      textToUser: "",
    };
    if (thread.blockerUserId) {
      blockInfo.threadHaveBlock = true;
      if (thread.blockerUserId === auth.uid) {
        blockInfo.blockerIsMe = true;
        blockInfo.textToUser = "You block this user";
      } else {
        blockInfo.textToUser = "This user block you";
      }
    }
    return (
      <Paper className={classes.root}>
        <Dialog
          fullScreen
          open={this.state.openEditMeetingPlan}
          onClose={this.handleCloseEditMeetingPlan}
          TransitionComponent={Transition}
        >
          <EditMeetingPlanDetails
            auth={auth}
            message={this.state.message}
            thread={this.props.thread}
            locationConfig={this.props.locationConfig}
            handleCloseEditMeetingPlan={this.handleCloseEditMeetingPlan}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          onClose={this.handleCloseMeetingPlan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openMeetingPlan}
        >
          <MeetingPlanDialog
            onSendMeetingPlan={this.onSendMeetingPlan}
            handleCloseMeetingPlan={this.handleCloseMeetingPlan}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openPlanNameDialog}
        >
          <PlanNameDialog
            // onSendMeetingPlan={this.onSendMeetingPlan}
            thread={this.props.thread}
            message={this.state.message}
            handleClosePlanNameDialog={this.handleClosePlanNameDialog}
          />
        </Dialog>
        <Menu
          anchorEl={this.state.anchorElDeleteMessage}
          keepMounted
          open={Boolean(this.state.anchorElDeleteMessage)}
          onClose={this.handleCloseDeleteMessageMenu}
        >
          <MenuItem onClick={this.handleDeleteMessage}>
            {/* <IconButton> */}
            <DeleteIcon
              sx={{ color: (theme) => theme.palette.primary.accent }}
            />
            {/* </IconButton> */}
            {intl.formatMessage({
              id: "delete",
            })}
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleCloseMenu}
        >
          {!this.state.block ? (
            <div>
              <ReportUserComponent thread={thread} />
              <MenuItem
                onClick={() => {
                  this.handleBlockUser();
                  this.handleCloseMenu();
                }}
                key={2}
              >
                {intl.formatMessage({
                  id: "block",
                })}
              </MenuItem>
            </div>
          ) : (
            <MenuItem
              onClick={() => {
                this.handleUnBlockUser();
                this.handleCloseMenu();
              }}
              key={2}
            >
              {intl.formatMessage({
                id: "un_block",
              })}
            </MenuItem>
          )}
        </Menu>
        {this.props.reports.blockSpinner ? (
          this.props.reports.blockSpinner !== false ? (
            <Backdrop
              className={classes.backdrop}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          ) : null
        ) : null}
        <div className={classes.flexHeder}>
          <div className={classes.flex}>
            {window.innerWidth >= 0 && window.innerWidth < 600 && (
              <IconButton onClick={this.onCloseThread}>
                <ArrowBackIcon className={classes.arrowBackIcon} />
              </IconButton>
            )}

            <div className={classes.avatar}>
              <Link
                to={
                  auth.uid === this.props.thread.senderId
                    ? "/userProfile/" + this.props.thread.receiverId
                    : "/userProfile/" + this.props.thread.senderId
                }
              >
                <Avatar
                  className={classes.avatarComponent}
                  src={
                    auth.uid === this.props.thread.senderId
                      ? this.props.thread.receiverPhoto
                      : this.props.thread.senderPhoto
                  }
                  /*onClick={() => {
                    this.handleOpenSellerProfile(thread);
                  }}*/
                />
              </Link>
            </div>
            <div>
              {this.props.isChatWithAdmin !== "isChatWithAdmin" ? (
                <Link
                  to={
                    auth.uid === this.props.thread.senderId
                      ? "/userProfile/" + this.props.thread.receiverId
                      : "/userProfile/" + this.props.thread.senderId
                  }
                  style={{ height: "0px", textDecoration: "none" }}
                >
                  <Typography variant="h5" className={classes.messageTitle}>
                    {auth.uid === this.props.thread.senderId
                      ? this.props.thread.receiverName
                      : this.props.thread.senderName}
                  </Typography>
                </Link>
              ) : (
                <Typography variant="h5" className={classes.messageTitle}>
                  {auth.uid === this.props.thread.senderId
                    ? this.props.thread.receiverName
                    : this.props.thread.senderName}
                </Typography>
              )}
              {userStatus && !this.props.thread.blockerUserId ? (
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  className={classes.typographyReactTimeAgo}
                >
                  <ReactTimeAgo
                    date={new Date(userStatus.lastSeenTimestamp)}
                    style={{ textTransform: "none" }}
                  />
                </Typography>
              ) : null}
            </div>
          </div>
          {!this.props.thread.blockerUserId &&
          !this.props.isAdmin &&
          this.props.isChatWithAdmin !== "isChatWithAdmin" ? (
            <IconButton onClick={this.handleClickMenu}>
              <MoreVertIcon className={classes.moreVertIcon} />
            </IconButton>
          ) : this.props.thread.blockerUserId === this.props.auth.uid ? (
            <IconButton onClick={this.handleClickMenu}>
              <MoreVertIcon className={classes.moreVertIcon} />
            </IconButton>
          ) : null}
        </div>

        <div className={classes.chatWindow}>
          {!thread.isProductSold ? (
            <div>
              {auth.uid === thread.senderId ? (
                <div>
                  {this.state.senderConfirmLegalMessage ? (
                    <div>
                      <div className={classes.containerXIcon}>
                        <div>
                          <CloseIcon
                            className={classes.xIcon}
                            onClick={this.handleCloseConfirmLegalMessage}
                          />
                        </div>
                      </div>
                      <div className={classes.containerConfirmLegalMessage}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="center"
                          className={
                            !rdd.isMobile
                              ? classes.typographyConfirmLegalMessage
                              : classes.typographyConfirmLegalMessageMobile
                          }
                        >
                          {intl.formatMessage({
                            id: "confirm_legal_message",
                          })}
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  {this.state.receiverConfirmLegalMessage ? (
                    <div>
                      <div className={classes.containerXIcon}>
                        <div>
                          <CloseIcon
                            className={classes.xIcon}
                            onClick={this.handleCloseConfirmLegalMessage}
                          />
                        </div>
                      </div>
                      <div className={classes.containerConfirmLegalMessage}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="center"
                          className={classes.typographyConfirmLegalMessage}
                        >
                          {intl.formatMessage({
                            id: "confirm_legal_message",
                          })}
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : (
            <div>
              {auth.uid === thread.senderId ? (
                <div className={classes.containerIsProductSold}>
                  <Typography variant="subtitle2" gutterBottom align="center">
                    {intl.formatMessage({
                      id: "product_is_sold",
                    })}
                  </Typography>
                </div>
              ) : null}
            </div>
          )}
          <List
            className={
              this.props.isDialog
                ? classes.listDialog
                : auth.uid === thread.senderId
                ? !thread.senderConfirmLegalTimestamp
                  ? classes.listWithConfirmMessage
                  : classes.list
                : !thread.isProductSold
                ? !thread.receiverConfirmLegalTimestamp
                  ? classes.listWithConfirmMessage
                  : classes.list
                : classes.list
            }
            ref={this.messagescontainerRef}
          >
            {messages &&
              Object.keys(messages).map((msgId) => {
                var message = messages[msgId];
                var messageRender = null;
                switch (message.messageType) {
                  case 1:
                    if (!this.props.profile.isAdmin) {
                      // if user to user
                      messageRender =
                        auth.uid === message.userId ? (
                          <ListItem className={classes.messages}>
                            <MoreIcon
                              className={classes.moreIcon}
                              onClick={(e) => {
                                this.handleClickDeleteMessageMenu(
                                  e,
                                  thread.threadId,
                                  message.id
                                );
                              }}
                            />
                            {
                              <div>
                                {message.imageUrl1 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl1}
                                      className={classes.showImage}
                                      alt="imageUrl1"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl2 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl2}
                                      className={classes.showImage}
                                      alt="imageUrl2"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl3 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl3}
                                      className={classes.showImage}
                                      alt="imageUrl3"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl4 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl4}
                                      className={classes.showImage}
                                      alt="imageUrl4"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl5 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl5}
                                      className={classes.showImage}
                                      alt="imageUrl5"
                                    />
                                  </div>
                                ) : null}
                                {!message.text || message.text === "" ? null : (
                                  <Typography
                                    className={classes.messageContainer}
                                  >
                                    {message.text}
                                  </Typography>
                                )}
                              </div>
                            }
                          </ListItem>
                        ) : (
                          <ListItem>
                            {
                              <div>
                                {message.imageUrl1 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl1}
                                      className={classes.showImage}
                                      alt="imageUrl1"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl2 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl2}
                                      className={classes.showImage}
                                      alt="imageUrl2"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl3 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl3}
                                      className={classes.showImage}
                                      alt="imageUrl3"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl4 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl4}
                                      className={classes.showImage}
                                      alt="imageUrl4"
                                    />
                                  </div>
                                ) : null}
                                {message.imageUrl5 ? (
                                  <div>
                                    <img
                                      src={message.imageUrl5}
                                      className={classes.showImage}
                                      alt="imageUrl5"
                                    />
                                  </div>
                                ) : null}
                                {!message.text || message.text === "" ? null : (
                                  <Typography
                                    className={classes.messageContainerReceiver}
                                  >
                                    {message.text}
                                  </Typography>
                                )}
                              </div>
                            }
                          </ListItem>
                        );
                    } else {
                      if (
                        window.location.href ===
                        constantsConfig.mainUrl + "threads"
                      ) {
                        messageRender =
                          auth.uid === message.userId ? ( // if admin with user
                            <ListItem className={classes.messages}>
                              <MoreIcon
                                className={classes.moreIcon}
                                onClick={(e) => {
                                  this.handleClickDeleteMessageMenu(
                                    e,
                                    thread.threadId,
                                    message.id
                                  );
                                }}
                              />
                              {
                                <div>
                                  {message.imageUrl1 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl1}
                                        className={classes.showImage}
                                        alt="imageUrl1"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl2 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl2}
                                        className={classes.showImage}
                                        alt="imageUrl2"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl3 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl3}
                                        className={classes.showImage}
                                        alt="imageUrl3"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl4 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl4}
                                        className={classes.showImage}
                                        alt="imageUrl4"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl5 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl5}
                                        className={classes.showImage}
                                        alt="imageUrl5"
                                      />
                                    </div>
                                  ) : null}
                                  {!message.text ||
                                  message.text === "" ? null : (
                                    <Typography
                                      className={classes.messageContainer}
                                    >
                                      {message.text}
                                    </Typography>
                                  )}
                                </div>
                              }
                            </ListItem>
                          ) : (
                            <ListItem>
                              {
                                <div>
                                  {message.imageUrl1 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl1}
                                        className={classes.showImage}
                                        alt="imageUrl1"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl2 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl2}
                                        className={classes.showImage}
                                        alt="imageUrl2"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl3 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl3}
                                        className={classes.showImage}
                                        alt="imageUrl3"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl4 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl4}
                                        className={classes.showImage}
                                        alt="imageUrl4"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl5 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl5}
                                        className={classes.showImage}
                                        alt="imageUrl5"
                                      />
                                    </div>
                                  ) : null}
                                  {!message.text ||
                                  message.text === "" ? null : (
                                    <Typography
                                      className={
                                        classes.messageContainerReceiver
                                      }
                                    >
                                      {message.text}
                                    </Typography>
                                  )}
                                </div>
                              }
                            </ListItem>
                          );
                      } else {
                        // if admin show messages between users
                        messageRender =
                          message.userId === this.props.thread.senderId ? (
                            <ListItem className={classes.messages}>
                              {
                                <div>
                                  {message.imageUrl1 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl1}
                                        className={classes.showImage}
                                        alt="imageUrl1"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl2 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl2}
                                        className={classes.showImage}
                                        alt="imageUrl2"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl3 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl3}
                                        className={classes.showImage}
                                        alt="imageUrl3"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl4 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl4}
                                        className={classes.showImage}
                                        alt="imageUrl4"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl5 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl5}
                                        className={classes.showImage}
                                        alt="imageUrl5"
                                      />
                                    </div>
                                  ) : null}
                                  {!message.text ||
                                  message.text === "" ? null : (
                                    <Typography
                                      className={classes.messageContainer}
                                    >
                                      {message.text}
                                    </Typography>
                                  )}
                                </div>
                              }
                            </ListItem>
                          ) : (
                            <ListItem>
                              {
                                <div>
                                  {message.imageUrl1 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl1}
                                        className={classes.showImage}
                                        alt="imageUrl1"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl2 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl2}
                                        className={classes.showImage}
                                        alt="imageUrl2"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl3 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl3}
                                        className={classes.showImage}
                                        alt="imageUrl3"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl4 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl4}
                                        className={classes.showImage}
                                        alt="imageUrl4"
                                      />
                                    </div>
                                  ) : null}
                                  {message.imageUrl5 ? (
                                    <div>
                                      <img
                                        src={message.imageUrl5}
                                        className={classes.showImage}
                                        alt="imageUrl5"
                                      />
                                    </div>
                                  ) : null}
                                  {!message.text ||
                                  message.text === "" ? null : (
                                    <Typography
                                      className={
                                        classes.messageContainerReceiver
                                      }
                                    >
                                      {message.text}
                                    </Typography>
                                  )}
                                </div>
                              }
                            </ListItem>
                          );
                      }
                    }
                    break;
                  case 2:
                    var orderId = message.subjectId;
                    messageRender =
                      auth.uid === message.userId ? (
                        <ListItem className={classes.messages}>
                          <IconButton
                            onClick={() => this.handelOpenOrder(orderId)}
                          >
                            <ShoppingCartIcon />
                          </IconButton>

                          <Button
                            style={{ padding: 0 }}
                            onClick={() => this.handelOpenOrder(orderId)}
                          >
                            <Typography className={classes.messageContainer}>
                              {message.text}
                            </Typography>
                          </Button>
                        </ListItem>
                      ) : (
                        <ListItem>
                          <Button
                            style={{ padding: 0 }}
                            onClick={() => this.handelOpenOrder(orderId)}
                          >
                            <Typography
                              className={classes.messageContainerReceiver}
                            >
                              {message.text}
                            </Typography>
                          </Button>
                          <IconButton
                            onClick={() => this.handelOpenOrder(orderId)}
                          >
                            <ShoppingCartIcon />
                          </IconButton>
                        </ListItem>
                      );
                    break;
                  case 7:
                    messageRender =
                      auth.uid === message.userId ? (
                        <ListItem className={classes.messages}>
                          <IconButton
                            onClick={() =>
                              this.handleOpenRateReview(
                                message.subjectId,
                                message
                              )
                            }
                          >
                            <RateReviewIcon />
                          </IconButton>

                          <Button
                            style={{ padding: 0 }}
                            onClick={() =>
                              this.handleOpenRateReview(
                                message.subjectId,
                                message
                              )
                            }
                          >
                            <Typography className={classes.messageContainer}>
                              {message.text}
                            </Typography>
                          </Button>
                        </ListItem>
                      ) : (
                        <ListItem>
                          <Button
                            style={{ padding: 0 }}
                            onClick={() =>
                              this.handleOpenRateReview(
                                message.subjectId,
                                message
                              )
                            }
                          >
                            <Typography
                              className={classes.messageContainerReceiver}
                            >
                              {message.text}
                            </Typography>
                          </Button>
                          <IconButton
                            onClick={() =>
                              this.handleOpenRateReview(
                                message.subjectId,
                                message
                              )
                            }
                          >
                            <RateReviewIcon />
                          </IconButton>
                        </ListItem>
                      );
                    break;
                  case 8:
                    messageRender =
                      auth.uid === message.userId ? (
                        <ListItem className={classes.messages}>
                          <Typography className={classes.messageContainer}>
                            {message.text}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem>
                          <Typography
                            className={classes.messageContainerReceiver}
                          >
                            {message.text}
                          </Typography>
                        </ListItem>
                      );
                    break;
                  case 12:
                    messageRender =
                      auth.uid === message.userId ? (
                        <ListItem className={classes.messages}>
                          <MoreIcon
                            className={classes.moreIcon}
                            onClick={(e) => {
                              this.handleClickDeleteMessageMenu(
                                e,
                                thread.threadId,
                                message.id
                              );
                            }}
                          />
                          <ListItemText
                            className={classes.messageContainer}
                            disableTypography
                            // className={classes.messages}
                            sx={{
                              width: "30%",
                              "&:hover": { cursor: "pointer" },
                            }}
                            primary={
                              <React.Fragment>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.messageContainer}
                                  onClick={() => {
                                    this.handleOpenEditMeetingPlan(message);
                                  }}
                                >
                                  {new Intl.DateTimeFormat("en-US", options)
                                    .format(message.planDate)
                                    .toString() +
                                    " - " +
                                    new Intl.DateTimeFormat("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }).format(message.planTime)}
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  variant="body1"
                                  className={classes.messageContainer}
                                  onClick={() => {
                                    this.handleOpenEditMeetingPlan(message);
                                  }}
                                >
                                  {intl.formatMessage({
                                    id: "plan_reminder_message",
                                  })}
                                </Typography>
                                <Button
                                  onClick={() => {
                                    this.handleOpenPlanNameDialog(message);
                                  }}
                                  className={classes.normalButton}
                                >
                                  {message.planName === ""
                                    ? intl.formatMessage({
                                        id: "plan_name",
                                      })
                                    : intl.formatMessage({
                                        id: "update_plan_name",
                                      })}
                                </Button>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      ) : (
                        <ListItem>
                          <ListItemText
                            className={classes.messageContainerReceiver}
                            sx={{
                              width: "30%",
                              "&:hover": { cursor: "pointer" },
                            }}
                            disableTypography
                            primary={
                              <React.Fragment>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.messageContainerReceiver}
                                  onClick={() => {
                                    this.handleOpenEditMeetingPlan(message);
                                  }}
                                >
                                  {new Intl.DateTimeFormat("en-US", options)
                                    .format(message.planDate)
                                    .toString() +
                                    " - " +
                                    new Intl.DateTimeFormat("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }).format(message.planTime)}
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  variant="body1"
                                  className={classes.messageContainerReceiver}
                                  onClick={() => {
                                    this.handleOpenEditMeetingPlan(message);
                                  }}
                                >
                                  {intl.formatMessage({
                                    id: "plan_reminder_message",
                                  })}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      );
                    break;
                  default:
                    break;
                }
                return (
                  <div key={msgId} className={classes.flex}>
                    {messageRender}
                  </div>
                );
              })}
            {this.state.timeZone ? (
              <div className={classes.imageDiv}>
                <div className={classes.image}>
                  <ListItem alignItems="flex-start">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <ListItemAvatar
                          className={classes.listItemAvatarBedtimeIcon}
                        >
                          <BedtimeIcon className={classes.bedtimeIcon} />
                        </ListItemAvatar>
                      </Grid>
                      <Grid item sx={{ marginInlineStart: "5px" }}>
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="subtitle2"
                                // variant={
                                //   this.props.theme.breakpoints.down("md")
                                //     ? "subtitle2"
                                //     : "caption"
                                // }
                                className={classes.timeZoneTypography}
                              >
                                {intl.formatMessage({
                                  id: "its_text",
                                })}{" "}
                                {this.state.timeZone}{" "}
                                {intl.formatMessage({
                                  id: "for_your_text",
                                })}{" "}
                                {intl.formatMessage({
                                  id: "unable_sending_messages",
                                })}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </div>
              </div>
            ) : null}
          </List>
        </div>
        <div>
          {this.state.block ||
          this.props.thread.blockedUserId === auth.uid ||
          this.props.isAdmin ? null : (
            <div>
              {messages ? (
                <div>
                  {messages.length === 0 &&
                  this.props.isChatWithAdmin !== "isChatWithAdmin" ? (
                    <div>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.background,
                        }}
                      >
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={4}>
                            <Paper variant="outlined" className={classes.paper}>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonMessage}
                                onClick={(e) => {
                                  this.onSendHandle(
                                    e,
                                    this.state.buttonMessage1
                                  );
                                }}
                              >
                                {intl.formatMessage({
                                  id: "product_available_question",
                                })}
                              </Button>
                            </Paper>
                          </Grid>
                          <Grid item xs={4}>
                            <Paper variant="outlined" className={classes.paper}>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonMessage}
                                onClick={(e) => {
                                  this.onSendHandle(
                                    e,
                                    this.state.buttonMessage2
                                  );
                                }}
                              >
                                {intl.formatMessage({
                                  id: "buy_question",
                                })}
                              </Button>
                            </Paper>
                          </Grid>
                          <Grid item xs={4}>
                            <Paper variant="outlined" className={classes.paper}>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonMessage}
                                onClick={(e) => {
                                  this.onSendHandle(
                                    e,
                                    this.state.buttonMessage3
                                  );
                                }}
                              >
                                {intl.formatMessage({
                                  id: "meet_question",
                                })}
                              </Button>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {!window.location.href.includes("adminthreads") ? (
                <div className={classes.containerImageUpload}>
                  {/* {this.state.timeZone ? (
                    <div className={classes.imageDiv}>
                      <div className={classes.image}>
                        <ListItem alignItems="flex-start">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <ListItemAvatar sx={{ mt: 0, minWidth: 0 }}>
                                <BedtimeIcon />
                              </ListItemAvatar>
                            </Grid>
                            <Grid item sx={{ marginInlineStart: "5px" }}>
                              <ListItemText
                                // primary="Brunch this weekend?"
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="subtitle2"
                                      color="text.primary"
                                    >
                                      It's {this.state.timeZone} for your host.
                                      They will see your messages when they are
                                      back online.
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      </div>
                    </div>
                  ) : null} */}
                  <div className={classes.imageDiv}>
                    <div className={classes.image}>
                      <MultipleImageUpload
                        onHandleChangeImage={this.onHandleChangeImage}
                        currentImagesURL={this.state.currentImagesURL}
                        coverImageIndex={this.state.coverImageIndex}
                      />
                    </div>
                  </div>

                  <div className={classes.flexChatBox}>
                    {/* {this.props.isChatWithAdmin !== "isChatWithAdmin" ? (
                      <IconButton
                        aria-label="send"
                        sx={{ color: (theme) => theme.palette.primary.accent }}
                        onClick={(e) => {
                          this.handleOpenMeetingPlan();
                        }}
                      >
                        <PermContactCalendarIcon />
                      </IconButton>
                    ) : null} */}
                    <Input
                      className={classes.chatBox}
                      placeholder={intl.formatMessage({
                        id: "messages_type_message_here",
                      })}
                      inputProps={{ "aria-label": "description" }}
                      value={this.state.currentMessage}
                      onChange={this.onChangeText}
                      onKeyPress={this.handleKeyPress}
                    />

                    <div
                      className={
                        window.innerWidth >= 0 && window.innerWidth < 600
                          ? classes.buttonMobile
                          : classes.button
                      }
                    >
                      <IconButton
                        aria-label="send"
                        onClick={(e) => {
                          this.onSendHandle(e, null);
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div>
          <Snackbar
            open={this.state.openSnackBar}
            autoHideDuration={6000}
            onClose={this.handleCloseSnackBar}

            //anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <SnackbarContent
              style={{
                backgroundColor: this.props.theme.palette.primary.dark,
                color: this.props.theme.palette.primary.icons,

                //textAlign: "center",
              }}
              message={intl.formatMessage({
                id: "already_rated",
              })}
            />
          </Snackbar>
          <Dialog
            onClose={this.handleCloseRating}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={this.state.rate}
          >
            <div className={classes.flexHeder}>
              {" "}
              <IconButton
                className={classes.iconButton}
                onClick={this.handleCloseRating}
              >
                {globalLanguage !== "ar" ? <ArrowBack /> : <ArrowForword />}
              </IconButton>
              <Typography className={classes.typographyRateBuyer}>
                {intl.formatMessage({
                  id: "rate_the_buyer",
                })}
              </Typography>
            </div>
            <DialogContent className={classes.updateContent}>
              {" "}
              <StarRating
                ratingFromUserId={this.props.auth.uid}
                ratingToUserId={
                  thread.senderId === this.props.auth.uid
                    ? thread.receiverId
                    : thread.senderId
                }
                ratingToUserName={
                  thread.senderId === this.props.auth.uid
                    ? thread.receiverName
                    : thread.senderName
                }
                ratingToUserPhoto={
                  thread.senderId === this.props.auth.uid
                    ? thread.receiverPhoto
                    : thread.senderPhoto
                }
                handleSubmitRating={this.handleSubmitRating}
              />
            </DialogContent>
          </Dialog>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  var messages = state.firestore.ordered.messages;
  return {
    reducerOfMessages: state.seenMessage,
    messages: messages,
    locationConfig: state.locationReducer,
    buyerMessages: state.firestore.ordered
      ? state.firestore.ordered.buyerMessages
        ? state.firestore.ordered.buyerMessages
        : null
      : null,
    categoryProduct: state.firestore.ordered
      ? state.firestore.ordered.productForSell
        ? state.firestore.ordered.productForSell.length !== 0
          ? state.firestore.ordered.productForSell[0].category
            ? state.firestore.ordered.productForSell[0].category
            : null
          : null
        : null
      : null,
    auth: state.firebase.auth,
    reports: state.reports,
    blockUserStatus: state.reports.blockUserStatus,
    unBlockUserStatus: state.reports.unBlockUserStatus,
    profile: state.firebase.profile,
    userStatus: state.firestore.ordered
      ? state.firestore.ordered.userStatus
        ? state.firestore.ordered.userStatus[0]
        : null
      : null,
    loadingUpdatePlanName: state.messages.loadingUpdatePlanName,
    loadingUpdateMessage: state.messages.loadingUpdateMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMessage: (message) => dispatch(createMessage(message)),
    seenMessage: (message) => dispatch(seenMessage(message)),
    // createReport: (report) => dispatch(createReport(report)),
    addToCartLogging: (product) => dispatch(addToCartLogging(product)),
    addToCartFacebookPixel: (product) =>
      dispatch(addToCartFacebookPixel(product)),
    blockUser: (info) => dispatch(blockUser(info)),
    unBlockUser: (info) => dispatch(unBlockUser(info)),
    deleteMessage: (info) => dispatch(deleteMessage(info)),
    clearBlockVariables: () => dispatch(clearBlockVariables()),
    closeConfirmLegalMessage: (details) =>
      dispatch(closeConfirmLegalMessage(details)),
  };
};

// MessageComponent.propTypes = {
//   width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
// };
export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
      return [
        {
          collection: constantsConfig.collectionThreads,
          doc: props.thread.threadId,
          subcollections: [
            {
              collection: constantsConfig.collectionMessages,
              orderBy: ["timestamp", "asc"],
            },
          ],
          storeAs: constantsConfig.collectionMessages, // make sure to include this
        },
      ];
    }),
    firestoreConnect((props) => {
      return [
        {
          collection: constantsConfig.collectionThreads,
          doc: props.thread.threadId,
          subcollections: [
            {
              collection: constantsConfig.collectionMessages,
              where: ["userId", "==", props.thread.userIds[0]],
              // orderBy: ["timestamp", "asc"],
            },
          ],
          storeAs: "buyerMessages", // make sure to include this
        },
      ];
    }),
    firestoreConnect((props) => {
      return [
        {
          collection: constantsConfig.subCollectionShops,
          doc: props.thread.subjectId,
          storeAs: "productForSell", // make sure to include this
        },
      ];
    }),
    firestoreConnect((props) => {
      const id =
        props.auth.uid === props.thread.receiverId
          ? props.thread.senderId
          : props.thread.receiverId;
      return [
        {
          collection: constantsConfig.collectionUsersStatus,
          doc: id,
          storeAs: "userStatus", // make sure to include this
        },
      ];
    }),
    withStyles(styles, { withTheme: true })
  )(MessageComponent)
);
