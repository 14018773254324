// @flow
import React, { Component } from "react";
import * as imageConversion from "image-conversion";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { compose } from "redux";
import {
  Button,
  LinearProgress,
  CardMedia,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  Grid,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { withStyles } from "@mui/styles";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/Delete";
// import RotateRightIcon from '@mui/icons-material/RotateRight';
import { uuidv4 } from "./imageTools";
import EditImage from "./EditImage";
import { constantsConfig } from "../../../config/ConstantsConfig";

const styles = (theme) => ({
  card: {
    backgroundColor: theme.palette.primary.background,
  },
  media: {
    paddingTop: "60%", // 16:9
  },
  rotateMedia: {
    paddingTop: "60%", // 16:9
    paddingBottom: "30%",
  },
  cardActions: {
    zIndex: theme.zIndex.drawer + 1,
  },
  button: {
    ...theme.typography.normalButton,
  },
});

//Multiple image up load to firebase server
class MultipleImageUpload extends Component {
  //init state
  constructor(props) {
    super(props);
    const progress = [];
    if (props.currentImagesURL.length > 0) {
      for (let i = 0; i < props.currentImagesURL.length; i++) {
        progress.push(100);
      }
    }
    this.state = {
      images: props.currentImagesURL,
      progress: progress,
      testImage: null,
      urlResult: [],
      errorInImageCount: false,
      coverImageIndex: props.coverImageIndex,
      openCamera: false,
      isCamera: false,
      rotation: 0,
      files: null,
      openEditImage: false,
      isYardSale: props.isYardSale,
      yardSaleImage: constantsConfig.categoriesImages.yardSaleEvent,
      isChoosedDefaultYardSaleImage:
        props.currentImagesURL.length === 1 &&
        props.currentImagesURL[0] ===
          constantsConfig.categoriesImages.yardSaleEvent
          ? true
          : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (window.location.href.includes("threads"))
      if (
        nextProps.currentImagesURL.length === 0 &&
        this.state.images.length > 0
      ) {
        this.setState({
          images: nextProps.currentImagesURL,
        });
      }
  }
  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  rotateleft = () => {
    let newRotation = this.state.rotation - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };
  onChangeCover(count) {
    this.setState({ coverImageIndex: 0 });
    this.props.onHandleChangeImage("coverImageIndex", count);
  }

  /**
   * delete image selected
   * @param indexImage
   */
  onDeleteImage = (indexImage) => {
    var arrayUrlResult = [...this.state.urlResult]; // make a separate copy of the array
    var arrayImages = [...this.state.images];
    var arrayProgress = [...this.state.progress];
    var indexImageUrl = 0;
    for (let i = 0; i < arrayUrlResult.length; i++) {
      if (arrayUrlResult[i].index === indexImage) {
        indexImageUrl = i;
      }
    }
    arrayUrlResult.splice(indexImageUrl, 1);
    arrayImages.splice(indexImage, 1);
    arrayProgress.splice(indexImage, 1);
    this.setState({
      urlResult: arrayUrlResult,
      images: arrayImages,
      progress: arrayProgress,
    });
    if (arrayImages.length === 0 && this.props.handleRemoveTags) {
      this.props.handleRemoveTags();
    }
    this.props.onHandleChangeImage("images", indexImage);
  };

  /**
   * upload images selected to firebase
   */
  onUploadMultipleFiles = (file, index) => {
    // Get firebase
    // Create a root reference
    var storageRef = firebase.storage().ref();
    var progress = this.state.progress;
    var refThis = this;

    // Create a child reference
    new Promise(() => {
      var uuidv4Var = uuidv4();
      var imagesRef = storageRef.child("images/" + uuidv4Var);
      var uploadTask = imagesRef.put(file);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progressNumber =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progress[index] = progressNumber;
          refThis.setState({
            progress: progress,
          });
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
            default:
              break;
          }
        },
        function (error) {
          // Handle unsuccessful uploads
        },
        function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            var urlResult = refThis.state.urlResult;
            urlResult.push({ url: downloadURL, index: index });
            refThis.setState({
              urlResult: urlResult,
            });
            var currentImageURL = refThis.state.images[index];
            refThis.props.onHandleChangeImage("image", {
              url: downloadURL,
              index: index,
              currentImageURL: currentImageURL,
            });
          });
        }
      );
    });
  };

  handleCloseEditImage = () => {
    this.setState({
      openEditImage: false,
    });
  };

  uploadImage = async (e) => {
    const file = e.target.files[0];
    this.setState({
      files: e.target.files,
    });
    const base64 = await this.convertBase64(file);
    this.setState({
      base64: base64,
      openEditImage: true,
    });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // onSelectMultipleFiles = (e) => {
  //   e.preventDefault();
  //   return new Promise(() => {
  //     if (e.target.files.length + this.state.images.length <= 5) {
  //       this.setState({
  //         errorInImageCount: false
  //       });
  //       var imagesLength = this.state.images.length;
  //       if (e.target.files.length !== 0) {
  //         this.props.onHandleChangeImage(
  //           'onTagsProcess',
  //           e.target.files.length
  //         );
  //       }
  //       for (let i = 0; i < e.target.files.length; i++) {
  //         var imageFile = e.target.files[i];
  //         this.checkSizeImage(imageFile, i + imagesLength);
  //       }
  //     } else {
  //       this.setState({
  //         errorInImageCount: true
  //       });
  //     }
  //   });
  // };

  onSelectMultipleFiles = (e, imagesFiles) => {
    e.preventDefault();
    return new Promise(() => {
      if (this.state.files.length + this.state.images.length <= 5) {
        this.setState({
          errorInImageCount: false,
        });
        var imagesLength = this.state.images.length;
        if (this.state.files.length !== 0) {
          this.props.onHandleChangeImage(
            "onTagsProcess",
            this.state.files.length
          );
        }
        for (let i = 0; i < imagesFiles.length; i++) {
          var imageFile = imagesFiles[i].convertImageToUrl;
          this.checkSizeImage(imageFile, i + imagesLength);
        }
      } else {
        this.setState({
          errorInImageCount: true,
        });
      }
    });
  };

  checkSizeImage = (imageFile, index) => {
    if (imageFile.size > 200 * 1024) {
      this.compressedImage(imageFile, index);
    } else {
      this.addImage(imageFile, index);
    }
  };

  addImage = (imageFile, index) => {
    var progress = this.state.progress;
    var images = this.state.images;
    progress.push(0);
    images.push(URL.createObjectURL(imageFile));
    this.setState({
      progress: progress,
      images: images,
      // openEditImage: false
    });
    this.onUploadMultipleFiles(imageFile, index);
  };

  compressedImage = (imageFile, index) => {
    var rerThis = this;
    imageConversion
      .compressAccurately(imageFile, 200)
      .then(function (compressedFile) {
        return rerThis.addImage(compressedFile, index);
      })
      .catch(function (error) {});
  };

  handleCloseCamera = () => {
    this.setState({ openCamera: false });
  };

  handleTakePhoto = (dataUri) => {
    this.setState({ openCamera: false });
    this.setState({
      openEditImage: true,
      base64: dataUri,
    });
  };

  handleConvertTakePhoto = (dataUri) => {
    this.setState({
      isCamera: false,
    });
    var byteString;
    if (dataUri.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataUri.split(",")[1]);
    else byteString = unescape(dataUri.split(",")[1]);

    // separate out the mime component
    var mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    this.checkSizeImage(
      new Blob([ia], { type: mimeString }),
      this.state.images.length
    );
  };

  render() {
    //Count variable to display progress bar...
    var count = 0;
    const { rotation } = this.state;
    const { classes, intl } = this.props;
    return (
      <React.Fragment>
        <Dialog
          open={this.state.openEditImage}
          fullScreen
          onClose={this.handleCloseEditImage}
          aria-labelledby="form-dialog-title"
        >
          <EditImage
            isCamera={this.state.isCamera}
            src={this.state.base64}
            onSelectMultipleFiles={this.onSelectMultipleFiles}
            handleCloseEditImage={this.handleCloseEditImage}
            handleConvertTakePhoto={this.handleConvertTakePhoto}
          />
        </Dialog>
        <Dialog
          onClose={this.handleCloseCamera}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={this.state.openCamera}
        >
          <Camera
            onTakePhotoAnimationDone={(dataUri) => {
              this.handleTakePhoto(dataUri);
            }}
            isFullscreen={false}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
        </Dialog>
        {this.state.isChoosedDefaultYardSaleImage ? (
          <Grid container spacing={2} style={{ marginBottom: "2.5em" }}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Card className={classes.card}>
                <CardMedia src={this.state.yardSaleImage} component="img" />
                <CardActions className={classes.cardActions} disableSpacing>
                  <IconButton
                    aria-label="delete photo"
                    onClick={() => {
                      this.setState({
                        isChoosedDefaultYardSaleImage: false,
                        images: [],
                      });
                      this.props.handleDeleteDefaultYardSaleImage();
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        ) : null}
        {!this.state.isChoosedDefaultYardSaleImage ? (
          <Grid //grid list show all images...
            container
            spacing={2}
            style={{ marginBottom: "5px" }}
          >
            {this.state.images &&
              [...this.state.images].map((file, index) => {
                const countTemp = count;
                const img = (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={file}>
                    <Card className={classes.card}>
                      <CardMedia //card media has image url...
                        image={file}
                        className={
                          this.state.rotation === 0 ||
                          Math.abs(this.state.rotation) === 180 ||
                          Math.abs(this.state.rotation) === 360
                            ? classes.media
                            : classes.rotateMedia
                        }
                        style={{ transform: `rotate(${rotation}deg)` }}
                      />
                      {this.state.progress[count] === 100 ? (
                        <CardActions
                          className={classes.cardActions}
                          disableSpacing
                        >
                          <IconButton
                            aria-label="delete photo"
                            onClick={() => this.onDeleteImage(index)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                          {/* <IconButton
                          aria-label="delete photo"
                          onClick={this.rotate}
                        >
                          <RotateRightIcon />
                        </IconButton> */}
                          {!window.location.href.includes("threads") ? (
                            <div>
                              {this.state.coverImageIndex !== count ? (
                                <IconButton
                                  aria-label="cover photo"
                                  onClick={() => {
                                    this.onChangeCover(countTemp);
                                  }}
                                >
                                  <WallpaperIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  aria-label="cover photo"
                                  onClick={() => {
                                    this.onChangeCover(-1);
                                  }}
                                >
                                  <CheckIcon />
                                </IconButton>
                              )}
                            </div>
                          ) : null}
                        </CardActions>
                      ) : (
                        <CardContent>
                          <LinearProgress //linear progress with value of state progress...
                          //      variant="determinate"
                          // value={this.state.progress[count]}
                          />
                        </CardContent>
                      )}
                    </Card>
                    {/* <input onClick={this.rotateleft} type="button" value="left" /> */}
                    {/* <input onClick={this.rotate} type="button" value="right" /> */}
                  </Grid>
                );
                //increment count for each file..
                count++;
                return img;
              })}
          </Grid>
        ) : null}
        {this.state.isChoosedDefaultYardSaleImage === false ? (
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button //button to select images to upload in fire store
                variant="contained"
                component="label"
                className={classes.button}
              >
                {intl.formatMessage({
                  id: "upload_images",
                })}

                <input
                  type="file"
                  style={{ display: "none" }}
                  // onChange={this.onSelectMultipleFiles}
                  onChange={this.uploadImage}
                  accept="image/*"
                  multiple
                />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                component="label"
                className={classes.button}
                onClick={() =>
                  this.setState({ openCamera: true, isCamera: true })
                }
              >
                {intl.formatMessage({
                  id: "take_photo",
                })}
              </Button>
            </Grid>
            {this.state.isYardSale && this.state.images.length === 0 ? (
              <Grid item>
                <Button
                  variant="contained"
                  component="label"
                  className={classes.button}
                  onClick={() => {
                    this.setState({ isChoosedDefaultYardSaleImage: true });
                    this.props.handleChooseDefaultYardSaleImage(
                      this.state.yardSaleImage
                    );
                    this.props.handleRemoveTags();
                  }}
                >
                  {intl.formatMessage({
                    id: "use_default_yard_photo",
                  })}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        ) : null}

        {this.state.errorInImageCount && (
          <Alert //alert when user select more
            severity="error"
          >
            {intl.formatMessage({
              id: "over_limit_camera",
            })}
          </Alert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebase: state.firebase,
  };
};

export default injectIntl(
  compose(connect(mapStateToProps), withStyles(styles))(MultipleImageUpload)
);
