//@flow
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { injectIntl } from "react-intl";
import firebase from "firebase/compat/app";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MultipleImageUpload from "./images/MultipleImageUpload";
import MapComponent from "../other/MapComponent";
import { createProduct, editProduct } from "../../store/product/ProductActions";
import { constantsConfig } from "../../config/ConstantsConfig";
import {
  postLogging,
  purchaseLogging,
} from "../../store/analyticsLogging/GoogleLogging";
import {
  purchaseFacebookPixel,
  productPostFacebookPixel,
} from "../../store/analyticsLogging/FacebookPixel";
import { isEmptyOrSpaces } from "../../functions/Functions";
import AddOtherModel from "./AddOtherModel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = (theme) => ({
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
  layout: {
    width: "auto",
    marginTop: "35px",
    marginInlineStart: theme.spacing(2),
    marginInlineEnd: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    marginTop: "1em",
  },
  textField: {
    width: "100%",
  },
  errImageMessageTypography: {
    color: theme.palette.primary.errColor,
  },
  buttonProgress: {
    color: theme.palette.primary.icons,
  },
  postButton: {
    ...theme.typography.normalButton,
  },
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
});

function translateConditionStatus(condition, intl) {
  if (condition === "Other (see description)")
    return intl.formatMessage({
      id: "condition_other",
    });
  if (condition === "For Parts")
    return intl.formatMessage({
      id: "condition_for_parts",
    });
  if (condition === "Used (normal wear)")
    return intl.formatMessage({
      id: "condition_used",
    });
  if (condition === "Open Box (never used)")
    return intl.formatMessage({
      id: "condition_open_box",
    });
  if (condition === "Reconditioned/Cretified")
    return intl.formatMessage({
      id: "condition_reconditioned",
    });
  if (condition === "New (never used)")
    return intl.formatMessage({
      id: "condition_new",
    });
}

class AddMobile extends Component {
  constructor(props) {
    super(props);
    if (props.type === "add") {
      this.state = {
        ...props.locationConfig,
        currency: "EUR",
        currencyCode: props.currencyCode,
        currentImagesURL: [],
        coverImageIndex: -1,
        tags: [],
        onTagsProcess: 0,
        imageUrl1: null,
        imageUrl2: null,
        imageUrl3: null,
        imageUrl4: null,
        imageUrl5: null,
        price: 1,
        defaultConditionStatus: "Open Box (never used)",
        condition: 3,
        conditionStatus: "Open Box (never used)",
        conditionsStatusArray: [
          "Other (see description)",
          "For Parts",
          "Used (normal wear)",
          "Open Box (never used)",
          "Reconditioned/Cretified",
          "New (never used)",
        ],
        description: "",
        disabled: false,
        title: null,
        mobileBrand: "",
        mobileBrandId: null,
        mobileModel: "",
        mobileModelId: null,
        mobileInternalMemory: "",
        mobileNetworkCarrier: "",
        mobileIsUnLocked: false,
        mobileOtherBrand: "",
        spinner: false,
        mobileModels: null,
        mobilesModels: null,
        mobilesModelsIds: null,
        isPriceNull: false,
        mobileLocation: null,
        brandFromOthers: false,
        modelFromOthers: false,
        isMobileBrandNull: false,
        isMobileModelNull: false,
        isMobileOtherBrand: false,
        isImageUrlNull: false,
        isOpenSnackbar: false,
        openOthersDialog: false,
      };
    } else {
      this.state = {
        ...props.locationConfig,
        currency: "EUR",
        currencyCode: props.productData.currencyCode
          ? props.productData.currencyCode
          : null,
        currentImagesURL: props.currentImagesURL,
        coverImageIndex: props.coverImageIndex,
        tags: props.productData.tags ? props.productData.tags : null,
        onTagsProcess: 0,
        imageUrl1: props.productData.imageUrl1
          ? props.productData.imageUrl1
          : null,
        imageUrl2: props.productData.imageUrl2
          ? props.productData.imageUrl2
          : null,
        imageUrl3: props.productData.imageUrl3
          ? props.productData.imageUrl3
          : null,
        imageUrl4: props.productData.imageUrl4
          ? props.productData.imageUrl4
          : null,
        imageUrl5: props.productData.imageUrl5
          ? props.productData.imageUrl5
          : null,
        price: props.productData.price ? props.productData.price : 1,
        productId: props.productData.id,
        defaultConditionStatus: "Open Box (never used)",
        condition: props.productData.condition
          ? props.productData.condition
          : null,
        conditionStatus: props.productData.conditionStatus
          ? props.productData.conditionStatus
          : null,
        conditionsStatusArray: [
          "Other (see description)",
          "For Parts",
          "Used (normal wear)",
          "Open Box (never used)",
          "Reconditioned/Cretified",
          "New (never used)",
        ],
        description: props.productData.description
          ? props.productData.description
          : "",
        disabled: false,
        title: props.productData.title ? props.productData.title : null,
        mobileBrand:
          props.productData.mobileBrandId === constantsConfig.mobileOtherId
            ? "other"
            : props.productData.mobileBrand
            ? props.productData.mobileBrand
            : "",
        mobileBrandId: props.productData.mobileBrandId
          ? props.productData.mobileBrandId
          : null,
        mobileModel: props.productData.mobileModel
          ? props.productData.mobileModel
          : "",
        mobileModelId: props.productData.mobileModelId
          ? props.productData.mobileModelId
          : null,
        mobileInternalMemory: props.productData.mobileInternalMemory
          ? props.productData.mobileInternalMemory
          : "",
        mobileNetworkCarrier: props.productData.mobileNetworkCarrier
          ? props.productData.mobileNetworkCarrier
          : "",
        mobileIsUnLocked: props.productData.mobileIsUnLocked,
        allThisFavoriteProduct: props.allThisFavoriteProduct,
        spinner: false,
        mobileModels: null,
        mobilesModels: null,
        mobilesModelsIds: null,
        mobileOtherBrand:
          props.productData.mobileBrandId === constantsConfig.mobileOtherId
            ? props.productData.mobileBrand
            : "",
        brandFromOthers:
          props.productData.mobileBrandId !== constantsConfig.mobileOtherId
            ? false
            : true,
        modelFromOthers: props.productData.mobileModelId ? false : true,
        isPriceNull: false,
        isMobileBrandNull: false,
        isMobileModelNull: false,
        isImageUrlNull: false,
        isMobileOtherBrand: false,
        isOpenSnackbar: false,
        openOthersDialog: false,
        mobileLocation: {
          city: props.productData.city ? props.productData.city : null,
          country: props.productData.country ? props.productData.country : null,
          countryCode: props.productData.countryCode
            ? props.productData.countryCode
            : null,
          currency: props.productData.currencyCode
            ? props.productData.currencyCode
            : null,
          latitude: props.productData.latitude
            ? props.productData.latitude
            : null,
          longitude: props.productData.longitude
            ? props.productData.longitude
            : null,
          readableLocation: props.productData.readableLocation
            ? props.productData.readableLocation
            : null,
          state: props.productData.state ? props.productData.state : null,
          zip: props.productData.zip ? props.productData.zip : null,
        },
      };
    }
  }

  componentDidMount() {
    if (this.props.type === "edit") {
      this.handleGetModels(this.props.productData.mobileBrandId);
    }
  }

  handleClickOthersModel = () => {
    this.setState({
      mobileModel: "",
      mobileModelId: null,
      // openOthersDialog: true,
      modelFromOthers: true,
    });
  };

  handleCloseOthersDialog = () => {
    this.setState({
      openOthersDialog: false,
    });
  };

  handleChangeOthersModel = (event) => {
    this.setState({
      mobileModel: event.target.value,
      title: event.target.value,
    });
    if (!isEmptyOrSpaces(event.target.value))
      this.setState({
        isMobileModelNull: false,
      });
  };

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "images":
        switch (value) {
          case 0:
            this.setState({
              imageUrl1: "",
            });
            for (let i = 2; i <= 5; i++) {
              if (i === 2) {
                if (this.state.imageUrl2 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl2,
                    imageUrl2: "",
                  });
                  break;
                }
              } else if (i === 3) {
                if (this.state.imageUrl3 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl3,
                    imageUrl3: "",
                  });
                  break;
                }
              } else if (i === 4) {
                if (this.state.imageUrl4 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl4,
                    imageUrl4: "",
                  });
                  break;
                }
              } else {
                if (this.state.imageUrl5 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl5,
                    imageUrl5: "",
                  });
                  break;
                }
              }
            }
            break;
          case 1:
            this.setState({
              imageUrl2: "",
            });
            break;
          case 2:
            this.setState({
              imageUrl3: "",
            });
            break;
          case 3:
            this.setState({
              imageUrl4: "",
            });
            break;
          case 4:
            this.setState({
              imageUrl5: "",
            });
            break;
          default:
            break;
        }
        this.state.currentImagesURL.splice(value, 1);

        break;
      case "image":
        switch (value.index) {
          case 0:
            this.setState({
              imageUrl1: value.url,
              isImageUrlNull: false,
            });
            break;
          case 1:
            this.setState({
              imageUrl2: value.url,
            });
            break;
          case 2:
            this.setState({
              imageUrl3: value.url,
            });
            break;
          case 3:
            this.setState({
              imageUrl4: value.url,
            });
            break;
          case 4:
            this.setState({
              imageUrl5: value.url,
            });
            break;
          default:
            break;
        }

        break;
      case "coverImageIndex":
        this.changeCoverIndex(value);
        break;
      case "map":
        this.setState({
          mobileLocation: value,
        });
        break;

      //default value...
      default:
        break;
    }
  };

  onHandleChangeImage = (type, value) => {
    this.handleChangeValue(type, value);
  };

  onHandleChangeCondition = (event, value) => {
    this.setState({
      condition: value,
      conditionStatus: this.state.conditionsStatusArray[value],
    });
  };

  handleGetModels = async (mobileBrandId) => {
    const query = firebase
      .firestore()
      .collection(constantsConfig.collectionMobiles)
      .doc(mobileBrandId)
      .collection("models")
      .orderBy("model", "asc");

    const snapshot = await query.get();
    const modelsIds = snapshot.docs.map((doc) => doc.id);
    const models = snapshot.docs.map((doc) => doc.data());
    this.setState({
      spinner: false,
      mobilesModels: models,
      mobilesModelsIds: modelsIds,
    });
  };

  handleChange = (event, type) => {
    const digits_only = (string) =>
      [...string].every((c) => "0123456789".includes(c));
    if (type === "mobileBrand") {
      for (let i = 0; i < this.props.mobilesBrands.length; i++) {
        if (this.props.mobilesBrands[i].brand === event.target.value) {
          if (
            this.props.mobilesBrands[i].brandId ===
            constantsConfig.mobileOtherId
          ) {
            this.setState({
              mobileBrand: this.props.mobilesBrands[i].brand,
              mobileBrandId: this.props.mobilesBrands[i].brandId,
              isMobileBrandNull: false,
              mobileModel: "",
              mobileModelId: null,
              title: null,
              brandFromOthers: true,
              isMobileModelNull: true,
              modelFromOthers: false,
            });
          } else {
            this.setState({
              mobileBrand: this.props.mobilesBrands[i].brand,
              mobileBrandId: this.props.mobilesBrands[i].brandId,
              isMobileBrandNull: false,
              mobileModel: "",
              mobileModelId: null,
              title: null,
              isMobileModelNull: true,
              brandFromOthers: false,
              spinner: true,
              modelFromOthers: false,
            });
            this.handleGetModels(this.props.mobilesBrands[i].brandId);
            break;
          }
        }
      }
    }
    if (type === "mobileOtherBrand")
      this.setState({
        isMobileOtherBrand: false,
        mobileOtherBrand: event.target.value,
      });
    if (type === "mobileModel") {
      if (this.state.brandFromOthers) {
        this.setState({
          mobileModel: event.target.value,
          title: event.target.value,
          isMobileModelNull: false,
        });
      } else {
        for (let i = 0; i < this.state.mobilesModels.length; i++) {
          if (this.state.mobilesModels[i].model === event.target.value) {
            this.setState({
              mobileModel: this.state.mobilesModels[i].model,
              mobileModelId: this.state.mobilesModelsIds[i],
              title: this.state.mobilesModels[i].model,
              isMobileModelNull: false,
            });
            break;
          }
        }
      }
    }
    if (type === "mobileInternalMemory")
      this.setState({
        mobileInternalMemory: event.target.value,
      });
    if (type === "mobileNetworkCarrier")
      this.setState({
        mobileNetworkCarrier: event.target.value,
      });
    if (type === "mobileIsUnLocked")
      if (event.target.value === "Yes")
        this.setState({
          mobileIsUnLocked: true,
        });
      else
        this.setState({
          mobileIsUnLocked: false,
        });
    if (type === "description")
      this.setState({
        description: event.target.value,
      });
    if (type === "price") {
      if (
        parseInt(event.target.value) === 0 ||
        !digits_only(event.target.value)
      ) {
        this.setState({
          price: event.target.value,
          isPriceNull: true,
        });
      } else {
        this.setState({
          price: event.target.value,
          isPriceNull: false,
        });
      }
    }
  };

  handleSubmit = () => {
    if (
      !this.state.mobileBrand ||
      (this.state.mobileBrandId === constantsConfig.mobileOtherId &&
        isEmptyOrSpaces(this.state.mobileOtherBrand) === true) ||
      isEmptyOrSpaces(this.state.mobileModel) === true ||
      !this.state.imageUrl1 ||
      !this.state.price ||
      (this.state.price && parseInt(this.state.price) <= 0)
    ) {
      this.setState({
        isOpenSnackbar: true,
      });
      if (!this.state.mobileBrand)
        this.setState({
          isMobileBrandNull: true,
        });
      if (!this.state.mobileOtherBrand)
        this.setState({
          isMobileOtherBrand: true,
        });
      if (isEmptyOrSpaces(this.state.mobileModel) === true)
        this.setState({
          isMobileModelNull: true,
        });
      if (!this.state.imageUrl1)
        this.setState({
          isImageUrlNull: true,
        });
      if (
        !this.state.price ||
        (this.state.price && parseInt(this.state.price) <= 0)
      ) {
        this.setState({
          isPriceNull: true,
        });
      }
    } else {
      this.setState({
        disabled: true,
      });
      var tags = [];
      tags.push(
        this.state.mobileBrandId !== constantsConfig.mobileOtherId
          ? this.state.mobileBrand.toString()
          : this.state.mobileOtherBrand.toString()
      );
      tags.push(this.state.mobileModel.toString());
      if (!isEmptyOrSpaces(this.state.mobileInternalMemory))
        tags.push(this.state.mobileInternalMemory.toString());
      if (!isEmptyOrSpaces(this.state.mobileNetworkCarrier))
        tags.push(this.state.mobileNetworkCarrier.toString());
      if (
        this.state.mobileIsUnLocked !== null &&
        this.state.mobileIsUnLocked !== false
      )
        tags.push("UnLocked");
      if (this.props.type === "add") {
        const updateMobile = {
          imageUrl1: this.state.imageUrl1,
          imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
          imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
          imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
          imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
          price: parseInt(this.state.price),
          formattedPrice: this.state.price.toString(),
          title: this.state.title,
          mobileBrand:
            this.state.mobileBrandId !== constantsConfig.mobileOtherId
              ? this.state.mobileBrand
              : this.state.mobileOtherBrand,
          mobileBrandId: this.state.mobileBrandId,
          mobileModel: this.state.mobileModel,
          mobileModelId: this.state.mobileModelId,
          mobileInternalMemory: !isEmptyOrSpaces(
            this.state.mobileInternalMemory
          )
            ? this.state.mobileInternalMemory
            : null,
          mobileNetworkCarrier: !isEmptyOrSpaces(
            this.state.mobileNetworkCarrier
          )
            ? this.state.mobileNetworkCarrier
            : null,
          mobileIsUnLocked: this.state.mobileIsUnLocked,
          condition: this.state.condition,
          conditionStatus: this.state.conditionStatus,
          rating: 3.5,
          tags: tags,
          description: !isEmptyOrSpaces(this.state.description)
            ? this.state.description
            : null,
          category: this.props.mobilesCategory,
          categoryId: this.props.mobilesCategory.categoryId,
          isSoldOnBingoDeal: false,
          isFoodItem: false,
          isBoosted: false,
          currencyCode: this.state.currencyCode,
          impressions: 0,
          clicks: 0,
          boostTimestamp: 0,
          isActive: true,
          isUsedProduct: true,
          platform: "web",
          firmOnPrice: false,
          seller: this.props.profile,
          sellerId: this.props.profile.id,
          ...this.state.mobileLocation,
        };
        var loggingProduct = {
          title: this.state.title,
          category: this.props.mobilesCategory,
        };
        this.props.postLogging(loggingProduct);
        this.props.purchaseLogging(updateMobile);
        this.props.purchaseFacebookPixel(loggingProduct);
        this.props.productPostFacebookPixel(loggingProduct);
        this.props.createProduct(updateMobile);
      } else {
        const updateMobile = {
          imageUrl1: this.state.imageUrl1,
          imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
          imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
          imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
          imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
          id: this.state.productId,
          price: parseInt(this.state.price),
          formattedPrice: this.state.price.toString(),
          title: this.state.title,
          mobileBrand:
            this.state.mobileBrandId !== constantsConfig.mobileOtherId
              ? this.state.mobileBrand
              : this.state.mobileOtherBrand,
          mobileBrandId: this.state.mobileBrandId,
          mobileModel: this.state.mobileModel,
          mobileModelId: this.state.mobileModelId,
          mobileInternalMemory: !isEmptyOrSpaces(
            this.state.mobileInternalMemory
          )
            ? this.state.mobileInternalMemory
            : null,
          mobileNetworkCarrier: !isEmptyOrSpaces(
            this.state.mobileNetworkCarrier
          )
            ? this.state.mobileNetworkCarrier
            : null,
          mobileIsUnLocked: this.state.mobileIsUnLocked,
          condition: this.state.condition,
          conditionStatus: this.state.conditionStatus,
          rating: 3.5,
          tags: tags,
          description: !isEmptyOrSpaces(this.state.description)
            ? this.state.description
            : null,
          category: this.props.mobilesCategory,
          categoryId: this.props.mobilesCategory.categoryId,
          isSoldOnBingoDeal: false,
          isFoodItem: false,
          isBoosted: false,
          currencyCode: this.state.currencyCode,
          impressions: 0,
          clicks: 0,
          boostTimestamp: 0,
          isActive: true,
          isUsedProduct: true,
          platform: "web",
          firmOnPrice: false,
          seller: this.props.profile,
          sellerId: this.props.profile.id,
          ...this.state.mobileLocation,
        };
        this.props.editProduct(updateMobile);
      }
    }
  };

  render() {
    const { classes, intl, handleClose, mobilesBrands, usedProductReducer } =
      this.props;
    const conditionTranslated = translateConditionStatus(
      this.state.conditionStatus,
      intl
    );
    if (usedProductReducer.productState === "CREATE_PRODUCT") {
      if (this.props.type === "add")
        window.location.href =
          constantsConfig.mainUrl +
          "product/" +
          usedProductReducer.productId +
          "/Open/BoostProduct";
      else
        window.location.href =
          constantsConfig.mainUrl + "product/" + this.state.productId;
    }
    return (
      <React.Fragment>
        <Dialog
          onClose={this.handleCloseOthersDialog}
          aria-labelledby="simple-dialog-title"
          fullWidth
          open={this.state.openOthersDialog}
        >
          <AddOtherModel
            model={this.state.mobileModel}
            handleChangeOthersModel={this.handleChangeOthersModel}
            handleCloseOthersDialog={this.handleCloseOthersDialog}
          />
        </Dialog>
        <Snackbar
          open={this.state.isOpenSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert onClose={this.handleCloseSnackbar} severity="error">
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "fill_required_fields",
              })}
            </Typography>
          </Alert>
        </Snackbar>
        <div className={classes.dialogsBackground}>
          <CloseIcon className={classes.xIcon} onClick={handleClose} />
        </div>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {this.props.type === "add"
            ? intl.formatMessage({
                id: "add_mobile",
              })
            : intl.formatMessage({
                id: "edit_mobile",
              })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              <MultipleImageUpload
                handleRemoveTags={this.handleRemoveTags}
                onHandleChangeImage={this.onHandleChangeImage}
                currentImagesURL={this.state.currentImagesURL}
                coverImageIndex={this.state.coverImageIndex}
              />
              {this.state.isImageUrlNull ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "upload_a_photo",
                  })}
                </Typography>
              ) : null}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isMobileBrandNull ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "brand_title",
                  })}
                  className={classes.textField}
                  value={this.state.mobileBrand}
                  onChange={(e) => this.handleChange(e, "mobileBrand")}
                >
                  {mobilesBrands ? (
                    mobilesBrands.map((mobile) => {
                      return (
                        <MenuItem key={mobile.brandId} value={mobile.brand}>
                          <Typography variant="body1">
                            {mobile.brand}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                </TextField>
              ) : (
                <TextField
                  error
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "brand_title",
                  })}
                  className={classes.textField}
                  value={this.state.mobileBrand}
                  onChange={(e) => this.handleChange(e, "mobileBrand")}
                >
                  {mobilesBrands &&
                    mobilesBrands.map((mobile) => {
                      return (
                        <MenuItem key={mobile.brandId} value={mobile.brand}>
                          <Typography variant="body1">
                            {mobile.brand}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </Grid>
            {this.state.brandFromOthers ? (
              <Grid item className={classes.gridItem}>
                {!this.state.isMobileOtherBrand ? (
                  <TextField
                    id="mobileOtherBrand"
                    label="Other Brand"
                    variant="standard"
                    className={classes.textField}
                    value={this.state.mobileOtherBrand}
                    onChange={(e) => this.handleChange(e, "mobileOtherBrand")}
                  />
                ) : (
                  <TextField
                    error
                    id="mobileOtherBrand"
                    label="Other Brand"
                    variant="standard"
                    className={classes.textField}
                    value={this.state.mobileOtherBrand}
                    onChange={(e) => this.handleChange(e, "mobileOtherBrand")}
                  />
                )}
              </Grid>
            ) : null}
            <Grid item className={classes.gridItem}>
              {this.state.spinner === true ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_model_title",
                  })}
                  value={this.state.mobileModel}
                  className={classes.textField}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Grid>
                </TextField>
              ) : (
                <div>
                  {this.state.mobilesModels ||
                  this.state.brandFromOthers ||
                  this.state.modelFromOthers ? (
                    <div>
                      {!this.state.isMobileModelNull ? (
                        <div>
                          {this.state.brandFromOthers ||
                          this.state.modelFromOthers ? (
                            <TextField
                              id="mobileModel"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              variant="standard"
                              className={classes.textField}
                              value={this.state.mobileModel}
                              onChange={(e) =>
                                this.state.brandFromOthers
                                  ? this.handleChange(e, "mobileModel")
                                  : this.handleChangeOthersModel(e)
                              }
                            />
                          ) : (
                            <TextField
                              select
                              required
                              variant="standard"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              className={classes.textField}
                              value={this.state.mobileModel}
                              onChange={(e) =>
                                this.handleChange(e, "mobileModel")
                              }
                            >
                              {this.state.mobilesModels &&
                                this.state.mobilesModels.map((model) => {
                                  return (
                                    <MenuItem
                                      key={model.modelId}
                                      value={model.model}
                                    >
                                      <Typography variant="body1">
                                        {model.model}
                                      </Typography>
                                    </MenuItem>
                                  );
                                })}
                              {/* <MenuItem
                                value={'Others'}
                                onClick={this.handleClickOthersModel}
                              >
                                <Typography variant="body1">Others</Typography>
                              </MenuItem> */}
                            </TextField>
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.state.brandFromOthers ||
                          this.state.modelFromOthers ? (
                            <TextField
                              error
                              id="mobileModel"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              variant="standard"
                              className={classes.textField}
                              value={this.state.mobileModel}
                              onChange={(e) =>
                                this.state.brandFromOthers
                                  ? this.handleChange(e, "mobileModel")
                                  : this.handleChangeOthersModel(e)
                              }
                            />
                          ) : (
                            <TextField
                              error
                              select
                              required
                              variant="standard"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              className={classes.textField}
                              value={this.state.mobileModel}
                              onChange={(e) =>
                                this.handleChange(e, "mobileModel")
                              }
                            >
                              {this.state.mobilesModels &&
                                this.state.mobilesModels.map((model) => {
                                  return (
                                    <MenuItem
                                      key={model.modelId}
                                      value={model.model}
                                    >
                                      <Typography variant="body1">
                                        {model.model}
                                      </Typography>
                                    </MenuItem>
                                  );
                                })}
                              {/* <MenuItem
                                value={'Others'}
                                onClick={this.handleClickOthersModel}
                              >
                                <Typography variant="body1">Others</Typography>
                              </MenuItem> */}
                            </TextField>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {!this.state.isMobileModelNull ? (
                        <TextField
                          select
                          required
                          variant="standard"
                          label={intl.formatMessage({
                            id: "dialog_model_title",
                          })}
                          value={this.state.mobileModel}
                          className={classes.textField}
                        >
                          <Typography align="center" variant="body1">
                            {intl.formatMessage({
                              id: "mobile_model_warning",
                            })}
                          </Typography>
                        </TextField>
                      ) : (
                        <TextField
                          error
                          select
                          required
                          variant="standard"
                          label={intl.formatMessage({
                            id: "dialog_model_title",
                          })}
                          value={this.state.mobileModel}
                          className={classes.textField}
                        >
                          <Typography align="center" variant="body1">
                            {intl.formatMessage({
                              id: "mobile_model_warning",
                            })}
                          </Typography>
                        </TextField>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="mobileInternalMemory"
                label={intl.formatMessage({
                  id: "default_internal_memory",
                })}
                variant="standard"
                className={classes.textField}
                value={this.state.mobileInternalMemory}
                onChange={(e) => this.handleChange(e, "mobileInternalMemory")}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="mobileNetworkCarrier"
                label={intl.formatMessage({
                  id: "network_carrier",
                })}
                variant="standard"
                className={classes.textField}
                value={this.state.mobileNetworkCarrier}
                onChange={(e) => this.handleChange(e, "mobileNetworkCarrier")}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              {this.state.mobileIsUnLocked != null ? (
                <TextField
                  select
                  variant="standard"
                  label={intl.formatMessage({
                    id: "is_unlocked",
                  })}
                  className={classes.textField}
                  value={!this.state.mobileIsUnLocked ? "No" : "Yes"}
                  onChange={(e) => this.handleChange(e, "mobileIsUnLocked")}
                >
                  <MenuItem value="Yes">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "yes",
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="No">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "no",
                      })}
                    </Typography>
                  </MenuItem>
                </TextField>
              ) : (
                <TextField
                  select
                  variant="standard"
                  label={intl.formatMessage({
                    id: "is_unlocked",
                  })}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "mobileIsUnLocked")}
                >
                  <MenuItem value="Yes">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "yes",
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="No">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "no",
                      })}
                    </Typography>
                  </MenuItem>
                </TextField>
              )}
            </Grid>
            <Grid item container className={classes.gridItem}>
              <Grid item xs={12} sm={6}>
                <Slider
                  defaultValue={3}
                  aria-labelledby="discrete-slider"
                  step={1}
                  marks
                  min={0}
                  max={5}
                  onChange={this.onHandleChangeCondition}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ marginInlineStart: "5%" }}
                  variant="subtitle2"
                >
                  {conditionTranslated}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="description"
                label={intl.formatMessage({
                  id: "product_description",
                })}
                multiline
                rows={4}
                className={classes.textField}
                value={this.state.description}
                onChange={(e) => this.handleChange(e, "description")}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isPriceNull ? (
                <Input
                  autoFocus
                  placeholder={intl.formatMessage({
                    id: "tab_price",
                  })}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "price")}
                  startAdornment={
                    <InputAdornment position="start">
                      {this.state.currencyCode}
                    </InputAdornment>
                  }
                  defaultValue={this.state.price}
                  type="number"
                  min={1}
                  required
                  error={this.state.errorInPrice}
                />
              ) : (
                <Input
                  autoFocus
                  placeholder={intl.formatMessage({
                    id: "tab_price",
                  })}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "price")}
                  startAdornment={
                    <InputAdornment position="start">
                      {this.state.currencyCode}
                    </InputAdornment>
                  }
                  defaultValue={this.state.price}
                  type="number"
                  min={0}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <MapComponent
                height="200px"
                zoom={15}
                onHandleChangeValue={this.handleChangeValue}
                addProductData={
                  this.props.type === "add"
                    ? this.props.locationConfig
                    : this.state.mobileLocation
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {!this.state.disabled ? (
            <Button
              className={classes.postButton}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              {this.props.type === "add"
                ? intl.formatMessage({
                    id: "product_post",
                  })
                : intl.formatMessage({
                    id: "edit",
                  })}
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    usedProductReducer: state.ProductReducer,
    mobilesBrands: state.firestore.ordered.mobilesBrands,
    mobilesCategory: state.firestore.ordered.mobilesCategory
      ? state.firestore.ordered.mobilesCategory[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
    editProduct: (product) => dispatch(editProduct(product)),
    postLogging: (product) => dispatch(postLogging(product)),
    purchaseLogging: (product) => dispatch(purchaseLogging(product)),
    purchaseFacebookPixel: (product) =>
      dispatch(purchaseFacebookPixel(product)),
    productPostFacebookPixel: (product) =>
      dispatch(productPostFacebookPixel(product)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
      return [
        {
          collection: constantsConfig.collectionMobiles,
          orderBy: ["brand", "asc"],
          storeAs: "mobilesBrands",
        },
      ];
    }),
    firestoreConnect((props, state) => {
      return [
        {
          collection: constantsConfig.collectionCategories,
          where: ["categoryRanking", "==", 800],
          storeAs: "mobilesCategory",
        },
      ];
    })
  )(AddMobile)
);
