import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as LocalizationProviderTime } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { convertTo24HrsFormat } from "../../functions/Functions";
import { updateMessage } from "../../store/messages/NewMessagesAction";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = (theme) => ({
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
  gridItem: {
    width: "100%",
    marginTop: "1em",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
  },
});

class MeetingPlanDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planDate: props.message
        ? props.message.planDate
          ? props.message.planDate
          : Date.now()
        : Date.now(),
      planTime: props.message
        ? props.message.planTime
          ? props.message.planTime
          : Date.now()
        : Date.now(),
      openSnackbar: false,
      timeCase: false,
      dateCase: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loadingUpdateMessage === "done") {
      this.props.handleCloseMeetingPlan();
    }
  }

  handleChangeDate = (newDate) => {
    if (newDate !== null)
      this.setState({
        planDate: new Date(new Date(newDate).toDateString()).getTime(),
      });
  };

  handleChangeTime = (newTime) => {
    this.setState({
      planTime: new Date(newTime).getTime(),
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      timeCase: false,
      dateCase: false,
      openSnackbar: false,
    });
  };

  handleSubmit = () => {
    var currentMessage;
    if (!isNaN(this.state.planDate) && !isNaN(this.state.planTime)) {
      if (
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(this.state.planDate) <
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(Date.now()) ||
        (new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(this.state.planDate) ===
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(Date.now()) &&
          convertTo24HrsFormat(
            new Intl.DateTimeFormat("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(this.state.planTime)
          ) <
            convertTo24HrsFormat(
              new Intl.DateTimeFormat("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(Date.now())
            ))
      ) {
        if (
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(this.state.planDate) <
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(Date.now())
        ) {
          this.setState({
            dateCase: true,
            openSnackbar: true,
          });
        } else if (
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(this.state.planDate) ===
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(Date.now()) &&
          convertTo24HrsFormat(
            new Intl.DateTimeFormat("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(this.state.planTime)
          ) <
            convertTo24HrsFormat(
              new Intl.DateTimeFormat("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(Date.now())
            )
        ) {
          this.setState({
            openSnackbar: true,
            timeCase: true,
          });
        } else {
          this.setState({
            openSnackbar: true,
            dateCase: true,
          });
        }
      } else {
        if (this.props.message) {
          currentMessage = {
            messageDetails: {
              message: this.props.message,
              senderId: this.props.thread.senderId,
              receiverId: this.props.thread.receiverId,
              threadId: this.props.thread.threadId,
            },
            text: "",
            threadId: this.props.thread.threadId,
            messageId: this.props.message.id,
            planDate: this.state.planDate,
            planTime: this.state.planTime,
          };
          this.props.updateMessage(currentMessage);
        } else {
          currentMessage = {
            text: "",
            planDate: this.state.planDate,
            planTime: this.state.planTime,
          };
          this.props.onSendMeetingPlan(currentMessage);
        }
      }
    } /*else {
      if (!isNaN(this.state.planDate) && !isNaN(this.state.planTime)) {
        if (
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(this.state.planDate) <
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(Date.now()) ||
          (new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(this.state.planDate) ===
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(Date.now()) &&
            convertTo24HrsFormat(
              new Intl.DateTimeFormat("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(this.state.planTime)
            ) <
              convertTo24HrsFormat(
                new Intl.DateTimeFormat("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(Date.now())
              ))
        ) {
          if (
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(this.state.planDate) <
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(Date.now())
          ) {
            this.setState({
              dateCase: true,
              openSnackbar: true,
            });
          } else if (
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(this.state.planDate) ===
              new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(Date.now()) &&
            convertTo24HrsFormat(
              new Intl.DateTimeFormat("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(this.state.planTime)
            ) <
              convertTo24HrsFormat(
                new Intl.DateTimeFormat("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(Date.now())
              )
          ) {
            this.setState({
              openSnackbar: true,
              timeCase: true,
            });
          } else {
            this.setState({
              openSnackbar: true,
              dateCase: true,
            });
          }
        } else {
          currentMessage = {
            text: "",
            planDate: this.state.planDate,
            planTime: this.state.planTime,
          };
          this.props.onSendMeetingPlan(currentMessage);
        }
      }
    }*/
  };

  render() {
    const { classes, intl, loadingUpdateMessage } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          open={this.state.openSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert severity="error">
            {intl.formatMessage({
              id: this.state.dateCase
                ? "invalid_plan_date"
                : "invalid_plan_time",
            })}
          </Alert>
        </Snackbar>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {intl.formatMessage({
            id: "plan_dialog_title",
          })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={intl.formatMessage({
                    id: "plan_date",
                  })}
                  openTo="day"
                  minDate={Date.now()}
                  views={["year", "month", "day"]}
                  value={this.state.planDate}
                  onChange={(newDate) => this.handleChangeDate(newDate)}
                  renderInput={(params) => (
                    <TextField required {...params} style={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item className={classes.gridItem}>
              <LocalizationProviderTime dateAdapter={AdapterDayjs}>
                <TimePicker
                  label={intl.formatMessage({
                    id: "plan_time",
                  })}
                  minTime={
                    new Date(this.state.planDate) > new Date(Date.now())
                      ? null
                      : dayjs(new Date(Date.now()))
                  }
                  value={this.state.planTime}
                  onChange={(newTime) => {
                    this.handleChangeTime(newTime);
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} style={{ width: "100%" }} />
                  )}
                />
              </LocalizationProviderTime>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {loadingUpdateMessage === "loading" ? null : (
            <Button
              className={classes.postButton}
              color="primary"
              onClick={this.props.handleCloseMeetingPlan}
            >
              {intl.formatMessage({
                id: "cancel",
              })}
            </Button>
          )}
          {loadingUpdateMessage !== "loading" ? (
            <Button
              className={classes.postButton}
              color="primary"
              onClick={this.handleSubmit}
            >
              {intl.formatMessage({
                id: "ok",
              })}
            </Button>
          ) : (
            <Button color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loadingUpdateMessage: state.messages.loadingUpdateMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMessage: (info) => dispatch(updateMessage(info)),
  };
};

export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(MeetingPlanDialog)
);
