// @flow
import locationConfig from "../../config/NewLocationConfig";
import { getAllInfoByISO } from "iso-country-currency";

export const editLocationInfo = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const auth = getState().firebase.auth;
    const autherId = auth.uid;
    const firestore = getFirestore();

    if (autherId) {
      firestore
        .collection("users")
        .doc(autherId)
        .get()
        .then((doc) => {
          const userDoc = doc.data();
          if (userDoc.bingoDealLocation) {
            const bingoDealLocation = userDoc.bingoDealLocation;
            const response = {
              ...bingoDealLocation,
              currency: getAllInfoByISO(userDoc.bingoDealLocation.countryCode)
                .currency,
            };
            dispatch({ type: "LOACTION_ACCEPT", response });
          } else if (userDoc.country) {
            const userLocation = {
              city: userDoc.city ? userDoc.city : "",
              country: userDoc.country ? userDoc.country : "",
              countryCode: userDoc.countryCode ? userDoc.countryCode : "",
              latitude: userDoc.latitude ? userDoc.latitude : "",
              longitude: userDoc.longitude ? userDoc.longitude : "",
              readableLocation: userDoc.readableLocation
                ? userDoc.readableLocation
                : "",
              state: userDoc.state ? userDoc.state : "",
              zip: userDoc.zip ? userDoc.zip : "",
            };
            const response = {
              ...userLocation,
              currency: getAllInfoByISO(userDoc.countryCode).currency,
            };
            dispatch({ type: "LOACTION_ACCEPT", response });
          } else {
            locationConfig()
              .then((response) => {
                const bingoDealLocation = response.bingoDealLocation;
                firestore
                  .collection("users")
                  .doc(autherId)
                  .set({
                    ...userDoc,
                    ...bingoDealLocation,
                  })
                  .then(() => {
                    response = {
                      ...response.bingoDealLocation,
                      currency: getAllInfoByISO(
                        response.bingoDealLocation.countryCode
                      ).currency,
                    };
                    dispatch({ type: "LOACTION_ACCEPT", response });
                  })
                  .catch((err) => {
                    dispatch({ type: "LOACTION_ERROR" });
                  });
              })
              .catch((err) => {
                dispatch({ type: "LOACTION_ERROR" });
              });
          }
        })
        .catch((err) => {
          dispatch({ type: "LOACTION_ERROR" });
        });
    } else {
      locationConfig()
        .then((res) => {
          const bingoDealLocation = res.bingoDealLocation;
          const response = {
            ...bingoDealLocation,
            currency: getAllInfoByISO(res.bingoDealLocation.countryCode)
              .currency,
          };
          dispatch({ type: "LOACTION_ACCEPT", response });
        })
        .catch((err) => {
          dispatch({ type: "LOACTION_ERROR" });
        });
    }

    //then method execut when befor task complete
  };
};

export const updateProfileEditLocationInfo = (response) => {
  return (dispatch) => {
    dispatch({ type: "LOACTION_ACCEPT", response });

    //then method execut when befor task complete
  };
};
