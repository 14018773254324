// @flow
import React from 'react';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ArrowBackIosTwoToneIcon from '@mui/icons-material/ArrowBackIosTwoTone';
import CardMedia from '@mui/material/CardMedia';
import MobileStepper from '@mui/material/MobileStepper';
import { constantsConfig } from '../../../config/ConstantsConfig';
import { injectIntl } from 'react-intl';
import Swipe from 'react-easy-swipe';

const useStyles = makeStyles((theme) => ({
  divContainer: {
    padding: '30px',
    backgroundColor: theme.palette.primary.background
  },
  root: {
    maxWidth: 80,
    flexGrow: 1,
    marginTop: '10px',
    marginInlineStart: '20px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.background
  },
  skipButton: {
    ...theme.typography.normalButton,
    color: theme.palette.primary.secondaryText,
    fontSize: '15px'
  },
  backButton: {
    ...theme.typography.normalButton,
    color: theme.palette.primary.primaryText,
    fontSize: '35px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '15px',
    width: '20px',
    '&:hover': {
      borderRadius: '15px',
      width: '20px',
      fontSize: '35px',
      color: theme.palette.primary.primaryText,
      backgroundColor: theme.palette.primary.dark
    }
  },
  media: {
    height: '100%',
    paddingTop: '80%', // 16:9 56.25
    marginTop: '1px'
  },
  description: {
    color: theme.palette.primary.secondaryText
  },
  nextButton: {
    ...theme.typography.normalButton,
    fontSize: '15px',
    padding: '15px',
    width: '150px',
    maxWidth: '150px',
    borderRadius: '30px',
    marginTop: '25px'
  },
  arrowBackIosTwoToneIcon: {
    color: theme.palette.primary.icons
  },
  flexContentCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContentBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const SellScreen = ({ intl, ...props }) => {
  const classes = useStyles();
  const onSwipeMove = (position, event) => {
    if (position.x < 0) props.nextStep();
    else props.prevStep();
  };
  return (
    <React.Fragment>
      <Swipe onSwipeMove={onSwipeMove}>
        <div className={classes.divContainer}>
          <CssBaseline />
          <Container fixed onTouchMove={props.nextStep}>
            <div className={classes.flexContentBetween}>
              <div>
                <Typography align="left">
                  <Button
                    className={classes.backButton}
                    onClick={props.prevStep}
                  >
                    <ArrowBackIosTwoToneIcon
                      className={classes.arrowBackIosTwoToneIcon}
                    />
                  </Button>
                </Typography>
              </div>
              <div>
                <Typography align="right">
                  <Button
                    className={classes.skipButton}
                    onClick={props.handleCloseOnBoardingScreens}
                  >
                    {intl.formatMessage({
                      id: 'skip'
                    })}
                  </Button>
                </Typography>
              </div>
            </div>
            <CardMedia
              className={classes.media}
              image={constantsConfig.imgSell}
            />{' '}
            <div className={classes.flexContentCenter}>
              <Typography variant="h3" gutterBottom>
                {intl.formatMessage({
                  id: 'title_sell'
                })}
              </Typography>
            </div>
            <div className={classes.flexContentCenter}>
              <Typography variant="body1" className={classes.description}>
                {intl.formatMessage({
                  id: 'sell_description'
                })}
              </Typography>
            </div>
            <div className={classes.flexContentCenter}>
              <Button
                variant="contained"
                color="primary"
                className={classes.nextButton}
                onClick={props.nextStep}
              >
                {intl.formatMessage({
                  id: 'next'
                })}
              </Button>
            </div>
            <div className={classes.flexContentCenter}>
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={1}
                className={classes.root}
              />
            </div>
          </Container>
        </div>
      </Swipe>
    </React.Fragment>
  );
};

export default injectIntl(SellScreen);
