// @flow
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import withFirebaseAuth from "react-with-firebase-auth";
import * as rdd from "react-device-detect";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from "@mui/styles";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import CloseIcon from "@mui/icons-material/Close";
import AppleIcon from "@mui/icons-material/Apple";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import firebase from "../../config/FirebaseConfig";
import { signInGoogle } from "../../store/auth/AuthActions";
import "firebase/compat/auth";

const firebaseAppAuth = firebase.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const styles = (theme) => ({
  SignUp: {
    maxHeight: 40,
    marginTop: 0,
  },
  form: {
    marginTop: 0,
    width: "350px",
    backgroundColor: theme.palette.primary.background,
  },
  formMobile: {
    marginTop: 0,
    width: "100%",
    backgroundColor: theme.palette.primary.background,
  },
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    backgroundColor: theme.palette.primary.background,
    marginInlineEnd: "10px",
  },
  containerCloseIcon: {
    backgroundColor: theme.palette.primary.background,
  },
  OR: {
    fontFamily: "Arial",
    fontSize: 20,
    maxHeight: 0.5,
    marginTop: "10px",
  },
});
class SignUpAndSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Check if dialog didn't open from AppBar
      isOpenSignIn:
        (props.isOpenSignIn === false ||
          props.isOpenSignIn === null ||
          !props.isOpenSignIn) &&
        (props.isOpenSignUp === false ||
          props.isOpenSignUp === null ||
          !props.isOpenSignUp)
          ? true
          : props.isOpenSignIn,
      isOpenSignUp:
        (props.isOpenSignIn === false ||
          props.isOpenSignIn === null ||
          !props.isOpenSignIn) &&
        (props.isOpenSignUp === false ||
          props.isOpenSignUp === null ||
          !props.isOpenSignUp)
          ? false
          : props.isOpenSignUp,
      onGoogle: false,
      openBackdrop: false,
    };
  }
  componentDidMount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log("nextProps", nextProps);
    if (nextProps.openBackdrop) {
      this.setState({ openBackdrop: true });
    }
    firebase.auth().onAuthStateChanged((user) => {
      if ((user && this.state.onGoogle) || (user && user.phoneNumber != null)) {
        var updateAuth = {
          id: user.uid,
          name: user.displayName != null ? user.displayName : "user",
          email: user.email != null ? user.email : "",
          photoUrl: user.photoURL ? user.photoURL : "",
          phoneNumber: user.phoneNumber != null ? user.phoneNumber : "",
          createdTimestamp: Date.now(),
        };
        this.props.signInGoogle(updateAuth);
        this.setState({
          onGoogle: false,
        });
      }
    });
    if (nextProps.signInSuccessfully || nextProps.signUpSuccessfully)
      this.props.handleCloseSignUpAndSignIn();
  }
  async signInWithApple(props) {
    const auth = firebase.auth();
    const provider = new firebase.auth.OAuthProvider("apple.com");
    const result = await auth.signInWithPopup(provider);

    // logged-in Apple user
    if (result.user) {
      // put data of user in updateAuth variable
      var updateAuth = {
        id: result.user.uid,
        name:
          result.user.displayName != null ? result.user.displayName : "user",
        email: result.user.email != null ? result.user.email : "",
        photoUrl: result.user.photoURL ? result.user.photoURL : "",
        phoneNumber:
          result.user.phoneNumber != null ? result.user.phoneNumber : "",
        createdTimestamp: Date.now(),
      };
      // call back signInGoogle function from AuthActions
      props.signInGoogle(updateAuth);
    }
  }
  // handler to change between dialogs of Sign up and log in
  handleChangeSignInSignUp = () => {
    this.setState({
      isOpenSignIn: !this.state.isOpenSignIn,
      isOpenSignUp: !this.state.isOpenSignUp,
    });
  };
  uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: this.props.redirect ? this.props.redirect : null,
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (info) => {
        this.setState({ onGoogle: true });
      },
      //signInSuccess: () => false,
    },
  };

  render() {
    const {
      classes,
      handleCloseSignUpAndSignIn,
      authErrorSignIn,
      authErrorSignUp,
      intl,
    } = this.props;
    return (
      <Fragment>
        <Dialog
          open={this.state.openBackdrop}
          onClose={this.setCloseChatLoading}
          fullWidth
        >
          <Backdrop
            //className={classes.backdrop}
            open={this.state.openBackdrop}
          >
            <CircularProgress className={classes.circularProgress} />
          </Backdrop>
        </Dialog>
        <div className={classes.containerCloseIcon}>
          <CloseIcon
            className={classes.xIcon}
            onClick={
              authErrorSignIn === "loading" || authErrorSignUp === "loading"
                ? null
                : handleCloseSignUpAndSignIn
            }
          />
        </div>
        <div className={!rdd.isMobile ? classes.form : classes.formMobile}>
          <div>
            {this.state.isOpenSignIn ? (
              <Typography variant="h3" align="center">
                {intl.formatMessage({
                  id: "login",
                })}
              </Typography>
            ) : (
              <Typography
                variant="h3"
                align="center"
                className={classes.SignUp}
              >
                {intl.formatMessage({
                  id: "sign_up",
                })}
              </Typography>
            )}
            <div align="center" className={classes.containerGoogleButton}>
              <StyledFirebaseAuth
                uiConfig={this.uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </div>
            <div align="center">
              <button
                onClick={(props) => this.signInWithApple(this.props)}
                style={{
                  fontWeight: 500,
                  height: "auto",
                  lineHeight: "normal",
                  maxWidth: "220px",
                  minHeight: "40px",
                  padding: "8px 16px",
                  textAlign: "left",
                  width: "100%",
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                }}
                className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-phone firebaseui-id-idp-button"
              >
                <span>
                  <AppleIcon
                    style={{ color: (theme) => theme.palette.primary.price }}
                  />
                </span>
                <span
                  // align="center"
                  className="firebaseui-idp-text firebaseui-idp-text-long"
                  style={{ paddingLeft: "12px", marginTop: "5px" }}
                >
                  Sign in with apple
                </span>
              </button>
            </div>
            <Typography align="center" className={classes.OR}>
              {intl.formatMessage({
                id: "or",
              })}
            </Typography>
            <br />

            {this.state.isOpenSignIn ? (
              <SignIn
                handleChangeSignInSignUp={this.handleChangeSignInSignUp}
                handleCloseSignUpAndSignIn={handleCloseSignUpAndSignIn}
              />
            ) : (
              <SignUp
                handleChangeSignInSignUp={this.handleChangeSignInSignUp}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openBackdrop: state.auth.openBackdrop,
    signInSuccessfully: state.auth.signInSuccessfully,
    signUpSuccessfully: state.auth.signUpSuccessfully,
    authErrorSignIn: state.auth.authErrorSignIn,
    authErrorSignUp: state.auth.authErrorSignUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInGoogle: (newUser) => dispatch(signInGoogle(newUser)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withFirebaseAuth({
      providers,
      firebaseAppAuth,
    })
  )(SignUpAndSignIn)
);
