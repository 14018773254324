// @flow
const initState = {
  bingoDealLocation: "waitting",
};

const locationReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOACTION_ACCEPT":
      return action.response;

    case "LOACTION_ERROR":
      return null;

    default:
      return state;
  }
};

export default locationReducer;
