export function isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
}

export function convertTo24HrsFormat(time) {
  const slicedTime = time.split(/(PM|AM)/gm)[0];

  let [hours, minutes] = slicedTime.split(":");

  if (hours === "12") {
    hours = "00";
  }

  let updateHourAndMin;

  function addition(hoursOrMin) {
    updateHourAndMin =
      hoursOrMin.length < 2 ? (hoursOrMin = `${0}${hoursOrMin}`) : hoursOrMin;

    return updateHourAndMin;
  }

  if (time.endsWith("PM")) {
    hours = parseInt(hours, 10) + 12;
  }

  return `${addition(hours)}:${addition(minutes)}`;
}

export function getLast30Days() {
  return new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
}

export function getLast7Days() {
  return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
}

export function getYesterdayDate() {
  return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
}

export function getReviewTypeId(reviewTypeName) {
  switch (reviewTypeName) {
    case "late":
      return "RCDEi1XrGpfrFTIEfxpb";
    case "onTime":
      return "F2Pp5B3pIyiZxEVCwJ8a";
    case "reliable":
      return "JiI7StWTQdZf6KO0stTZ";
    case "unreliable":
      return "05DKHx4nxIndTulBHRcT";
    case "communicative":
      return "sSB1N5DKURaYkUT0FxuY";
    case "uncommunicative":
      return "f0SSi5j9PlxkVEWXJH8s";
    case "friendly":
      return "2NYgwZHl7dWsq47Dhg5A";
    case "impolite":
      return "48c5y1z9mWSatPu6qtfb";
    case "itemAsDescribed":
      return "ORYMazy0aOt7Mq1tFLuP";
    case "itemNotDescribed":
      return "RhX8dPiul2rBDUBiyKq2";
    case "other":
      return "9P1GUDsofUoRa1GXkmLn";
    default:
      return;
  }
}

export function getReviewName(reviewTypeName) {
  switch (reviewTypeName) {
    case "late":
      return "Late";
    case "onTime":
      return "OnTime";
    case "reliable":
      return "Reliable";
    case "unreliable":
      return "Unreliable";
    case "communicative":
      return "Communicative";
    case "uncommunicative":
      return "Uncommunicative";
    case "friendly":
      return "Friendly";
    case "impolite":
      return "Impolite";
    case "itemAsDescribed":
      return "Item as described";
    case "itemNotDescribed":
      return "Item not as described";
    case "other":
      return "Other";
    default:
      return;
  }
}
