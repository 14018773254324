// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import withFirebaseAuth from "react-with-firebase-auth";
import "firebase/compat/auth";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { signInGoogle } from "../../store/auth/AuthActions";
import { constantsConfig } from "../../config/ConstantsConfig";
import { globalLanguage } from "../../theme/ThemeContext";
import { signUp } from "../../store/auth/AuthActions";
import firebase from "../../config/FirebaseConfig";
import { isEmptyOrSpaces } from "../../functions/Functions";

const styles = (theme) => ({
  OR: {
    fontFamily: "Arial",
    fontSize: 20,
    maxHeight: 0.5,
    marginTop: 0.5,
  },
  Button: {
    ...theme.typography.normalButton,
    width: "19em",
    maxWidth: "19em",
  },
  link: {
    color: theme.palette.primary.main,
  },
  linkLogIn: {
    color: theme.palette.primary.main,
    marginInlineStart: "3px",
    "&:hover": { color: theme.palette.primary.main, cursor: "pointer" },
  },
  SignInWithGoogle: {
    fontSize: 15,
    fontWeight: "bold",
  },
  CircularProgress: {
    color: theme.palette.primary.icons,
  },
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
  formControl: {
    width: "20em",
    maxWidth: "20em",
    padding: 9,
    border: "0.5px solid",
    borderRadius: "5px",
    borderColor: theme.palette.primary.secondaryText,
    "&:mouseClick": {
      borderColor: theme.palette.primary.main,
      //cursor: "pointer",
    },
  },
  formControlError: {
    width: "20em",
    maxWidth: "20em",
    padding: 9,
    border: "0.5px solid",
    borderRadius: "5px",
    borderColor: theme.palette.primary.errColor,
    "&:mouseClick": {
      borderColor: theme.palette.primary.main,
      //cursor: "pointer",
    },
  },
  socialTitleSignUp: {
    padding: theme.spacing(0, 2, 1, 2),
    color: theme.palette.primary.primaryText,
    opacity: 1,
  },
  authErrorDiv: {
    color: theme.palette.primary.errColor,
  },
  gridItem: {
    marginTop: "0.5em",
  },
});

const firebaseAppAuth = firebase.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};
// function translateAuthError(intl, authError) {
//   if (authError === "Please insert your name.")
//     return intl.formatMessage({
//       id: "auth_insert_your_name",
//     });
//   if (authError === "The email address is badly formatted.")
//     return intl.formatMessage({
//       id: "auth_email_address_badly",
//     });
//   if (authError === "The email address is already in use by another account.")
//     return intl.formatMessage({
//       id: "auth_email_address_used",
//     });
//   if (authError === "Password should be at least 6 characters")
//     return intl.formatMessage({
//       id: "auth_invalid_password",
//     });
// }
class SignUp extends Component {
  state = {
    email: " ",
    password: " ",
    name: "",
    createdTimestamp: Date.now(),
    firstTime: true,
    nameEmpty: false,
    spinner: false,
    hintLoginWithGoogle: false,
    isEmailNull: false,
    isPasswordNull: false,
    isNameNull: false,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    if (this.state.email.includes("gmail")) {
      this.setState({
        hintLoginWithGoogle: true,
      });
    }
    if (e.target.id === "email")
      this.setState({
        isEmailNull: false,
      });
    if (e.target.id === "name")
      this.setState({
        isNameNull: false,
      });
    if (e.target.id === "password")
      this.setState({
        isPasswordNull: false,
      });
  };
  handleStartSpinner = () => {
    this.setState({
      spinner: true,
    });
  };
  handleCloseSpinner = () => {
    this.setState({
      spinner: false,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (
      isEmptyOrSpaces(this.state.name) ||
      isEmptyOrSpaces(this.state.email) ||
      isEmptyOrSpaces(this.state.password)
    ) {
      if (isEmptyOrSpaces(this.state.name))
        this.setState({
          isNameNull: true,
          spinner: false,
        });
      if (isEmptyOrSpaces(this.state.email))
        this.setState({
          isEmailNull: true,
          spinner: false,
        });
      if (isEmptyOrSpaces(this.state.password))
        this.setState({
          isPasswordNull: true,
          spinner: false,
        });
    } else {
      this.setState({
        isNameNull: false,
        isEmailNull: false,
        isPasswordNull: false,
      });
      const profile = {
        name: this.state.name,
        email: this.state.email,
        photoUrl: null,
        phoneNumber: null,
      };
      this.props.signUp(this.state, profile);
      // this.handleCloseSpinner();
    }
  };

  render() {
    const { authErrorSignUp, classes, handleChangeSignInSignUp, intl } =
      this.props;
    // const authErrorTranslated = translateAuthError(intl, authErrorSignUp);
    return (
      <div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "emails_notifications",
              })}
            </Typography>
            <input
              id="email"
              type="text"
              className={
                !this.state.isEmailNull
                  ? classes.formControl
                  : classes.formControlError
              }
              placeholder="Ex. name@mail.com"
              onChange={this.handleChange}
            />
            {this.state.hintLoginWithGoogle ? (
              <Typography variant="subtitle2">
                {intl.formatMessage({
                  id: "auth_can_sign_in_google",
                })}
              </Typography>
            ) : null}
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "name",
              })}
            </Typography>
            <input
              id="name"
              type="text"
              className={
                !this.state.isNameNull
                  ? classes.formControl
                  : classes.formControlError
              }
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "password",
              })}
            </Typography>
            <input
              id="password"
              type="password"
              className={
                !this.state.isPasswordNull
                  ? classes.formControl
                  : classes.formControlError
              }
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            {authErrorSignUp !== "loading" ? (
              <Button
                variant="contained"
                fullWidth
                className={classes.Button}
                color="primary"
                align="center"
                onClick={(e) => {
                  this.handleStartSpinner();
                  this.handleSubmit(e);
                }}
              >
                {intl.formatMessage({
                  id: "sign_up",
                })}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.Button}
                align="center"
              >
                <CircularProgress
                  size={25}
                  className={classes.CircularProgress}
                />
              </Button>
            )}
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.authErrorDiv}
            >
              {this.state.isNameNull ||
              this.state.isEmailNull ||
              this.state.isPasswordNull ? (
                <div>
                  {intl.formatMessage({
                    id: "fill_required_fields",
                  })}
                </div>
              ) : (
                <div>
                  {authErrorSignUp && authErrorSignUp !== "loading" ? (
                    <p>{authErrorSignUp}</p>
                  ) : null}
                </div>
              )}
            </Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Divider variant="middle" />
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography align="center" variant="body1">
              {intl.formatMessage({
                id: "auth_have_account",
              })}
              {""}
              <span
                onClick={handleChangeSignInSignUp}
                className={classes.linkLogIn}
              >
                {intl.formatMessage({
                  id: "login",
                })}
              </span>
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography
              variant="subtitle2"
              className={classes.socialTitleSignUp}
              align="center"
            >
              {intl.formatMessage({
                id: "log_terms_privacy_part1",
              })}{" "}
              <a
                className={classes.link}
                href={
                  globalLanguage === "ar"
                    ? constantsConfig.privacyLinkArabic
                    : constantsConfig.privacyLink
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: "terms_of_service",
                })}
              </a>{" "}
              {intl.formatMessage({
                id: "and",
              })}{" "}
              <a
                className={classes.link}
                href={
                  globalLanguage === "ar"
                    ? constantsConfig.termsLinkArabic
                    : constantsConfig.termsLink
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: "privacy_policy",
                })}
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authErrorSignUp: state.auth.authErrorSignUp,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser, profile) => dispatch(signUp(newUser, profile)),
    signInGoogle: (newUser) => dispatch(signInGoogle(newUser)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withFirebaseAuth({
      firebaseAppAuth,
      providers,
    })
  )(SignUp)
);
