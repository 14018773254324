// @flow
import React from 'react';
import { constantsConfig } from '../../config/ConstantsConfig';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Hidden } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background
  },
  rootGridShops: {
    flexGrow: 1
  },
  paper: {
    padding: 20,
    marginTop: 10,
    marginBottom: 0,
    border: 'none',
    backgroundColor: theme.palette.primary.background
  },
  paperButtons: {
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
    border: 'none',
    backgroundColor: theme.palette.primary.background
  },
  paperButtonsMobile: {
    padding: 10,
    marginTop: 15,
    marginBottom: 5,
    width: '65%',
    border: 'none',
    backgroundColor: theme.palette.primary.background
  },
  logoImage: {
    width: '25%',
    height: '25%'
  },
  leftImage: {
    width: '75%',
    height: '75%',
    marginTop: '15px',
    marginBottom: -20
  },
  logoName: {
    marginTop: '20px',
    marginBottom: '-10px'
  },
  container: {
    marginTop: '50px'
  },
  downloadButtons: {
    ...theme.typography.downloadDialogButtons,
    padding: '10px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      ...theme.typography.downloadDialogButtons,
      padding: '10px',
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main
    }
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  androidIcon: {
    width: '25%',
    height: '25%'
  },
  appleIcon: {
    width: '23%',
    height: '23%'
  },
  androidIconMobile: {
    width: '21%',
    height: '21%'
  },
  appleIconMobile: {
    width: '19%',
    height: '19%'
  },
  xIcon: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: '10px'
  },
  flexContentCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const DialogDownload = (props) => {
  const classes = useStyles();
  const { handleClose, intl } = props;
  return (
    <div className={classes.root}>
      <div>
        <CloseIcon className={classes.xIcon} onClick={handleClose} />
      </div>
      <Grid container>
        <Grid item sm>
          <Paper variant="outlined" className={classes.paper}>
            <img
              src={constantsConfig.imgHandFreeze}
              className={classes.leftImage}
              alt=""
            />
          </Paper>
        </Grid>
        <Grid item sm>
          <Paper variant="outlined" className={classes.paper}>
            <div className={classes.container}>
              <div className={classes.flexContentCenter}>
                <img
                  src={constantsConfig.logoListedNearMe}
                  className={classes.logoImage}
                  alt=""
                />
              </div>
              <div className={classes.flexContentCenter}>
                <Typography
                  variant="h3"
                  color="primary"
                  gutterBottom
                  className={classes.logoName}
                >
                  {intl.formatMessage({
                    id: 'app_name'
                  })}
                </Typography>
              </div>
              <Hidden smDown implementation="css">
                <div className={classes.flexContentCenter}>
                  <Grid container>
                    <Grid item sm>
                      <Paper variant="outlined" className={classes.paper}>
                        <div className={classes.flexContentCenter}>
                          <a
                            href={constantsConfig.playStoreLink}
                            className={classes.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.downloadButtons}
                            >
                              <img
                                src={constantsConfig.imgAndroidIcon}
                                className={classes.androidIcon}
                                alt=""
                              />
                              <div style={{ marginInlineStart: '15px' }}>
                                {intl.formatMessage({
                                  id: 'download_for'
                                })}
                                <br />
                                {intl.formatMessage({
                                  id: 'android'
                                })}
                              </div>
                            </Button>
                          </a>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item sm>
                      <Paper
                        variant="outlined"
                        className={classes.paperButtons}
                      >
                        <div className={classes.flexContentCenter}>
                          <a
                            href={constantsConfig.appStoreLink}
                            className={classes.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.downloadButtons}
                            >
                              <img
                                src={constantsConfig.imgAppleIcon}
                                className={classes.appleIcon}
                                alt=""
                              />
                              <div style={{ marginInlineStart: '15px' }}>
                                {intl.formatMessage({
                                  id: 'download_for'
                                })}
                                <br />
                                {intl.formatMessage({
                                  id: 'apple_ios'
                                })}
                              </div>
                            </Button>
                          </a>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Hidden>
              <Hidden smUp implementation="css">
                <div className={classes.flexContentCenter}>
                  <Paper
                    variant="outlined"
                    className={classes.paperButtonsMobile}
                  >
                    <div className={classes.flexContentCenter}>
                      <a
                        href={constantsConfig.playStoreLink}
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.downloadButtons}
                        >
                          <img
                            src={constantsConfig.imgAndroidIcon}
                            className={classes.androidIconMobile}
                            alt=""
                          />
                          <div style={{ marginInlineStart: '15px' }}>
                            {intl.formatMessage({
                              id: 'download_for'
                            })}
                            <br />
                            {intl.formatMessage({
                              id: 'android'
                            })}
                          </div>
                        </Button>
                      </a>
                    </div>
                  </Paper>
                </div>
                <div className={classes.flexContentCenter}>
                  <Paper
                    variant="outlined"
                    className={classes.paperButtonsMobile}
                  >
                    <div className={classes.flexContentCenter}>
                      <a
                        href={constantsConfig.appStoreLink}
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.downloadButtons}
                        >
                          <img
                            src={constantsConfig.imgAppleIcon}
                            className={classes.appleIconMobile}
                            alt=""
                          />
                          <div style={{ marginInlineStart: '15px' }}>
                            {intl.formatMessage({
                              id: 'download_for'
                            })}
                            <br />
                            {intl.formatMessage({
                              id: 'apple_ios'
                            })}
                          </div>
                        </Button>
                      </a>
                    </div>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(DialogDownload);
