// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import withFirebaseAuth from "react-with-firebase-auth";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import "firebase/compat/auth";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { constantsConfig } from "../../config/ConstantsConfig";
import { globalLanguage } from "../../theme/ThemeContext";
import ForgotPassword from "./ForgotPassword";
import { signIn } from "../../store/auth/AuthActions";
import firebase from "../../config/FirebaseConfig";
import { signInGoogle } from "../../store/auth/AuthActions";
import { isEmptyOrSpaces } from "../../functions/Functions";
import withRouter from "../hooks/WithRouter";

const firebaseAppAuth = firebase.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};
function translateAuthError(intl, authError) {
  if (authError === "Your E-mail or password is incorrect")
    return intl.formatMessage({
      id: "auth_email_password_incorrect",
    });
}
const styles = (theme) => ({
  Button: {
    ...theme.typography.normalButton,
    width: "19em",
    maxWidth: "19em",
  },
  hintLoginWithGoogle: {
    color: theme.palette.primary.dark,
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": { cursor: "pointer" },
  },
  linkSignUp: {
    color: theme.palette.primary.main,
    marginInlineStart: "3px",
    "&:hover": { color: theme.palette.primary.main, cursor: "pointer" },
  },
  CircularProgress: {
    color: theme.palette.primary.icons,
  },
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    backgroundColor: theme.palette.primary.background,
    marginInlineEnd: "10px",
  },
  formControl: {
    width: "20em",
    maxWidth: "20em",
    padding: 9,
    border: "0.5px solid",
    borderRadius: "5px",
    borderColor: theme.palette.primary.secondaryText,
    "&:mouseClick": {
      borderColor: theme.palette.primary.main,
    },
  },
  formControlError: {
    width: "20em",
    maxWidth: "20em",
    padding: 9,
    border: "0.5px solid",
    borderRadius: "5px",
    borderColor: theme.palette.primary.errColor,
    "&:mouseClick": {
      borderColor: theme.palette.primary.main,
    },
  },
  socialTitleSignin: {
    padding: theme.spacing(0, 2, 1, 2),
    color: theme.palette.primary.primaryText,
    opacity: 1,
  },
  authErrorDiv: {
    color: theme.palette.primary.errColor,
  },
  gridItem: {
    marginTop: "0.5em",
  },
});

class SignIn extends Component {
  state = {
    email: " ",
    password: " ",
    spinner: false,
    open: false,
    isEmailNull: false,
    isPasswordNull: false,
    isOpenPhoneNumber: false,
    isSignedIn: false,
    hintLoginWithGoogle: false,
  };
  handleOpenForgotPassword = () => {
    this.setState({
      open: true,
    });
  };
  handleCloseForgotPassword = () => {
    this.setState({
      open: false,
    });
  };
  handleOpenPhoneNumber = () => {
    this.setState({
      isOpenPhoneNumber: true,
    });
  };
  handleClosePhoneNumber = () => {
    this.setState({
      isOpenPhoneNumber: false,
    });
  };
  handleStartSpinner = () => {
    this.setState({
      spinner: true,
    });
  };
  handleCloseSpinner = () => {
    this.setState({
      spinner: false,
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    if (this.state.email.includes("gmail")) {
      this.setState({
        hintLoginWithGoogle: true,
      });
    }
    if (e.target.id === "email")
      this.setState({
        isEmailNull: false,
      });
    if (e.target.id === "password")
      this.setState({
        isPasswordNull: false,
      });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (
      isEmptyOrSpaces(this.state.email) ||
      isEmptyOrSpaces(this.state.password)
    ) {
      if (isEmptyOrSpaces(this.state.email))
        this.setState({
          spinner: false,
          isEmailNull: true,
        });
      if (isEmptyOrSpaces(this.state.password))
        this.setState({
          spinner: false,
          isPasswordNull: true,
        });
    } else {
      this.setState({
        isEmailNull: false,
        isPasswordNull: false,
      });
      var cred = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.signIn(cred);
    }
  };

  render() {
    const {
      signInSuccessfully,
      authErrorSignIn,
      classes,
      handleChangeSignInSignUp,
      handleCloseSignUpAndSignIn,
      intl,
    } = this.props;
    const authErrorTranslated = translateAuthError(intl, authErrorSignIn);
    // if (signInSuccessfully) return <Navigate to="/" />;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleCloseForgotPassword}
          aria-labelledby="form-dialog-title"
        >
          <ForgotPassword
            handleCloseSignIn={handleCloseSignUpAndSignIn}
            handleCloseForgotPassword={this.handleCloseForgotPassword}
          />
        </Dialog>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "emails_notifications",
              })}
            </Typography>
            <input
              id="email"
              type="text"
              className={
                !this.state.isEmailNull
                  ? classes.formControl
                  : classes.formControlError
              }
              placeholder="Ex. name@mail.com"
              onChange={this.handleChange}
            />
            {this.state.hintLoginWithGoogle ? (
              <Typography
                className={classes.hintLoginWithGoogle}
                variant="subtitle2"
                gutterBottom
              >
                {intl.formatMessage({
                  id: "auth_can_sign_in_google",
                })}
              </Typography>
            ) : null}
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "password",
              })}
            </Typography>
            <input
              id="password"
              type="password"
              className={
                !this.state.isPasswordNull
                  ? classes.formControl
                  : classes.formControlError
              }
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            {authErrorSignIn !== "loading" ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.Button}
                align="center"
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
              >
                {intl.formatMessage({
                  id: "login",
                })}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.Button}
                align="center"
              >
                <CircularProgress
                  size={25}
                  className={classes.CircularProgress}
                />
              </Button>
            )}
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2" className={classes.authErrorDiv}>
              {this.state.isEmailNull || this.state.isPasswordNull ? (
                <div>
                  {intl.formatMessage({
                    id: "fill_required_fields",
                  })}
                </div>
              ) : authErrorTranslated ? (
                <p>{authErrorTranslated}</p>
              ) : null}
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography
              variant="body1"
              gutterBottom
              onClick={this.handleOpenForgotPassword}
              className={classes.link}
            >
              {intl.formatMessage({
                id: "forgot_password",
              })}
            </Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Divider variant="middle" />
          </Grid>
          <Grid item>
            <Typography align="center" variant="body1">
              {intl.formatMessage({
                id: "auth_do_not_have_account",
              })}
              {""}
              <span
                onClick={handleChangeSignInSignUp}
                className={classes.linkSignUp}
              >
                {intl.formatMessage({
                  id: "sign_up",
                })}
              </span>
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography
              variant="subtitle2"
              className={classes.socialTitleSignin}
              align="center"
            >
              {intl.formatMessage({
                id: "log_terms_privacy_part1",
              })}{" "}
              <a
                className={classes.link}
                href={
                  globalLanguage === "ar"
                    ? constantsConfig.privacyLinkArabic
                    : constantsConfig.privacyLink
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: "terms_of_service",
                })}
              </a>{" "}
              {intl.formatMessage({
                id: "and",
              })}{" "}
              <a
                className={classes.link}
                href={
                  globalLanguage === "ar"
                    ? constantsConfig.termsLinkArabic
                    : constantsConfig.termsLink
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: "privacy_policy",
                })}
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signInSuccessfully: state.auth.signInSuccessfully,
    authErrorSignIn: state.auth.authErrorSignIn,
    auth: state.firebase.auth,
    loading: state.auth.authErrorSignIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    signInGoogle: (newUser) => dispatch(signInGoogle(newUser)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withFirebaseAuth({
      providers,
      firebaseAppAuth,
    })
  )(SignIn)
);
