// @flow
const initState = { ratingState: null };

const RatingReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_RATING":
      return state;
    case "CREATE_REVIEW":
      return state;

    case "CREATE_RATING_ERROR":
      return { state };
    case "CREATE_REVIEW_ERROR":
      return { state };

    default:
      return state;
  }
};

export default RatingReducer;
