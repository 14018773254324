// @flow
const initState = {
  messageError: false,
  loadingCloseConfirmLegalMessage: false,
  seenMessage: true,
  loadingUpdatePlanName: null,
  loadingUpdateMessage: null,
  loadingDeleteMessage: null,
};

const messagesReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_MESSAGE":
      return { ...initState };
    case "CREATE_MESSAGE_ERROR":
      return { messageError: true };
    case "CREATE_THREAD":
      return { ...initState };
    case "CREATE_THREAD_ERROR":
      return { ...initState };
    case "DELETE_THREAD":
      return { ...initState };
    case "DELETE_THREAD_ERROR":
      return { ...initState };
    case "SEEN_MESSAGE":
      return { ...state, seenMessage: true };
    case "SEEN_MESSAGE_ERROR":
      return { ...state };
    case "UN_SEEN_MESSAGE":
      return { ...state, seenMessage: false };
    case "START_LOADING_CONFIRM_MESSAGE_SPINNER":
      return { ...state, loadingCloseConfirmLegalMessage: "loading" };
    case "CLOSE_CONFIRM_LEGAL_MESSAGE":
      return { ...state, loadingCloseConfirmLegalMessage: "done" };
    case "CLOSE_CONFIRM_LEGAL_MESSAGE_ERROR":
      return { ...state };
    case "UPDATE_PLAN_NAME_SPINNER":
      return { ...state, loadingUpdatePlanName: "loading" };
    case "UPDATE_PLAN_NAME":
      return { ...state, loadingUpdatePlanName: "done" };
    case "UPDATE_PLAN_NAME_ERROR":
      return { ...state, loadingUpdatePlanName: "error" };
    case "UPDATE_MESSAGE_SPINNER":
      return { ...state, loadingUpdateMessage: "loading" };
    case "UPDATE_MESSAGE":
      return { ...state, loadingUpdateMessage: "done" };
    case "UPDATE_MESSAGE_ERROR":
      return { ...state, loadingUpdateMessage: "error" };
    case "DELETE_MESSAGE_SPINNER":
      return { ...state, loadingDeleteMessage: "loading" };
    case "DELETE_MESSAGE":
      return { ...state, loadingDeleteMessage: "done" };
    case "DELETE_MESSAGE_ERROR":
      return { ...state, loadingDeleteMessage: "error" };

    default:
      return state;
  }
};

export default messagesReducer;
