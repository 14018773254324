import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { isEmptyOrSpaces } from "../../functions/Functions";
import { updatePlanName } from "../../store/messages/NewMessagesAction";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = (theme) => ({
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
  gridItem: {
    width: "100%",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
  },
});

class PlanNameDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planName: props.message.planName
        ? props.message.planName !== ""
          ? props.message.planName
          : ""
        : "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loadingUpdatePlanName === "done") {
      this.props.handleClosePlanNameDialog();
    }
  }

  handleCloseSnackbar = () => {
    this.setState({
      openSnackbar: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      isEmptyField: false,
      openSnackbar: false,
      planName: e.target.value,
    });
  };

  handleSubmit = () => {
    if (isEmptyOrSpaces(this.state.planName) === true) {
      this.setState({
        isEmptyField: true,
        openSnackbar: true,
      });
    } else {
      var info = {
        messageDetails: {
          message: this.props.message,
          senderId: this.props.thread.senderId,
          receiverId: this.props.thread.receiverId,
          threadId: this.props.thread.threadId,
        },
        threadId: this.props.thread.threadId,
        messageId: this.props.message.id,
        planName: this.state.planName,
      };
      this.props.updatePlanName(info);
    }
  };

  render() {
    const { classes, intl, loadingUpdatePlanName } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          open={this.state.openSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert onClose={this.handleCloseSnackbar} severity="error">
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "fill_required_fields",
              })}
            </Typography>
          </Alert>
        </Snackbar>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {intl.formatMessage({
            id: "plan_name",
          })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              {!this.state.isEmptyField ? (
                <TextField
                  required
                  id="outlined-required"
                  label={intl.formatMessage({
                    id: "plan_name",
                  })}
                  defaultValue={this.state.planName}
                  onChange={this.handleChange}
                  sx={{ width: "100%" }}
                />
              ) : (
                <TextField
                  error
                  required
                  id="outlined-required"
                  label={intl.formatMessage({
                    id: "plan_name",
                  })}
                  defaultValue={this.state.planName}
                  onChange={this.handleChange}
                  sx={{ width: "100%" }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {loadingUpdatePlanName === "loading" ? null : (
            <Button
              className={classes.postButton}
              color="primary"
              onClick={this.props.handleClosePlanNameDialog}
            >
              {intl.formatMessage({
                id: "cancel",
              })}
            </Button>
          )}
          {loadingUpdatePlanName !== "loading" ? (
            <Button
              className={classes.postButton}
              color="primary"
              onClick={this.handleSubmit}
            >
              {intl.formatMessage({
                id: "save",
              })}
            </Button>
          ) : (
            <Button color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loadingUpdatePlanName: state.messages.loadingUpdatePlanName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlanName: (info) => dispatch(updatePlanName(info)),
  };
};

export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(PlanNameDialog)
);
