import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { injectIntl } from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';

const styles = (theme) => ({
  icons: {
    color: theme.palette.primary.accent
  },
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background
  }
});

class CreateNewListing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      intl,
      classes,
      handleCloseNewListingDialog,
      handleOpenAddProduct,
      handleOpenAddVehicleDialog,
      handleOpenAddHomeDialog,
      handleOpenAddYardSaleEventDialog,
      handleOpenAddMobileDialog,
      handleOpenAddTabletDialog
    } = this.props;
    return (
      <React.Fragment>
        <DialogTitle
          id="simple-dialog-title"
          variant="h5"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {intl.formatMessage({
            id: 'new_listing_title'
          })}
        </DialogTitle>
        <DialogContent className={classes.dialogsBackground}>
          <List>
            <ListItem
              button
              onClick={(e) => {
                handleCloseNewListingDialog();
                handleOpenAddMobileDialog();
              }}
            >
              <ListItemAvatar>
                <PhoneAndroidIcon className={classes.icons} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" className={classes.listItemText}>
                    {intl.formatMessage({
                      id: 'category_mobiles'
                    })}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                handleCloseNewListingDialog();
                handleOpenAddTabletDialog();
              }}
            >
              <ListItemAvatar>
                <TabletAndroidIcon className={classes.icons} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" className={classes.listItemText}>
                    {intl.formatMessage({
                      id: 'category_tablets'
                    })}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                handleCloseNewListingDialog();
                handleOpenAddVehicleDialog();
              }}
            >
              <ListItemAvatar>
                <DriveEtaIcon className={classes.icons} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" className={classes.listItemText}>
                    {intl.formatMessage({
                      id: 'category_vehicles'
                    })}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                handleCloseNewListingDialog();
                handleOpenAddHomeDialog();
              }}
            >
              <ListItemAvatar>
                <HomeIcon className={classes.icons} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" className={classes.listItemText}>
                    {intl.formatMessage({
                      id: 'create_home_title'
                    })}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                handleCloseNewListingDialog();
                handleOpenAddYardSaleEventDialog();
              }}
            >
              <ListItemAvatar>
                <EventIcon className={classes.icons} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" className={classes.listItemText}>
                    {intl.formatMessage({
                      id: 'category_yard_sale_event'
                    })}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                handleCloseNewListingDialog();
                handleOpenAddProduct();
              }}
            >
              <ListItemAvatar>
                <ShoppingBasketIcon className={classes.icons} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" className={classes.listItemText}>
                    {intl.formatMessage({
                      id: 'items_title'
                    })}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(CreateNewListing));
