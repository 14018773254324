// @flow
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import Album from "../products/ProductListForSell";
import { Dialog, Typography, DialogContent } from "@mui/material";
import { constantsConfig } from "../../config/ConstantsConfig";
import { firestoreConnect } from "react-redux-firebase";
import DrawerSellPage from "../layout/DrawerSellPage";
import { Hidden, Drawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import StarRating from "../products/StarRating";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForword from "@mui/icons-material/ArrowForward";
import { addRateReview } from "../layout/AddRateReview";
import { injectIntl } from "react-intl";
import { globalLanguage } from "../../theme/ThemeContext";
import withRouter from "../hooks/WithRouter";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background,
  },
  rootGridShops: {
    flexGrow: 1,
    marginTop: "20px",
  },
  updateContent: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.background,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.background,
  },
  cardGrid: {
    paddingTop: theme.spacing(5.5),
    paddingBottom: theme.spacing(5.5),
  },
  paper: {
    height: 400,
    width: 250,
    borderRadius: "25px",
  },
  card: {
    maxWidth: 345,
    height: 425,
    padding: "10px",
    borderRadius: "25px",
  },

  fab: {
    color: theme.palette.primary.icons,
    backgroundColor: theme.palette.primary.main,
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  media: {
    height: 225,
    borderRadius: "25px",
  },
  flexHeder: {
    height: "40px",
    display: "flex",
    alignItems: "left",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "space-between",
  },
  locationOnIcon: {
    fontSize: "20px",
    color: theme.palette.primary.main,
  },
  iconButton: {
    color: theme.palette.primary.icons,
  },
  typographyRateBuyer: {
    color: theme.palette.primary.icons,
    marginRight: "9em",
    marginTop: "7px",
  },
  typographyRateBuyerMobile: {
    color: theme.palette.primary.icons,
    marginRight: "4em",
    marginTop: "8px",
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(10),
  },
  flexContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  mainContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.background,
    paddingTop: "10px",
    paddingBottom: "1em",
  },
});

class SellPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isOpenAddShop: false,
      mobileOpen: false,
      rate: this.props.rateToUserId !== "null" ? true : false,
    };
  }
  handleCloseAddShop = () => {
    this.setState({
      isOpenAddShop: false,
    });
  };
  handleCloseRating = () => {
    this.setState({ rate: false });
  };
  handleOpenAddShop = () => {
    this.setState({
      isOpenAddShop: true,
    });
  };

  handleSubmitRating = (
    ratingScore,
    isPositiveReview,
    reviewDetails,
    reviewsNames
  ) => {
    const Rate = {
      ratingScore: ratingScore,
      ratingFromUserId: this.props.auth.uid,
      ratingToUserId: this.props.rateToUserId,
      ratingFromType: "buyer",
      subjectId: this.props.subjectId,
    };
    var reviews = [];
    reviewsNames.map((i) => {
      const rev = {
        reviewTypeName: i.reviewTypeName,

        reviewTypeId: this.getReviewTypeId(i.reviewTypeName),
        isPositiveReview: isPositiveReview,
        reviewDetails: reviewDetails,
        reviewFromUserId: this.props.auth.uid,
        reviewToUserId: this.props.rateToUserId,
        reviewFromType: "buyer",
        subjectId: this.props.subjectId,
      };
      reviews.push(rev);
      return reviews;
    });
    if (reviewDetails !== "") {
      const rev = {
        reviewTypeName: "Other",

        reviewTypeId: this.getReviewTypeId("Other"),
        isPositiveReview: isPositiveReview,
        reviewDetails: reviewDetails,
        reviewFromUserId: this.props.auth.uid,
        reviewToUserId: this.props.rateToUserId,
        reviewFromType: "buyer",
        subjectId: this.props.subjectId,
      };
      reviews.push(rev);
    }

    const dataRateReview = {
      rateData: Rate,
      reviewData: reviews ? (reviews.length !== 0 ? reviews : null) : null,
    };
    addRateReview(dataRateReview);
    this.props.router.navigate("/sellpageproducts/null");
    this.handleCloseRating();
  };
  getReviewTypeId = (reviewTypeName) => {
    switch (reviewTypeName) {
      case "Late":
        return "RCDEi1XrGpfrFTIEfxpb";
      case "OnTime":
        return "F2Pp5B3pIyiZxEVCwJ8a";
      case "Reliable":
        return "JiI7StWTQdZf6KO0stTZ";
      case "Unreliable":
        return "05DKHx4nxIndTulBHRcT";
      case "Communicative":
        return "sSB1N5DKURaYkUT0FxuY";
      case "Uncommunicative":
        return "f0SSi5j9PlxkVEWXJH8s";
      case "Friendly":
        return "2NYgwZHl7dWsq47Dhg5A";
      case "Impolite":
        return "48c5y1z9mWSatPu6qtfb";
      case "Item as described":
        return "ORYMazy0aOt7Mq1tFLuP";
      case "Item not as described":
        return "RhX8dPiul2rBDUBiyKq2";
      case "Other":
        return "9P1GUDsofUoRa1GXkmLn";
      default:
        return;
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  handleChangeTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes, auth, intl } = this.props;
    if (!auth.uid) {
      return (window.location.href = constantsConfig.mainUrl);
    }

    return (
      <div className={classes.root}>
        {/*Laptop*/}
        <div
          style={
            {
              /*marginInlineStart: 250*/
            }
          }
        >
          <Dialog
            onClose={this.handleCloseRating}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={this.state.rate}
          >
            <Hidden smDown implementation="css">
              <div className={classes.flexHeder}>
                {" "}
                <IconButton
                  className={classes.iconButton}
                  onClick={this.handleCloseRating}
                >
                  {globalLanguage !== "ar" ? <ArrowBack /> : <ArrowForword />}
                </IconButton>
                <Typography
                  variant="h5"
                  className={classes.typographyRateBuyer}
                >
                  {intl.formatMessage({
                    id: "rate_the_buyer",
                  })}
                </Typography>
              </div>
              <DialogContent className={classes.updateContent}>
                {" "}
                <StarRating
                  ratingFromUserId={auth.uid}
                  ratingToUserId={this.props.ratingToUserId}
                  ratingToUserName={this.props.rateToUserName}
                  ratingToUserPhoto={this.props.rateToUserPhoto}
                  handleSubmitRating={this.handleSubmitRating}
                />
              </DialogContent>
            </Hidden>
            <Hidden smUp implementation="css">
              <div className={classes.flexHeder}>
                {" "}
                <IconButton
                  className={classes.iconButton}
                  onClick={this.handleCloseRating}
                >
                  {globalLanguage !== "ar" ? <ArrowBack /> : <ArrowForword />}
                </IconButton>
                <Typography
                  variant="h5"
                  className={classes.typographyRateBuyerMobile}
                >
                  {intl.formatMessage({
                    id: "rate_the_buyer",
                  })}
                </Typography>
              </div>
              <DialogContent className={classes.updateContent}>
                {" "}
                <StarRating
                  ratingFromUserId={auth.uid}
                  ratingToUserId={this.props.ratingToUserId}
                  ratingToUserName={this.props.rateToUserName}
                  ratingToUserPhoto={this.props.rateToUserPhoto}
                  handleSubmitRating={this.handleSubmitRating}
                />
              </DialogContent>
            </Hidden>
          </Dialog>
          <Hidden smDown implementation="css">
            {/* <DrawerSellPage /> */}
            <main className={classes.mainContent}>
              <Album router={this.props.router} ownerProductsId={auth.uid} />
            </main>
          </Hidden>
        </div>
        {/*Mobile*/}
        <div>
          <Hidden smUp implementation="css">
            <Drawer
              // container={container}
              variant="temporary"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaperMobile,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DrawerSellPage />
            </Drawer>

            {/* <div className={classes.flexContentCenter}>
              <IconButton
                className={classes.IconButton}
                onClick={this.handleDrawerToggle}
                style={{ marginTop: '25px' }}
              >
                <FilterListIcon />
              </IconButton>
            </div> */}
            <div>
              <Album history={this.props.history} ownerProductsId={auth.uid} />
            </div>
          </Hidden>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    rateToUserId: props.router.params.rateToUserId.split("+")[0],
    subjectId: props.router.params.rateToUserId.split("+")[1],
    rateToUserName: state.firestore.ordered.rateToUser
      ? state.firestore.ordered.rateToUser[0]
        ? state.firestore.ordered.rateToUser[0].name
        : null
      : null,
    rateToUserPhoto: state.firestore.ordered.rateToUser
      ? state.firestore.ordered.rateToUser[0]
        ? state.firestore.ordered.rateToUser[0].photoUrl
        : null
      : null,
  };
};
export default injectIntl(
  withRouter(
    compose(
      withStyles(styles),
      connect(mapStateToProps),
      firestoreConnect((props, state) => {
        return [
          {
            collection: constantsConfig.collectionShops,
            where: ["ownerId", "==", props.auth.uid],
          },
        ];
      }),
      firestoreConnect((props, state) => {
        return [
          {
            collection: constantsConfig.collectionUsers,
            where: ["id", "==", props.router.params.rateToUserId.split("+")[0]],
            storeAs: "rateToUser",
          },
        ];
      })
    )(SellPage)
  )
);
