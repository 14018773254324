import React from "react";
import { makeStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  ListItem: {
    "&:hover": { cursor: "pointer" },
  },
  containerGrid: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.background,
  },
  inline: {
    display: "inline",
    width: "100%",
  },
  list: {
    overflowY: "auto",
    height: window.innerHeight - 300,
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.30)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.secondaryText,
      outline: "1px solid slategrey",
    },
  },
  buttonSellOnListedNearMe: {
    ...theme.typography.didNotSellOnListedButton,
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.main,
  },
  container: {
    backgroundColor: theme.palette.primary.background,
  },
}));

const ListOfBuyingUsers = ({ intl, ...props }) => {
  const classes = useStyles();
  const { auth } = props;
  if (props.threadsSpinner)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.containerGrid}
      >
        <CircularProgress />
      </Grid>
    );
  return (
    <List className={classes.container}>
      <div className={classes.list}>
        {props.threads &&
          props.threads.map((thread, index) => (
            <React.Fragment key={index}>
              <ListItem
                className={classes.ListItem}
                alignItems="flex-start"
                onClick={() => {
                  props.handleNextStep();
                  props.handleChooseThread(thread);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={thread.subjectName}
                    src={
                      auth.uid === thread.senderId
                        ? thread.receiverPhoto
                        : thread.senderPhoto
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        <div style={{ marginTop: "10px" }}>
                          {auth.uid === thread.senderId
                            ? thread.receiverName
                            : thread.senderName}
                        </div>
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonSellOnListedNearMe}
        onClick={() => {
          props.handleNotSoldOnListedNearMe();
        }}
      >
        {intl.formatMessage({
          id: "not_sold_listed_near_me",
        })}
      </Button>
    </List>
  );
};

export default injectIntl(ListOfBuyingUsers);
