// @flow
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

function checkIsSelected(selectedAmenities, amenitie) {
  for (let i = 0; i < selectedAmenities.length; i++) {
    if (selectedAmenities[i] === amenitie) return true;
  }
  return false;
}

class AmenitiesChipsDialog extends Component {
  constructor(props) {
    super(props);
    if (props.selectedAmenities.length !== 0) {
      const isSelected = checkIsSelected(
        props.selectedAmenities,
        props.amenitie
      );
      this.state = {
        isSelected: isSelected
      };
    } else
      this.state = {
        isSelected: false
      };
  }
  handleChangeSelected = () => {
    if (this.state.isSelected === false)
      this.setState({
        isSelected: true
      });
    else
      this.setState({
        isSelected: false
      });
  };
  render() {
    const {
      amenitie,
      amenitieTranslated,
      handleAddAmenities,
      handleRemoveAmenitiesItem
    } = this.props;
    return (
      <React.Fragment>
        <Grid item>
          {this.state.isSelected ? (
            <Chip
              label={
                <Typography variant="body1">{amenitieTranslated}</Typography>
              }
              onClick={(e) => {
                this.handleChangeSelected();
                handleRemoveAmenitiesItem(e, amenitie);
              }}
              color="primary"
            />
          ) : (
            <Chip
              avatar={<AddIcon />}
              label={
                <Typography variant="body1">{amenitieTranslated}</Typography>
              }
              onClick={(e) => {
                this.handleChangeSelected();
                handleAddAmenities(e, amenitie);
              }}
            />
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default injectIntl(AmenitiesChipsDialog);
