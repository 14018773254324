// @flow
import { constantsConfig } from "../../config/ConstantsConfig";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig, vapidKey } from "../../config/FirebaseConfig";
import axios from "axios";
const messaging = getMessaging();
// import firebase from 'firebase/compat/app';
const processSendNotification = (
  dispatch,
  thread,
  messageDetails,
  sendEmailAndNotificationV1
) => {
  var receiverId = thread.receiverId,
    senderName = thread.senderName;
  if (receiverId === messageDetails.message.userId) {
    receiverId = thread.senderId;
    senderName = thread.receiverName;
  }
  sendEmailAndNotificationV1(
    JSON.stringify({
      type: 2,
      notificationData: {
        message: messageDetails.message.text,
        notificationText: thread.subjectName,
        userId: receiverId,
        notificationPhotoUrl: thread.subjectPhoto,
        subjectId: thread.threadId,
      },
      emailData: {
        senderName: senderName,
        threadId: thread.threadId,
        userId: receiverId,
        userEmail: "",
      },
    })
  )
    .then((res) => {
      dispatch({ type: "CREATE_MESSAGE" });
    })
    .catch((err) => {
      dispatch({ type: "CREATE_MESSAGE_ERROR", err });
    });
};

export const updateSeenMessage = (messageDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SEEN_MESSAGE" });
  };
};

export const seenMessage = (messageDetails, isUpdate) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("threads")
      .doc(messageDetails.threadId)
      .update({
        recentMessagesNotSeenByUserId: null,
      })
      .then(() => {
        dispatch({ type: "SEEN_MESSAGE" });
      })
      .catch((err) => {
        dispatch({ type: "SEEN_MESSAGE_ERROR", err });
      });
  };
};

export const sendNotification = (
  messageDetails,
  dispatch,
  getFirebase,
  getFirestore
) => {
  // return (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const firebase = getFirebase();
  const functions = firebase.functions();
  var sendEmailAndNotificationV1 = functions.httpsCallable(
    "sendEmailNotification"
  );
  let receiverId =
    messageDetails.message.userId === messageDetails.receiverId
      ? messageDetails.senderId
      : messageDetails.receiverId;
  firestore
    .collection("threads")
    .doc(messageDetails.threadId)
    .get()
    .then((doc) => {
      var thread = doc.data();
      firestore
        .collection("threads")
        .doc(messageDetails.threadId)
        .set({
          ...thread,
          lastModifiedTimestamp: new Date().getTime(),
          senderLastMessageTimestamp: new Date().getTime(),
          recentMessagesNotSeenByUserId: receiverId,
        })
        .then(() => {
          dispatch({ type: "CREATE_MESSAGE" });
          processSendNotification(
            dispatch,
            thread,
            messageDetails,
            sendEmailAndNotificationV1
          );
        })
        .catch((err) => {
          dispatch({ type: "CREATE_MESSAGE_ERROR", err });
        });
    });
  // };
};

export const createMessage = (messageDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "UN_SEEN_MESSAGE" });
    const firestore = getFirestore();

    firestore
      .collection("threads")
      .doc(messageDetails.threadId)
      .collection("messages")
      .add({
        ...messageDetails.message,
        timestamp: new Date().getTime(),
      })
      .then(() => {
        dispatch({ type: "CREATE_MESSAGE" });
        sendNotification(messageDetails, dispatch, getFirebase, getFirestore);
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MESSAGE_ERROR", err });
      });
  };
};

export const closeConfirmLegalMessage = (details) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "START_LOADING_CONFIRM_MESSAGE_SPINNER" });
    if (details.uid === details.thread.senderId) {
      firestore
        .collection("threads")
        .doc(details.thread.threadId)
        .update({
          senderConfirmLegalTimestamp: new Date().getTime(),
        })
        .then(() => {
          dispatch({ type: "CLOSE_CONFIRM_LEGAL_MESSAGE" });
        })
        .catch((err) => {
          dispatch({ type: "CLOSE_CONFIRM_LEGAL_MESSAGE_ERROR" });
        });
    } else {
      firestore
        .collection("threads")
        .doc(details.thread.threadId)
        .update({
          receiverConfirmLegalTimestamp: new Date().getTime(),
        })
        .then(() => {
          dispatch({ type: "CLOSE_CONFIRM_LEGAL_MESSAGE" });
        })
        .catch((err) => {
          dispatch({ type: "CLOSE_CONFIRM_LEGAL_MESSAGE_ERROR" });
        });
    }
  };
};

export const createArchivedThreads = (thread) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionArchivedThreads)
      .doc(thread.threadId)
      .set({
        ...thread,
        // id: thread.threadId,
      })
      .then(() => {
        dispatch({ type: "CREATE_THREAD" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_THREAD_ERROR" });
      });
  };
};

export const createArchivedMessages = (threadId, message) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionArchivedThreads)
      .doc(threadId)
      .collection(constantsConfig.collectionMessages)
      .add({
        ...message,
        // id: thread.threadId,
      })
      .then(() => {
        dispatch({ type: "CREATE_MESSAGE" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MESSAGE_ERROR" });
      });
  };
};

export const updateMessage = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "UPDATE_MESSAGE_SPINNER" });
    if (info.planDate) {
      firestore
        .collection(constantsConfig.collectionThreads)
        .doc(info.threadId)
        .collection(constantsConfig.collectionMessages)
        .doc(info.messageId)
        .update({
          planDate: info.planDate,
          planTime: info.planTime,
        })
        .then(() => {
          sendNotification(
            info.messageDetails,
            dispatch,
            getFirebase,
            getFirestore
          );
          dispatch({ type: "UPDATE_MESSAGE" });
        })
        .catch((err) => {
          dispatch({ type: "UPDATE_MESSAGE_ERROR" });
        });
    } else {
      const { planLocation } = info;
      firestore
        .collection(constantsConfig.collectionThreads)
        .doc(info.threadId)
        .collection(constantsConfig.collectionMessages)
        .doc(info.messageId)
        .update({
          planLocation,
        })
        .then(() => {
          sendNotification(
            info.messageDetails,
            dispatch,
            getFirebase,
            getFirestore
          );
          dispatch({ type: "UPDATE_MESSAGE" });
        })
        .catch((err) => {
          dispatch({ type: "UPDATE_MESSAGE_ERROR" });
        });
    }
  };
};

export const updatePlanName = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "UPDATE_PLAN_NAME_SPINNER" });
    firestore
      .collection(constantsConfig.collectionThreads)
      .doc(info.threadId)
      .collection(constantsConfig.collectionMessages)
      .doc(info.messageId)
      .update({
        planName: info.planName,
      })
      .then(() => {
        sendNotification(
          info.messageDetails,
          dispatch,
          getFirebase,
          getFirestore
        );
        dispatch({ type: "UPDATE_PLAN_NAME" });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_PLAN_NAME_ERROR" });
      });
  };
};

export const deleteMessage = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "DELETE_MESSAGE_SPINNER" });
    firestore
      .collection(constantsConfig.collectionThreads)
      .doc(info.threadId)
      .collection(constantsConfig.collectionMessages)
      .doc(info.messageId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_MESSAGE" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_MESSAGE_ERROR" });
      });
  };
};

export const deleteThread = (thread) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(constantsConfig.collectionThreads)
      .doc(thread.threadId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_THREAD" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_THREAD_ERROR" });
      });
  };
};

export const sendNotificationTest = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    // const firestore = getFirestore();
    const functions = firebase.functions();
    var sendEmailNotification = functions.httpsCallable(
      "sendEmailNotification_2023"
    );
    console.log("data", data);
    const currentToken = await getToken(messaging, {
      vapidKey: vapidKey,
    });
    console.log("currentToken");
    if (currentToken) {
      console.log("currentTokenSuccess");
    }
    sendEmailNotification(
      JSON.stringify({
        type: 2,
        notificationData: {
          message: data.message,
          notificationText: data.notificationText,
          userId: data.userId,
          notificationPhotoUrl: data.notificationPhotoUrl,
          subjectId: data.subjectId,
          thread: data.thread,
          webToken: currentToken,
        },
        emailData: {
          senderName: data.thread.senderName,
          threadId: data.thread.threadId,
          emailPhotoUrl: data.thread.subjectPhoto,
          subjectId: data.thread.subjectId,
          receiverId: data.thread.receiverId,
          receiverEmail: data.user.email ? data.user.email : "",
        },
      })
    )
      .then((res) => {
        console.log("success");
        // dispatch({ type: "CREATE_MESSAGE" });
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({ type: "CREATE_MESSAGE_ERROR", err });
      });
  };
};
