import { searchProducts } from "../product/GetProductsData";
export const changeSearchText = (searchText) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SEARSH_TEXT", searchText });

    //then method execut when befor task complete
  };
};

export const clearSearchText = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_SEARCH_TEXT" });
    //then method execut when befor task complete
  };
};

export const getSearchProducts = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "LOADING_SEARCH_PRODUCTS" });
    var products = [];
    var filteredProducts = [];
    searchProducts({
      countryCode: info.countryCode,
      categoryId: info.categoryId,
      startAfterProductId: info.startAfterProductId,
      latitude: info.latitude,
      longitude: info.longitude,
      typeProducts: info.typeProducts,
      radius: info.radius,
      price: info.price ? info.price : null,
      maxResult: 100,
    })
      .then((res) => {
        products.push(...res);
        for (let i = 0; i < products.length; i++) {
          if (
            products[i].title
              .toLowerCase()
              .includes(info.searchText.toLowerCase()) ||
            (products[i].description &&
              products[i].description
                .toLowerCase()
                .includes(info.searchText.toLowerCase()))
          ) {
            var product = products[i];
            filteredProducts.push(product);
          }
        }
        dispatch({ type: "GET_SEARCH_PRODUCTS_SUCCESS", filteredProducts });
      })
      .catch((err) => {
        dispatch({ type: "GET_SEARCH_PRODUCTS_ERROR" });
      });
  };
};
