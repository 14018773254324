// @flow
import React, { Component } from 'react';
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

class PriceForm extends Component {
  state = {
    price: this.props.addProductData.price,
    currencyCode: '',
    firstTime: true,
    errorInPrice: false
  };
  componentDidMount() {
    const { locationConfig } = this.props;
    if (locationConfig) {
      this.props.onHandleChangeValue('currencyCode', locationConfig.currency);

      this.setState({ currencyCode: locationConfig.currency });
    }
    if (this.state.firstTime) {
      /*  analytics.logEvent("view_item", {
        product_name: this.props.addProductData.title,
      });*/
      this.setState({ firstTime: false });
    }
  }

  onChangeHandler = (e) => {
    const digits_only = (string) =>
      [...string].every((c) => '0123456789'.includes(c));

    if (!digits_only(e.target.value) || this.props.isPriceNull === true) {
      this.setState({ errorInPrice: true });
    } else {
      this.setState({ errorInPrice: false });
    }
    this.props.onHandleChangeValue('price', e.target.value);
  };
  render() {
    const { intl } = this.props;
    return (
      <React.Fragment>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">
            {intl.formatMessage({
              id: 'tab_price'
            })}
          </InputLabel>
          {this.props.isPriceNull === false ? (
            <Input
              autoFocus
              id="standard-adornment-amount"
              placeholder={intl.formatMessage({
                id: 'tab_price'
              })}
              onChange={this.onChangeHandler}
              startAdornment={
                <InputAdornment position="start">
                  {this.state.currencyCode}
                </InputAdornment>
              }
              defaultValue={this.state.price}
              type="number"
              min={0}
              required
              error={this.state.errorInPrice}
            />
          ) : (
            <Input
              autoFocus
              id="standard-adornment-amount"
              placeholder={intl.formatMessage({
                id: 'tab_price'
              })}
              onChange={this.onChangeHandler}
              startAdornment={
                <InputAdornment position="start">
                  {this.state.currencyCode}
                </InputAdornment>
              }
              defaultValue={this.state.price}
              type="number"
              min={0}
              required
              error={true}
            />
          )}
        </FormControl>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    locationConfig: state.locationReducer
  };
};
export default injectIntl(connect(mapStateToProps)(PriceForm));
