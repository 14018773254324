import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({}));

const StarRatingNew = ({ intl, ...props }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    late: false,
    unreliable: false,
    uncommunicative: false,
    impolite: false,
    itemNotDescribed: false,
    onTime: false,
    reliable: false,
    communicative: false,
    friendly: false,
    itemAsDescribed: false,
    other: false,
  });
  const [rating, setRating] = React.useState(null);
  const [reviews, setReviews] = React.useState([]);
  const [otherNotes, setOtherNotes] = React.useState("");
  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const {
    late,
    unreliable,
    uncommunicative,
    impolite,
    itemNotDescribed,
    onTime,
    reliable,
    communicative,
    friendly,
    itemAsDescribed,
    other,
  } = state;

  const handleChangeOtherNotes = (event) => {
    setOtherNotes(event.target.value);
  };

  const handleChangeState = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    if (!event.target.checked) {
      var index = reviews.indexOf(event.target.name);
      reviews.splice(index, 1);
    } else {
      reviews.push(event.target.name);
    }
  };

  const handleChangeRating = (event, newValue) => {
    setRating(newValue);
    setEnableSubmit(newValue ? true : false);
    if (newValue >= 1 && newValue <= 3) {
      setState({
        ...state,
        onTime: false,
        reliable: false,
        communicative: false,
        friendly: false,
        itemAsDescribed: false,
        other: false,
      });
      setReviews([]);
      setOtherNotes("");
    } else {
      setState({
        ...state,
        late: false,
        unreliable: false,
        uncommunicative: false,
        impolite: false,
        itemNotDescribed: false,
        other: false,
      });
      setReviews([]);
      setOtherNotes("");
    }
  };

  const handleSubmit = () => {
    props.handleSubmitRating(
      rating,
      rating <= 3 ? false : true,
      otherNotes,
      reviews,
      props.myThread
    );
  };
  return (
    <React.Fragment>
      <Container
        sx={{
          padding: 3,
          backgroundColor: (theme) => theme.palette.primary.background,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Avatar
              src={props.myThread.receiverPhoto}
              alt={props.myThread.receiverPhoto}
              className={classes.ratingToUserPhoto}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.ratingQ}>
              {intl.formatMessage({
                id: "rate_review_question",
              })}{" "}
              {props.myThread.receiverName}{" "}
              {intl.formatMessage({
                id: "again",
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Rating
              name="simple-controlled"
              size="large"
              value={rating}
              onChange={(event, newValue) => {
                handleChangeRating(event, newValue);
              }}
              sx={{ color: (theme) => theme.palette.primary.main }}
            />
          </Grid>
        </Grid>
        {rating >= 1 && rating <= 3 ? (
          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={late}
                      onChange={handleChangeState}
                      name="late"
                    />
                  }
                  label={intl.formatMessage({
                    id: "ontime_negative",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unreliable}
                      onChange={handleChangeState}
                      name="unreliable"
                    />
                  }
                  label={intl.formatMessage({
                    id: "reliable_negative",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uncommunicative}
                      onChange={handleChangeState}
                      name="uncommunicative"
                    />
                  }
                  label={intl.formatMessage({
                    id: "communicative_negative",
                  })}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={impolite}
                      onChange={handleChangeState}
                      name="impolite"
                    />
                  }
                  label={intl.formatMessage({
                    id: "friendly_negative",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemNotDescribed}
                      onChange={handleChangeState}
                      name="itemNotDescribed"
                    />
                  }
                  label={intl.formatMessage({
                    id: "itemasdescribed_negative",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={other}
                      onChange={handleChangeState}
                      name="other"
                    />
                  }
                  label={intl.formatMessage({
                    id: "product_other",
                  })}
                />
              </FormGroup>
            </FormControl>
          </Box>
        ) : rating > 3 ? (
          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onTime}
                      onChange={handleChangeState}
                      name="onTime"
                    />
                  }
                  label={intl.formatMessage({
                    id: "ontime_postive",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reliable}
                      onChange={handleChangeState}
                      name="reliable"
                    />
                  }
                  label={intl.formatMessage({
                    id: "reliable_postive",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={communicative}
                      onChange={handleChangeState}
                      name="communicative"
                    />
                  }
                  label={intl.formatMessage({
                    id: "communicative_postive",
                  })}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={friendly}
                      onChange={handleChangeState}
                      name="friendly"
                    />
                  }
                  label={intl.formatMessage({
                    id: "friendly_postive",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemAsDescribed}
                      onChange={handleChangeState}
                      name="itemAsDescribed"
                    />
                  }
                  label={intl.formatMessage({
                    id: "itemasdescribed_postive",
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={other}
                      onChange={handleChangeState}
                      name="other"
                    />
                  }
                  label={intl.formatMessage({
                    id: "product_other",
                  })}
                />
              </FormGroup>
            </FormControl>
          </Box>
        ) : null}
        {other ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="otherNotes"
              label={intl.formatMessage({
                id: "product_other",
              })}
              multiline
              rows={4}
              onChange={handleChangeOtherNotes}
              sx={{ width: "100%" }}
              value={otherNotes}
            />
          </Grid>
        ) : null}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "0.5em" }}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={!enableSubmit}
            onClick={handleSubmit}
            sx={{ width: "100%" }}
          >
            {intl.formatMessage({
              id: "submit",
            })}
          </Button>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default injectIntl(StarRatingNew);
