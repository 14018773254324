// @flow
const initState = { error: null };

const payementReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_CHECKOUT_SESSION_ERROR":
      return { error: action.error };
    default:
      return state;
  }
};

export default payementReducer;
