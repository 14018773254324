import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { sendNotificationTest } from "../../store/messages/NewMessagesAction";
// import { Typography } from "@mui/material";
import { constantsConfig } from "../../config/ConstantsConfig";
class TestNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Hello",
      notificationText: "BMW",
      userId: "AWgek3YNlWgeuTSrDu0KSMhe1IH2",
      notificationPhotoUrl:
        "https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/images%2F16126152055257E95460D-4EC0-440C-8ABB-E7EB59AFE76C?alt=media&token=e68a1df0-b0a6-44dc-be15-e1c000022f49",
      subjectId: "mRUP8guVyX8ir48MAaWo",
      threadId: "mRUP8guVyX8ir48MAaWo",
      senderId: "dDhWatlEQogTl1HUzeko43NYPMr1",
      receiverId: "AWgek3YNlWgeuTSrDu0KSMhe1IH2",
      thread: null,
      user: null,
    };
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleGetThread = async () => {
    const query = firebase
      .firestore()
      .collection(constantsConfig.collectionThreads)
      .where("threadId", "==", this.state.threadId);
    const snapshot = await query.get();
    const thread = snapshot.docs.map((doc) => doc.data());
    this.setState({
      thread: thread[0],
    });
    this.handleGetUser(thread[0]);
  };
  handleGetUser = async (thread) => {
    const query = firebase
      .firestore()
      .collection(constantsConfig.collectionUsers)
      .where("id", "==", this.state.receiverId);
    const snapshot = await query.get();
    const user = snapshot.docs.map((doc) => doc.data());
    this.setState({
      user: user[0],
    });
    var data = {
      message: this.state.message,
      notificationText: this.state.notificationText,
      userId: this.state.userId,
      notificationPhotoUrl: this.state.notificationPhotoUrl,
      subjectId: this.state.subjectId,
      thread: thread,
      user: user[0],
    };
    this.props.sendNotificationTest(data);
  };
  handleSendNotification = () => {
    // var thread = {
    //   userIds: ["dDhWatlEQogTl1HUzeko43NYPMr1", "AWgek3YNlWgeuTSrDu0KSMhe1IH2"],
    //   lastEmailTimestamp: 1670158129283,
    //   receiverLastMessageTimestamp: 1670158917813,
    //   recentMessagesNotSeenByUserId: null,
    //   senderName: "Fadi",
    //   lastModifiedTimestamp: 1670158917813,
    //   subjectId: "yqvxeeTyX4T11mQE1iMS",
    //   creationTimestamp: 1670140306635,
    //   senderLastMessageTimestamp: 1670157505412,
    //   senderPhoto: "",
    //   receiverConfirmLegalTimestamp: 1670152999339,
    //   receiverPhoto:
    //     "https://lh3.googleusercontent.com/-NADvVsoc430/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclte5pd08NU6pm8Y-_vQbCmUOpE5w/photo.jpg",
    //   senderId: "dDhWatlEQogTl1HUzeko43NYPMr1",
    //   isChatSupport: false,
    //   subjectName: "Bmw",
    //   senderConfirmLegalTimestamp: 1670156873439,
    //   recentMessageNotSeenByUserId: "AWgek3YNlWgeuTSrDu0KSMhe1IH2",
    //   receiverName: "Mahmoud Ghabbour",
    //   subjectPhoto:
    //     "https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/images%2F16126152055257E95460D-4EC0-440C-8ABB-E7EB59AFE76C?alt=media&token=e68a1df0-b0a6-44dc-be15-e1c000022f49",
    //   mliTimeStamp: null,
    //   receiverId: "AWgek3YNlWgeuTSrDu0KSMhe1IH2",
    //   threadId: "mRUP8guVyX8ir48MAaWo",
    // };
    this.handleGetThread();
    // this.handleGetUser();
    // console.log("this.state", this.state);
    // this.props.sendNotificationTest(thread);
  };
  render() {
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <Grid item>
            <TextField
              required
              id="message"
              label="Message"
              value={this.state.message}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="notificationText"
              label="Notification Text"
              value={this.state.notificationText}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="userId"
              label="User Id"
              value={this.state.userId}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <Grid item>
            <TextField
              required
              id="notificationPhotoUrl"
              label="Notification Photo Url"
              value={this.state.notificationPhotoUrl}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="subjectId"
              label="Subject Id"
              value={this.state.subjectId}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="threadId"
              label="Thread Id"
              value={this.state.threadId}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <Grid item>
            <TextField
              required
              id="senderId"
              label="Sender Id"
              value={this.state.senderId}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="receiverId"
              label="Receiver Id"
              value={this.state.receiverId}
              variant="standard"
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        {/* <Typography variant="h4" color="primary" sx={{ mt: 5 }}>
          Thread
        </Typography> */}
        <Button
          sx={{ mt: 5 }}
          variant="contained"
          color="primary"
          onClick={this.handleSendNotification}
        >
          Send Notification
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    sendNotificationTest: (data) => dispatch(sendNotificationTest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestNotifications);
