// @flow
const initState = {
  reportState: null,
  deleted: false,
  blockSpinner: false,
  blockUserStatus: null,
  unBlockUserStatus: null,
  closeReportSpinner: null,
  unClosedReportSpinner: null,
};

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_REPORT":
      return { reportState: "block" };

    case "CREATE_REPORT_ERROR":
      return state;
    case "BLOCK_USER":
      return {
        ...state,
        blockUserStatus: "success",
        unBlockUserStatus: null,
        blockSpinner: false,
      };
    case "BLOCK_USER_ERROR":
      return { ...state, blockUserStatus: "failed" };
    case "UN_BLOCK_USER":
      return {
        ...state,
        unBlockUserStatus: "success",
        blockUserStatus: null,
        blockSpinner: false,
      };
    case "UN_BLOCK_USER_ERROR":
      return { ...state, unBlockUserStatus: "failed" };
    case "DELETE_REPORT":
      return state;
    case "DELETE_REPORT_ERROR":
      return state;
    case "CLOSE_REPORT":
      return {
        deleted: action.deleted,
        reportState: null,
      };
    case "UNCLOSE_REPORT":
      return {
        deleted: action.deleted,
        reportState: null,
      };
    case "BLOCK_SPINNER":
      return {
        ...state,
        blockSpinner: "loading",
      };
    case "STOP_BLOCK_SPINNER":
      return {
        ...state,
        blockSpinner: false,
      };
    case "CLOSE_REPORT_ERROR":
      return state;
    case "UNCLOSE_REPORT_ERROR":
      return state;
    case "CLOSE_REPORT_SPINNER":
      return { ...state, closeReportSpinner: "loading" };
    case "CREATE_CLOSED_REPORT":
      return { ...state, closeReportSpinner: "success" };
    case "CREATE_CLOSED_REPORT_ERROR":
      return { ...state, closeReportSpinner: "error" };
    case "UNCLOSED_REPORT_SPINNER":
      return { ...state, unClosedReportSpinner: "loading" };
    case "CREATE_UNCLOSED_REPORT":
      return { ...state, unClosedReportSpinner: "success" };
    case "CREATE_UNCLOSED_REPORT_ERROR":
      return { ...state, unClosedReportSpinner: "error" };
    case "CLEAR_BLOCK_VARIABLE":
      return { ...state, blockUserStatus: null, unBlockUserStatus: null };

    default:
      return state;
  }
};

export default reportReducer;
