import React from 'react';
import { Component } from 'react';
import { withStyles } from '@mui/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import firebase from 'firebase/compat/app';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { constantsConfig } from '../../config/ConstantsConfig';
import { Dialog, CircularProgress, Backdrop } from '@mui/material';
import MessageComponent from '../messaging/MessageComponent';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    marginInlineStart: '15rem'
  },
  images: {
    width: '3rem',
    height: '3rem'
  },
  tableCell: {
    backgroundColor: theme.palette.primary.background
  },
  profileImage: {
    maxWidth: '5rem',
    maxHeight: '5rem'
  },
  button: {
    ...theme.typography.normalButton
  },
  flexSpaceBetween: {
    display: 'flex',
    justifySpace: 'space-between'
  }
});

class AdminThreadsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openChatLoading: false,
      openChatDialog: false,
      messages: null,
      thread: this.props.thread
    };
  }
  handleOpenProduct = (productId) => {
    window.open('/product/' + productId);
  };
  handleOpenChatDialog = (e) => {
    e.preventDefault();
    this.setState({ openChatDialog: true });
  };
  setOpenChatLoading = () => {
    this.setState({
      openChatLoading: true
    });
  };
  setCloseChatLoading = () => {
    this.setState({
      openChatLoading: false
    });
  };
  handleCloseChatDialog = () => {
    this.setState({
      openChatDialog: false
    });
  };
  sendNotifyAction = async () => {
    // var thisRef = this;
    // Get last message of sender
    // const query = firebase
    //   .firestore()
    //   .collection(constantsConfig.collectionThreads)
    //   .doc(this.props.thread.threadId)
    //   .collection(constantsConfig.collectionMessages)
    //   .where('userId', '==', this.props.thread.senderId)
    //   .orderBy('timestamp', 'desc')
    //   .limit(1);
    // const snapshot = await query.get();
    // const messages = snapshot.docs.map((doc) => doc.data());

    // thisRef.setOpenChatLoading();
    // console.log(
    //   'thisRef.props.thread.receiverId',
    //   thisRef.props.thread.receiverId
    // );
    // var sendMessgae = firebase.functions().httpsCallable('sendMessage');
    // var sendEmailAndNotification = firebase
    //   .functions()
    //   .httpsCallable('sendEmailAndNotification');
    // sendMessgae(
    //   JSON.stringify({
    //     message: {
    //       messageType: 1,
    //       name: thisRef.props.profile.name,
    //       subjectId: thisRef.props.thread.subjectId,
    //       text: messages
    //         ? messages.length > 0
    //           ? messages[messages.length - 1].text
    //           : null
    //         : null,
    //       userId: thisRef.props.auth.uid,
    //       timestamp: new Date().getTime()
    //     },
    //     threadId: thisRef.props.thread.threadId,
    //     receiverId: thisRef.props.thread.receiverId,
    //     onlyNotification: true
    //   })
    // )
    //   .then(function (result) {
    //     // Read result of the Cloud Function.
    //     thisRef.setCloseChatLoading();
    //   })
    //   .catch(function (error) {
    //     thisRef.setCloseChatLoading();
    //     thisRef.handleCloseChatDialog();
    //   });
    // console.log('messages', messages);
    // console.log('thisRef.props', thisRef.props);
    // sendEmailAndNotification(
    //   JSON.stringify({
    //     type: 2,
    //     notificationData: {
    //       notificationText: messages
    //         ? messages.length > 0
    //           ? messages[messages.length - 1].text
    //           : null
    //         : null,
    //       userId: thisRef.props.thread.receiverId,
    //       notificationPhotoUrl: thisRef.props.thread.subjectPhoto,
    //       // subjectId: thisRef.props.thread.subjectId,
    //       message: messages
    //         ? messages.length > 0
    //           ? messages[messages.length - 1].text
    //           : null
    //         : null
    //     },
    //     emailData: {
    //       senderName: thisRef.props.thread.senderName,
    //       threadId: thisRef.props.thread.threadId,
    //       userId: thisRef.props.thread.receiverId,
    //       receiverEmail: ''
    //     }
    //   })
    // )
    //   .then(function (result) {
    //     // Read result of the Cloud Function.
    //     console.log('result', result);
    //     thisRef.setCloseChatLoading();
    //   })
    //   .catch(function (error) {
    //     console.log('error', error);
    //     thisRef.setCloseChatLoading();
    //     thisRef.handleCloseChatDialog();
    //   });
  };
  render() {
    const { classes, thread } = this.props;
    return (
      <TableRow key={thread.threadId} className={classes.tableRow}>
        <Dialog
          open={this.state.openChatLoading}
          onClose={this.setCloseChatLoading}
          fullWidth
        >
          <Backdrop
            //className={classes.backdrop}
            open={this.state.openChatLoading}
          >
            <CircularProgress className={classes.circularProgress} />
          </Backdrop>
        </Dialog>
        <Dialog
          open={this.state.openChatDialog}
          onClose={this.handleCloseChatDialog}
          fullWidth
        >
          {this.state.thread && (
            <MessageComponent
              thread={this.props.thread}
              isAdmin={true}
              isDialog
            />
          )}
        </Dialog>
        <TableCell hover>
          <Typography variant="subtitle2" className={classes.product}>
            <div className={classes.flexSpaceBetween}>
              <div>
                <img
                  className={classes.profileImage}
                  src={
                    thread.senderPhoto
                      ? thread.senderPhoto
                      : constantsConfig.logoListedNearMe
                  }
                  align="left"
                  alt={''}
                />
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  style={{ margin: '20px 0px 0px 5px' }}
                >
                  {thread.senderName}
                </Typography>
              </div>
            </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" className={classes.product}>
            <div className={classes.flexSpaceBetween}>
              <div>
                <img
                  className={classes.profileImage}
                  src={
                    thread.receiverPhoto
                      ? thread.receiverPhoto
                      : constantsConfig.logoListedNearMe
                  }
                  align="left"
                  alt={''}
                />
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  style={{ margin: '20px 0px 0px 5px' }}
                >
                  {thread.receiverName}
                </Typography>
              </div>
            </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.product}
            onClick={() => this.handleOpenProduct(thread.subjectId)}
          >
            <div className={classes.flexSpaceBetween}>
              <div>
                <img
                  className={classes.profileImage}
                  src={thread.subjectPhoto}
                  align="left"
                  alt={''}
                />
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  style={{ margin: '20px 0px 0px 5px' }}
                >
                  {thread.subjectName}
                </Typography>
              </div>
            </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={(e) => {
              this.handleOpenChatDialog(e);
            }}
          >
            Open
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.sendNotifyAction}
          >
            Send Notify
          </Button>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">{thread.threadId}</Typography>
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    profile: state.firestore.ordered.users
      ? state.firestore.ordered.users[0]
      : null
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(AdminThreadsTable);
