// @flow
const initState = { searchText: "", products: [], searchProductsStatus: null };

const searchReducer = (state = initState, action) => {
  switch (action.type) {
    case "SEARSH_TEXT":
      return { searchText: action.searchText };
    case "CLEAR_SEARCH_TEXT":
      return { searchText: "", products: [] };
    case "GET_SEARCH_PRODUCTS_SUCCESS":
      return {
        searchText: "",
        products: action.filteredProducts,
        searchProductsStatus: "successful",
      };
    case "GET_SEARCH_PRODUCTS_ERROR":
      return { searchText: "", searchProductsStatus: null, products: [] };
    case "LOADING_SEARCH_PRODUCTS":
      return { searchProductsStatus: "loading", products: [] };
    default:
      return state;
  }
};

export default searchReducer;
