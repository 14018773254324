// @flow
import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import MessageSnackbar from "./components/messaging/MessageSnackbarContent";
import { connect } from "react-redux";
import { updateMessagingToken } from "./store/auth/AuthActions";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { SnackbarProvider, useSnackbar } from "notistack";
import { editLocationInfo } from "./store/location/NewLocationActions";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { createBrowserHistory } from "history";
import {
  bugsnagApiKey,
  isProductionBuild,
  appVersion,
} from "./config/FirebaseConfig";
import is from "is_js";
import SplashScreen from "./components/other/SplashScreen";
import SellPageProducts from "./components/sell/SellPageProducts";
import LanguageDialog from "./components/layout/LanguageDialog";
import DashboardAllReviews from "./components/dashboard/DashboardAllReviews";
import DashboardMyFavoriteProducts from "./components/dashboard/DashboardMyFavoriteProducts";
import { makeStyles } from "@mui/styles";
// import CaptureImageTest from './components/products/images/CaptureImageTest';
import TestReusableGrid from "./components/layout/TestReusableGrid";
import TestSignInWithApple from "./components/layout/TestSignInWithApple";
import ProductsGrid from "./components/products/ProductsGrid";
import DashboardProductsAllCountries from "./components/dashboard/DashboardProductsAllCountries";
import DashboardThreadsTable from "./components/dashboard/DashboardThreadsTable";
import { updateUserStatus } from "./store/auth/AuthActions";
// import { constantsConfig } from "./config/ConstantsConfig";
import SlideShowProducts from "./components/products/SlideShowProducts";
import TestBackdropPage from "./components/products/TestBackdropPage";
import SearchTest from "./components/search/SearchTest";
import TestNotifications from "./components/messaging/TestNotifications";

const SellPageBoosted = React.lazy(() =>
  import("./components/sell/SellPageboosted")
);
const ForgotPassword = React.lazy(() =>
  import("./components/auth/ForgotPassword")
);

const DetailsForm = React.lazy(() =>
  import("./components/products/DetailsForm")
);
const CategoryDialog = React.lazy(() =>
  import("./components/other/CategoryDialog")
);
const CarModelsList = React.lazy(() =>
  import("./components/products/admin/CarModelsList")
);
const MobileModelsList = React.lazy(() =>
  import("./components/products/admin/MobileModelsList")
);
const TabletModelsList = React.lazy(() =>
  import("./components/products/admin/TabletModelsList")
);
const AdminUserProfile = React.lazy(() =>
  import("./components/admin/AdminUserProfile")
);
const UserProfile = React.lazy(() => import("./components/layout/UserProfile"));
const Products = React.lazy(() => import("./components/products/ProductsList"));

const DashboardTheme = React.lazy(() =>
  import("./components/dashboard/DashboardTheme")
);
const DashboardUsers = React.lazy(() =>
  import("./components/dashboard/DashboardUsers")
);
const ProductFilter = React.lazy(() =>
  import("./components/products/ProductWithFiltering")
);
const FullMessagingComponent = React.lazy(() =>
  import("./components/messaging/FullMessagingComponent")
);
const DashboardAllProducts = React.lazy(() =>
  import("./components/dashboard/DashboardAllProducts")
);
const DashboardEditCategory = React.lazy(() =>
  import("./components/dashboard/DashboardEditCategory")
);
const DashboardUserActions = React.lazy(() =>
  import("./components/dashboard/DashboardUserActions")
);
const DashboardArchivedProducts = React.lazy(() =>
  import("./components/dashboard/DashboardArchivedProducts")
);
const DashboardAllRatings = React.lazy(() =>
  import("./components/dashboard/DashboardAllRatings")
);
const DashboardProductViewActions = React.lazy(() =>
  import("./components/dashboard/DashboardProductViewActions")
);
const DashboardProductsByCountry = React.lazy(() =>
  import("./components/dashboard/DashboardProductsByCountry")
);
const DashboardProductsWithOtherBrand = React.lazy(() =>
  import("./components/dashboard/DashboardProductsWithOtherBrand")
);
const DashboardProductsWithOtherModel = React.lazy(() =>
  import("./components/dashboard/DashboardProductsWithOtherModel")
);
const DashboardUsersByCountry = React.lazy(() =>
  import("./components/dashboard/DashboardUsersByCountry")
);
const DashboardSellerContactProductActions = React.lazy(() =>
  import("./components/dashboard/DashboardSellerContactProductActions")
);
const DashboardProductViews = React.lazy(() =>
  import("./components/dashboard/DashboardProductViews")
);
const DashboardAllReports = React.lazy(() =>
  import("./components/dashboard/DashboardAllReports")
);

const DashboardProductsCards = React.lazy(() =>
  import("./components/dashboard/DashboardProductsCards")
);
const DashboardBlockedProducts = React.lazy(() =>
  import("./components/dashboard/DashboardBlockedProducts")
);
const DashboardBlockedUsers = React.lazy(() =>
  import("./components/dashboard/DashboardBlockedUsers")
);
const DashboardClosedReports = React.lazy(() =>
  import("./components/dashboard/DashboardClosedReports")
);
const DashboardChatSupport = React.lazy(() =>
  import("./components/dashboard/DashboardChatSupport")
);
const DashboardThreads = React.lazy(() =>
  import("./components/dashboard/DashboardThreads")
);
const DashboardThreadMetrics = React.lazy(() =>
  import("./components/dashboard/DashboardThreadMetrics")
);
const DashboardBlockReportsDetails = React.lazy(() =>
  import("./components/dashboard/DashboardBlockReportsDetails")
);
const DashboardReportDetails = React.lazy(() =>
  import("./components/dashboard/DashboardReportDetails")
);
const DashboardSearchActions = React.lazy(() =>
  import("./components/dashboard/DashboardSearchActions")
);
const DashboardVehicles = React.lazy(() =>
  import("./components/dashboard/DashboardVehicles")
);
const DashboardMobiles = React.lazy(() =>
  import("./components/dashboard/DashboardMobiles")
);
const DashboardTablets = React.lazy(() =>
  import("./components/dashboard/DashboardTablets")
);
const DashboardThreadsWithMessagesDetails = React.lazy(() =>
  import("./components/dashboard/DashboardThreadsWithMessagesDetails")
);
const AdminFullMessagingComponent = React.lazy(() =>
  import("./components/admin/AdminFullMessagingComponent")
);
const ProductDetails1 = React.lazy(() =>
  import("./components/products/ProductDetails")
);
const SearchPage = React.lazy(() =>
  import("./components/search/SearchComponent")
);
const WalmartApi = React.lazy(() => import("./components/testApi/WalmartApi"));

const Unsubscribe = React.lazy(() => import("./components/other/Unsubscribe"));

const RedirectPage = React.lazy(() =>
  import("./components/other/RedirectPage")
);

const useStyles = makeStyles((theme) => ({
  lotties: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.background,
  },
}));

// const appInfoAndroid = {
//   imageUrl: constantsConfig.logoBannerListedNearMe,
//   name: "Listed Near Me",
//   infoText: "Free - In Google Play",
//   linkButtonText: "VIEW",
//   linkUrl: constantsConfig.playStoreLink,
// };

// const appInfoIos = {
//   imageUrl: constantsConfig.logoBannerListedNearMe,
//   name: "Listed Near Me",
//   infoText: "Free - In App Store",
//   linkButtonText: "VIEW",
//   linkUrl: constantsConfig.appStoreLink,
// };
const history = createBrowserHistory();
// Initialize Bugsnag
Bugsnag.releaseStage = isProductionBuild ? "production" : "development";
Bugsnag.notifyReleaseStages = ["production", "staging"];

Bugsnag.start({
  apiKey: bugsnagApiKey,
  plugins: [new BugsnagPluginReact(React)],
  appVersion: appVersion,
});

// const ErrorBoundary = Bugsnag.getPlugin("react");
function App(props) {
  const {
    auth,
    updateMessagingToken,
    editLocationInfo,
    locationConfig,
    updateUserStatus,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  if (!locationConfig) editLocationInfo();
  if (auth.uid) {
    if (!is.ie() && !is.safari()) {
      updateMessagingToken(auth.uid);
      const messaging = firebase.messaging();

      messaging.onMessage((payload) => {
        if (!window.location.href.includes(payload.data.tag))
          enqueueSnackbar({ data: payload.data, uid: auth.uid });
      });
      firebase.auth().onIdTokenChanged(function (user) {
        if (user) {
          // User is signed in or token was refreshed.
          updateUserStatus(auth.uid);
        }
      });
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.
          updateUserStatus(auth.uid);
        }
      });
    }
  }

  return (
    <BrowserRouter history={history}>
      <Navbar />
      <div>
        <Suspense
          fallback={
            <div
              className={classes.lotties}
              style={{ height: window.innerHeight }}
            >
              <SplashScreen />
            </div>
          }
        >
          <Routes>
            <Route
              exact
              path="/product/:id/thread/:threadId"
              element={<ProductDetails1 />}
            />
            <Route
              exact
              path="/mobilemodelslist/:id"
              element={<MobileModelsList />}
            />
            <Route
              exact
              path="/carmodelslist/:id"
              element={<CarModelsList />}
            />
            <Route
              exact
              path="/tabletmodelslist/:id"
              element={<TabletModelsList />}
            />
            <Route
              exact
              path="/adminreportdetails/:id"
              element={<DashboardReportDetails />}
            />
            <Route
              exact
              path="/adminuserprofile/:id"
              element={<AdminUserProfile />}
            />
            <Route exact path="/userProfile/:id" element={<UserProfile />} />
            <Route
              exact
              path="/product/:id/Open/BoostProduct"
              element={<ProductDetails1 />}
            />
            <Route
              exact
              path="/product/:id/:isarchivedorblocked"
              element={<ProductDetails1 />}
            />
            {/* <Route
            exact
            path="/product/:id/:isBlocked"
            element={ProductDetails1}
          /> */}
            <Route exact path="/product/:id" element={<ProductDetails1 />} />
            <Route
              exact
              path="/dashboardfavoriteproducts/:id"
              element={<DashboardMyFavoriteProducts />}
            />
            <Route
              exact
              path="/testnotifications"
              element={<TestNotifications />}
            />
            <Route exact path="/searchtest" element={<SearchTest />} />
            <Route exact path="/walmartapi" element={<WalmartApi />} />
            <Route exact path="/categorydialog" element={<CategoryDialog />} />
            <Route exact path="/detailsform" element={<DetailsForm />} />
            <Route exact path="/listednearme" element={<DashboardUsers />} />
            <Route
              exact
              path="/listednearme/dashboardblockedproducts"
              element={<DashboardBlockedProducts />}
            />
            <Route
              exact
              path="/listednearme/dashboardblockedusers"
              element={<DashboardBlockedUsers />}
            />
            <Route
              exact
              path="/listednearme/dashboardclosedreports"
              element={<DashboardClosedReports />}
            />
            <Route
              exact
              path="/actions/:userId/dashboarduseractions"
              element={<DashboardUserActions />}
            />
            <Route
              exact
              path="/listednearme/dashboardallratings"
              element={<DashboardAllRatings />}
            />
            <Route
              exact
              path="/listednearme/dashboardallreviews"
              element={<DashboardAllReviews />}
            />
            <Route
              exact
              path="/listednearme/dashboardthreadsWithmessagesdetails"
              element={<DashboardThreadsWithMessagesDetails />}
            />
            <Route
              exact
              path="/listednearme/dashboardthreadmetrics"
              element={<DashboardThreadMetrics />}
            />
            <Route
              exact
              path="/listednearme/dashboardthreadstable"
              element={<DashboardThreadsTable />}
            />
            <Route
              exact
              path="/listednearme/dashboardblockreportsdetails"
              element={<DashboardBlockReportsDetails />}
            />
            <Route
              exact
              path="/listednearme/dashboardproductswithotherbrand"
              element={<DashboardProductsWithOtherBrand />}
            />
            <Route
              exact
              path="/listednearme/dashboardproductswithothermodel"
              element={<DashboardProductsWithOtherModel />}
            />
            <Route
              exact
              path="/listednearme/dashboardallthreads"
              element={<DashboardThreads />}
            />
            <Route
              exact
              path="/listednearme/dashboardallproducts"
              element={<DashboardAllProducts />}
            />{" "}
            <Route
              exact
              path="/listednearme/dashboardeditcategory"
              element={<DashboardEditCategory />}
            />
            <Route
              exact
              path="/listednearme/dashboardarchivedproducts"
              element={<DashboardArchivedProducts />}
            />{" "}
            <Route
              exact
              path="/listednearme/dashboardproductscards"
              element={<DashboardProductsCards />}
            />
            <Route
              exact
              path="/listednearme/dashboardchatsupport"
              element={<DashboardChatSupport />}
            />
            <Route
              exact
              path="/listednearme/dashboardsearchactions"
              element={<DashboardSearchActions />}
            />
            <Route
              exact
              path="/listednearme/dashboardproductviewactions"
              element={<DashboardProductViewActions />}
            />
            <Route
              exact
              path="/listednearme/dashboarsellercontactproductactions"
              element={<DashboardSellerContactProductActions />}
            />
            <Route
              exact
              path="/listednearme/dashboardproductviews"
              element={<DashboardProductViews />}
            />
            <Route
              exact
              path="/listednearme/dashboardproductsbycountry"
              element={<DashboardProductsByCountry />}
            />
            <Route
              exact
              path="/listednearme/dashboardusersbycountry"
              element={<DashboardUsersByCountry />}
            />
            <Route
              exact
              path="/listednearme/dashboardproductsallcountries"
              element={<DashboardProductsAllCountries />}
            />
            <Route
              exact
              path="/listednearme/dashboardallreports"
              element={<DashboardAllReports />}
            />
            <Route
              exact
              path="/listednearme/dashboardmobiles"
              element={<DashboardMobiles />}
            />
            <Route
              exact
              path="/listednearme/dashboardtablets"
              element={<DashboardTablets />}
            />
            <Route
              exact
              path="/listednearme/dashboardvehicles"
              element={<DashboardVehicles />}
            />
            <Route
              exact
              path="/sellpageproducts/:rateToUserId"
              element={<SellPageProducts />}
            />
            <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
            <Route exact path="/productlist" element={<Products />} />
            <Route exact path="/buy/addProduct" element={<ProductFilter />} />
            <Route exact path="/buy" element={<ProductFilter />} />
            <Route
              exact
              path="/sellpageboosted"
              element={<SellPageBoosted />}
            />
            <Route exact path="/search" element={<SearchPage />} />
            <Route exact path="/Language" element={<LanguageDialog />} />
            <Route
              path="/listednearme/adminthreads/:userId"
              element={<AdminFullMessagingComponent />}
            />
            <Route path="/threads" element={<FullMessagingComponent />} />
            {/* <Route path="/captureimagetest" element={CaptureImageTest} /> */}
            <Route
              path="/testsigninwithapple"
              element={<TestSignInWithApple />}
            />
            {/* <Route path="/testopenslides" element={TestOpenSlides} /> */}
            <Route path="/testreusablegrid" element={<TestReusableGrid />} />
            <Route path="/testbackdroppage" element={<TestBackdropPage />} />
            <Route path="/productsgrid" element={<ProductsGrid />} />
            <Route
              exact
              path="/filter/:categoryName/:categoryId"
              element={<ProductFilter />}
            />
            <Route
              exact
              path="/newest/filter/:categoryName/:categoryId"
              element={<ProductFilter />}
            />
            <Route
              exact
              path="/nearme120miles/filter/:categoryName/:categoryId"
              element={<ProductFilter />}
            />
            <Route
              exact
              path="/nearme30miles/filter/:categoryName/:categoryId"
              element={<ProductFilter />}
            />
            <Route exact path="/nearme120miles" element={<ProductFilter />} />
            <Route exact path="/nearme30miles" element={<ProductFilter />} />
            <Route
              exact
              path="/slidehowproducts"
              element={<SlideShowProducts />}
            />
            <Route exact path="/newest" element={<ProductFilter />} />
            <Route exact path="/dashboardtheme" element={<DashboardTheme />} />
            <Route
              exact
              path="/redirectpage/threads/:threadId"
              element={<RedirectPage />}
            />
            <Route exact path="/" element={<ProductFilter />} />
            <Route
              exact
              path="/unsubscribe/:id/:email"
              element={<Unsubscribe />}
            />
            <Route exact path="/privacynotice.html" />
            <Route exact path="/privacynotice-ar.html" />
            <Route exact path="/termsofservice.html" />
            <Route exact path="/termsofservice-ar.html" />
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    locationConfig: state.locationConfig,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateMessagingToken: (uid) => dispatch(updateMessagingToken(uid)),
    editLocationInfo: () => dispatch(editLocationInfo()),
    updateUserStatus: (uid) => dispatch(updateUserStatus(uid)),
  };
};

const AppWithConnect = connect(mapStateToProps, mapDispatchToProps)(App);

export default function AppNotistack() {
  return (
    <SnackbarProvider
      maxSnack={3}
      content={(key, message) => (
        <MessageSnackbar id={key} messageInfo={message} />
      )}
    >
      <AppWithConnect />
    </SnackbarProvider>
  );
}
