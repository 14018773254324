import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Card, CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DrawerSellPage from '../layout/DrawerSellPage';
import Toolbar from '@mui/material/Toolbar';
import { Hidden } from '@mui/material';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.primary.background
  },
  rootGrid: {
    flexGrow: 1,
    // position: '-webkit-sticky',
    position: 'sticky',
    top: 20,
    bottom: 20,
    paddingTop: '40px',
    paddingBottom: '40px',
    zIndex: 5
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    borderRadius: '25px',
    minWidth: 120,
    minHeight: 400,
    backgroundColor: theme.palette.primary.background
  },
  control: {
    padding: theme.spacing(2)
  },
  card: {
    maxWidth: '100%',
    padding: '10px',
    borderRadius: '25px',
    height: 385,
    minWidth: 120,
    minHeight: 400,
    backgroundColor: theme.palette.primary.background
  },
  price: {
    color: theme.palette.primary.price,
    padding: 5,
    textAlign: 'center',
    fontSize: '12px'
  },
  priceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderTopLeftRadius: '25px'
  },
  distanceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderTopRightRadius: '25px',
    right: 0
  },
  imgContainer: {
    position: 'relative'
  },
  media: {
    height: 225,
    borderRadius: '25px'
  },
  boostedContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    bottom: 0,
    right: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderBottomRightRadius: '25px'
  },
  flexStart: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'start'
  },
  typographyLocationOnIcon: {
    color: theme.palette.primary.main,
    fontFamily: 'Quicksand',
    fontWeight: 'bolder',
    fontSize: '15px',
    marginTop: '2px'
  },
  locationOnIcon: {
    fontSize: '20px',
    color: theme.palette.primary.main
  },
  location: {
    fontFamily: 'Quicksand',
    fontWeight: 'bold',
    color: theme.palette.primary.primaryText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5em' /* fallback */,
    height: '3em',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  },
  typographyReactTimeAgo: {
    marginTop: '5px',
    color: theme.palette.primary.secondaryText,
    fontSize: '10px',
    fontFamily: 'Quicksand',
    fontWeight: 'bold'
  },
  paperMobile: {
    maxWidth: 170,
    minWidth: 170,
    borderRadius: '25px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.background
  },
  shopName: {
    color: theme.palette.primary.primaryText,
    fontWeight: 'bolder',
    fontFamily: 'Quicksand',
    fontSize: '18px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5em' /* fallback */,
    height: '3em',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
    /* autoprefixer: off */
  }
});

class TestReusableGrid extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <DrawerSellPage />
        </Hidden>
        <main className={classes.content}>
          <Toolbar />
          <Grid container className={classes.rootGrid} spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3}>
                {[
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                  18, 19, 20, 21, 22, 23, 24, 25
                ].map((value) => (
                  <Grid key={value} item xs={6} sm={3}>
                    <Paper className={classes.paper}>
                      <Card className={classes.card}>
                        <CardActionArea>
                          <div className={classes.priceContiner}>
                            <Typography className={classes.price}>
                              25 USD
                            </Typography>
                          </div>
                          <div className={classes.distanceContiner}>
                            <Typography className={classes.price}>
                              25 km
                            </Typography>
                          </div>
                          <div className={classes.imgContainer}>
                            <CardMedia
                              className={classes.media}
                              // ref={setImageRef}
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F002c2b5f-ddf3-4d11-96cb-cdce37e8b834?alt=media&token=a8da4df9-170f-4b2f-bb0f-930bf035f5c8'
                              }
                              // title={product.title}
                              component="img"
                              // onLoad={onLoadCardImage}
                            />

                            <div className={classes.boostedContainer}>
                              <Typography className={classes.price}>
                                Boosted
                              </Typography>
                            </div>
                          </div>

                          <CardContent>
                            <Typography
                              align="left"
                              className={classes.shopName}
                            >
                              BMW BMW BMW BMW
                            </Typography>
                            <div className={classes.flexStart}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.typographyLocationOnIcon}
                              >
                                <LocationOnIcon
                                  className={classes.locationOnIcon}
                                />
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className={classes.location}
                              >
                                New york , USA
                              </Typography>
                            </div>
                            <Typography
                              variant="button"
                              display="block"
                              gutterBottom
                              align="right"
                              className={classes.typographyReactTimeAgo}
                            >
                              2 minutes ago
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(TestReusableGrid);
