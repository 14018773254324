// @flow
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/firestore";
const geoQueries = require("./geoQueries");

export const getProducts = (req) => {
  // const functions = firebase.functions();
  return new Promise(async (resolve, reject) => {
    if (req.typeProducts === 0) {
      /*var productFeedBasedLocation = functions.httpsCallable(
        "productFeedBasedLocation"
      );*/
      product_feed_based_location(
        JSON.stringify({
          ...req,
        })
      )
        .then((result) => {
          resolve({ products: JSON.parse(result), near: true });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      /* var url =
        (isProductionBuild
          ? 'https://us-central1-listednearme.cloudfunctions.net/api/products?'
          : 'https://us-central1-listednearmedev.cloudfunctions.net/api/products?') +
        'countryCode=' +
        req.countryCode +
        (req.categoryId ? '&categoryId=' + req.categoryId : '') +
        (req.startAfterProductId
          ? '&startAfterProductId=' + req.startAfterProductId
          : '');*/
      let products = await productFeed(
        JSON.stringify({
          countryCode: req.countryCode,
          startAfterProductId: req.startAfterProductId,
          categoryId: req.categoryId,
        })
      );
      if (products) {
        resolve({ products: JSON.parse(products), near: true });
      } else {
        console.log("can't fetch data");
        reject("can't fetch data");
      }
    }
  });
};
const productFeed = async (params, context) => {
  // Parsing Params
  const admin = firebase;

  // console.log("Recevied params: " + params);
  const parsedParams = JSON.parse(params);
  // console.log("ParsedParams: " + params);
  const countryCode = parsedParams.countryCode;
  if (!countryCode) {
    return "Invalid country code";
  }
  const categoryId = parsedParams.categoryId;

  // console.log("countryCode: " + countryCode);
  // console.log("categoryId: " + categoryId);
  const pageSize = 30;

  const startAfterProductId = parsedParams.startAfterProductId;

  // check start after and return all products after with no boosted products
  const feedProductsCollection = admin.firestore().collection("products");
  if (startAfterProductId) {
    let startAfterProductReference = await feedProductsCollection
      .doc(startAfterProductId)
      .get();
    // console.log("Start after product Id: " + startAfterProductId);
    if (!startAfterProductReference) {
      return null;
    }
    try {
      var query;
      if (categoryId) {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)
          .orderBy("createdTimestamp", "desc")
          .startAfter(startAfterProductReference)
          .limit(pageSize);
      } else {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .orderBy("createdTimestamp", "desc")
          .startAfter(startAfterProductReference)
          .limit(pageSize);
      }
      let querySnapshot = await query.get();
      const products = [];
      querySnapshot.docs.map((doc) => {
        products.push(doc.data());
      });

      // console.log(
      //   'Products fetched successfully starting after productId: ' +
      //     startAfterProductId +
      //     ' total is: ' +
      //     products.length
      // );
      return JSON.stringify(products);
    } catch (error) {
      console.log(error);
      return null;
    }
  } else {
    // fetch first page with boosted item (make sure last item is not boosted item)
    // Fetching Products
    const boostedProductsCollection = admin
      .firestore()
      .collection("boosted_products");

    // Fetching Boosted Products only for first page
    var boostedProducts = [];

    try {
      var boostedProductsQuery;
      if (categoryId) {
        boostedProductsQuery = boostedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)

          .orderBy("impressions")
          .limit(10);
      } else {
        boostedProductsQuery = boostedProductsCollection
          .where("countryCode", "==", countryCode)

          .orderBy("impressions")
          .limit(10);
      }
      let boostedProductsQuerySnapshot = await boostedProductsQuery.get();
      boostedProductsQuerySnapshot.docs.map((doc) => {
        boostedProducts.push(doc.data());
      });

      // console.log(
      //   'Boosted fetched successfully, total is: ' + boostedProducts.length
      // );
    } catch (error) {
      // console.log("Boosted Products" + error);
    }

    // Fetching Feed Products
    try {
      var query;
      if (categoryId) {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)
          .orderBy("createdTimestamp", "desc")
          .limit(pageSize);
      } else {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .orderBy("createdTimestamp", "desc")
          .limit(pageSize);
      }
      let querySnapshot = await query.get();
      const products = [];
      var productsIndex = 0;
      var boostedProductsIndex = 0;
      querySnapshot.docs.map((doc) => {
        products.push(doc.data());
        productsIndex++;
        if (
          productsIndex % 4 === 0 &&
          boostedProductsIndex < boostedProducts.length
        ) {
          products.push(boostedProducts[boostedProductsIndex]);
          boostedProductsIndex++;
        }
      });

      // console.log(
      //   'Products fetched successfully, total is: ' + products.length
      // );

      // if last product is boosted remove it to maintain pagination from the feed collection
      if (
        products.length > 2 &&
        products[products.length - 1].isBoosted === true
      ) {
        products.length = products.length - 1;
      }

      return JSON.stringify(products);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
};

const product_feed_based_location = async (feedInfo) => {
  const admin = firebase;
  const data = JSON.parse(feedInfo);
  const countryCode = data.countryCode;
  const categoryId = data.categoryId;
  const startAfterProductId = data.startAfterProductId;
  const latitude = data.latitude;
  const longitude = data.longitude;
  const radius = data.radius ? data.radius : 100;
  //  const isNear = data.near;
  //Comparer Function
  function GetSortOrder(point1) {
    return function (a, b) {
      var d1 = geoQueries.distance(point1, [
        a.data().latitude
          ? a.data().latitude
          : a.data().bingoDealLocation.latitude,
        a.data().longitude
          ? a.data().longitude
          : a.data().bingoDealLocation.longitude,
      ]);
      var d2 = geoQueries.distance(point1, [
        b.data().latitude
          ? b.data().latitude
          : b.data().bingoDealLocation.latitude,
        b.data().longitude
          ? b.data().longitude
          : b.data().bingoDealLocation.longitude,
      ]);
      if (d1 > d2) {
        return 1;
      } else if (d1 < d2) {
        return -1;
      }
      return 0;
    };
  }
  //check Distance
  function checkDistance(product) {
    return (
      geoQueries.distance(
        [latitude, longitude],
        [
          product.data().latitude
            ? product.data().latitude
            : product.data().bingoDealLocation.latitude,
          product.data().longitude
            ? product.data().longitude
            : product.data().bingoDealLocation.longitude,
        ]
      ) <= radius
    );
  }
  /*if (!isNear) {
    var products = [];
    product_feed_based_country(data, 30, products, isNear);
    return { products: products, near: false };
  }*/

  // const pageSize = 30;

  // check start after and return all products after with no boosted products
  const feedProductsCollection = admin.firestore().collection("products");
  if (startAfterProductId) {
    let startAfterProductReference = await feedProductsCollection
      .doc(startAfterProductId)
      .get();
    if (!startAfterProductReference) {
      /* throw new functions.https.HttpsError(
        "feed_failure",
        "error: startAfterProductId does not exist: " + startAfterProductId
      );*/
    }
    try {
      var query, queries;
      if (categoryId) {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false);
      } else {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false);
      }

      queries = geoQueries.getQueriesForDocumentsAround(
        query,
        { lat: latitude, lon: longitude },
        radius
      );
      return await Promise.all(queries)
        .then((results) => {
          return results
            .map((qs) => qs.docs)
            .reduce((acc, docs) => [...acc, ...docs]);
        })
        .then((matchingRefs) => {
          /*
    matchingRefs is now an Array[DocumentReferences] from firestore
    if no documents matched the queries above it will be an empty array
   */
          let filteredMatchingRefs = matchingRefs.filter(checkDistance);
          filteredMatchingRefs.sort(GetSortOrder([latitude, longitude]));

          var products = filteredMatchingRefs.map(function (doc) {
            var disKm = geoQueries.distance(
              [latitude, longitude],
              [
                doc.data().latitude
                  ? doc.data().latitude
                  : doc.data().bingoDealLocation.latitude,
                doc.data().longitude
                  ? doc.data().longitude
                  : doc.data().bingoDealLocation.longitude,
              ]
            );
            return {
              ...doc.data(),
              distanceKm: disKm,
              distanceMile: disKm * 0.621371192,
            };
          });
          if (products.length > 100) {
            products.slice(0, 101);
          }

          return JSON.stringify(products);
        })
        .catch((error) => {
          console.log(error);
          /* throw new functions.https.HttpsError(
            "feed_failure",
            "Failed to get data"
          );*/
        });
    } catch (error) {
      console.log(error);

      /* throw new functions.https.HttpsError(
        "feed_failure",
        "Failed to get data"
      );*/
    }
  } else {
    // fetch first page with boosted item (make sure last item is not boosted item)
    // Fetching Products
    const boostedProductsCollection = admin
      .firestore()
      .collection("boosted_products");

    // Fetching Boosted Products only for first page
    var boostedProducts = [];
    var queries;

    try {
      var boostedProductsQuery;
      if (categoryId) {
        boostedProductsQuery = boostedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false);
      } else {
        boostedProductsQuery = boostedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false);
        // .orderBy('createdTimestamp', 'desc');
      }
      queries = geoQueries.getQueriesForDocumentsAround(
        boostedProductsQuery,
        { lat: latitude, lon: longitude },
        radius
      );

      await Promise.all(queries)
        .then((results) => {
          return results
            .map((qs) => qs.docs)
            .reduce((acc, docs) => [...acc, ...docs]);
        })
        .then((matchingRefs) => {
          let filteredMatchingRefs = matchingRefs.filter(checkDistance);

          filteredMatchingRefs.sort(GetSortOrder([latitude, longitude]));

          filteredMatchingRefs.map((doc) => {
            var disKm = geoQueries.distance(
              [latitude, longitude],
              [
                doc.data().latitude
                  ? doc.data().latitude
                  : doc.data().bingoDealLocation.latitude,
                doc.data().longitude
                  ? doc.data().longitude
                  : doc.data().bingoDealLocation.longitude,
              ]
            );
            boostedProducts.push({
              ...doc.data(),
              distanceKm: disKm,
              distanceMile: disKm * 0.621371192,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          /* throw new functions.https.HttpsError(
            "feed_failure",
            "Failed to get data"
          );*/
        });
    } catch (error) {
      console.log(error);
    }

    // Fetching Feed Products
    try {
      var query;
      if (categoryId) {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false);
      } else {
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false);
      }

      queries = geoQueries.getQueriesForDocumentsAround(
        query,
        { lat: latitude, lon: longitude },
        radius
      );

      return await Promise.all(queries)
        .then((results) => {
          return results
            .map((qs) => qs.docs)
            .reduce((acc, docs) => [...acc, ...docs]);
        })
        .then((matchingRefs) => {
          var products = [];
          var productsIndex = 0;
          var boostedProductsIndex = 0;
          let filteredMatchingRefs = matchingRefs.filter(checkDistance);

          filteredMatchingRefs.sort(GetSortOrder([latitude, longitude]));

          if (filteredMatchingRefs.length > 100) {
            filteredMatchingRefs.slice(0, 101);
          }

          filteredMatchingRefs.map((doc) => {
            var disKm = geoQueries.distance(
              [latitude, longitude],
              [
                doc.data().latitude
                  ? doc.data().latitude
                  : doc.data().bingoDealLocation.latitude,
                doc.data().longitude
                  ? doc.data().longitude
                  : doc.data().bingoDealLocation.longitude,
              ]
            );
            if (
              productsIndex % 4 === 0 &&
              boostedProductsIndex < boostedProducts.length
            ) {
              products.push(boostedProducts[boostedProductsIndex]);
              boostedProductsIndex++;
            }
            products.push({
              ...doc.data(),
              distanceKm: disKm,
              distanceMile: disKm * 0.621371192,
            });

            productsIndex++;
          });

          while (boostedProductsIndex < boostedProducts.length) {
            products.push(boostedProducts[boostedProductsIndex]);
            boostedProductsIndex++;
          }
          // if last product is boosted remove it to maintain pagination from the feed collection

          return JSON.stringify(products);
        })
        .catch((error) => {
          console.log(error);
          /* throw new functions.https.HttpsError(
            "feed_failure",
            "Failed to get data"
          );*/
        });
    } catch (error) {
      console.log(error);
      /*   throw new functions.https.HttpsError(
        "feed_failure",
        "Failed to get data"
      );*/
    }
  }
};

export const searchProducts = async (params, context) => {
  // Parsing Params
  const admin = firebase;
  const countryCode = params.countryCode;
  if (!countryCode) {
    return "Invalid country code";
  }
  const price = params.price;
  const categoryId = params.categoryId;
  const radius = params.radius;
  const pageSize = params.maxResult;
  const latitude = params.latitude;
  const longitude = params.longitude;
  const feedProductsCollection = admin.firestore().collection("products");
  function GetSortOrder(point1) {
    return function (a, b) {
      var d1 = geoQueries.distance(point1, [
        a.data().latitude
          ? a.data().latitude
          : a.data().bingoDealLocation.latitude,
        a.data().longitude
          ? a.data().longitude
          : a.data().bingoDealLocation.longitude,
      ]);
      var d2 = geoQueries.distance(point1, [
        b.data().latitude
          ? b.data().latitude
          : b.data().bingoDealLocation.latitude,
        b.data().longitude
          ? b.data().longitude
          : b.data().bingoDealLocation.longitude,
      ]);
      if (d1 > d2) {
        return 1;
      } else if (d1 < d2) {
        return -1;
      }
      return 0;
    };
  }
  //check Distance
  function checkDistance(product) {
    return (
      geoQueries.distance(
        [latitude, longitude],
        [
          product.data().latitude
            ? product.data().latitude
            : product.data().bingoDealLocation.latitude,
          product.data().longitude
            ? product.data().longitude
            : product.data().bingoDealLocation.longitude,
        ]
      ) <= radius
    );
  }
  try {
    var query, queries;
    if (categoryId) {
      if (price)
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("price", "<=", price)
          .where("categoryId", "==", categoryId)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false)
          // .orderBy("lastModifiedTimestamp", "desc")
          // .limit(pageSize);
      else
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("categoryId", "==", categoryId)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false)
          // .orderBy("lastModifiedTimestamp", "desc")
          // .limit(pageSize);
    } else {
      if (price) {
        console.log("test", price);
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("price", ">=", price)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false)
          // .orderBy("lastModifiedTimestamp", "desc")
          // .limit(pageSize);
      } else
        query = feedProductsCollection
          .where("countryCode", "==", countryCode)
          .where("isActive", "==", true)
          .where("isFoodItem", "==", false)
          // .orderBy("lastModifiedTimestamp", "desc")
          // .limit(pageSize);
    }
    queries = geoQueries.getQueriesForDocumentsAround(
      query,
      { lat: latitude, lon: longitude },
      radius
    );
    return await Promise.all(queries)
      .then((results) => {
        return results
          .map((qs) => qs.docs)
          .reduce((acc, docs) => [...acc, ...docs]);
      })
      .then((matchingRefs) => {
        /*
    matchingRefs is now an Array[DocumentReferences] from firestore
    if no documents matched the queries above it will be an empty array
   */
        let filteredMatchingRefs = matchingRefs.filter(checkDistance);
        filteredMatchingRefs.sort(GetSortOrder([latitude, longitude]));
        var products = filteredMatchingRefs.map(function (doc) {
          var disKm = geoQueries.distance(
            [latitude, longitude],
            [
              doc.data().latitude
                ? doc.data().latitude
                : doc.data().bingoDealLocation.latitude,
              doc.data().longitude
                ? doc.data().longitude
                : doc.data().bingoDealLocation.longitude,
            ]
          );
          return {
            ...doc.data(),
            distanceKm: disKm,
            distanceMile: disKm * 0.621371192,
          };
        });
        if (products.length > 100) {
          products.slice(0, 101);
        }
        return products;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
    return null;
  }
};
