// @flow
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardActionArea,
  Paper,
  DialogTitle,
  Dialog
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { BoostedProduct } from '../../store/product/ProductActions';
import Grid from '@mui/material/Grid';
import {
  clickProduct,
  impressionProduct
} from '../../store/product/BoostedTransactions';
import { injectIntl } from 'react-intl';
import BoostProductDialog from './boostProduct/BoostProductDialogContent';
import { constantsConfig } from '../../config/ConstantsConfig';
import ReactTimeAgo from 'react-time-ago';

const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%'
  },
  wrapper: {
    width: '100%',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  containerDialog: {
    backgroundColor: theme.palette.primary.background
  },
  flexContentBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  root: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.primary.background
  },
  paper: {
    backgroundColor: theme.palette.primary.background,
    padding: theme.spacing(0),
    textAlign: 'center',
    borderRadius: '25px',
    minWidth: 120,
    minHeight: 400
  },
  control: {
    padding: theme.spacing(2)
  },
  card: {
    backgroundColor: theme.palette.primary.background,
    maxWidth: '100%',
    padding: '10px',
    borderRadius: '25px',
    height: 385,
    minWidth: 120,
    minHeight: 400
  },
  adminCard: {
    backgroundColor: theme.palette.primary.background,
    maxWidth: '100%',
    padding: '10px',
    borderRadius: '25px',
    height: 415,
    minWidth: 120,
    minHeight: 400
  },
  price: {
    color: theme.palette.primary.price,
    padding: 5,
    textAlign: 'center'
  },
  priceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderTopLeftRadius: '25px'
  },
  distanceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderTopRightRadius: '25px',
    right: 0
  },
  imgContainer: {
    position: 'relative'
  },
  media: {
    height: 225,
    borderRadius: '25px'
  },
  boostedContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    bottom: 0,
    right: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderBottomRightRadius: '25px'
  },
  flexStart: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'start'
  },
  typographyLocationOnIcon: {
    color: theme.palette.primary.main,
    marginTop: '2px'
  },
  locationOnIcon: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main
  },
  location: {
    color: theme.palette.primary.primaryText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5em' /* fallback */,
    height: '3em',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  },
  typographyReactTimeAgo: {
    marginTop: '5px',
    color: theme.palette.primary.secondaryText,
    fontSize: '10px',
    fontFamily: 'Quicksand',
    fontWeight: 'bold'
  },
  paperMobile: {
    maxWidth: 170,
    minWidth: 170,
    borderRadius: '25px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.background
  },
  productName: {
    color: theme.palette.primary.primaryText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5em' /* fallback */,
    height: '3em',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  }
}));

const convertPriceToString = (price) => {
  let strPrice = price.toString();
  let charsPrice = [...strPrice];
  if (charsPrice.length <= 3) return strPrice;
  let result = [];
  let count = 1;
  for (let i = charsPrice.length - 1; i >= 0; i--) {
    result.push(charsPrice[i]);
    if (count % 3 === 0 && count !== charsPrice.length) {
      result.push(',');
    }
    count++;
  }
  return result.reverse().join('');
};

const ProductCard = ({ intl, ...props }) => {
  const { product, router, needBoosted, auth } = props;
  const { imageUrl1 } = product;
  const classes = useStyles();
  const [openConfirm, setopenConfirm] = useState(false);
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageRef, setImageRef] = useState();

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== imageUrl1) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(imageUrl1);
                observer.unobserve(imageRef);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%'
          }
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(imageUrl1);
      }
    }
    return () => {
      didCancel = true;
      // on component cleanup, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [imageUrl1, imageSrc, imageRef]);

  const onClickCard = () => {
    if (needBoosted) {
      setopenConfirm(true);
    } else {
      if (product.isBoosted) {
        clickProduct(product);
      }
      if (!product.shopId) {
        router.navigate('/product/' + product.id);
        /* window.location.href = constantsConfig.mainUrl + "/" + product.id;*/
      } else {
        router.navigate(
          '/shop/' +
            product.shopId +
            '/dashboardproducts/productdetails/' +
            product.id
        );
      }
    }
  };

  const onLoadCardImage = (product) => {
    if (imageSrc !== placeHolder && product.isBoosted) {
      impressionProduct(product);
    }
  };
  return (
    <Grid item xs={6} sm={4} md={3}>
      <Dialog
        open={openConfirm}
        onClose={() => setopenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <div className={classes.containerDialog}>
          <div className={classes.flexContentBetween}>
            <DialogTitle id="alert-dialog-title">
              {intl.formatMessage({
                id: 'boost_this_product'
              })}
            </DialogTitle>
          </div>

          <BoostProductDialog product={product} user={auth} />
        </div>
      </Dialog>

      <Paper className={classes.paper}>
        <Card
          className={
            window.location.href.includes('adminuserprofile')
              ? classes.adminCard
              : classes.card
          }
        >
          <CardActionArea onClick={onClickCard}>
            {!product.yardSaleDate1 ? (
              <div className={classes.priceContiner}>
                <Typography variant="subtitle2" className={classes.price}>
                  {product.currencyCode === 'undefined' ||
                  product.currencyCode === null
                    ? product.price
                    : product.currencyCode +
                      ' ' +
                      convertPriceToString(product.price)}
                </Typography>
              </div>
            ) : null}
            {product.distanceKm ? (
              <div className={classes.distanceContiner}>
                {product.countryCode ? (
                  <Typography variant="subtitle2" className={classes.price}>
                    {product.countryCode === 'US'
                      ? parseInt(product.distanceMile) + ' miles'
                      : parseInt(product.distanceKm) + ' km'}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" className={classes.price}>
                    {product.bingoDealLocation.countryCode === 'US'
                      ? parseInt(product.distanceMile) + ' miles'
                      : parseInt(product.distanceKm) + ' km'}
                  </Typography>
                )}
              </div>
            ) : null}
            <div className={classes.imgContainer}>
              <CardMedia
                className={classes.media}
                ref={setImageRef}
                src={imageSrc}
                title={product.title}
                component="img"
                onLoad={onLoadCardImage}
              />
              {product.isBoosted ? (
                <div className={classes.boostedContainer}>
                  <Typography variant="subtitle2" className={classes.price}>
                    {intl.formatMessage({
                      id: 'boosted'
                    })}
                  </Typography>
                </div>
              ) : null}
            </div>

            <CardContent>
              <Typography
                align="left"
                variant="h5"
                className={classes.productName}
              >
                {product.yardSaleDate1
                  ? product.yardSaleDate3
                    ? new Date(new Date().toLocaleDateString()).getTime() <=
                      product.yardSaleDate1
                      ? constantsConfig.daysName[
                          new Date(product.yardSaleDate1).getDay()
                        ] +
                        ' - ' +
                        new Intl.DateTimeFormat('en-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(product.yardSaleStartTime)
                      : new Date(new Date().toLocaleDateString()).getTime() >
                          product.yardSaleDate1 &&
                        new Date(new Date().toLocaleDateString()).getTime() <=
                          product.yardSaleDate2
                      ? constantsConfig.daysName[
                          new Date(product.yardSaleDate2).getDay()
                        ] +
                        ' - ' +
                        new Intl.DateTimeFormat('en-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(product.yardSaleStartTime)
                      : constantsConfig.daysName[
                          new Date(product.yardSaleDate3).getDay()
                        ] +
                        ' - ' +
                        new Intl.DateTimeFormat('en-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(product.yardSaleStartTime)
                    : product.yardSaleDate2
                    ? new Date(new Date().toLocaleDateString()).getTime() <=
                      product.yardSaleDate1
                      ? constantsConfig.daysName[
                          new Date(product.yardSaleDate1).getDay()
                        ] +
                        ' - ' +
                        new Intl.DateTimeFormat('en-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(product.yardSaleStartTime)
                      : constantsConfig.daysName[
                          new Date(product.yardSaleDate2).getDay()
                        ] +
                        ' - ' +
                        new Intl.DateTimeFormat('en-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(product.yardSaleStartTime)
                    : constantsConfig.daysName[
                        new Date(product.yardSaleDate1).getDay()
                      ] +
                      ' - ' +
                      new Intl.DateTimeFormat('en-US', {
                        hour: '2-digit',
                        minute: '2-digit'
                      }).format(product.yardSaleStartTime)
                  : product.title}
              </Typography>
              <div className={classes.flexStart}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.typographyLocationOnIcon}
                >
                  <LocationOnIcon className={classes.locationOnIcon} />
                </Typography>
                {product && product.state ? (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    align="left"
                    className={classes.location}
                  >
                    {(product.city ? product.city + ', ' : '') + product.state}
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    align="left"
                    className={classes.location}
                  >
                    {product.bingoDealLocation &&
                      (product.bingoDealLocation.city
                        ? product.bingoDealLocation.city + ', '
                        : '') + product.bingoDealLocation.state}
                  </Typography>
                )}
              </div>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                align="right"
                className={classes.typographyReactTimeAgo}
              >
                <ReactTimeAgo date={new Date(product.createdTimestamp)} />
              </Typography>
            </CardContent>
          </CardActionArea>
          {window.location.href.includes('adminuserprofile') ? (
            <Typography align="center">
              <DeleteIcon
                onClick={() => props.handleOpenAdminConfirmPage(product)}
              />
            </Typography>
          ) : null}
        </Card>
      </Paper>
    </Grid>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    BoostedProduct: (pid) => dispatch(BoostedProduct(pid))
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  };
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ProductCard)
);
