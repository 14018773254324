import ReactPixel from "react-facebook-pixel";

export const purchaseFacebookPixel = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 001_Facebook
    ReactPixel.trackCustom("purchase", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const productPostFacebookPixel = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 002_Facebook
    ReactPixel.trackCustom("product_post", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const contactSellerFacebookPixel = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 012_Facebook
    ReactPixel.trackCustom("product_contact_seller", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const makeOfferFacebookPixel = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 013_Facebook
    ReactPixel.trackCustom("product_make_offer", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const addToCartFacebookPixel = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 021_Facebook
    ReactPixel.trackCustom("fb_mobile_add_to_cart", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const viewItemFacebookPixel = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 031_Facebook
    ReactPixel.trackCustom("fb_mobile_content_view", {
      product_name: product.title,
      product_category: product.category,
    });
  };
};

export const viewSearchResultsFacebookPixel = (searchData) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // 051_Facebook
    ReactPixel.trackCustom("FB.AppEvents.EventNames.SEARCHED", {
      search_params: searchData.currentRefinement,
      search_total_hits: searchData.hits,
    });
  };
};
