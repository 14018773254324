// @flow
const initState = {
  authErrorSignIn: null,
  authErrorSignUp: null,
  signInSuccessfully: false,
  signUpSuccessfully: false,
  signOutSuccess: false,
  forgotPassword: null,
  deleted: false,
  unsubscribed: false,
  loading: null,
  openBackdrop: false,
  followLoading: null,
  editProfileLoading: null,
  deleteProfileLoading: null,
  deleteUserSpinner: false,
  unBlockUserSpinner: null,
  deleteFollowingsAndFollowersError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        authErrorSignIn: "Your E-mail or password is incorrect",
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        signOutSuccess: false,
        signUpSuccessfully: false,
        signInSuccessfully: true,
      };
    case "OPEN_BACKDROP":
      return {
        ...state,
        openBackdrop: true,
      };
    case "RESET_PASSWORD_ERROR":
      return {
        ...state,
        forgotPassword: "This E-mail is not found",
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        forgotPassword: "Please, check your E-mail to reset password",
      };
    case "SIGNOUT_SUCCESS":
      return {
        ...state,
        signInSuccessfully: false,
        signUpSuccessfully: false,
        signOutSuccess: true,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        signUpSuccessfully: true,
        signInSuccessfully: false,
        signOutSuccess: false,
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        authErrorSignUp: action.err.code,
      };
    case "LOADING_SIGN_IN":
      return {
        ...state,
        authErrorSignIn: "loading",
      };
    case "LOADING_AUTH":
      return {
        ...state,
        authErrorSignUp: "loading",
      };
    case "LOADING_FOLLOW":
      return {
        ...state,
        followLoading: "loading",
      };
    case "DELETE_PROFILE_LOADING":
      return {
        ...state,
        deleteProfileLoading: "loading",
      };
    case "EDIT_PROFILE_SUCCESS":
      return {
        ...state,
        authErrorSignIn: null,
        editProfileLoading: "Done",
      };
    case "RESET_EDIT_PROFILE_LOADING":
      return {
        ...state,
        editProfileLoading: null,
      };
    case "EDIT_PROFILE_ERROR":
      return {
        ...state,
        authErrorSignIn: action.err.message,
        editProfileLoading: null,
      };
    case "LOADING_EDIT_PROFILE":
      return {
        ...state,
        editProfileLoading: "loading",
      };
    case "DELETE_PROFILE_SUCCESS":
      return {
        ...state,
        deleteProfileLoading: "success",
      };
    case "DELETE_PROFILE_ERROR":
      return {
        ...state,
        deleteProfileLoading: null,
      };
    case "UPDATE_MESSAGE_TOKEN_SUCCESS":
      return {
        ...state,
      };
    case "UPDATE_MESSAGE_TOKEN_ERROR":
      return {
        ...state,
      };
    case "BLOCK_USER_FROM_WEBSITE":
      return {
        ...state,
      };
    case "BLOCK_USER_FROM_WEBSITE_ERROR":
      return {
        ...state,
      };
    case "DELETE_USER":
      return {
        ...state,
        deleted: action.deleted,
        deleteUserSpinner: "success",
      };
    case "DELETE_USER_SPINNER":
      return {
        ...state,
        deleteUserSpinner: "loading",
      };
    case "DELETE_FOLLOWINGS_AND_FOLLOWERS":
      return {
        ...state,
      };
    case "DELETE_FOLLOWINGS_AND_FOLLOWERS_ERROR":
      return {
        ...state,
        deleteFollowingsAndFollowersError: "error",
      };
    case "UNBLOCK_USER":
      return {
        deleted: action.deleted,
        deleteUserSpinner: false,
      };

    case "DELETE_USER_ERROR":
      return {
        ...state,
        authErrorSignIn: action.err.message,
        deleteUserSpinner: "error",
      };
    case "UNBLOCK_USER_ERROR":
      return {
        ...state,
        authErrorSignIn: action.err.message,
      };
    case "CREATE_BLOCKED_USER":
      return {
        ...state,
      };
    case "UNBLOCK_USER_SPINNER":
      return {
        ...state,
        unBlockUserSpinner: "loading",
      };
    case "CREATE_UNBLOCKED_USER":
      return {
        ...state,
        unBlockUserSpinner: "success",
      };
    case "CREATE_BLOCKED_USER_ERROR":
      return {
        ...state,
        authErrorSignIn: action.err.message,
      };
    case "CREATE_UNBLOCKED_USER_ERROR":
      return {
        ...state,
        authErrorSignIn: action.err.message,
        unBlockUserSpinner: "error",
      };
    case "UNSUBSCRIBE_SUCCES":
      return {
        ...state,
        unsubscribed: true,
      };
    case "UNSUBSCRIBE_ERROR":
      return {
        ...state,
        unsubscribed: false,
      };
    case "UPDATE_USER_STATUS":
      return {
        ...state,
      };
    case "UPDATE_USER_STATUS_ERROR":
      return {
        ...state,
      };
    case "MAKE_FOLLOW":
      return {
        ...state,
        followLoading: null,
      };
    case "MAKE_FOLLOW_ERROR":
      return {
        ...state,
        followLoading: null,
      };
    case "MAKE_UN_FOLLOW":
      return {
        ...state,
        followLoading: null,
      };
    case "MAKE_UN_FOLLOW_ERROR":
      return {
        ...state,
        followLoading: null,
      };
    case "CLEAR_AUTH_ERROR":
      return {
        ...state,
        authErrorSignIn: null,
        authErrorSignUp: null,
      };
    default:
      return state;
  }
};

export default authReducer;
