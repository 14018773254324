import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBack from "@mui/icons-material/ArrowBack";
import MarkAsSoldUsersListNew from "./MarkAsSoldUsersListNew";
import withRouter from "../hooks/WithRouter";
import { addRateReview } from "../layout/AddRateReview";
import { getThreadIdForSubjectAndSeller } from "../messaging/Messageing";
import { deleteUsedProduct } from "../../store/product/DeleteUsedProduct";
import {
  getReviewName,
  getReviewTypeId,
  isEmptyOrSpaces,
} from "../../functions/Functions";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.background,
  },
}));
const SellProductDialog = ({ intl, ...props }) => {
  const classes = useStyles();
  const [step, setStep] = React.useState(1);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const onSendHandle = async (myThread) => {
    const thread = props.threads[0];
    const buyer = {
      name: thread.senderName,
      id: thread.senderId,
      photoUrl: thread.senderPhoto,
    };
    getThreadIdForSubjectAndSeller({
      receiverId: myThread.receiverId,
      receiverName: myThread.receiverName,
      receiverPhoto: myThread.receiverPhoto,
      senderId: myThread.senderId,
      senderName: myThread.senderName,
      senderPhoto: myThread.senderPhoto,
      subjectId: myThread.subjectId,
      subjectName: myThread.subjectName,
      subjectPhoto: thread.subjectPhoto,
    })
      .then((response) => {
        var receiverName = myThread.receiverName;
        try {
          var sendMessgae = firebase.functions().httpsCallable("sendMessage");

          sendMessgae(
            JSON.stringify({
              message: {
                messageType: 7,
                name: receiverName,
                subjectId: myThread.subjectId,
                text: intl.formatMessage({
                  id: "rate_seller",
                }),
                userId: myThread.senderId,
                timestamp: new Date().getTime(),
              },
              threadId: response.threadId,
              receiverId: myThread.receiverId,
            })
          )
            .then(() => {
              // Read result of the Cloud Function.
              deleteUsedProduct({
                product: props.product,
                isSoldOnBingoDeal: true,
                buyer: buyer,
                buyerId: thread.senderId,
              })
                .then(() => {
                  props.router.navigate("/sellpageproducts/null");
                })
                .catch((err) => {
                  console.log("error        ", err);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleNotSoldOnListedNearMe = () => {
    setOpenBackdrop(true);
    deleteUsedProduct({
      product: props.product,
      isSoldOnBingoDeal: false,
    })
      .then(() => {
        props.router.navigate("/sellpageproducts/null");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleSubmitRating = (
    ratingScore,
    isPositiveReview,
    reviewDetails,
    reviewsNames,
    myThread
  ) => {
    setOpenBackdrop(true);
    const Rate = {
      ratingScore: ratingScore,
      ratingFromUserId: props.auth.uid,
      ratingToUserId: myThread.receiverId,
      ratingFromType: "buyer",
      subjectId: props.product.id,
    };
    var reviews = [];
    reviewsNames.map((reviewName) => {
      const rev = {
        reviewTypeName: getReviewName(reviewName),
        reviewTypeId: getReviewTypeId(reviewName),
        isPositiveReview: isPositiveReview,
        reviewDetails: reviewDetails,
        reviewFromUserId: props.auth.uid,
        reviewToUserId: myThread.receiverId,
        reviewFromType: "buyer",
        subjectId: props.product.id,
      };
      reviews.push(rev);
      return reviews;
    });
    if (!isEmptyOrSpaces(reviewDetails)) {
      const rev = {
        reviewTypeName: "Other",
        reviewTypeId: getReviewTypeId("other"),
        isPositiveReview: isPositiveReview,
        reviewDetails: reviewDetails,
        reviewFromUserId: props.auth.uid,
        reviewToUserId: myThread.receiverId,
        reviewFromType: "buyer",
        subjectId: props.product.id,
      };
      reviews.push(rev);
    }

    const dataRateReview = {
      rateData: Rate,
      reviewData: reviews ? (reviews.length !== 0 ? reviews : null) : null,
    };
    addRateReview(dataRateReview);
    onSendHandle(myThread);
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress className={classes.circularProgress} />
      </Backdrop>
      <AppBar sx={{ position: "relative", minHeight: 0 }}>
        <Toolbar
          sx={{
            "@media (min-width: 600px)": {
              minHeight: 0,
            },
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={step === 1 ? props.handleClose : handlePreviousStep}
            aria-label="close"
          >
            {step === 1 ? <CloseIcon /> : <ArrowBack />}
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {intl.formatMessage({
              id: "rate_the_buyer",
            })}
          </Typography>
        </Toolbar>
      </AppBar>
      <MarkAsSoldUsersListNew
        step={step}
        threads={props.threads}
        product={props.product}
        threadsSpinner={props.threadsSpinner}
        handleClose={props.handleClose}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
        handleSubmitRating={handleSubmitRating}
        handleNotSoldOnListedNearMe={handleNotSoldOnListedNearMe}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SellProductDialog))
);
