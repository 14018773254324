// @flow
const initState = { actionError: null };

const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case "EVENT_ACTION_SUCESS":
      return {
        actionError: false,
      };
    case "EVENT_ACTION_ERROR":
      return {
        actionError: true,
      };
    default:
      return state;
  }
};

export default eventReducer;
