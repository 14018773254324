import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { compose } from "redux";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { injectIntl } from "react-intl";
import { blockUser, deleteProfile } from "../../store/auth/AuthActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { deleteMessage } from "../../store/messages/NewMessagesAction";
import withRouter from "../hooks/WithRouter";

const styles = (theme) => ({
  containerDialog: {
    backgroundColor: theme.palette.primary.background,
  },
  flexContentBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "100%",
  },
  wrapper: {
    width: "100%",

    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class ConfirmDeleteMessageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      //   products: props.products,
      //   numProducts: props.products.length,
      //   items: null,
      //   isAdmin: props.isAdmin ? true : false,
      //   userId: props.isAdmin ? props.user.id : props.auth.uid,
    };
  }

  handleDeleteMessage = () => {
    var info = {
      threadId: this.props.threadId,
      messageId: this.props.messageId,
    };
    this.props.deleteMessage(info);
  };

  render() {
    const {
      intl,
      classes,
      loadingDeleteMessage,
      handleCloseConfirmDeleteMessageDialog,
    } = this.props;
    // if (loadingDeleteMessage === "done") return <Navigate to="/threads" />;
    return (
      <div className={classes.containerDialog}>
        <div className={classes.flexContentBetween}>
          <DialogTitle
            id="alert-dialog-title"
            variant="h6"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {intl.formatMessage({
              id: "delete_plan_question",
            })}
          </DialogTitle>
        </div>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={this.handleDeleteMessage}
              className={classes.button}
            >
              {intl.formatMessage({
                id: "yes",
              })}
            </Button>
            {loadingDeleteMessage === "loading" ||
            this.state.spinner === true ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : null}
          </div>
          {loadingDeleteMessage === "loading" ? null : (
            <Button
              onClick={handleCloseConfirmDeleteMessageDialog}
              className={classes.button}
            >
              {intl.formatMessage({
                id: "no",
              })}
            </Button>
          )}
        </DialogActions>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loadingDeleteMessage: state.messages.loadingDeleteMessage,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    deleteMessage: (info) => dispatch(deleteMessage(info)),
  };
};

export default withRouter(
  injectIntl(
    compose(
      withStyles(styles),
      connect(mapStateToProps, mapDispatchToProps)
    )(ConfirmDeleteMessageDialog)
  )
);
