// @flow
import React, { Component } from "react";
import StepperAddProduct from "./StepperAddProduct";
import { connect } from "react-redux";
import {
  createProduct,
  editProduct,
} from "../../store/product/ProductActions";
import { constantsConfig } from "../../config/ConstantsConfig";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import { compose } from "redux";
import {
  postLogging,
  purchaseLogging,
} from "../../store/analyticsLogging/GoogleLogging";
import {
  purchaseFacebookPixel,
  productPostFacebookPixel,
} from "../../store/analyticsLogging/FacebookPixel";
import firebase from "firebase/compat/app";
import { isEmptyOrSpaces } from "../../functions/Functions";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background,
  },
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
});
/*
 */

class AddProduct extends Component {
  /**
   * constructer of component
   *
   * @param props
   */
  constructor(props) {
    super(props);
    //init state
    if (props.type === "add")
      this.state = {
        productInformation: {
          ...this.props.productInformation,
          tags: [],
        },
        currentImagesURL: [],
        coverImageIndex: -1,
        onTagsProcess: 0,
        isChoosedCategory: false,
        isTitle: false,
        isDescriptionNull: false,
        isPriceNull: false,
        isTitleNull: false,
        isCategoryNull: false,
      };
    else {
      this.state = {
        productInformation: {
          ...this.props.productInformation.productsDetails,
        },
        allThisFavoriteProduct: props.allThisFavoriteProduct,
        currentImagesURL: this.props.productInformation.currentImagesURL,
        coverImageIndex: this.props.productInformation.coverImageIndex,
        onTagsProcess: 0,
        isDescriptionNull: false,
        isPriceNull: false,
        isTitleNull: false,
      };
    }
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkCompeleteInforamtion = this.checkCompeleteInforamtion.bind(this);
  }

  changeCategoryAutomatically = () => {
    if (this.state.productInformation.tags != null) {
      if (this.state.isChoosedCategory === false) {
        let mySet = new Set();
        for (let i = 0; i < this.state.productInformation.tags.length; i++) {
          mySet.add(this.state.productInformation.tags[i].toLowerCase());
        }
        if (
          mySet.has("bmw") ||
          mySet.has("vehicle") ||
          mySet.has("car") ||
          mySet.has("rim") ||
          mySet.has("tire") ||
          mySet.has("automatic") ||
          mySet.has("manual") ||
          mySet.has("wheel")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.vehicles)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("footwear") ||
          mySet.has("aqua") ||
          mySet.has("turquoise") ||
          mySet.has("Shoe") ||
          mySet.has("Shoes") ||
          mySet.has("teal") ||
          mySet.has("azure") ||
          mySet.has("Sport") ||
          mySet.has("Sportswear") ||
          mySet.has("skate") ||
          mySet.has("athletic")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.sportsAndOutdoors)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("metalworking") ||
          mySet.has("tool") ||
          mySet.has("fork") ||
          mySet.has("wood")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.autoParts)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("kitchen") ||
          mySet.has("dishwasher") ||
          mySet.has("room") ||
          mySet.has("tv") ||
          mySet.has("refrigerator") ||
          mySet.has("cooler") ||
          mySet.has("lamp") ||
          mySet.has("microwave") ||
          mySet.has("bed") ||
          mySet.has("drawer")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.homeAndAppliances)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("laptop") ||
          mySet.has("laptop") ||
          mySet.has("technology") ||
          mySet.has("electronic") ||
          mySet.has("netbook") ||
          mySet.has("website") ||
          mySet.has("computer") ||
          mySet.has("brand") ||
          mySet.has("gadget")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.electronics)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("table") ||
          mySet.has("furniture") ||
          mySet.has("building") ||
          mySet.has("home") ||
          mySet.has("table") ||
          mySet.has("house")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.furniture)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("metal") ||
          mySet.has("glass") ||
          mySet.has("silver") ||
          mySet.has("tableware") ||
          mySet.has("serveware")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.antiquesAndCollectibles)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("jewellery") ||
          mySet.has("fashion") ||
          mySet.has("gold") ||
          mySet.has("earrings") ||
          mySet.has("brass") ||
          mySet.has("gamestone") ||
          mySet.has("clothes") ||
          mySet.has("t-shirt") ||
          mySet.has("pants") ||
          mySet.has("jacket")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.fashionAndAccessories)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
        if (
          mySet.has("pink") ||
          mySet.has("child") ||
          mySet.has("boy") ||
          mySet.has("toy") ||
          mySet.has("toys") ||
          mySet.has("cushion")
        ) {
          firebase
            .firestore()
            .collection("categories")
            .doc(constantsConfig.categoriesId.babyAndKids)
            .get()
            .then((doc) => {
              const data = doc.data();
              this.setState({
                productInformation: {
                  ...this.state.productInformation,
                  categoryId: data.categoryId,
                  category: data,
                },
              });
            });
        }
      }
    }
  };

  checkCompeleteInforamtion() {
    const digits_only = (string) =>
      [...string].every((c) => "0123456789".includes(c));
    if (
      !isEmptyOrSpaces(this.state.productInformation.title) &&
      !isEmptyOrSpaces(this.state.productInformation.imageUrl1) &&
      this.state.productInformation.price !== "" &&
      this.state.productInformation.price !== 0 &&
      this.state.productInformation.countryCode !== "" &&
      digits_only(this.state.productInformation.price.toString()) &&
      this.state.productInformation.categoryId !== ""
    ) {
      return true;
    }
    if (this.state.productInformation.categoryId === "") {
      this.setState({
        isCategoryNull: true,
      });
    }
    if (isEmptyOrSpaces(this.state.productInformation.title)) {
      this.setState({
        isTitleNull: true,
      });
    }
    if (
      this.state.productInformation.price <= 0 ||
      this.state.productInformation.price === ""
    ) {
      this.setState({
        isPriceNull: true,
      });
    }
    if (this.state.productInformation.categoryId !== "") {
      this.setState({
        isCategoryNull: false,
      });
    }
    if (this.state.productInformation.price > 0) {
      this.setState({
        isPriceNull: false,
      });
    }
    if (!isEmptyOrSpaces(this.state.productInformation.title)) {
      this.setState({
        isTitleNull: false,
      });
    }
    return false;
  }

  handleSubmit() {
    if (this.props.type === "add") {
      var loggingProduct = {
        title: this.state.productInformation.title,
        category: this.state.productInformation.category,
      };
      this.props.createProduct(this.state.productInformation);
      this.props.postLogging(loggingProduct);
      this.props.purchaseLogging(this.state.productInformation);
      this.props.purchaseFacebookPixel(loggingProduct);
      this.props.productPostFacebookPixel(loggingProduct);
    } else {
      this.props.editProduct(this.state.productInformation);
    }
  }

  /**
   * handle any change value in any details of add product...
   *
   * @param whatChange //string has whate value change in sup component step...
   * @param value //value of change
   */
  handleChangeValue(whatChange, value) {
    switch (whatChange) {
      case "onTagsProcess":
        //  const category=predictCategory()
        this.setState({
          onTagsProcess: value + this.state.onTagsProcess,
        });
        break;
      case "title":
        this.setState({
          productInformation: {
            ...this.state.productInformation,
            title: value,
            sellerId: this.props.sellerId,
          },
        });
        break;
      case "description":
        this.setState({
          productInformation: {
            ...this.state.productInformation,
            description: isEmptyOrSpaces(value) ? "" : value,
          },
        });
        break;
      case "images":
        switch (value) {
          case 0:
            var imageUrl = "imageUrl";
            var productInformation = this.state.productInformation;

            productInformation["imageUrl1"] = "";
            for (let i = 2; i <= 5; i++) {
              if (
                this.state.productInformation[imageUrl + i.toString()] !== ""
              ) {
                productInformation["imageUrl1"] =
                  this.state.productInformation[imageUrl + i.toString()];
                productInformation[imageUrl + i.toString()] = "";
                break;
              }
            }

            break;
          case 1:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl2: "",
              },
            });
            break;
          case 2:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl3: "",
              },
            });
            break;
          case 3:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl4: "",
              },
            });
            break;
          case 4:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl5: "",
              },
            });
            break;
          default:
            break;
        }
        this.state.currentImagesURL.splice(value, 1);

        break;
      case "image":
        switch (value.index) {
          case 0:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl1: value.url,
              },
            });
            break;
          case 1:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl2: value.url,
              },
            });
            break;
          case 2:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl3: value.url,
              },
            });
            break;
          case 3:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl4: value.url,
              },
            });
            break;
          case 4:
            this.setState({
              productInformation: {
                ...this.state.productInformation,
                imageUrl5: value.url,
              },
            });
            break;
          default:
            break;
        }

        break;
      case "condition":
        this.setState({
          productInformation: {
            ...this.state.productInformation,
            condition: value.condition,
            conditionStatus: value.conditionStatus,
          },
        });
        break;
      case "category":
        this.setState({
          isChoosedCategory: true,
          productInformation: {
            ...this.state.productInformation,
            categoryId: value.categoryId,
            category: { ...value },
          },
        });
        break;
      case "map":
        this.setState({
          productInformation: {
            ...this.state.productInformation,
            ...value,
          },
        });
        break;
      case "price":
        var floatValue = parseFloat(value);
        if (value !== "")
          this.setState({
            productInformation: {
              ...this.state.productInformation,
              price: floatValue,
              formattedPrice: value,
            },
          });
        else
          this.setState({
            productInformation: {
              ...this.state.productInformation,
              price: "",
              formattedPrice: "",
            },
          });
        break;
      case "currencyCode":
        this.setState({
          productInformation: {
            ...this.state.productInformation,

            currencyCode: value,
          },
        });
        break;
      case "coverImageIndex":
        this.changeCoverIndex(value);

        break;
      case "tags":
        var onTagsProcess = this.state.onTagsProcess - 1;

        this.setState({
          onTagsProcess: onTagsProcess,
          productInformation: {
            ...this.state.productInformation,
            tags: value,
          },
        });
        this.changeCategoryAutomatically();

        break;

      //default value...
      default:
        break;
    }
  }
  changeCoverIndex = (value) => {
    this.setState({
      coverImageIndex: 0,
    });

    var imageUrl1 = this.state.productInformation.imageUrl1;
    var currentImage = this.state.currentImagesURL[value];
    var currentImage1 = this.state.currentImagesURL[0];
    var currentImagesURL = this.state.currentImagesURL;

    switch (value) {
      case 0:
        break;
      case 1:
        var imageUrl2 = this.state.productInformation.imageUrl2;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          productInformation: {
            ...this.state.productInformation,
            imageUrl2: imageUrl1,
            imageUrl1: imageUrl2,
          },
        });

        break;
      case 2:
        var imageUrl3 = this.state.productInformation.imageUrl3;

        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          productInformation: {
            ...this.state.productInformation,
            imageUrl3: imageUrl1,
            imageUrl1: imageUrl3,
          },
        });
        break;
      case 3:
        var imageUrl4 = this.state.productInformation.imageUrl4;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          productInformation: {
            ...this.state.productInformation,
            imageUrl4: imageUrl1,
            imageUrl1: imageUrl4,
          },
        });
        break;
      case 4:
        var imageUrl5 = this.state.productInformation.imageUrl5;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          productInformation: {
            ...this.state.productInformation,
            imageUrl5: imageUrl1,
            imageUrl1: imageUrl5,
          },
        });
        break;
      default:
        break;
    }
  };

  handleRemoveTags = () => {
    this.setState({
      productInformation: {
        ...this.state.productInformation,
        tags: [],
      },
    });
  };

  render() {
    const { product, handleClose, classes } = this.props;
    if (product.productState === "CREATE_PRODUCT") {
      if (this.props.type === "add")
        window.location.href =
          constantsConfig.mainUrl +
          "product/" +
          product.productId +
          "/Open/BoostProduct";
      else
        window.location.href =
          constantsConfig.mainUrl + "product/" + product.productId;
    }
    //render stepper component...
    return (
      <div className={classes.root}>
        <div>
          <CloseIcon className={classes.xIcon} onClick={handleClose} />
        </div>
        <StepperAddProduct
          onHandleChangeValue={this.handleChangeValue}
          handleSubmit={this.handleSubmit}
          addProductData={this.state}
          type={this.props.type}
          checkCompeleteInforamtion={this.checkCompeleteInforamtion}
          changeCategoryAutomatically={this.changeCategoryAutomatically}
          isChoosedCategory={this.state.isChoosedCategory}
          productInformation={this.state.productInformation}
          isPriceNull={this.state.isPriceNull}
          isDescriptionNull={this.state.isDescriptionNull}
          isTitleNull={this.state.isTitleNull}
          isCategoryNull={this.state.isCategoryNull}
          handleRemoveTags={this.handleRemoveTags}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    product: state.usedProductReducer,
    sellerId: state.firebase.auth.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
    editProduct: (product) => dispatch(editProduct(product)),
    postLogging: (product) => dispatch(postLogging(product)),
    purchaseLogging: (product) => dispatch(purchaseLogging(product)),
    purchaseFacebookPixel: (product) =>
      dispatch(purchaseFacebookPixel(product)),
    productPostFacebookPixel: (product) =>
      dispatch(productPostFacebookPixel(product)),
    resetState: () => dispatch({ type: "RESET_PRODUCT" }),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AddProduct);
