// @flow
import { constantsConfig } from "../../config/ConstantsConfig";

export const createReport = (report) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("reports")
      .add({})
      .then((respose) => {
        firestore
          .collection("reports")
          .doc(respose.id)
          .set({
            ...report,
            timestamp: new Date().getTime(),
          })
          .then(() => {
            dispatch({ type: "CREATE_REPORT" });
          })
          .catch((err) => {
            dispatch({ type: "CREATE_REPORT_ERROR", err });
          });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_REPORT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const blockUser = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    // const firestore = getFirestore();
    const functions = firebase.functions();
    var blockUser_2022 = functions.httpsCallable("blockUser_2022");
    dispatch({ type: "BLOCK_SPINNER" });
    blockUser_2022(
      JSON.stringify({
        blockerUserId: info.blockerUserId,
        threadId: info.threadId,
        blockedUserId: info.blockedUserId,
        subjectName: info.subjectName,
      })
    )
      .then((response) => {
        dispatch({ type: "BLOCK_USER" });
        // dispatch({ type: "STOP_BLOCK_SPINNER" });
      })
      .catch((err) => {
        dispatch({ type: "BLOCK_USER_ERROR", err });
      });
  };
};

export const unBlockUser = (info) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "BLOCK_SPINNER" });
    firestore
      .collection(constantsConfig.collectionThreads)
      .doc(info.threadId)
      .update({ blockerUserId: null })
      .then((respose) => {
        dispatch({ type: "UN_BLOCK_USER" });
        // dispatch({ type: "STOP_BLOCK_SPINNER" });
      })
      .catch((err) => {
        dispatch({ type: "UN_BLOCK_USER_ERROR", err });
      });
  };
};

export const clearBlockVariables = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "CLEAR_BLOCK_VARIABLE" });
  };
};

export const closeReportByAdmin = (reportId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var deleted = true;
    firestore
      .collection("reports")
      .doc(reportId)
      .delete()
      .then(() => {
        dispatch({ type: "CLOSE_REPORT", deleted });
      })
      .catch((err) => {
        dispatch({ type: "CLOSE_REPORT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const uncloseReportByAdmin = (rId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var deleted = true;
    firestore
      .collection("closed_reports")
      .doc(rId)

      .delete()
      .then(() => {
        dispatch({ type: "UNCLOSE_REPORT", deleted });
      })
      .catch((err) => {
        dispatch({ type: "UNCLOSE_REPORT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const createClosedReport = (report, reportId, closeTimestamp) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "CLOSE_REPORT_SPINNER" });
    firestore
      .collection(constantsConfig.collectionClosedReports)
      .doc(reportId)
      .set({
        ...report,
        closeTimestamp,
      })
      .then(() => {
        dispatch({ type: "CREATE_CLOSED_REPORT" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_CLOSED_REPORT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};

export const createUnClosedReport = (report, rId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    dispatch({ type: "UNCLOSED_REPORT_SPINNER" });
    firestore
      .collection(constantsConfig.collectionReports)
      .doc(rId)
      .set({
        ...report,
      })
      .then(() => {
        dispatch({ type: "CREATE_UNCLOSED_REPORT" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_UNCLOSED_REPORT_ERROR", err });
      });
    //then method execut when befor task complete
  };
};
