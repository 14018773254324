// @flow
const initState = {
  openDialogApp: true,
};

const BoardingScreenReducer = (state = initState, action) => {
  switch (action.type) {
    case "CLOSE_DIALOG":
      state.openDialogApp = false;
      return state;
    case "OPEN_DIALOG_ERROR":
      return state;
    default:
      return state;
  }
};

export default BoardingScreenReducer;
