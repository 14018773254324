import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import ProductCard from './ProductCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
  root: {
    // display: "flex",
    height: '100%',
    backgroundColor: theme.palette.primary.background
  },
  rootGrid: {
    flexGrow: 1,
    // position: '-webkit-sticky',
    position: 'sticky',
    // top: 20,
    bottom: 20,
    // paddingTop: "40px",
    zIndex: 1,
    backgroundColor: theme.palette.primary.background
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    borderRadius: '25px',
    minWidth: 120,
    minHeight: 400,
    backgroundColor: theme.palette.primary.background
  },
  control: {
    padding: theme.spacing(2)
  },
  card: {
    maxWidth: '100%',
    padding: '10px',
    borderRadius: '25px',
    height: 385,
    minWidth: 120,
    minHeight: 400,
    backgroundColor: theme.palette.primary.background
  },
  price: {
    color: theme.palette.primary.price,
    padding: 5,
    textAlign: 'center',
    fontSize: '12px'
  },
  priceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderTopLeftRadius: '25px'
  },
  distanceContiner: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderTopRightRadius: '25px',
    right: 0
  },
  imgContainer: {
    position: 'relative'
  },
  media: {
    height: 225,
    borderRadius: '25px'
  },
  boostedContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    bottom: 0,
    right: 0,
    width: '40%',
    position: 'absolute',
    zIndex: 9,
    borderBottomRightRadius: '25px'
  },
  flexStart: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'start'
  },
  paperMobile: {
    maxWidth: 170,
    minWidth: 170,
    borderRadius: '25px'
  },
  content: {
    flexGrow: 1,
    paddingBottom: 0,
    padding: theme.spacing(3),

    backgroundColor: theme.palette.primary.background
  },
  buttonRemoveAllProducts: {
    ...theme.typography.normalButton,
    marginTop: 0,
    float: 'right',
    zIndex: 2
  }
});

class ProductsGrid extends Component {
  render() {
    const {
      classes,
      products,
      router,
      needBoosted,
      removeAllProducts,
      handleOpenRemoveAllProducts,
      handleOpenAdminConfirmPage,
      intl
    } = this.props;
    return (
      <div className={classes.root}>
        {/* <Hidden smDown implementation="css">
          <DrawerSellPage />
        </Hidden> */}
        <main className={classes.content}>
          <Toolbar />
          {removeAllProducts && removeAllProducts === true ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonRemoveAllProducts}
                startIcon={<DeleteIcon />}
                onClick={handleOpenRemoveAllProducts}
              >
                {intl.formatMessage({
                  id: 'remove_all_products'
                })}
              </Button>
            </div>
          ) : null}{' '}
          {removeAllProducts && removeAllProducts === true ? (
            <div style={{ marginTop: '80px' }}>
              <Grid container className={classes.rootGrid} spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={3}>
                    {products.map((product) => (
                      <ProductCard
                        key={product.id}
                        product={product}
                        router={router}
                        handleOpenAdminConfirmPage={handleOpenAdminConfirmPage}
                        needBoosted={
                          needBoosted === true ? needBoosted : undefined
                        }
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <Grid container className={classes.rootGrid} spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={3}>
                  {products.map((product, index) => (
                    <ProductCard
                      key={product.id + index}
                      product={product}
                      router={router}
                      handleOpenAdminConfirmPage={handleOpenAdminConfirmPage}
                      needBoosted={
                        needBoosted === true ? needBoosted : undefined
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </main>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(ProductsGrid));
