// @flow
import firebase from 'firebase/compat/app';
import "firebase/functions";

export const getThreadIdForSubjectAndSeller = (req) => {
  return new Promise((resolve, reject) => {
    var threadId = firebase
      .functions()
      .httpsCallable("getThreadIdForSubjectAndSeller");

    threadId(JSON.stringify(req))
      .then(function (result) {
        // Read result of the Cloud Function.
        resolve(JSON.parse(result.data));
      })
      .catch(function (error) {
        reject("Error");
      });
  });
};
