// @flow
import { actionsId } from "./ActionsInformation";
export const createAction = (actionDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("actions")
      .add({
        ...actionDetails,
        actionId: actionsId[actionDetails.actionType],
        timestamp: new Date().getTime(),
      })
      .then((res) => {
        dispatch({ type: "EVENT_ACTION_SUCESS" });
      })
      .catch((err) => {
        dispatch({ type: "EVENT_ACTION_ERORR", err });
      });
  };
};
