// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    marginInlineStart: '15rem'
  },
  images: {
    width: '3rem',
    height: '3rem'
  },
  tableCell: {
    width: '10em',
    maxWidth: '10em',
    wordWrap: 'break-word',
    backgroundColor: theme.palette.primary.background
  },
  profileImage: {
    maxWidth: '5rem',
    maxHeight: '5rem'
  },
  button: {
    ...theme.typography.normalButton
  },
  flexSpaceBetween: {
    display: 'flex',
    justifySpace: 'space-between'
  },
  circularProgress: {
    color: theme.palette.primary.icons
  },
  product: {
    '&:hover': { cursor: 'pointer' }
  }
});

class AdminAllReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, review } = this.props;
    return (
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2" className={classes.product}>
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(review.reviewTimestamp)}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewFromUserId}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewToUserId}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">
            {review.isPositiveReview.toString()}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewDetails}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewTypeId}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewId}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewTypeName}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">{review.reviewFromType}</Typography>
        </TableCell>
      </TableRow>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {};
};
export default injectIntl(
  compose(withStyles(styles), connect(mapStateToProps))(AdminAllReviews)
);
