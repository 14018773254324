// @flow
import React from "react";
import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { injectIntl } from "react-intl";
import Calendar from "react-calendar";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MultipleImageUpload from "./images/MultipleImageUpload";
import MapComponent from "../other/MapComponent";
import AmenitiesChipsDialog from "./AmenitiesChipsDialog";
import { createProduct, editProduct } from "../../store/product/ProductActions";
import { constantsConfig } from "../../config/ConstantsConfig";
import {
  postLogging,
  purchaseLogging,
} from "../../store/analyticsLogging/GoogleLogging";
import {
  purchaseFacebookPixel,
  productPostFacebookPixel,
} from "../../store/analyticsLogging/FacebookPixel";
import "react-calendar/dist/Calendar.css";
import { isEmptyOrSpaces } from "../../functions/Functions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function translateConditionStatus(condition, intl) {
  if (condition === "Other (see description)")
    return intl.formatMessage({
      id: "condition_other",
    });
  if (condition === "For Parts")
    return intl.formatMessage({
      id: "condition_for_parts",
    });
  if (condition === "Used (normal wear)")
    return intl.formatMessage({
      id: "condition_used",
    });
  if (condition === "New (never used)")
    return intl.formatMessage({
      id: "condition_new",
    });
}

function translateLeaseLength(length, intl) {
  if (length === "Month to month")
    return intl.formatMessage({
      id: "month_to_month",
    });
  if (length === "3 months")
    return intl.formatMessage({
      id: "three_months",
    });
  if (length === "6 months")
    return intl.formatMessage({
      id: "six_months",
    });
  if (length === "1 year")
    return intl.formatMessage({
      id: "one_year",
    });
  if (length === "1+ year")
    return intl.formatMessage({
      id: "more_one_year",
    });
}

function translatepropertyTypes(type, intl) {
  if (type === "Apartment")
    return intl.formatMessage({
      id: "apartment",
    });
  if (type === "House")
    return intl.formatMessage({
      id: "house",
    });
  if (type === "Room only")
    return intl.formatMessage({
      id: "room_only",
    });
  if (type === "Townhouse")
    return intl.formatMessage({
      id: "townhouse",
    });
}

function translateAirConditioningTypes(type, intl) {
  if (type === "Central AC")
    return intl.formatMessage({
      id: "central_ac",
    });
  if (type === "AC available")
    return intl.formatMessage({
      id: "ac_available",
    });
  if (type === "None")
    return intl.formatMessage({
      id: "none",
    });
}

function translateLaundryTypes(type, intl) {
  if (type === "In-unit laundry")
    return intl.formatMessage({
      id: "in_unit_laundry",
    });
  if (type === "Laundry in building")
    return intl.formatMessage({
      id: "laundry_building",
    });
  if (type === "Laundry available")
    return intl.formatMessage({
      id: "laundry_available",
    });
  if (type === "None")
    return intl.formatMessage({
      id: "none",
    });
}

function translateParkingTypes(type, intl) {
  if (type === "Garage parking")
    return intl.formatMessage({
      id: "garage_parking",
    });
  if (type === "Street parking")
    return intl.formatMessage({
      id: "street_parking",
    });
  if (type === "Off-street parking")
    return intl.formatMessage({
      id: "off_street_parking",
    });
  if (type === "Parking available")
    return intl.formatMessage({
      id: "available_parking",
    });
  if (type === "None")
    return intl.formatMessage({
      id: "none",
    });
}

function translateHeatingTypes(type, intl) {
  if (type === "Central heating")
    return intl.formatMessage({
      id: "central_heating",
    });
  if (type === "Electric heating")
    return intl.formatMessage({
      id: "electric_heating",
    });
  if (type === "Gas heating")
    return intl.formatMessage({
      id: "gas_heating",
    });
  if (type === "Radiator heating")
    return intl.formatMessage({
      id: "radiator_heating",
    });
  if (type === "Heating available")
    return intl.formatMessage({
      id: "available_heating",
    });
  if (type === "None")
    return intl.formatMessage({
      id: "none",
    });
}

function translateAmenities(type, intl) {
  if (type === "Balcony")
    return intl.formatMessage({
      id: "balcony",
    });
  if (type === "Basement")
    return intl.formatMessage({
      id: "basement",
    });
  if (type === "Bike Parking")
    return intl.formatMessage({
      id: "bike_parking",
    });
  if (type === "Cable TV")
    return intl.formatMessage({
      id: "cable_tv",
    });
  if (type === "Ceiling Fan")
    return intl.formatMessage({
      id: "ceiling_fan",
    });
  if (type === "Concierge")
    return intl.formatMessage({
      id: "concierge",
    });
  if (type === "Dishwasher")
    return intl.formatMessage({
      id: "dishwasher",
    });
  if (type === "Doorman")
    return intl.formatMessage({
      id: "doorman",
    });
  if (type === "Elevator")
    return intl.formatMessage({
      id: "elevator",
    });
  if (type === "Fireplace")
    return intl.formatMessage({
      id: "fireplace",
    });
  if (type === "Fitness Center")
    return intl.formatMessage({
      id: "fitness_center",
    });
  if (type === "Garbage Disposal")
    return intl.formatMessage({
      id: "garbage_disposal",
    });
  if (type === "Microwave")
    return intl.formatMessage({
      id: "microwave",
    });
  if (type === "Online Application")
    return intl.formatMessage({
      id: "online_app",
    });
  if (type === "Oven")
    return intl.formatMessage({
      id: "oven",
    });
  if (type === "Package Service")
    return intl.formatMessage({
      id: "package_service",
    });
  if (type === "Patio")
    return intl.formatMessage({
      id: "patio",
    });
  if (type === "Pet Park")
    return intl.formatMessage({
      id: "pet_park",
    });
  if (type === "Refrigerator")
    return intl.formatMessage({
      id: "refrigerator",
    });
  if (type === "Resident Lounge")
    return intl.formatMessage({
      id: "resident_lounge",
    });
  if (type === "Roof Deck")
    return intl.formatMessage({
      id: "roof_deck",
    });
  if (type === "Secured Entry")
    return intl.formatMessage({
      id: "secured_entry",
    });
  if (type === "Storage")
    return intl.formatMessage({
      id: "storage",
    });
  if (type === "Swimming Pool")
    return intl.formatMessage({
      id: "swimming_pool",
    });
  if (type === "Walk-in Closet")
    return intl.formatMessage({
      id: "walk_in_closet",
    });
  if (type === "Wheelchair Access")
    return intl.formatMessage({
      id: "wheelchair_access",
    });
}

const styles = (theme) => ({
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
  layout: {
    width: "auto",
    marginTop: "35px",
    marginInlineStart: theme.spacing(2),
    marginInlineEnd: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    marginTop: "1em",
  },
  textField: {
    width: "100%",
  },
  amenitiesButton: {
    ...theme.typography.normalButton,
  },
  errImageMessageTypography: {
    color: theme.palette.primary.errColor,
  },
  buttonProgress: {
    color: theme.palette.primary.icons,
  },
  postButton: {
    ...theme.typography.normalButton,
  },
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
});

class AddHome extends Component {
  constructor(props) {
    super(props);
    var years = [];
    for (let i = 2022; i >= 1901; i--) {
      years.push(i);
    }
    if (props.type === "add") {
      this.state = {
        currentImagesURL: [],
        coverImageIndex: -1,
        tags: [],
        onTagsProcess: 0,
        propertyLotSizeUnit: "Square feet",
        rating: 3.5,
        isHomesForSaleOrRentNull: false,
        isPropertyTypeNull: false,
        isBedroomsNull: false,
        isBathroomsNull: false,
        isImageUrlNull: false,
        isPriceNull: false,
        isDescriptionNull: false,
        isPropertyAreaNegativeValue: false,
        isLotSizeNegativeValue: false,
        isOpenSnackbar: false,
        leaseLengths: [
          "Month to month",
          "3 months",
          "6 months",
          "1 year",
          "1+ year",
        ],
        propertyTypes: ["Apartment", "House", "Room only", "Townhouse"],
        airConditioningTypes: ["Central AC", "AC available", "None"],
        laundryTypes: [
          "In-unit laundry",
          "Laundry in building",
          "Laundry available",
          "None",
        ],
        parkingTypes: [
          "Garage parking",
          "Street parking",
          "Off-street parking",
          "Parking available",
          "None",
        ],
        heatingTypes: [
          "Central heating",
          "Electric heating",
          "Gas heating",
          "Radiator heating",
          "Heating available",
          "None",
        ],
        amenities: [
          "Balcony",
          "Basement",
          "Bike Parking",
          "Cable TV",
          "Ceiling Fan",
          "Concierge",
          "Dishwasher",
          "Doorman",
          "Elevator",
          "Fireplace",
          "Fitness Center",
          "Garbage Disposal",
          "Microwave",
          "Online Application",
          "Oven",
          "Package Service",
          "Patio",
          "Pet Park",
          "Refrigerator",
          "Resident Lounge",
          "Roof Deck",
          "Secured Entry",
          "Storage",
          "Swimming Pool",
          "Walk-in Closet",
          "Wheelchair Access",
        ],
        selectedAmenities: [],
        isOpenAmenitiesDialog: false,
        isOpenDateDialog: false,
        disabled: false,
        imageUrl1: null,
        imageUrl2: null,
        imageUrl3: null,
        imageUrl4: null,
        imageUrl5: null,
        years: years,
        condition: 2,
        conditionStatus: "Used (normal wear)",
        propertyForRent: false,
        propertyForSale: false,
        propertyType: "",
        propertyBedrooms: 1,
        propertyBathrooms: 1,
        currencyCode: props.currencyCode,
        defaultValueDateCalendar: new Date(),
        dateAvailableTimestamp: null,
        price: 1,
        description: "",
        propertyAddress: null,
        propertyLocation: null,
        propertyArea: "",
        propertyAreaUnit: "Square feet",
        propertyYearBuilt: "",
        propertyYearRenovated: "",
        propertyLotSize: "",
        propertyLaundry: "",
        propertyParking: "",
        propertyAc: "",
        propertyHeating: "",
        propertyAmenities: null,
        propertyLeaseLength: "",
        propertyCatFriendly: false,
        propertyDogFriendly: false,
        homeLocation: null,
      };
    } else {
      this.state = {
        currentImagesURL: props.currentImagesURL,
        coverImageIndex: props.coverImageIndex,
        tags: [],
        onTagsProcess: 0,
        propertyLotSizeUnit: "Square feet",
        rating: 3.5,
        isHomesForSaleOrRentNull: false,
        isPropertyTypeNull: false,
        isBedroomsNull: false,
        isBathroomsNull: false,
        isImageUrlNull: false,
        isPriceNull: false,
        isDescriptionNull: false,
        isPropertyAreaNegativeValue: false,
        isLotSizeNegativeValue: false,
        isOpenSnackbar: false,
        leaseLengths: [
          "Month to month",
          "3 months",
          "6 months",
          "1 year",
          "1+ year",
        ],
        propertyTypes: ["Apartment", "House", "Room only", "Townhouse"],
        airConditioningTypes: ["Central AC", "AC available", "None"],
        laundryTypes: [
          "In-unit laundry",
          "Laundry in building",
          "Laundry available",
          "None",
        ],
        parkingTypes: [
          "Garage parking",
          "Street parking",
          "Off-street parking",
          "Parking available",
          "None",
        ],
        heatingTypes: [
          "Central heating",
          "Electric heating",
          "Gas heating",
          "Radiator heating",
          "Heating available",
          "None",
        ],
        amenities: [
          "Balcony",
          "Basement",
          "Bike Parking",
          "Cable TV",
          "Ceiling Fan",
          "Concierge",
          "Dishwasher",
          "Doorman",
          "Elevator",
          "Fireplace",
          "Fitness Center",
          "Garbage Disposal",
          "Microwave",
          "Online Application",
          "Oven",
          "Package Service",
          "Patio",
          "Pet Park",
          "Refrigerator",
          "Resident Lounge",
          "Roof Deck",
          "Secured Entry",
          "Storage",
          "Swimming Pool",
          "Walk-in Closet",
          "Wheelchair Access",
        ],
        allThisFavoriteProduct: props.allThisFavoriteProduct,
        selectedAmenities: props.productData.propertyAmenities
          ? props.productData.propertyAmenities
          : [],
        isOpenAmenitiesDialog: false,
        isOpenDateDialog: false,
        disabled: false,
        imageUrl1: props.productData.imageUrl1
          ? props.productData.imageUrl1
          : null,
        imageUrl2: props.productData.imageUrl2
          ? props.productData.imageUrl2
          : null,
        imageUrl3: props.productData.imageUrl3
          ? props.productData.imageUrl3
          : null,
        imageUrl4: props.productData.imageUrl4
          ? props.productData.imageUrl4
          : null,
        imageUrl5: props.productData.imageUrl5
          ? props.productData.imageUrl5
          : null,
        productId: props.productData.id,
        years: years,
        condition: props.productData.condition
          ? props.productData.condition
          : null,
        conditionStatus: props.productData.conditionStatus
          ? props.productData.conditionStatus
          : null,
        propertyForRent: props.productData.propertyForRent
          ? props.productData.propertyForRent
          : null,
        propertyForSale: props.productData.propertyForSale
          ? props.productData.propertyForSale
          : null,
        propertyType: props.productData.propertyType
          ? props.productData.propertyType
          : "",
        propertyBedrooms: props.productData.propertyBedrooms
          ? props.productData.propertyBedrooms
          : null,
        propertyBathrooms: props.productData.propertyBathrooms
          ? props.productData.propertyBathrooms
          : null,
        currencyCode: props.productData.currencyCode
          ? props.productData.currencyCode
          : null,
        defaultValueDateCalendar: new Date(),
        dateAvailableTimestamp: props.productData.dateAvailableTimestamp
          ? props.productData.dateAvailableTimestamp
          : null,
        price: props.productData.price ? props.productData.price : null,
        description: props.productData.description
          ? props.productData.description
          : "",
        propertyAddress: props.productData.propertyAddress
          ? props.productData.propertyAddress
          : null,
        propertyLocation: props.productData.propertyLocation
          ? props.productData.propertyLocation
          : null,
        propertyArea: props.productData.propertyArea
          ? props.productData.propertyArea
          : "",
        propertyAreaUnit: props.productData.propertyAreaUnit
          ? props.productData.propertyAreaUnit
          : null,
        propertyYearBuilt: props.productData.propertyYearBuilt
          ? props.productData.propertyYearBuilt
          : "",
        propertyYearRenovated: props.productData.propertyYearRenovated
          ? props.productData.propertyYearRenovated
          : "",
        propertyLotSize: props.productData.propertyLotSize
          ? props.productData.propertyLotSize
          : "",
        propertyLaundry: props.productData.propertyLaundry
          ? props.productData.propertyLaundry
          : "",
        propertyParking: props.productData.propertyParking
          ? props.productData.propertyParking
          : "",
        propertyAc: props.productData.propertyAc
          ? props.productData.propertyAc
          : "",
        propertyHeating: props.productData.propertyHeating
          ? props.productData.propertyHeating
          : "",
        propertyAmenities: props.productData.propertyAmenities
          ? props.productData.propertyAmenities
          : null,
        propertyLeaseLength: props.productData.propertyLeaseLength
          ? props.productData.propertyLeaseLength
          : "",
        propertyCatFriendly: props.productData.propertyCatFriendly
          ? props.productData.propertyCatFriendly
          : null,
        propertyDogFriendly: props.productData.propertyDogFriendly
          ? props.productData.propertyDogFriendly
          : null,
        homeLocation: {
          city: props.productData.city ? props.productData.city : null,
          country: props.productData.country ? props.productData.country : null,
          countryCode: props.productData.countryCode
            ? props.productData.countryCode
            : null,
          currency: props.productData.currencyCode
            ? props.productData.currencyCode
            : null,
          latitude: props.productData.latitude
            ? props.productData.latitude
            : null,
          longitude: props.productData.longitude
            ? props.productData.longitude
            : null,
          readableLocation: props.productData.readableLocation
            ? props.productData.readableLocation
            : null,
          state: props.productData.state ? props.productData.state : null,
          zip: props.productData.zip ? props.productData.zip : null,
        },
      };
    }
  }

  handleChange = (event, type) => {
    const digits_only = (string) =>
      [...string].every((c) => "0123456789".includes(c));
    if (type === "sale_or_rent") {
      if (event.target.value === "for_sale")
        this.setState({
          propertyForRent: false,
          propertyForSale: true,
          isHomesForSaleOrRentNull: false,
          propertyDogFriendly: false,
          propertyCatFriendly: false,
          propertyLeaseLength: "",
        });
      else
        this.setState({
          propertyForRent: true,
          propertyForSale: false,
          isHomesForSaleOrRentNull: false,
        });
    }
    if (type === "propertyType")
      this.setState({
        propertyType: event.target.value,
        isPropertyTypeNull: false,
      });
    if (type === "propertyBedrooms") {
      if (!digits_only(event.target.value)) {
        this.setState({
          propertyBedrooms: event.target.value,
          isBedroomsNull: true,
        });
      } else {
        this.setState({
          propertyBedrooms: event.target.value,
          isBedroomsNull: false,
        });
      }
    }
    if (type === "propertyBathrooms") {
      if (!digits_only(event.target.value)) {
        this.setState({
          propertyBathrooms: event.target.value,
          isBathroomsNull: true,
        });
      } else {
        this.setState({
          propertyBathrooms: event.target.value,
          isBathroomsNull: false,
        });
      }
    }
    if (type === "propertyLeaseLength")
      this.setState({
        propertyLeaseLength: event.target.value,
      });
    if (type === "propertyArea") {
      if (
        parseInt(event.target.value) === 0 ||
        !digits_only(event.target.value)
      ) {
        this.setState({
          propertyArea: event.target.value,
          isPropertyAreaNegativeValue: true,
        });
      } else {
        this.setState({
          propertyArea: event.target.value,
          isPropertyAreaNegativeValue: false,
        });
      }
    }
    if (type === "propertyLotSize") {
      if (
        parseInt(event.target.value) === 0 ||
        !digits_only(event.target.value)
      ) {
        this.setState({
          propertyLotSize: event.target.value,
          isLotSizeNegativeValue: true,
        });
      } else {
        this.setState({
          propertyLotSize: event.target.value,
          isLotSizeNegativeValue: false,
        });
      }
    }
    if (type === "propertyLaundry")
      this.setState({
        propertyLaundry: event.target.value,
      });
    if (type === "propertyParking")
      this.setState({
        propertyParking: event.target.value,
      });
    if (type === "propertyYearBuilt")
      this.setState({
        propertyYearBuilt: event.target.value,
      });
    if (type === "propertyYearRenovated")
      this.setState({
        propertyYearRenovated: event.target.value,
      });
    if (type === "propertyAc")
      this.setState({
        propertyAc: event.target.value,
      });
    if (type === "propertyHeating")
      this.setState({
        propertyHeating: event.target.value,
      });
    if (type === "description")
      this.setState({
        description: event.target.value,
        isDescriptionNull: false,
      });
    if (type === "price") {
      if (
        parseInt(event.target.value) === 0 ||
        !digits_only(event.target.value)
      ) {
        this.setState({
          price: event.target.value,
          isPriceNull: true,
        });
      } else {
        this.setState({
          price: event.target.value,
          isPriceNull: false,
        });
      }
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleChangeDate = (newDate) => {
    this.setState({
      dateAvailableTimestamp: new Date(
        new Date(newDate).toDateString()
      ).getTime(),
    });
  };

  handleChangeSwitch = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  changeCoverIndex = (value) => {
    this.setState({
      coverImageIndex: 0,
    });

    var imageUrl1 = this.state.imageUrl1;
    var currentImage = this.state.currentImagesURL[value];
    var currentImage1 = this.state.currentImagesURL[0];
    var currentImagesURL = this.state.currentImagesURL;

    switch (value) {
      case 0:
        break;
      case 1:
        var imageUrl2 = this.state.imageUrl2;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          imageUrl2: imageUrl1,
          imageUrl1: imageUrl2,
        });

        break;
      case 2:
        var imageUrl3 = this.state.imageUrl3;

        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          imageUrl3: imageUrl1,
          imageUrl1: imageUrl3,
        });
        break;
      case 3:
        var imageUrl4 = this.state.imageUrl4;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          imageUrl4: imageUrl1,
          imageUrl1: imageUrl4,
        });
        break;
      case 4:
        var imageUrl5 = this.state.imageUrl5;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          imageUrl5: imageUrl1,
          imageUrl1: imageUrl5,
        });
        break;
      default:
        break;
    }
  };

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "images":
        switch (value) {
          case 0:
            this.setState({
              imageUrl1: "",
            });
            for (let i = 2; i <= 5; i++) {
              if (i === 2) {
                if (this.state.imageUrl2 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl2,
                    imageUrl2: "",
                  });
                  break;
                }
              } else if (i === 3) {
                if (this.state.imageUrl3 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl3,
                    imageUrl3: "",
                  });
                  break;
                }
              } else if (i === 4) {
                if (this.state.imageUrl4 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl4,
                    imageUrl4: "",
                  });
                  break;
                }
              } else {
                if (this.state.imageUrl5 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl5,
                    imageUrl5: "",
                  });
                  break;
                }
              }
            }
            break;
          case 1:
            this.setState({
              imageUrl2: "",
            });
            break;
          case 2:
            this.setState({
              imageUrl3: "",
            });
            break;
          case 3:
            this.setState({
              imageUrl4: "",
            });
            break;
          case 4:
            this.setState({
              imageUrl5: "",
            });
            break;
          default:
            break;
        }
        this.state.currentImagesURL.splice(value, 1);

        break;
      case "image":
        switch (value.index) {
          case 0:
            this.setState({
              imageUrl1: value.url,
              isImageUrlNull: false,
            });
            break;
          case 1:
            this.setState({
              imageUrl2: value.url,
            });
            break;
          case 2:
            this.setState({
              imageUrl3: value.url,
            });
            break;
          case 3:
            this.setState({
              imageUrl4: value.url,
            });
            break;
          case 4:
            this.setState({
              imageUrl5: value.url,
            });
            break;
          default:
            break;
        }

        break;
      case "coverImageIndex":
        this.changeCoverIndex(value);
        break;
      case "map":
        this.setState({
          homeLocation: value,
        });
        break;
      default:
        break;
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      isOpenSnackbar: false,
    });
  };

  onHandleChangeImage = (type, value) => {
    this.handleChangeValue(type, value);
  };

  handleOpenAmenitiesDialog = () => {
    this.setState({
      isOpenAmenitiesDialog: true,
    });
  };

  handleCloseAmenitiesDialog = () => {
    this.setState({
      isOpenAmenitiesDialog: false,
    });
  };

  handleOpenDateDialog = () => {
    this.setState({
      isOpenDateDialog: true,
    });
  };

  handleCloseDateDialog = (e, type) => {
    if (type === "done")
      this.setState({
        isOpenDateDialog: false,
        dateAvailableTimestamp: new Date(
          this.state.defaultValueDateCalendar.toLocaleDateString()
        ).getTime(),
      });
    else
      this.setState({
        isOpenDateDialog: false,
      });
  };

  handleAddAmenities = (e, amenitie) => {
    this.state.selectedAmenities.push(amenitie);
  };

  handleRemoveAmenitiesItem = (e, amenitieRemoved) => {
    const newSelectedAmenities = this.state.selectedAmenities.filter(
      (amenitie) => amenitie !== amenitieRemoved
    );
    this.setState({
      selectedAmenities: newSelectedAmenities,
    });
  };

  handleSubmit = () => {
    if (
      (!this.state.propertyForRent && !this.state.propertyForSale) ||
      !this.state.propertyType ||
      !this.state.propertyBathrooms ||
      !this.state.propertyBedrooms ||
      !this.state.imageUrl1 ||
      !this.state.price ||
      (this.state.price && parseInt(this.state.price) <= 0) ||
      this.state.isPropertyAreaNegativeValue ||
      this.state.isLotSizeNegativeValue
    ) {
      this.setState({
        isOpenSnackbar: true,
      });
      if (!this.state.propertyForRent && !this.state.propertyForSale) {
        this.setState({
          isHomesForSaleOrRentNull: true,
        });
      }
      if (!this.state.propertyType) {
        this.setState({
          isPropertyTypeNull: true,
        });
      }
      if (!this.state.propertyBathrooms) {
        this.setState({
          isBathroomsNull: true,
        });
      }
      if (!this.state.propertyBedrooms) {
        this.setState({
          isBedroomsNull: true,
        });
      }
      if (!this.state.imageUrl1) {
        this.setState({
          isImageUrlNull: true,
        });
      }
      if (
        !this.state.price ||
        (this.state.price && parseInt(this.state.price) <= 0)
      ) {
        this.setState({
          isPriceNull: true,
        });
      }
    } else {
      this.setState({
        disabled: true,
      });
      var propertyAreaUnit =
        this.state.homeLocation.countryCode === "US"
          ? "Square feet"
          : "Square meter";
      var propertyLotSizeUnit =
        this.state.homeLocation.countryCode === "US" ? "Acres" : "Hectares";
      var loggingProduct = {
        title:
          this.state.propertyForSale === true
            ? this.state.propertyArea
              ? this.state.propertyType +
                " For Sale " +
                this.state.propertyBedrooms +
                " bedrooms " +
                this.state.propertyBathrooms +
                " bathrooms Property Area " +
                this.state.propertyArea +
                " " +
                propertyAreaUnit
              : this.state.propertyType +
                " For Sale " +
                this.state.propertyBedrooms +
                " bedrooms " +
                this.state.propertyBathrooms +
                " bathrooms"
            : this.state.propertyArea
            ? this.state.propertyType +
              " For Rent " +
              this.state.propertyBedrooms +
              " bedrooms " +
              this.state.propertyBathrooms +
              " bathrooms Property Area " +
              this.state.propertyArea +
              " " +
              propertyAreaUnit
            : this.state.propertyType +
              " For Rent " +
              this.state.propertyBedrooms +
              " bedrooms " +
              this.state.propertyBathrooms +
              " bathrooms",
        category: this.props.homesCategory,
      };

      var tags = [];
      tags.push(this.state.propertyType.toString());
      tags.push(this.state.propertyBedrooms.toString() + " Bedrooms");
      tags.push(this.state.propertyBathrooms.toString() + " Bathrooms");
      if (this.state.propertyArea)
        tags.push(
          "Property Area " +
            this.state.propertyArea.toString() +
            " " +
            propertyAreaUnit.toString()
        );
      if (this.state.propertyLotSize)
        tags.push(
          "Lot size " +
            this.state.propertyLotSize.toString() +
            " " +
            propertyLotSizeUnit.toString()
        );
      if (this.state.propertyLaundry)
        tags.push("Laundry type " + this.state.propertyLaundry.toString());
      if (this.state.propertyParking)
        tags.push("Parking type " + this.state.propertyParking.toString());
      if (this.state.propertyAc)
        tags.push("Air conditioning type " + this.state.propertyAc.toString());
      if (this.state.propertyYearBuilt)
        tags.push("Year built " + this.state.propertyYearBuilt.toString());
      if (this.state.propertyYearRenovated)
        tags.push(
          "Year renovated " + this.state.propertyYearRenovated.toString()
        );
      if (this.state.propertyHeating)
        tags.push("Heating type " + this.state.propertyHeating.toString());
      if (
        this.state.propertyCatFriendly !== null &&
        this.state.propertyCatFriendly !== false
      )
        tags.push("Cat friendly");
      if (
        this.state.propertyDogFriendly !== null &&
        this.state.propertyDogFriendly !== false
      )
        tags.push("Dog friendly");
      if (this.state.propertyLeaseLength)
        tags.push("Lease length " + this.state.propertyLeaseLength.toString());
      if (this.state.propertyAddress)
        tags.push("Property address " + this.state.propertyAddress.toString());

      if (this.props.type === "add") {
        if (this.state.propertyForSale === true) {
          const updateHome = {
            imageUrl1: this.state.imageUrl1,
            imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
            imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
            imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
            imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
            title:
              this.state.propertyForSale === true
                ? this.state.propertyArea
                  ? this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms Property Area " +
                    this.state.propertyArea +
                    " " +
                    propertyAreaUnit
                  : this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms"
                : this.state.propertyArea
                ? this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms Property Area " +
                  this.state.propertyArea +
                  " " +
                  propertyAreaUnit
                : this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms",
            propertyLotSize: this.state.propertyLotSize
              ? parseInt(this.state.propertyLotSize)
              : null,
            propertyLotSizeUnit: propertyLotSizeUnit,
            propertyParking: this.state.propertyParking
              ? this.state.propertyParking
              : null,
            propertyType: this.state.propertyType,
            propertyYearBuilt: this.state.propertyYearBuilt
              ? this.state.propertyYearBuilt
              : null,
            rating: 3.5,
            condition: this.state.condition,
            conditionStatus: this.state.conditionStatus,
            tags: tags,
            price: parseInt(this.state.price),
            formattedPrice: this.state.price.toString(),
            propertyAc: this.state.propertyAc ? this.state.propertyAc : null,
            propertyAddress: this.state.homeLocation
              ? this.state.homeLocation.readableLocation
              : null,
            propertyAmenities: this.state.selectedAmenities
              ? this.state.selectedAmenities
              : null,
            propertyArea: this.state.propertyArea
              ? parseInt(this.state.propertyArea)
              : null,
            propertyAreaUnit: propertyAreaUnit,
            propertyBathrooms: parseInt(this.state.propertyBathrooms),
            propertyBedrooms: parseInt(this.state.propertyBedrooms),
            propertyCatFriendly: this.state.propertyCatFriendly,
            propertyDogFriendly: this.state.propertyDogFriendly,
            propertyForRent: this.state.propertyForRent
              ? this.state.propertyForRent
              : false,
            propertyForSale: this.state.propertyForSale
              ? this.state.propertyForSale
              : false,
            propertyHeating: this.state.propertyHeating
              ? this.state.propertyHeating
              : null,
            propertyLaundry: this.state.propertyLaundry
              ? this.state.propertyLaundry
              : null,
            propertyLocation: this.state.homeLocation
              ? this.state.homeLocation
              : null,
            description: !isEmptyOrSpaces(this.state.description)
              ? this.state.description
              : null,
            category: this.props.homesCategory,
            categoryId: this.props.homesCategory.categoryId,
            isSoldOnBingoDeal: false,
            isFoodItem: false,
            isBoosted: false,
            currencyCode: this.state.currencyCode,
            impressions: 0,
            clicks: 0,
            boostTimestamp: 0,
            isActive: true,
            isUsedProduct: true,
            platform: "web",
            firmOnPrice: false,
            seller: this.props.profile,
            sellerId: this.props.profile.id,
            ...this.state.homeLocation,
          };

          this.props.createProduct(updateHome);
          this.props.postLogging(loggingProduct);
          this.props.purchaseLogging(updateHome);
          this.props.purchaseFacebookPixel(loggingProduct);
          this.props.productPostFacebookPixel(loggingProduct);
        } else {
          const updateHome = {
            imageUrl1: this.state.imageUrl1,
            imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
            imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
            imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
            imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
            dateAvailableTimestamp: this.state.dateAvailableTimestamp,
            title:
              this.state.propertyForSale === true
                ? this.state.propertyArea
                  ? this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms Property Area " +
                    this.state.propertyArea +
                    " " +
                    propertyAreaUnit
                  : this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms"
                : this.state.propertyArea
                ? this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms Property Area " +
                  this.state.propertyArea +
                  " " +
                  propertyAreaUnit
                : this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms",
            propertyLotSize: this.state.propertyLotSize
              ? parseInt(this.state.propertyLotSize)
              : null,
            propertyLotSizeUnit: propertyLotSizeUnit,
            propertyParking: this.state.propertyParking
              ? this.state.propertyParking
              : null,
            propertyType: this.state.propertyType,
            propertyYearBuilt: this.state.propertyYearBuilt
              ? this.state.propertyYearBuilt
              : null,
            rating: 3.5,
            condition: this.state.condition,
            conditionStatus: this.state.conditionStatus,
            tags: tags,
            price: parseInt(this.state.price),
            formattedPrice: this.state.price.toString(),
            propertyAc: this.state.propertyAc ? this.state.propertyAc : null,
            propertyAddress: this.state.homeLocation
              ? this.state.homeLocation.readableLocation
              : null,
            propertyAmenities: this.state.selectedAmenities
              ? this.state.selectedAmenities
              : null,
            propertyArea: this.state.propertyArea
              ? parseInt(this.state.propertyArea)
              : null,
            propertyAreaUnit: propertyAreaUnit,
            propertyBathrooms: parseInt(this.state.propertyBathrooms),
            propertyBedrooms: parseInt(this.state.propertyBedrooms),
            propertyCatFriendly: this.state.propertyCatFriendly,
            propertyDogFriendly: this.state.propertyDogFriendly,
            propertyForRent: this.state.propertyForRent
              ? this.state.propertyForRent
              : false,
            propertyForSale: this.state.propertyForSale
              ? this.state.propertyForSale
              : false,
            propertyHeating: this.state.propertyHeating
              ? this.state.propertyHeating
              : null,
            propertyLeaseLength: this.state.propertyLeaseLength
              ? this.state.propertyLeaseLength
              : null,
            propertyLaundry: this.state.propertyLaundry
              ? this.state.propertyLaundry
              : null,
            propertyYearRenovated: this.state.propertyYearRenovated
              ? this.state.propertyYearRenovated
              : null,
            propertyLocation: this.state.homeLocation
              ? this.state.homeLocation
              : null,
            description: !isEmptyOrSpaces(this.state.description)
              ? this.state.description
              : null,
            category: this.props.homesCategory,
            categoryId: this.props.homesCategory.categoryId,
            isSoldOnBingoDeal: false,
            isFoodItem: false,
            isBoosted: false,
            currencyCode: this.state.currencyCode,
            impressions: 0,
            clicks: 0,
            boostTimestamp: 0,
            isActive: true,
            isUsedProduct: true,
            platform: "web",
            firmOnPrice: false,
            seller: this.props.profile,
            sellerId: this.props.profile.id,
            ...this.state.homeLocation,
          };
          this.props.createProduct(updateHome);
          this.props.postLogging(loggingProduct);
          this.props.purchaseLogging(updateHome);
          this.props.purchaseFacebookPixel(loggingProduct);
          this.props.productPostFacebookPixel(loggingProduct);
        }
      } else {
        if (this.state.propertyForSale === true) {
          const updateHome = {
            imageUrl1: this.state.imageUrl1,
            imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
            imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
            imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
            imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
            id: this.state.productId,
            title:
              this.state.propertyForSale === true
                ? this.state.propertyArea
                  ? this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms Property Area " +
                    this.state.propertyArea +
                    " " +
                    propertyAreaUnit
                  : this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms"
                : this.state.propertyArea
                ? this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms Property Area " +
                  this.state.propertyArea +
                  " " +
                  propertyAreaUnit
                : this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms",
            propertyLotSize: this.state.propertyLotSize
              ? parseInt(this.state.propertyLotSize)
              : null,
            propertyLotSizeUnit: propertyLotSizeUnit,
            propertyParking: this.state.propertyParking
              ? this.state.propertyParking
              : null,
            propertyType: this.state.propertyType,
            propertyYearBuilt: this.state.propertyYearBuilt
              ? this.state.propertyYearBuilt
              : null,
            rating: 3.5,
            condition: this.state.condition,
            conditionStatus: this.state.conditionStatus,
            tags: tags,
            price: parseInt(this.state.price),
            formattedPrice: this.state.price.toString(),
            propertyAc: this.state.propertyAc ? this.state.propertyAc : null,
            propertyAddress: this.state.homeLocation
              ? this.state.homeLocation.readableLocation
              : null,
            propertyAmenities: this.state.selectedAmenities
              ? this.state.selectedAmenities
              : null,
            propertyArea: this.state.propertyArea
              ? parseInt(this.state.propertyArea)
              : null,
            propertyAreaUnit: propertyAreaUnit,
            propertyBathrooms: parseInt(this.state.propertyBathrooms),
            propertyBedrooms: parseInt(this.state.propertyBedrooms),
            propertyCatFriendly: this.state.propertyCatFriendly,
            propertyDogFriendly: this.state.propertyDogFriendly,
            propertyLeaseLength: this.state.propertyLeaseLength,
            propertyForRent: this.state.propertyForRent
              ? this.state.propertyForRent
              : false,
            propertyForSale: this.state.propertyForSale
              ? this.state.propertyForSale
              : false,
            propertyHeating: this.state.propertyHeating
              ? this.state.propertyHeating
              : null,
            propertyLaundry: this.state.propertyLaundry
              ? this.state.propertyLaundry
              : null,
            propertyLocation: this.state.homeLocation
              ? this.state.homeLocation
              : null,
            description: !isEmptyOrSpaces(this.state.description)
              ? this.state.description
              : null,
            category: this.props.homesCategory,
            categoryId: this.props.homesCategory.categoryId,
            isSoldOnBingoDeal: false,
            isFoodItem: false,
            isBoosted: false,
            currencyCode: this.state.currencyCode,
            impressions: 0,
            clicks: 0,
            boostTimestamp: 0,
            isActive: true,
            isUsedProduct: true,
            platform: "web",
            firmOnPrice: false,
            seller: this.props.profile,
            sellerId: this.props.profile.id,
            ...this.state.homeLocation,
          };
          this.props.editProduct(updateHome);
        } else {
          const updateHome = {
            imageUrl1: this.state.imageUrl1,
            imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
            imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
            imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
            imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
            id: this.state.productId,
            dateAvailableTimestamp: this.state.dateAvailableTimestamp,
            title:
              this.state.propertyForSale === true
                ? this.state.propertyArea
                  ? this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms Property Area " +
                    this.state.propertyArea +
                    " " +
                    propertyAreaUnit
                  : this.state.propertyType +
                    " For Sale " +
                    this.state.propertyBedrooms +
                    " bedrooms " +
                    this.state.propertyBathrooms +
                    " bathrooms"
                : this.state.propertyArea
                ? this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms Property Area " +
                  this.state.propertyArea +
                  " " +
                  propertyAreaUnit
                : this.state.propertyType +
                  " For Rent " +
                  this.state.propertyBedrooms +
                  " bedrooms " +
                  this.state.propertyBathrooms +
                  " bathrooms",
            propertyLotSize: this.state.propertyLotSize
              ? parseInt(this.state.propertyLotSize)
              : null,
            propertyLotSizeUnit: propertyLotSizeUnit,
            propertyParking: this.state.propertyParking
              ? this.state.propertyParking
              : null,
            propertyType: this.state.propertyType,
            propertyYearBuilt: this.state.propertyYearBuilt
              ? this.state.propertyYearBuilt
              : null,
            rating: 3.5,
            condition: this.state.condition,
            conditionStatus: this.state.conditionStatus,
            tags: tags,
            price: parseInt(this.state.price),
            formattedPrice: this.state.price.toString(),
            propertyAc: this.state.propertyAc ? this.state.propertyAc : null,
            propertyAddress: this.state.homeLocation
              ? this.state.homeLocation.readableLocation
              : null,
            propertyAmenities: this.state.selectedAmenities
              ? this.state.selectedAmenities
              : null,
            propertyArea: this.state.propertyArea
              ? parseInt(this.state.propertyArea)
              : null,
            propertyAreaUnit: propertyAreaUnit,
            propertyBathrooms: parseInt(this.state.propertyBathrooms),
            propertyBedrooms: parseInt(this.state.propertyBedrooms),
            propertyCatFriendly: this.state.propertyCatFriendly,
            propertyDogFriendly: this.state.propertyDogFriendly,
            propertyForRent: this.state.propertyForRent
              ? this.state.propertyForRent
              : false,
            propertyForSale: this.state.propertyForSale
              ? this.state.propertyForSale
              : false,
            propertyHeating: this.state.propertyHeating
              ? this.state.propertyHeating
              : null,
            propertyLeaseLength: this.state.propertyLeaseLength
              ? this.state.propertyLeaseLength
              : null,
            propertyLaundry: this.state.propertyLaundry
              ? this.state.propertyLaundry
              : null,
            propertyYearRenovated: this.state.propertyYearRenovated
              ? this.state.propertyYearRenovated
              : null,
            propertyLocation: this.state.homeLocation
              ? this.state.homeLocation
              : null,
            description: !isEmptyOrSpaces(this.state.description)
              ? this.state.description
              : null,
            category: this.props.homesCategory,
            categoryId: this.props.homesCategory.categoryId,
            isSoldOnBingoDeal: false,
            isFoodItem: false,
            isBoosted: false,
            currencyCode: this.state.currencyCode,
            impressions: 0,
            clicks: 0,
            boostTimestamp: 0,
            isActive: true,
            isUsedProduct: true,
            platform: "web",
            firmOnPrice: false,
            seller: this.props.profile,
            sellerId: this.props.profile.id,
            ...this.state.homeLocation,
          };
          this.props.editProduct(updateHome);
        }
      }
    }
  };

  render() {
    const { classes, handleClose, intl, usedProductReducer } = this.props;
    const translatedCondition = translateConditionStatus(
      this.state.conditionStatus,
      intl
    );
    if (usedProductReducer.productState === "CREATE_PRODUCT") {
      if (this.props.type === "add")
        window.location.href =
          constantsConfig.mainUrl +
          "product/" +
          usedProductReducer.productId +
          "/Open/BoostProduct";
      else
        window.location.href =
          constantsConfig.mainUrl + "product/" + this.state.productId;
    }
    const renderCalendar = (
      <Dialog
        open={this.state.isOpenDateDialog}
        onClose={this.handleCloseDateDialog}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle>
          {intl.formatMessage({
            id: "date_available",
          })}
        </DialogTitle>
        <DialogContent>
          <Calendar onChange={this.handleChangeDate} calendarType="Hebrew" />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={(e) => this.handleCloseDateDialog(e, "cancel")}
          >
            {intl.formatMessage({
              id: "cancel",
            })}
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={(e) => this.handleCloseDateDialog(e, "done")}
          >
            {intl.formatMessage({
              id: "done",
            })}
          </Button>
        </DialogActions>
      </Dialog>
    );
    const renderAmenities = (
      <Dialog
        onClose={this.handleCloseAmenitiesDialog}
        open={this.state.isOpenAmenitiesDialog}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={"sm"}
      >
        <div className={classes.dialogsBackground}>
          <DialogTitle id="simple-dialog-title" variant="h5" color="primary">
            {intl.formatMessage({
              id: "amenities",
            })}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              {this.state.amenities &&
                this.state.amenities.map((amenitie) => {
                  const amenitieTranslated = translateAmenities(amenitie, intl);
                  return (
                    <AmenitiesChipsDialog
                      key={amenitie}
                      amenitie={amenitie}
                      amenitieTranslated={amenitieTranslated}
                      selectedAmenities={this.state.selectedAmenities}
                      handleAddAmenities={this.handleAddAmenities}
                      handleRemoveAmenitiesItem={this.handleRemoveAmenitiesItem}
                    />
                  );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCloseAmenitiesDialog}
            >
              {intl.formatMessage({
                id: "done",
              })}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
    return (
      <React.Fragment>
        <Snackbar
          open={this.state.isOpenSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert onClose={this.handleCloseSnackbar} severity="error">
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "fill_required_fields",
              })}
            </Typography>
          </Alert>
        </Snackbar>
        <div className={classes.dialogsBackground}>
          <CloseIcon className={classes.xIcon} onClick={handleClose} />
        </div>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {this.props.type === "add"
            ? intl.formatMessage({
                id: "add_home",
              })
            : intl.formatMessage({
                id: "edit_home",
              })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              <MultipleImageUpload
                handleRemoveTags={this.handleRemoveTags}
                onHandleChangeImage={this.onHandleChangeImage}
                currentImagesURL={this.state.currentImagesURL}
                coverImageIndex={this.state.coverImageIndex}
              />
              {this.state.isImageUrlNull ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "upload_a_photo",
                  })}
                </Typography>
              ) : null}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isHomesForSaleOrRentNull ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "create_home_title",
                  })}
                  className={classes.textField}
                  defaultValue={
                    this.props.type === "edit"
                      ? this.state.propertyForSale
                        ? "for_sale"
                        : "for_rent"
                      : ""
                  }
                  onChange={(e) => this.handleChange(e, "sale_or_rent")}
                >
                  <MenuItem value="for_sale">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "for_sale",
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="for_rent">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "for_rent",
                      })}
                    </Typography>
                  </MenuItem>
                </TextField>
              ) : (
                <TextField
                  error
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "create_home_title",
                  })}
                  className={classes.textField}
                  defaultValue={
                    this.props.type === "edit"
                      ? this.state.propertyForSale
                        ? "for_sale"
                        : "for_rent"
                      : ""
                  }
                  onChange={(e) => this.handleChange(e, "sale_or_rent")}
                >
                  <MenuItem value="for_sale">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "for_sale",
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="for_rent">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "for_rent",
                      })}
                    </Typography>
                  </MenuItem>
                </TextField>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isPropertyTypeNull ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "property_type",
                  })}
                  className={classes.textField}
                  value={this.state.propertyType}
                  onChange={(e) => this.handleChange(e, "propertyType")}
                >
                  {this.state.propertyTypes.map((type) => {
                    const typeTranslated = translatepropertyTypes(type, intl);
                    return (
                      <MenuItem key={type} value={type}>
                        <Typography variant="body1">
                          {typeTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                <TextField
                  error
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "property_type",
                  })}
                  className={classes.textField}
                  value={this.state.propertyType}
                  onChange={(e) => this.handleChange(e, "propertyType")}
                >
                  {this.state.propertyTypes.map((type) => {
                    const typeTranslated = translatepropertyTypes(type, intl);
                    return (
                      <MenuItem key={type} value={type}>
                        <Typography variant="body1">
                          {typeTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isBedroomsNull ? (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyBedrooms")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "bedrooms",
                      })}
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyBedrooms}
                  type="number"
                  min={1}
                  required
                />
              ) : (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyBedrooms")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "bedrooms",
                      })}
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyBedrooms}
                  type="number"
                  min={1}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isBathroomsNull ? (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyBathrooms")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "bathrooms",
                      })}
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyBathrooms}
                  type="number"
                  min={1}
                  required
                />
              ) : (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyBathrooms")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "bathrooms",
                      })}
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyBathrooms}
                  type="number"
                  min={1}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isPropertyAreaNegativeValue ? (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyArea")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "property_area",
                      })}{" "}
                      (
                      {intl.formatMessage({
                        id: this.state.homeLocation
                          ? this.state.homeLocation.countryCode
                            ? this.state.homeLocation.countryCode === "US"
                              ? "square_feet"
                              : "square_meter"
                            : "square_feet"
                          : "square_feet",
                      })}
                      )
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyArea}
                  type="number"
                  min={1}
                  required
                />
              ) : (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyArea")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "property_area",
                      })}{" "}
                      (
                      {intl.formatMessage({
                        id: this.state.homeLocation
                          ? this.state.homeLocation.countryCode
                            ? this.state.homeLocation.countryCode === "US"
                              ? "square_feet"
                              : "square_meter"
                            : "square_feet"
                          : "square_feet",
                      })}
                      )
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyArea}
                  type="number"
                  min={1}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isLotSizeNegativeValue ? (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyLotSize")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "lot_size",
                      })}{" "}
                      (
                      {intl.formatMessage({
                        id: this.state.homeLocation
                          ? this.state.homeLocation.countryCode
                            ? this.state.homeLocation.countryCode === "US"
                              ? "square_feet"
                              : "square_meter"
                            : "square_feet"
                          : "square_feet",
                      })}
                      )
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyLotSize}
                  type="number"
                  min={1}
                  required
                />
              ) : (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "propertyLotSize")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "lot_size",
                      })}{" "}
                      (
                      {intl.formatMessage({
                        id: this.state.homeLocation
                          ? this.state.homeLocation.countryCode
                            ? this.state.homeLocation.countryCode === "US"
                              ? "square_feet"
                              : "square_meter"
                            : "square_feet"
                          : "square_feet",
                      })}
                      )
                    </InputAdornment>
                  }
                  defaultValue={this.state.propertyLotSize}
                  type="number"
                  min={1}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "laundry_type",
                })}
                className={classes.textField}
                value={this.state.propertyLaundry}
                onChange={(e) => this.handleChange(e, "propertyLaundry")}
              >
                {this.state.laundryTypes.map((type) => {
                  const typeTranslated = translateLaundryTypes(type, intl);
                  return (
                    <MenuItem key={type} value={type}>
                      <Typography variant="body1">{typeTranslated}</Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "parking_type",
                })}
                className={classes.textField}
                value={this.state.propertyParking}
                onChange={(e) => this.handleChange(e, "propertyParking")}
              >
                {this.state.parkingTypes.map((type) => {
                  const typeTranslated = translateParkingTypes(type, intl);
                  return (
                    <MenuItem key={type} value={type}>
                      <Typography variant="body1">{typeTranslated}</Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "year_built",
                })}
                className={classes.textField}
                value={this.state.propertyYearBuilt}
                onChange={(e) => this.handleChange(e, "propertyYearBuilt")}
              >
                {this.state.years.map((year) => {
                  return (
                    <MenuItem key={year} value={year}>
                      <Typography variant="body1">{year}</Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "year_renovated",
                })}
                className={classes.textField}
                value={this.state.propertyYearRenovated}
                onChange={(e) => this.handleChange(e, "propertyYearRenovated")}
              >
                {this.state.years.map((year) => {
                  return (
                    <MenuItem key={year} value={year}>
                      <Typography variant="body1">{year}</Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "air_cond_type",
                })}
                className={classes.textField}
                value={this.state.propertyAc}
                onChange={(e) => this.handleChange(e, "propertyAc")}
              >
                {this.state.airConditioningTypes.map((type) => {
                  const typeTranslated = translateAirConditioningTypes(
                    type,
                    intl
                  );
                  return (
                    <MenuItem key={type} value={type}>
                      <Typography variant="body1">{typeTranslated}</Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {this.state.propertyForRent ? (
              <Grid item className={classes.gridItem}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={intl.formatMessage({
                      id: "date_available",
                    })}
                    openTo="day"
                    minDate={Date.now()}
                    views={["year", "month", "day"]}
                    value={
                      this.state.dateAvailableTimestamp
                        ? new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(this.state.dateAvailableTimestamp)
                        : null
                    }
                    onChange={(newDate) => this.handleChangeDate(newDate)}
                    renderInput={(params) => (
                      <TextField {...params} style={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}
            <Grid item className={classes.gridItem}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
                className={classes.amenitiesButton}
                onClick={this.handleOpenAmenitiesDialog}
              >
                {intl.formatMessage({
                  id: "amenities",
                })}
              </Button>
            </Grid>
            {this.state.selectedAmenities.length > 0 ? (
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  {this.state.selectedAmenities &&
                    this.state.selectedAmenities.map((amenitie) => {
                      const amenitieTranslated = translateAmenities(
                        amenitie,
                        intl
                      );
                      return (
                        <Grid item key={amenitie}>
                          <Chip
                            label={
                              <Typography variant="body1">
                                {amenitieTranslated}
                              </Typography>
                            }
                            clickable
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            ) : null}
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "heating_type",
                })}
                className={classes.textField}
                value={this.state.propertyHeating}
                onChange={(e) => this.handleChange(e, "propertyHeating")}
              >
                {this.state.heatingTypes.map((type) => {
                  const typeTranslated = translateHeatingTypes(type, intl);
                  return (
                    <MenuItem key={type} value={type}>
                      <Typography variant="body1">{typeTranslated}</Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {this.state.propertyForRent ? (
              <Grid item className={classes.gridItem}>
                <TextField
                  select
                  variant="standard"
                  label={intl.formatMessage({
                    id: "lease_length",
                  })}
                  className={classes.textField}
                  value={this.state.propertyLeaseLength}
                  onChange={(e) => this.handleChange(e, "propertyLeaseLength")}
                >
                  {this.state.leaseLengths.map((length) => {
                    const lengthTranslated = translateLeaseLength(length, intl);
                    return (
                      <MenuItem key={length} value={length}>
                        <Typography variant="body1">
                          {lengthTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            ) : null}
            {this.state.propertyForRent ? (
              <Grid item container className={classes.gridItem}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {intl.formatMessage({
                      id: "cat_friendly",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Switch
                    checked={this.state.propertyCatFriendly}
                    onChange={this.handleChangeSwitch}
                    color="primary"
                    name="propertyCatFriendly"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
              </Grid>
            ) : null}
            {this.state.propertyForRent ? (
              <Grid item container className={classes.gridItem}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {intl.formatMessage({
                      id: "dog_friendly",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Switch
                    checked={this.state.propertyDogFriendly}
                    onChange={this.handleChangeSwitch}
                    color="primary"
                    name="propertyDogFriendly"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item container className={classes.gridItem}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  {intl.formatMessage({
                    id: "product_condition",
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  {translatedCondition}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="description"
                label={intl.formatMessage({
                  id: "product_description",
                })}
                multiline
                rows={4}
                className={classes.textField}
                value={this.state.description}
                onChange={(e) => this.handleChange(e, "description")}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isPriceNull ? (
                <Input
                  autoFocus
                  placeholder="Price"
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "price")}
                  startAdornment={
                    <InputAdornment position="start">
                      {this.state.currencyCode}
                    </InputAdornment>
                  }
                  defaultValue={this.state.price}
                  type="number"
                  min={0}
                  required
                  error={this.state.errorInPrice}
                />
              ) : (
                <Input
                  autoFocus
                  placeholder="Price"
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "price")}
                  startAdornment={
                    <InputAdornment position="start">
                      {this.state.currencyCode}
                    </InputAdornment>
                  }
                  defaultValue={this.state.price}
                  type="number"
                  min={0}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <MapComponent
                height="200px"
                zoom={15}
                onHandleChangeValue={this.handleChangeValue}
                addProductData={
                  this.props.type === "add"
                    ? this.props.locationConfig
                    : this.state.homeLocation
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {!this.state.disabled ? (
            <Button
              className={classes.postButton}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              {this.props.type === "add"
                ? intl.formatMessage({
                    id: "product_post",
                  })
                : intl.formatMessage({
                    id: "edit",
                  })}
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
        {renderAmenities}
        {renderCalendar}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    usedProductReducer: state.ProductReducer,
    homesCategory: state.firestore.ordered.homesCategory
      ? state.firestore.ordered.homesCategory[0]
      : null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
    editProduct: (product) => dispatch(editProduct(product)),
    postLogging: (product) => dispatch(postLogging(product)),
    purchaseLogging: (product) => dispatch(purchaseLogging(product)),
    purchaseFacebookPixel: (product) =>
      dispatch(purchaseFacebookPixel(product)),
    productPostFacebookPixel: (product) =>
      dispatch(productPostFacebookPixel(product)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
      return [
        {
          collection: constantsConfig.collectionCategories,
          where: ["categoryRanking", "==", 1200],
          storeAs: "homesCategory",
        },
      ];
    })
  )(AddHome)
);
