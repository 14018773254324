// @flow
import { firebaseConfig } from "../../../config/FirebaseConfig";
import axios from "axios";

const submitToGoogle = async (imageUrl, processResult) => {
  try {
    let body = JSON.stringify({
      requests: [
        {
          features: [{ type: "LABEL_DETECTION" }],
          image: {
            source: {
              imageUri: imageUrl,
            },
          },
        },
      ],
    });
    axios
      .post(
        "https://vision.googleapis.com/v1/images:annotate?key=" +
          firebaseConfig.apiKey,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        processResult(res.data.responses);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};
export default submitToGoogle;
