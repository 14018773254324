// @flow
import firebase from 'firebase/compat/app';
import "firebase/functions";

export const deleteUsedProduct = (req) => {
  return new Promise((resolve, reject) => {
    var deleted = firebase.functions().httpsCallable("deleteUsedProduct");

    deleted(JSON.stringify(req))
      .then(function (result) {
        // Read result of the Cloud Function.
        resolve(result.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
