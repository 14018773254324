// @flow
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { Avatar } from '@mui/material';
import { constantsConfig } from '../../config/ConstantsConfig';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow:
      '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.20)'
  },

  typography: {
    fontWeight: 'bold'
  },
  actionRoot: {
    margin: '2px 2px 2px 2px',
    maxWidth: '300px',
    minWidth: '260px',
    height: 80
  },
  flex: { display: 'flex' },
  avatar: {
    height: '100%',
    width: '24%'
  },
  other: { width: '80%' },

  icons: {
    marginInlineEnd: 'auto'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }
}));

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();
  var thread = JSON.parse(props.messageInfo.data.threadJson);
  /* const { closeSnackbar } = useSnackbar();
  const handleDismiss = () => {
    closeSnackbar(props.id);
  };*/

  const handleClick = () => {
    window.location.href =
      constantsConfig.mainUrl + 'threads/' + props.messageInfo.data.tag;
  };
  var name =
    thread.receiverId === props.messageInfo.uid
      ? thread.senderName
      : thread.receiverName;

  var imageUrl =
    thread.receiverId === props.messageInfo.uid
      ? thread.senderPhoto
      : thread.receiverPhoto;

  var renderSnack = (
    <Card ref={ref} className={classes.card}>
      <CardActions onClick={handleClick} className={classes.actionRoot}>
        <Avatar className={classes.avatar} src={imageUrl} />

        <div className={classes.other}>
          <Typography className={classes.typography}>{name}</Typography>
          <div>{props.messageInfo.data.message}</div>
        </div>
      </CardActions>
    </Card>
  );
  return renderSnack;
});

export default SnackMessage;
