import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import firebase from "firebase/compat/app";
import { withStyles } from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MapComponent from "../other/MapComponent";
import { updateMessage } from "../../store/messages/NewMessagesAction";

const styles = (theme) => ({
  gridItem: {
    width: "100%",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
  },
});

class MeetingPlanMap extends Component {
  constructor(props) {
    super(props);
    // var meetingLocation = props.message.countryCode
    //   ? {
    //       address: props.message.address ? props.message.address : "",
    //       city: props.message.city ? props.message.city : "",
    //       country: props.message.country ? props.message.country : "",
    //       countryCode: props.message.countryCode
    //         ? props.message.countryCode
    //         : "",
    //       latitude: props.message.latitude ? props.message.latitude : "",
    //       longitude: props.message.longitude ? props.message.longitude : "",
    //       readableLocation: props.message.readableLocation
    //         ? props.message.readableLocation
    //         : "",
    //       state: props.message.state ? props.message.state : "",
    //     }
    //   : {};
    this.state = {
      planLocation: props.message.planLocation
        ? props.message.planLocation
        : null,
    };
  }

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "map":
        this.setState({
          planLocation: value,
        });
        break;

      //default value...
      default:
        break;
    }
  };

  handleSubmit = () => {
    var info = {
      messageDetails: {
        message: this.props.message,
        senderId: this.props.thread.senderId,
        receiverId: this.props.thread.receiverId,
        threadId: this.props.thread.threadId,
      },
      threadId: this.props.thread.threadId,
      messageId: this.props.message.id,
      planLocation: this.state.planLocation,
    };
    this.props.updateMessage(info);
  };

  render() {
    const { classes, loadingUpdateMessage, intl } = this.props;
    return (
      <React.Fragment>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {intl.formatMessage({
            id: "dialog_location_title",
          })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <MapComponent
            height="400px"
            zoom={15}
            onHandleChangeValue={this.handleChangeValue}
            addProductData={
              this.props.message.planLocation
                ? this.state.planLocation
                : this.props.locationConfig
            }
          />
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {loadingUpdateMessage === "loading" ? null : (
            <Button
              className={classes.postButton}
              color="primary"
              onClick={this.props.handleCloseLocationDialog}
            >
              {intl.formatMessage({
                id: "cancel",
              })}
            </Button>
          )}
          {loadingUpdateMessage !== "loading" ? (
            <Button
              className={classes.postButton}
              color="primary"
              onClick={this.handleSubmit}
            >
              {intl.formatMessage({
                id: "save",
              })}
            </Button>
          ) : (
            <Button color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loadingUpdateMessage: state.messages.loadingUpdateMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMessage: (info) => dispatch(updateMessage(info)),
  };
};

export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(MeetingPlanMap)
);
