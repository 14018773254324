// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './store/reducers/RootReducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore
} from 'redux-firestore';
import en from 'javascript-time-ago/locale/en';
import JavascriptTimeAgo from 'javascript-time-ago';
import { getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import fbConfig from './config/FirebaseConfig';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import SplashScreen from './components/other/SplashScreen';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import { ThemeProviderApp } from './theme/ThemeContext';
import { makeStyles } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight
    // backgroundColor: theme.palette.primary.background
  },
  lotties: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.background
  }
}));

const cache = createCache({
  key: 'css',
  prepend: true
});

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  const classes = useStyles();
  if (!isLoaded(auth)) {
    return (
      <div
        className={classes.lotties}
        style={{
          height: window.innerHeight
        }}
      >
        <SplashScreen />
      </div>
    );
  }
  return children;
}
JavascriptTimeAgo.locale(en);
const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fbConfig)
  )
);

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  attachAuthIsReady: true
};

const rrfProps = {
  firebase: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <CacheProvider value={cache}>
        <StylesProvider jss={jss}>
          <StyledEngineProvider injectFirst>
            <ThemeProviderApp>
              <AuthIsLoaded>
                <App />
              </AuthIsLoaded>
            </ThemeProviderApp>
          </StyledEngineProvider>
        </StylesProvider>
      </CacheProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);
