// @flow
const initState = {};

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_CLICK_TIMESTAMP_NOTIFICATION_SUCCESS':
      return { ...state };
    case 'UPDATE_CLICK_TIMESTAMP_NOTIFICATION_ERROR':
      return { ...state };
    default:
      return state;
  }
};

export default notificationsReducer;
