// @flow
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import "firebase/compat/functions";
import "firebase/compat/analytics";
//import ReactPixel from 'react-facebook-pixel';
export const isProductionBuild = true;
// Firebase Settings
export const firebaseConfig = isProductionBuild
  ? {
      apiKey: "AIzaSyDeKCEzkCGrgKFFFaP1xcYlagNTufq4Ano",
      authDomain: "listednearme.firebaseapp.com",
      databaseURL: "https://listednearme.firebaseio.com",
      projectId: "listednearme",
      storageBucket: "listednearme.appspot.com",
      messagingSenderId: "987191651199",
      appId: "1:987191651199:web:a6759a5287f33e1b42c84a",
      measurementId: "G-VSF1ZP92HZ",
    }
  : {
      apiKey: "AIzaSyAc4tKw2UlHMnJ59GTpztWUNb1e_m3ElwI",
      authDomain: "listednearmedev.firebaseapp.com",
      databaseURL: "https://listednearmedev.firebaseio.com",
      projectId: "listednearmedev",
      storageBucket: "listednearmedev.appspot.com",
      messagingSenderId: "628216024872",
      appId: "1:628216024872:web:78d153d5b256c473a94a1d",
      measurementId: "G-L5DHETRMNK",
    };

// Bugs Reporting
export const bugsnagApiKey = "f3c7f08685b8d9067e8618e9fa5a5c81";

export const stripeApiDev =
  "pk_test_51Iadl9B7vvXk7ob0ldcVsNpXeaN7TEfpdJGByupPcuhiPrUZ7n1Whx5HpNoHgnpYDzqvO9Nxuse5l7ASbzMtqL0A00wzmgdlya";
export const stripeApi =
  "pk_live_51IA2yrFl1I3W44elDJnO4NJnNjloMferlNbvJMmEdrgKTL9AUSigy7bVj6oNeeMwap4nRWnmvXsijDfe0XMVRlOF00luoTCsd0";

// Production vs development build
export const appVersion = "0.0.141";
export const ipKeyLcation = "741c551c4bba484abec40f4521363856";
//Production vs development Admin Id
export const AdminIdInProduction = "JSF0JnCiLDOH5O2PIi8HphIQH6D3";
export const SupportIdInProduction = "LD3XYAdC0wXLtpCf29QidaItPSM2";
export const AdminIdInDevelopment = "nXTeD4wPUFPSqZ6LIVZvK4YUG0p2";

export const Authorization = isProductionBuild
  ? "AAAA5dk1K38:APA91bFBlEtQqfYtxMGseKY9mO1DxBzPPnu-qGFddM8CFRw648odSM688mW6HnvKoKVJ_ELSsoUdq_Bg2Wv864Cs17ajrEb_AuOj2zgmiVxZ6bHSYF0jxUabQn1Kkc9VVBnOcPQJcDBa"
  : "AAAAkkSXzyg:APA91bHFaKScFknviWkOPa0DDElCEl44d-SWSiTA9TGI_f-h7XO6oy_x53FXJ6DH-xrrdA3k_HMCn4q2Fg3oARfJuZWsiMhrTO1AZUBjAySuRXJIzNQ-0q4SPUDKX71o50fi81wCsd-w";

export const vapidKey = isProductionBuild
  ? "BGvWMiCM72qT_3UiVtQJv6fgRd75_cnktD-KTtUs_qatJwUWqgtuRZm18OQfjHXAxOp8wPCiwsGgk-KQW5QE96g"
  : "BPKDtPUlmnP7SXAjz-afCBKUJWRy5ww5t0zqkgGvOyvCawqMCqmruNbL9mjMAXOyobgZGkLM5q0IkQXxOXIhlNY";
// Features Gatekeepers
//export const isAnalyticsEnabledInProduction = true;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();

export const auth = firebase.auth();
export const db = firebase.firestore();

export default firebase;
