// @flow
import { isProductionBuild } from './FirebaseConfig';
export var constantsConfig = {
  successUlr: '/successPayment',
  cancelUlr: '/cancelPayment',
  paymentBoost: [
    {
      days: 1,
      amount: 99,
      boostProductName: '1 day boost',
      boostProductId: 'prod_Iyw0ToIKWJzM75',
      boostPriceId: isProductionBuild
        ? 'price_1IMyW3Fl1I3W44el2z95Wvpu'
        : 'price_1IadoCB7vvXk7ob0RTrRc1rQ'
    },
    {
      days: 3,
      amount: 199,
      boostProductName: '3 day boost',
      boostProductId: 'prod_IywPCAwS8Ct2rA',
      boostPriceId: isProductionBuild
        ? 'price_1IMyWeFl1I3W44elZCpGiBe4'
        : 'price_1IadoCB7vvXk7ob0RTrRc1rQ'
    },

    {
      days: 7,
      amount: 399,
      boostProductName: '7 day boost',
      boostProductId: 'prod_IywPybXAypNiPr',
      boostPriceId: isProductionBuild
        ? 'price_1IMyWyFl1I3W44elbCBhdOid'
        : 'price_1IadoCB7vvXk7ob0RTrRc1rQ'
    }
  ],

  storage: 'BingoDeal',
  mainUrl: window.location.origin + '/',
  logoMainPage:
    'https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/images%2Flistednearme_logo.png?alt=media&token=aae4fdb0-5ae0-4b45-97b1-b6dbe2dc8451',
  logoListedNearMe:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F723ad229-1a0f-4c41-9697-a8d8b9f09ce7?alt=media&token=79bf724a-1794-4e8a-a9db-4a4928294817',
  logoBannerListedNearMe:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fsquare_logo_listednearme__3_.png?alt=media&token=5f7bc21b-18d6-462c-9935-f1eed09eb1b9',
  imgMainPage1:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/BingoDeal%2Fdownload%20(2).jfif?alt=media&token=4c54e008-6ed3-4be3-8dbc-9d1a3307ae2b',
  imgMainPage2:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/BingoDeal%2Fdownload.jfif?alt=media&token=adba70b3-d228-4ba9-a03c-c19b3b09d4a6',
  imgMainPage3:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/BingoDeal%2Fproduct_price.png?alt=media&token=1860aba2-db07-4510-b3a3-a87019c3fb17',
  imgMainPage4:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/BingoDeal%2Fdownload%20(1).jfif?alt=media&token=bc017e3b-ff25-4630-9dad-626c27748131',
  imgHandFreeze:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/BingoDeal%2Fhand-freeze.png?alt=media&token=6be8e078-decd-4135-b62e-9c029acc670e',
  imgAndroidIcon:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/BingoDeal%2Fandroid-icon-270x300.png?alt=media&token=db23f234-2c68-4c08-a718-430797b37b17',
  imgAppleIcon:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/images%2F505px-Apple_logo_black.svg.png?alt=media&token=723055e6-fed0-4eb3-bd37-09c23a12985e',
  imgBuy:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F1_buy.png?alt=media&token=135bae39-ec2d-43cb-be5c-df9915262a92',
  imgSell:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F2_sell.png?alt=media&token=08e47710-305a-49ec-9266-13ddcfe3b65d',
  imgShop:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F3_shop.png?alt=media&token=bdd3f114-70c4-43ed-b0b0-0191f7005831',
  imgShopList:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2FShops.png?alt=media&token=75ca0ff1-affa-41d7-be12-4f21838f313b',
  imgCreateNewProduct:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F4_create_new_product.png?alt=media&token=9f53e1a7-b5e3-4c04-9d39-5ef642bfba50',
  imgCreateNewShop:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2F5_create_new_shop.png?alt=media&token=2684fcf9-4eec-4334-b787-c66cb5502e05',
  blueSmartAppsLink: 'https://bluesmartapps.com/',
  privacyLink: 'https://listednearme.com/dynamicLinks/bjYi',
  privacyLinkArabic: 'https://listednearme.com/dynamicLinks/QYkB',
  termsLink: 'https://listednearme.com/dynamicLinks/4vDS',
  termsLinkArabic: 'https://listednearme.com/dynamicLinks/JTT6',
  termsofservice: 'https://listednearme.com/termsofservice.html',
  ImgPlaceholder:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/images%2Favatar-1577909_960_720.png?alt=media&token=b728edc9-c5d7-449a-b25d-7528ee084009',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.listednearme&hl=en_US',
  appStoreLink: 'https://apps.apple.com/us/app/id1527521661',
  markerIcon:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/marker.png?alt=media&token=be16d99d-d10f-4f2a-aec7-02ac04c91e41',
  markerHoverIcon:
    'https://firebasestorage.googleapis.com/v0/b/bingodealdev.appspot.com/o/markerHover.png?alt=media&token=0467cb94-feb3-4d37-a9e7-7c0d1673aa7f',
  logoPlayStore:
    'http://www.userlogos.org/files/logos/jumpordie/google_play_04.png',
  reportProblemLink: ' https://listednearme.com/dynamicLinks/H3Xd',
  algoliaIcon:
    'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Falgolia-blue-mark.png?alt=media&token=f897197e-6b13-4278-91aa-5fd8fdc349ec',
  shareButtonsLink: 'https://listednearme.com/dynamicLinks/3LNQ',
  collectionShops: 'shops',
  subCollectionShops: 'products',
  collectionCategories: 'categories',
  collectionOrders: 'orders',
  subCollectionShopOrders: 'OrderStatusHistory',
  collectionUsers: 'users',
  collectionThreads: 'threads',
  collectionMessages: 'messages',
  collectionRatings: 'ratings',
  collectionReviews: 'reviews',
  collectionReports: 'reports',
  collectionArchivedUsers: 'archived_users',
  collectionArchivedProducts: 'archived_products',
  collectionArchivedThreads: 'archived_threads',
  collectionClosedReports: 'closed_reports',
  collectionBlockedUsers: 'blocked_users',
  collectionBlockedProducts: 'blocked_products',
  collectionProductsViews: 'products_views',
  collectionActions: 'actions',
  collectionStatus: 'status',
  collectionMobiles: 'mobiles_models',
  collectionTablets: 'tablets_models',
  collectionNotifications: 'notifications',
  collectionActionsProductViews: 'actionsProductViews',
  collectionBoostedProducts: 'boosted_products',
  collectionFavorites: 'favorites',
  collectionFollowers: 'followers',
  collectionUsersStatus: 'users_status',
  collectionVehiclesModels: 'vehicles_models',
  conditionsStatus: [
    'Other (see description)',
    'For Parts',
    'Used (normal wear)',
    'Open Box (never used)',
    'Reconditioned/Cretified',
    'New (never used)'
  ],
  categoriesImages: {
    sportsAndOutdoors:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fsports.jpg?alt=media&token=5417ac72-2912-48be-9474-438beb20ba3b',
    autoParts:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Ftools.jpg?alt=media&token=46df4350-16da-428e-9863-12462a3ddf4d',
    homeAndAppliances:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fhomeappliances.jpg?alt=media&token=8542221d-046f-44a5-9503-0e4b113418be',
    homeForSaleOrRent:
      'https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/images%2F01eb0e66-7875-4833-8281-2cbf0bbd200d?alt=media&token=0d7e5957-b05b-4970-b0b6-c96f345160ce',
    electronics:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Felectronics.jpg?alt=media&token=0b01f158-bb47-4e06-81f2-e10065625cde',
    furniture:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Ffurniture.jpg?alt=media&token=0abb4b5d-4483-42d5-b77f-d1e98f0dc174',
    antiquesAndCollectibles:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fantiques.jpg?alt=media&token=33c95ad3-1228-458d-8d11-29f53a5849de',
    fashionAndAccessories:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fjewelry.jpg?alt=media&token=f79e2b66-f6c5-48be-b9fd-9604bb50252c',
    babyAndKids:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fbaby.jpg?alt=media&token=50d7e70c-8ea8-4594-b1ff-e30b45b2d2db',
    vehicles:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fvehicles.jpg?alt=media&token=e19f0a0f-7648-4462-94fe-6d119cdce715',
    others:
      'https://firebasestorage.googleapis.com/v0/b/bingodeal-c2b81.appspot.com/o/categoryImages%2Fmiscellaneous.jpg?alt=media&token=35a59a73-1e52-43a0-8a11-d9e0be92a1ac',
    yardSaleEvent:
      'https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/categories%2Fyard_sale_event.jpg?alt=media&token=06dadb34-a990-4c2c-907c-d49b1c18d100',
    mobiles:
      'https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/categories%2Fphone.jpg?alt=media&token=4f470b1a-ea25-409e-8e93-eec827ee496a',
    tablets:
      'https://firebasestorage.googleapis.com/v0/b/listednearme.appspot.com/o/categories%2Ftablet.jpg?alt=media&token=3ae72687-e228-4bb3-ad40-d229367eca7d'
  },
  categoriesId: {
    sportsAndOutdoors: 'JhnAzEzTae7RGy9higcn',
    autoParts: 'OyxkqOfCjld1pE7G0P31',
    homeAndAppliances: 'QqnVfR34mNc3sdcM8Kp9',
    electronics: 'RafITiYb154gATCYyqfP',
    furniture: 'eSpUBx3fwY82WwOCFEKd',
    antiquesAndCollectibles: 'jKZtnytjsNl1Fq3c76nY',
    fashionAndAccessories: 'jpUpLYKDo7IQxy4rHR18',
    babyAndKids: 'o3f2X9flEB4ETLPQLdhK',
    vehicles: 'pGW6FJQjHJU7BaeDy0i3',
    others: 'qUu1uCHqKTTMmemg91rD',
    homeForSaleOrRent: 'aVu7Hxc7blN2zSSHIoao',
    yardSaleEvent: 'nCF6RTVltzJ9KzTgFq69',
    mobiles: 'mXd9yzGEp9c9KIPDj5aV',
    tablets: 'DLy5hU0ySDrhpEdgmCQB'
  },
  conditionsImages: {
    other:
      'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fcondition_other.png?alt=media&token=8cb54ca0-e449-4a4f-a266-4e98ea010ff4',
    forParts:
      'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fcondition_for_parts_360.png?alt=media&token=2f0f6af8-463a-4b7b-bc03-1b60218a59f3',
    used: 'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fcondition_used_360.png?alt=media&token=4c2b91cb-905b-4466-8951-87bfe1060349',
    openBox:
      'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fcondition_open_box_360.png?alt=media&token=ad0a55be-a2f0-40a2-aa49-266fdb57cd43',
    reconditioned:
      'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fcondition_certified.png?alt=media&token=22fb2f4b-0d7a-417d-9bbf-ac2603bbe6df',
    new: 'https://firebasestorage.googleapis.com/v0/b/listednearmedev.appspot.com/o/images%2Fcondition_new.png?alt=media&token=1e96ff47-ee97-400b-936b-da285080b11e'
  },
  filtersRange: {
    condition: { min: 0, max: 5 },
    createdTimestamp: { min: 0, max: 180 },
    price: { min: 0, max: 10000 },
    distanceMile: { min: 5, max: 200 }, //in mile
    distanceKm: { min: 8, max: 320 } //in km
  },
  languagesSymbols: {
    english: 'en',
    arabic: 'ar',
    spanish: 'esp',
    chineseSimplified: 'zh-CN',
    chineseTraditional: 'zh-TW',
    czech: 'cs',
    danish: 'da',
    dutch: 'nl',
    finnish: 'fi',
    french: 'fr',
    german: 'de',
    greek: 'el',
    hungarian: 'hu',
    indonesian: 'in',
    italian: 'it',
    japanese: 'ja',
    korean: 'ko',
    norwegian: 'no',
    polish: 'pl',
    portuguese: 'pt-PT',
    portugueseBrazilian: 'pt-BR',
    romanian: 'ro',
    russian: 'ru',
    swedish: 'sv-SE',
    turkish: 'tr',
    ukrainian: 'uk',
    urdu: 'ur-PK',
    vietnamese: 'vi'
  },
  daysName: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  vehicleOtherId: 'FmlNiGb7PEGOVeYNppSp',
  mobileOtherId: 'kwVlSVtfAH40XMpQKhtz',
  tabletOtherId: 'PQpJ8BO94ntLiPcfEYL9'
};

// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger
