// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import AccountIcon from '@mui/icons-material/AccountBox';
import { makeStyles } from '@mui/styles';
import ThreadIcon from '@mui/icons-material/Chat';
import ReportIcon from '@mui/icons-material/Report';
import BlockedIcon from '@mui/icons-material/Block';
import ClosedIcon from '@mui/icons-material/ClosedCaption';
import SearchIcon from '@mui/icons-material/Search';
import ViewsIcon from '@mui/icons-material/Visibility';
import ContactIcon from '@mui/icons-material/ContactPhone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Toolbar from '@mui/material/Toolbar';
import RatingsIcon from '@mui/icons-material/StarRate';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import CardsIcon from '@mui/icons-material/CardMembership';
import ReviewsIcon from '@mui/icons-material/RateReview';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { constantsConfig } from '../../config/ConstantsConfig';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '3em',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.background
  },
  nested: {
    paddingInlineStart: theme.spacing(4)
  },
  listItemIcon: { color: theme.palette.primary.main },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: theme.palette.primary.background
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.background,
    width: drawerWidth,
    // marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  list: {
    marginTop: '150px'
  },
  toolbar: theme.mixins.toolbar,
  listDrawer: {
    marginTop: '5em'
  },
  drawerMobile: {
    zIndex: '0 !important',
    width: '270px'
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const DrawerAdmins = ({ intl, ...props }) => {
  const classes = useStyles();
  const [openProducts, setOpenProducts] = React.useState(false);
  const [openUsers, setOpenUsers] = React.useState(false);
  const [openCategories, setOpenCategories] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const [openThreads, setOpenThreads] = React.useState(false);
  const [openActions, setOpenActions] = React.useState(false);
  const handleClick = () => {
    setOpenProducts(!openProducts);
  };
  const handleClickActions = () => {
    setOpenActions(!openActions);
  };
  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };
  const handleClickCategories = () => {
    setOpenCategories(!openCategories);
  };
  const handleClickReports = () => {
    setOpenReports(!openReports);
  };
  const handleClickThreads = () => {
    setOpenThreads(!openThreads);
  };
  useEffect(() => {
    if (
      window.location.href.includes('users') ||
      window.location.href === constantsConfig.mainUrl + 'listednearme'
    ) {
      setOpenUsers(true);
    }
    if (
      (window.location.href.includes('product') ||
        window.location.href.includes('category')) &&
      !window.location.href.includes('action')
    ) {
      setOpenProducts(true);
    }
    if (
      window.location.href.includes('vehicles') ||
      window.location.href.includes('mobiles') ||
      window.location.href.includes('tablets')
    ) {
      setOpenCategories(true);
    }
    if (
      window.location.href.includes('thread') ||
      window.location.href.includes('chat')
    ) {
      setOpenThreads(true);
    }
    if (window.location.href.includes('report')) {
      setOpenReports(true);
    }
    if (window.location.href.includes('action')) {
      setOpenActions(true);
    }
  }, [props]);
  return (
    <div className={classes.drawer} aria-label="mailbox folders">
      {/* <Hidden smDown implementation="css"> */}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.root}>
          <ListItem button key={'Users'} onClick={handleClickUsers}>
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.listItemIcon}>
              <ListItemText>
                {intl.formatMessage({
                  id: 'users'
                })}
              </ListItemText>
            </ListItemIcon>
            {!openUsers ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'/listednearme'} className={classes.link}>
                <ListItem button key={'Users Details'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <AccountIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'users_details'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardusersbycountry/'}
                className={classes.link}
              >
                <ListItem button key={'Users By Country'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ViewsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'users_by_country'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>{' '}
          </Collapse>

          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardblockedusers/'}
                className={classes.link}
              >
                <ListItem button key={'Blocked Users'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <BlockedIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'blocked_users'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>{' '}
            </List>
          </Collapse>

          <ListItem button key={'Products'} onClick={handleClick}>
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.listItemIcon}>
              <ListItemText>
                {intl.formatMessage({
                  id: 'products'
                })}
              </ListItemText>
            </ListItemIcon>
            {!openProducts ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardallproducts/'}
                className={classes.link}
              >
                <ListItem button key={'Products Details'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'fragment_product_detail'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductscards/'}
                className={classes.link}
              >
                <ListItem button key={'Products Cards'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CardsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'products_cards'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductsallcountries/'}
                className={classes.link}
              >
                <ListItem button key={'Products All Countries'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CardsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'products_all_countries'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductswithotherbrand/'}
                className={classes.link}
              >
                <ListItem button key={'Products With Other Brand'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CardsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Products with other brand</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductswithothermodel/'}
                className={classes.link}
              >
                <ListItem button key={'Products With Other Model'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CardsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Products with other model</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardeditcategory/'}
                className={classes.link}
              >
                <ListItem button key={"Products'Categories"}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Edit Category</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardarchivedproducts/'}
                className={classes.link}
              >
                <ListItem button key={'Archived Products'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'archived_products'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardblockedproducts/'}
                className={classes.link}
              >
                <ListItem button key={'Blocked Products'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <BlockedIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'blocked_products'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductviews/'}
                className={classes.link}
              >
                <ListItem button key={'Product Views'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ViewsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'product_views'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductsbycountry/'}
                className={classes.link}
              >
                <ListItem button key={'Products By Country'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ViewsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'products_by_country'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button key={'Categories'} onClick={handleClickCategories}>
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.listItemIcon}>
              <ListItemText>
                {intl.formatMessage({
                  id: 'categories'
                })}
              </ListItemText>
            </ListItemIcon>
            {!openCategories ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse in={openCategories} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardvehicles/'}
                className={classes.link}
              >
                <ListItem button key={'Add Car'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <DirectionsCarIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Add Car</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openCategories} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardmobiles/'}
                className={classes.link}
              >
                <ListItem button key={'Add Mobile'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <PhoneAndroidIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Add Mobile</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openCategories} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardtablets/'}
                className={classes.link}
              >
                <ListItem button key={'Add Tablet'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <TabletAndroidIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Add Tablet</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button key={'Reports'} onClick={handleClickReports}>
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.listItemIcon}>
              <ListItemText>
                {intl.formatMessage({
                  id: 'reports'
                })}
              </ListItemText>
            </ListItemIcon>
            {!openReports ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse in={openReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardallreports/'}
                className={classes.link}
              >
                <ListItem button key={'Reports Details'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ReportIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'reports_details'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardblockreportsdetails/'}
                className={classes.link}
              >
                <ListItem button key={'Block Reports Details'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ReportIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Block Reports Details</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardclosedreports/'}
                className={classes.link}
              >
                <ListItem button key={'Closed Reports'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ClosedIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'closed_reports'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button key={'Threads'} onClick={handleClickThreads}>
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.listItemIcon}>
              <ListItemText>
                {intl.formatMessage({
                  id: 'threads'
                })}
              </ListItemText>
            </ListItemIcon>
            {!openThreads ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse in={openThreads} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardallthreads/'}
                className={classes.link}
              >
                <ListItem button key={'Threads Details'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ThreadIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'threads_details'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openThreads} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardthreadstable/'}
                className={classes.link}
              >
                <ListItem button key={'Threads Table'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ThreadIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Threads Table</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openThreads} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardthreadswithmessagesdetails/'}
                className={classes.link}
              >
                <ListItem button key={'Threads With Messages Details'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ThreadIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Threads With Messages Details</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openThreads} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardthreadmetrics/'}
                className={classes.link}
              >
                <ListItem button key={'Threads Metrics'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ThreadIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>Threads Metrics</ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openThreads} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardchatsupport/'}
                className={classes.link}
              >
                <ListItem button key={'Chat Support'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ThreadIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'chat_support'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button key={'Actions'} onClick={handleClickActions}>
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemIcon className={classes.listItemIcon}>
              <ListItemText>
                {intl.formatMessage({
                  id: 'actions'
                })}
              </ListItemText>
            </ListItemIcon>
            {!openActions ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse in={openActions} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardsearchactions/'}
                className={classes.link}
              >
                <ListItem button key={'Search Actions'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'search_actions'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Collapse in={openActions} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboardproductviewactions/'}
                className={classes.link}
              >
                <ListItem button key={'ProductView Actions'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ViewsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'product_view_actions'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <Collapse in={openActions} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={'/listednearme/dashboarsellercontactproductactions/'}
                className={classes.link}
              >
                <ListItem button key={'Seller Contact Product Actions'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ContactIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'contact_seller_product'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <Link
            to={'/listednearme/dashboardallratings'}
            className={classes.link}
          >
            <ListItem button key={'Ratings'}>
              <ListItemIcon className={classes.listItemIcon}>
                <RatingsIcon />
              </ListItemIcon>
              <ListItemIcon className={classes.listItemIcon}>
                <ListItemText>
                  {intl.formatMessage({
                    id: 'ratings'
                  })}
                </ListItemText>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link
            to={'/listednearme/dashboardallreviews'}
            className={classes.link}
          >
            <ListItem button key={'Reviews'}>
              <ListItemIcon className={classes.listItemIcon}>
                <ReviewsIcon />
              </ListItemIcon>
              <ListItemIcon className={classes.listItemIcon}>
                <ListItemText>
                  {intl.formatMessage({
                    id: 'reviews'
                  })}
                </ListItemText>
              </ListItemIcon>
            </ListItem>
          </Link>
        </div>
      </Drawer>
      {/* </Hidden> */}
      {/* <Hidden smUp implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerMobile
          }}
          variant="permanent"
          open
        >
          <div className={classes.listDrawer}>
            <List>
              <ListItem button key={'Users'} onClick={handleClickUsers}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'users'
                    })}
                  </ListItemText>
                </ListItemIcon>
                {!openUsers ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
              <Collapse in={openUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme'}>
                    <ListItem button key={'Users Details'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <AccountIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'users_details'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardusersbycountry/'}>
                    <ListItem button key={'Users By Country'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ViewsIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'users_by_country'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>{' '}
              </Collapse>

              <Collapse in={openUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardblockedusers/'}>
                    <ListItem button key={'Blocked Users'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <BlockedIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'blocked_users'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>{' '}
                </List>
              </Collapse>
              {/* <ListItem button key={'Shops'} onClick={handleClickShops}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'drawer_admin.shops'
                    })}
                  </ListItemText>
                </ListItemIcon>
              </ListItem> 
              

              <ListItem button key={'Products'} onClick={handleClick}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'products'
                    })}
                  </ListItemText>
                </ListItemIcon>
                {!openProducts ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardallproducts/'}>
                    <ListItem button key={'Products Details'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'fragment_product_detail'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardproductscards/'}>
                    <ListItem button key={'Products Cards'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <CardsIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'products_cards'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardeditcategory/'}>
                    <ListItem button key={"Products'Categories"}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'categories.edit_category'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardarchivedproducts/'}>
                    <ListItem button key={'Archived Products'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'archived_products'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardblockedproducts/'}>
                    <ListItem button key={'Blocked Products'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <BlockedIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'blocked_products'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardproductviews/'}>
                    <ListItem button key={'Product Views'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ViewsIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'product_views'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardproductsbycountry/'}>
                    <ListItem button key={'Products By Country'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ViewsIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'products_by_country'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <ListItem button key={'Reports'} onClick={handleClickReports}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'reports'
                    })}
                  </ListItemText>
                </ListItemIcon>
                {!openReports ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
              <Collapse in={openReports} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardallreports/'}>
                    <ListItem button key={'Reports Details'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ReportIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'reports_details'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openReports} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardclosedreports/'}>
                    <ListItem button key={'Closed Reports'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ClosedIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'closed_reports'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <ListItem button key={'Threads'} onClick={handleClickThreads}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'threads'
                    })}
                  </ListItemText>
                </ListItemIcon>
                {!openThreads ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
              <Collapse in={openThreads} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardallthreads/'}>
                    <ListItem button key={'Threads Details'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ThreadIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'threads_details'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openThreads} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardchatsupport/'}>
                    <ListItem button key={'Chat Support'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ThreadIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'chat_support'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <ListItem button key={'Actions'} onClick={handleClickActions}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'actions'
                    })}
                  </ListItemText>
                </ListItemIcon>
                {!openActions ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
              <Collapse in={openActions} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardsearchactions/'}>
                    <ListItem button key={'Search Actions'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'search_actions'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openActions} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardproductviewactions/'}>
                    <ListItem button key={'ProductView Actions'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ViewsIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'product_view_actions'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

              <Collapse in={openActions} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link
                    to={'/listednearme/dashboarsellercontactproductactions/'}
                  >
                    <ListItem button key={'Seller Contact Product Actions'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ContactIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'contact_seller_product'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Collapse in={openActions} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={'/listednearme/dashboardsellercontactshopactions/'}>
                    <ListItem button key={'Seller Contact Shop Actions'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ContactIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'contact_owner_shop'
                          })}
                        </ListItemText>
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Link to={'/listednearme/dashboardallratings'}>
                <ListItem button key={'Ratings'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <RatingsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'ratings'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
              <Link to={'/listednearme/dashboardallreviews'}>
                <ListItem button key={'Reviews'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ReviewsIcon />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'reviews'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </div>
        </Drawer>
      </Hidden> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default injectIntl(connect(mapStateToProps, null)(DrawerAdmins));
