// @flow
import React, { Component } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import { Typography, CircularProgress } from '@mui/material';
import { firebaseConfig } from '../../config/FirebaseConfig';
import { connect } from 'react-redux';

//init api key of google map
//Geocode.setLanguage()

//map component class
class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readableLocation: props.addProductData
        ? props.addProductData.readableLocation
        : '',
      city: props.addProductData ? props.addProductData.city : '',
      state: props.addProductData ? props.addProductData.state : '',
      country: props.addProductData ? props.addProductData.country : '',
      zip: props.addProductData ? props.addProductData.zip : '',
      countryCode: props.addProductData ? props.addProductData.countryCode : '',
      latitude: props.addProductData ? props.addProductData.latitude : '',
      longitude: props.addProductData ? props.addProductData.longitude : ''
    };
  }

  componentDidMount() {
    if (this.props.addProductData) {
      if (this.props.addProductData.readableLocation) {
        this.updateNewLatLng(
          this.props.addProductData.latitude,
          this.props.addProductData.longitude
        );
      } else {
        //get current position
        var pos = {
          lat: this.props.locationConfig.latitude
            ? this.props.locationConfig.latitude
            : this.props.locationConfig.bingoDealLocation.latitude,
          lng: this.props.locationConfig.longitude
            ? this.props.locationConfig.longitude
            : this.props.locationConfig.bingoDealLocation.longitude
        };

        //update state to current postion
        this.updateNewLatLng(pos.lat, pos.lng);
      }
    }
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    //Cheak if state doesn't change
    if (this.state.latitude !== nextState.latitude) {
      return true;
    }
    return false;
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = '';

    if (addressArray)
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
          city = addressArray[i].long_name;
          return city;
        }
      }
  };

  /**
   * Get the country and countyCode
   *
   * @param addressArray
   * @return {string}
   */
  getCountry = (addressArray) => {
    let Country = '';
    if (addressArray)
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'country' === addressArray[i].types[0]
        ) {
          Country = addressArray[i].long_name;
          const countryCode = addressArray[i].short_name;
          return { Country: Country, countryCode: countryCode };
        }
      }
  };

  /**
   * Get the zip code
   *
   * @param addressArray
   * @return {string}
   */
  getZip = (addressArray) => {
    let zip = '';
    if (addressArray)
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'postal_code' === addressArray[i].types[0]
        ) {
          zip = addressArray[i].long_name;
          return zip;
        }
      }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = '';
    if (addressArray)
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * update state from new lat lng
   *
   * @param newLat new lat position
   * @param newLng new lng position
   * @param addressArray address information json
   * @param address full addressstring
   */
  updateState = (newLat, newLng, addressArray, address) => {
    const city = this.getCity(addressArray),
      state = this.getState(addressArray),
      country = this.getCountry(addressArray),
      zip = this.getZip(addressArray);

    //update state
    this.setState({
      readableLocation: address ? address : '',
      city: city ? city : '',
      state: state ? state : '',
      country: country ? country.Country : '',
      countryCode: country ? country.countryCode : '',
      zip: zip ? zip : '',
      latitude: newLat,
      longitude: newLng
    });
    this.props.onHandleChangeValue('map', this.state);
  };

  /**
   * update from new lat lng
   *
   * @param newLat new lat position
   * @param newLng new lng position
   */
  updateNewLatLng = (newLat, newLng) => {
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components;

        //update state
        this.updateState(newLat, newLng, addressArray, address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    this.updateNewLatLng(newLat, newLng);
  };

  /**
   * When the user types an address in the search box
   * @param place json
   */
  onPlaceSelected = (place) => {
    if (place.formatted_address) {
      //get address from place json
      const latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      //update state
      this.updateNewLatLng(latValue, lngValue);
    }
  };

  AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap //google map component
        google={window.google}
        defaultZoom={this.props.zoom}
        defaultCenter={{
          lat: this.state.latitude,
          lng: this.state.latitude
        }}
        onClick={(info) => {
          this.updateNewLatLng(info.latLng.lat(), info.latLng.lng());
        }}
      >
        {this.state.readableLocation !== null && (
          <InfoWindow //info window to show information of position
            onClose={this.onInfoWindowClose}
            //set position info up to marker with value 0.0018
            position={{
              lat: this.state.latitude + 0.0018,
              lng: this.state.longitude
            }}
          >
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.readableLocation}
              </span>
            </div>
          </InfoWindow>
        )}

        <Marker //marker can drop it
          google={this.props.google}
          draggable={true}
          onDragEnd={this.onMarkerDragEnd}
          //set position of marker
          position={{
            lat: this.state.latitude,
            lng: this.state.longitude
          }}
        />

        <Marker />

        <Autocomplete //auto complete component
          style={{
            width: '96%',
            height: '40px',
            paddingInlineStart: '16px',
            marginTop: '2px',
            marginBottom: '10px'
          }}
          bounds={new window.google.maps.Circle({
            center: new window.google.maps.LatLng(
              this.state.latitude,
              this.state.latitude
            ),
            radius: 20000
          }).getBounds()}
          options={{
            types: ['(regions)'],
            componentRestrictions: { country: this.state.countryCode }
          }}
          // componentRestrictions={{ country: this.state.countryCode }}
          onPlaceSelected={this.onPlaceSelected}
        />
      </GoogleMap>
    ))
  );
  render() {
    let AsyncMap = this.AsyncMap;
    let map;
    map = (
      <div>
        <Typography variant="subtitle2" align="center">
          {this.state.readableLocation}
        </Typography>
        <AsyncMap //call asyncMap to rander map
          googleMapURL={
            'https://maps.googleapis.com/maps/api/js?key=' +
            firebaseConfig.apiKey +
            '&libraries=places'
          }
          loadingElement={
            <CircularProgress align="center" style={{ height: `100%` }} />
          }
          containerElement={
            <div style={{ height: this.props.height, zIndex: 5 }} />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
    return map;
  }
}
const mapStateToProps = (state) => {
  return {
    locationConfig: state.locationReducer
  };
};

export default connect(mapStateToProps)(Map);
