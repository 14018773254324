// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import { constantsConfig } from '../../config/ConstantsConfig';
import { useLanguage, globalLanguage } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.background
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '20em'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  container: {
    padding: 35,
    backgroundColor: theme.palette.primary.background
  },
  xIcon: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: '10px'
  },
  flexContentCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

function LanguageDialog({ intl, ...props }) {
  const { handleCloseLanguageDialog } = props;
  const [selectedLanguage, setSelectedLaguage] = React.useState(globalLanguage);
  const handleChange = (e) => {
    setSelectedLaguage(e.target.value);
  };

  const [language, setLanguage] = useLanguage();

  const updateLanguage = () => {
    const newLanguage = selectedLanguage;

    if (language === newLanguage) {
      return;
    }
    setLanguage(newLanguage);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <CloseIcon
          className={classes.xIcon}
          onClick={handleCloseLanguageDialog}
        />
        <DialogTitle
          variant="h5"
          id="form-dialog-title"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {intl.formatMessage({
            id: 'language'
          })}
        </DialogTitle>
      </div>
      <DialogContent>
        <div className={classes.flexContentCenter}>
          <div className={classes.container}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel id="demo-simple-select-helper-label">
                {intl.formatMessage({
                  id: 'language'
                })}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedLanguage}
                onChange={handleChange}
              >
                <MenuItem value={constantsConfig.languagesSymbols.english}>
                  {intl.formatMessage({
                    id: 'english_language'
                  })}
                </MenuItem>
                {props.countryCode !== 'US' && props.countryCode !== 'CA' ? (
                  <MenuItem value={constantsConfig.languagesSymbols.arabic}>
                    {intl.formatMessage({
                      id: 'arabic_language'
                    })}
                  </MenuItem>
                ) : null}
                <MenuItem value={constantsConfig.languagesSymbols.spanish}>
                  {intl.formatMessage({
                    id: 'spanish_language'
                  })}
                </MenuItem>
                <MenuItem
                  value={constantsConfig.languagesSymbols.chineseSimplified}
                >
                  {intl.formatMessage({
                    id: 'chinese_simplified_language'
                  })}
                </MenuItem>
                <MenuItem
                  value={constantsConfig.languagesSymbols.chineseTraditional}
                >
                  {intl.formatMessage({
                    id: 'chinese_traditional_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.czech}>
                  {intl.formatMessage({
                    id: 'czech_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.danish}>
                  {intl.formatMessage({
                    id: 'danish_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.dutch}>
                  {intl.formatMessage({
                    id: 'dutch_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.finnish}>
                  {intl.formatMessage({
                    id: 'finnish_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.french}>
                  {intl.formatMessage({
                    id: 'french_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.german}>
                  {intl.formatMessage({
                    id: 'german_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.greek}>
                  {intl.formatMessage({
                    id: 'greek_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.hungarian}>
                  {intl.formatMessage({
                    id: 'hungarian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.indonesian}>
                  {intl.formatMessage({
                    id: 'indonesian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.italian}>
                  {intl.formatMessage({
                    id: 'italian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.japanese}>
                  {intl.formatMessage({
                    id: 'japanese_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.korean}>
                  {intl.formatMessage({
                    id: 'korean_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.norwegian}>
                  {intl.formatMessage({
                    id: 'norwegian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.polish}>
                  {intl.formatMessage({
                    id: 'polish_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.portuguese}>
                  {intl.formatMessage({
                    id: 'portuguese_language'
                  })}
                </MenuItem>
                <MenuItem
                  value={constantsConfig.languagesSymbols.portugueseBrazilian}
                >
                  {intl.formatMessage({
                    id: 'portuguese_brazilian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.romanian}>
                  {intl.formatMessage({
                    id: 'romanian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.russian}>
                  {intl.formatMessage({
                    id: 'russian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.swedish}>
                  {intl.formatMessage({
                    id: 'swedish_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.turkish}>
                  {intl.formatMessage({
                    id: 'turkish_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.ukrainian}>
                  {intl.formatMessage({
                    id: 'ukrainian_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.urdu}>
                  {intl.formatMessage({
                    id: 'urdu_pakistan_language'
                  })}
                </MenuItem>
                <MenuItem value={constantsConfig.languagesSymbols.vietnamese}>
                  {intl.formatMessage({
                    id: 'vietnamese_language'
                  })}
                </MenuItem>
              </Select>
            </FormControl>
            <div className={classes.flexContentCenter}>
              <Button
                style={{ marginTop: 20 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  updateLanguage();
                  handleCloseLanguageDialog();
                }}
              >
                {intl.formatMessage({
                  id: 'apply'
                })}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {};
};

export default injectIntl(
  compose(connect(mapStateToProps, null))(LanguageDialog)
);
