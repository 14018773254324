import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
// import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
// import RotateRightIcon from '@mui/icons-material/RotateRight';
import Cropper from "cropperjs";
import { injectIntl } from "react-intl";
import "cropperjs/dist/cropper.min.css";

const styles = (theme) => ({
  image: {
    height: "80%",
    // maxHeight: '70%'
  },
});

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class EditImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageDestination: "",
      rotation: 0,
    };
    this.imageElement = React.createRef();
  }
  componentDidMount() {
    const cropper = new Cropper(this.imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: "100%",
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        this.setState({ imageDestination: canvas.toDataURL(this.props.src) });
      },
    });
  }
  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  handleAccept = (e, obj) => {
    if (this.props.isCamera === true) {
      this.props.handleConvertTakePhoto(this.state.imageDestination);
      this.props.handleCloseEditImage();
    } else {
      var images = [];
      var imageDestination = this.state.imageDestination;
      const convertImageToUrl = dataURLtoFile(imageDestination, "image.txt");
      images.push({ convertImageToUrl });
      this.props.onSelectMultipleFiles(e, images);
      this.props.handleCloseEditImage();
    }
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <React.Fragment>
        <DialogTitle
          id="form-dialog-title"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {intl.formatMessage({
            id: "edit_image",
          })}
        </DialogTitle>
        <DialogContent>
          <div className={classes.image}>
            <img
              ref={this.imageElement}
              src={this.props.src}
              alt="Source"
              // crossorigin
            />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              sx={{ width: "30%", marginTop: "0.5em" }}
              className={classes.doneButton}
              onClick={(e) => this.handleAccept(e, this.state.imageDestination)}
            >
              {intl.formatMessage({
                id: "done",
              })}
            </Button>
          </Grid>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(EditImage));
