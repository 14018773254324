import React, { Fragment, useEffect } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import firebase from "firebase/compat/app";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForword from "@mui/icons-material/ArrowForward";
import { FaRocket } from "react-icons/fa";
import getSymbolFromCurrency from "currency-symbol-map";
import ReactTimeAgo from "react-time-ago";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { constantsConfig } from "../../config/ConstantsConfig";
import {
  createFavoriteProduct,
  removeFavoriteProduct,
  deleteFavoriteProduct,
  updateViewsOrFavorites,
  addNewDocumentToViews,
} from "../../store/product/ProductActions";
import {
  makeOfferLogging,
  addWishListLogging,
  contactSellerLogging,
} from "../../store/analyticsLogging/GoogleLogging";
import {
  makeOfferFacebookPixel,
  contactSellerFacebookPixel,
} from "../../store/analyticsLogging/FacebookPixel";
import { getThreadIdForSubjectAndSeller } from "../messaging/Messageing";
import { createAction } from "../../store/actions/EventsActions";
import { deleteUsedProduct } from "../../store/product/DeleteUsedProduct";
import MessageComponent from "../messaging/MessageComponent";
import BoostDialogContent from "./boostProduct/BoostProductDialogContent";
import AddProdcut from "./AddProduct";
import Swipe from "react-easy-swipe";
import SellProductDialog from "./SellProductDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.price,
    backgroundColor: theme.palette.primary.slideShowBackground,
  },
  circularProgressBackdrop: {
    zIndex: theme.zIndex.drawer + 2,
  },
  productImage: {
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  imgContainer: {
    position: "relative",
  },
  topContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    top: 0,
    right: 0,
    height: "15%",
    width: "100%",
    position: "absolute",
    zIndex: 1,
  },
  bottomContainer: {
    backgroundColor: theme.palette.primary.transparentBackground,
    bottom: 0,
    right: 0,
    height: "35%",
    width: "100%",
    position: "absolute",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: "22%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20%",
    },
  },
  price: {
    color: theme.palette.primary.price,
    padding: 5,
    textAlign: "center",
    fontSize: "12px",
  },
  media: {
    width: "50%",
    height: "50%",
  },
  locationIcon: {
    marginTop: "5px",
    color: theme.palette.primary.main,
  },
  location: {},
  buttons: {
    ...theme.typography.normalButton,
    padding: 8,
    borderRadius: "0.5em",
    width: "14em",
    maxWidth: "14em",
    color: theme.palette.primary.icons,
    backgroundColor: theme.palette.primary.accent,
    [theme.breakpoints.down("sm")]: {
      padding: 3.5,
      borderRadius: "0.2em",
      width: "9em",
      maxWidth: "9em",
      fontSize: "0.5rem",
    },
  },
  containerTypography: {
    fontWeight: "bold",
    padding: 8,
    borderRadius: "0.5em",
    width: "14em",
    maxWidth: "14em",
    fontFamily: "Quicksand",
  },
  typography: {
    padding: 8,
    width: "12.5em",
    maxWidth: "12.5em",
    backgroundColor: theme.palette.primary.transparentBackground,
    color: theme.palette.primary.price,
    [theme.breakpoints.down("sm")]: {
      padding: 3.5,
      borderRadius: "0.2em",
      width: "9em",
      maxWidth: "9em",
    },
  },
  bottomGrid: {
    marginTop: "1em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2em",
    },
  },
  containerRightArrow: {
    backgroundColor: theme.palette.primary.transparentBackground,
    // top: 0,
    right: 0,
    height: "10%",
    width: "3%",
    position: "absolute",
    zIndex: 1,
  },
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    marginInlineEnd: "10px",
  },
  arrows: {
    // position: 'absolute',
    zIndex: 10,
  },
  icons: {
    color: theme.palette.primary.main,
    "&:hover": { cursor: "pointer" },
  },
  favoriteBorderIcon: {
    color: theme.palette.primary.main,
    "&:hover": { cursor: "pointer" },
  },
  viewsTypography: {
    fontFamily: "Quicksand",
    fontWeight: "bold",
    marginTop: "-0.5em",
    marginInlineStart: "0.5em",
  },
  shareIcon: {
    margin: "2px",
  },
  flexOfferHeder: {
    height: "60px",
    alignItems: "right",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "space-between",
  },
  xMakerOfferIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "40px",
    color: theme.palette.primary.price,
  },
  MakerOfferlabel: {
    float: "right",
    marginTop: "20px",
    marginLeft: "80px",
    width: "180px",
    color: theme.palette.primary.icons,
    marginBottom: "20px",
  },
  updateContent: {
    alignItems: "center",
    // backgroundColor: theme.palette.primary.background
  },
  OfferText: {
    align: "center",
    display: "flex",
    textAlign: "center",
    marginBottom: "10px",
  },
  offerButton: {
    width: "100%",
  },
  circularProgressMakeOfferButton: {
    color: theme.palette.primary.icons,
  },
  flexHeder: {
    height: "40px",
    display: "flex",
    alignItems: "left",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "space-between",
  },
  iconButton: {
    color: theme.palette.primary.icons,
  },
  typographySelectBuyer: {
    color: theme.palette.primary.icons,
    marginRight: "100px",
    marginTop: "7px",
  },
  EditProductDialog: {
    overflow: "hidden",
  },
  containerBoostDialog: {
    backgroundColor: theme.palette.primary.background,
  },
  containerXIcon: {
    position: "absolute",
    right: 0,
  },
  shareButtonsMenu: {
    "& .MuiList-root": {
      backgroundColor: theme.palette.primary.background,
    },
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.primary.background,
    },
  },
}));

const convertPriceToString = (price) => {
  let strPrice = price.toString();
  let charsPrice = [...strPrice];
  if (charsPrice.length <= 3) return strPrice;
  let result = [];
  let count = 1;
  for (let i = charsPrice.length - 1; i >= 0; i--) {
    result.push(charsPrice[i]);
    if (count % 3 === 0 && count !== charsPrice.length) {
      result.push(",");
    }
    count++;
  }
  return result.reverse().join("");
};

function SlideShowProducts({ intl, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [views, setViews] = React.useState(0);
  // const [favorites, setFavorites] = React.useState(null);
  const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [threadsSpinner, setThreadsSpinner] = React.useState(false);
  const [chooseBuyerSpinner, setChooseBuyerSpinner] = React.useState(false);
  const [openMakeOffer, setOpenMakeOffer] = React.useState(false);
  const [makeOfferSpinner, setMakeOfferSpinner] = React.useState(false);
  const [makeOfferPrice, setMakeOfferPrice] = React.useState(1);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openMainBackdrop, setOpenMainBackdrop] = React.useState(true);
  const [thread, setThread] = React.useState(null);
  const [openChatDialog, setOpenChatDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  // const [deleted, setDeleted] = React.useState(false);
  const [threads, setThreads] = React.useState(null);
  const [openAlreadyBoostSnackBar, setOpenAlreadyBoostSnackBar] =
    React.useState(false);
  const [snackBarBoostProduct /*, setSnackBarBoostProduct*/] = React.useState(
    window.location.href.includes("cancelPayment") ||
      window.location.href.includes("successPayment")
  );
  const [openBoostDialog, setOpenBoostDialog] = React.useState(
    window.location.href.includes("Open/BoostProduct")
  );
  const {
    product,
    auth,
    favoriteProducts,
    profile,
    loadingFavorite,
    boostedProductId,
    boostedProduct,
    allThisFavoriteProduct,
    handleShowSlideShow,
  } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMakeOffer = () => {
    setOpenMakeOffer(true);
  };

  const handleCloseMakeOffer = () => {
    setOpenMakeOffer(false);
  };

  const handleStartMakeOfferSpinner = () => {
    setMakeOfferSpinner(true);
  };

  const handleEndMakeOfferSpinner = () => {
    setMakeOfferSpinner(false);
  };

  const handleChangeMakeOfferPrice = (e) => {
    setMakeOfferPrice(e.target.value);
  };

  const handleCloseBackDrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackDrop = () => {
    setOpenBackdrop(true);
  };

  const handleCloseMainBackDrop = () => {
    setOpenMainBackdrop(false);
  };

  const handleCloseChat = () => {
    setOpenChatDialog(false);
  };

  const handleOpenBoostDialog = () => {
    setOpenBoostDialog(true);
  };

  const handleCloseBoostDialog = () => {
    setOpenBoostDialog(false);
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
    getThreadsForProduct();
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenAlreadyBoostSnackBar = () => {
    setOpenAlreadyBoostSnackBar(true);
  };
  const handleCloseAlreadyBoostSnackBar = () => {
    setOpenAlreadyBoostSnackBar(false);
  };

  // const handleCloseBoostSnackBar = () => {
  //   setOpenBoostDialog(false);
  //   setSnackBarBoostProduct(false);
  // };

  const getThreadsForProduct = async () => {
    setThreadsSpinner(true);
    if (props.product) {
      const query = firebase
        .firestore()
        .collection("threads")
        .orderBy("lastModifiedTimestamp", "desc")
        .where("subjectId", "==", props.product.id);

      const snapshot = await query.get();
      const items = snapshot.docs.map((doc) => doc.data());
      if (items) {
        setThreads(items);
        setThreadsSpinner(false);
      } else setThreadsSpinner(false);
    }
  };

  const onSendHandle = async (myThread, thread, buyer) => {
    setChooseBuyerSpinner(true);
    getThreadIdForSubjectAndSeller({
      receiverId: myThread.receiverId,
      receiverName: myThread.receiverName,
      receiverPhoto: myThread.receiverPhoto,
      senderId: myThread.senderId,
      senderName: myThread.senderName,
      senderPhoto: myThread.senderPhoto,
      subjectId: myThread.subjectId,
      subjectName: myThread.subjectName,
      subjectPhoto: thread.subjectPhoto,
    })
      .then((response) => {
        var receiverName = myThread.receiverName;
        try {
          var sendMessgae = firebase.functions().httpsCallable("sendMessage");

          sendMessgae(
            JSON.stringify({
              message: {
                messageType: 7,
                name: receiverName,
                subjectId: myThread.subjectId,
                text: intl.formatMessage({
                  id: "rate_seller",
                }),
                userId: myThread.senderId,
                timestamp: new Date().getTime(),
              },
              threadId: response.threadId,
              receiverId: myThread.receiverId,
            })
          )
            .then((result) => {
              //       // Read result of the Cloud Function.
              deleteUsedProduct({
                product: props.product,
                isSoldOnBingoDeal: true,
                buyer: buyer,
                buyerId: thread.senderId,
              })
                .then((response) => {
                  props.router.navigate(
                    "/sellpageproducts/" +
                      thread.senderId +
                      "+" +
                      thread.subjectId
                  );
                })
                .catch((err) => {
                  console.log("error        ", err);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (err) {}
        //  this.setState({ shouldScroll: true });
      })
      .catch((err) => {});
  };

  const handleOpenRating = (thread) => {
    const buyer = {
      name: thread.senderName,
      id: thread.senderId,
      photoUrl: thread.senderPhoto,
    };
    var senderId,
      receiverId,
      senderName,
      receiverName,
      senderPhoto,
      receiverPhoto;
    if (thread.receiverId === props.auth.uid) {
      senderId = thread.receiverId;
      receiverId = thread.senderId;
      senderName = thread.receiverName;
      receiverName = thread.senderName;
      senderPhoto = thread.receiverPhoto;
      receiverPhoto = thread.senderPhoto;
    } else {
      senderId = thread.senderId;
      receiverId = thread.receiverId;
      senderName = thread = senderName;
      receiverName = thread.receiverName;
      senderPhoto = thread.senderPhoto;
      receiverPhoto = thread.receiverPhoto;
    }
    var myThread = {
      senderId: senderId,
      receiverId: receiverId,
      senderName: senderName,
      receiverName: receiverName,
      subjectId: thread.subjectId,
      subjectName: thread.subjectName,
      senderPhoto: senderPhoto,
      receiverPhoto: receiverPhoto,
    };
    onSendHandle(myThread, thread, buyer);
  };

  const hendleDeletion = () => {
    setOpenDeleteDialog(false);
    // setDeleted(true);
    for (let i = 0; i < props.allThisFavoriteProduct.length; i++) {
      props.deleteFavoriteProduct(props.allThisFavoriteProduct[i].id);
    }

    deleteUsedProduct({
      product: props.product,
      isSoldOnBingoDeal: false,
    })
      .then((response) => {
        props.router.navigate("/");
      })
      .catch((err) => {
        console.log("error        ", err);
      });
  };

  const sendNotifyAction = (e) => {
    var senderMessage = firebase.functions().httpsCallable("sendMessage");
    const { product, auth, profile, createAction } = props;
    if (auth.uid) {
      // if (!this.state.threadId) {
      handleOpenBackDrop();
      getThreadIdForSubjectAndSeller({
        receiverId: product.sellerId,
        receiverName: product.seller.name ? product.seller.name : "",
        receiverPhoto: product.seller.photoUrl ? product.seller.photoUrl : "",
        senderId: auth.uid,
        senderName: profile.name ? profile.name : "",
        senderPhoto: profile.photoUrl ? profile.photoUrl : "",
        subjectId: product.id,
        subjectName: product.title ? product.title : "",
        subjectPhoto: product.imageUrl1 ? product.imageUrl1 : "",
      })
        .then((response) => {
          setThread(response);
          setOpenChatDialog(true);

          createAction({
            actionType: "SellerContactProduct",
            productId: product.id,
            productTitle: product.title,
            userId: auth.uid,
          });
          var loggingProduct = {
            title: product.title,
            category: product.category,
          };

          handleCloseBackDrop();
          handleEndMakeOfferSpinner();
          handleCloseMakeOffer();
          props.makeOfferLogging(loggingProduct);
          props.makeOfferFacebookPixel(loggingProduct);
          var receiverId = response.receiverId;
          if (receiverId === props.auth.uid) {
            receiverId = response.senderId;
          }
          senderMessage(
            JSON.stringify({
              message: {
                messageType: 8,
                name: product.seller.name ? product.seller.name : "",
                subjectId: product.id,
                text:
                  intl.formatMessage({
                    id: "make_offer_buy",
                  }) +
                  " " +
                  product.title +
                  " " +
                  intl.formatMessage({
                    id: "make_offer_buy_for",
                  }) +
                  " " +
                  makeOfferPrice +
                  " " +
                  product.currencyCode,
                userId: auth.uid,
                timestamp: new Date().getTime(),
              },
              threadId: response.threadId,
              receiverId: receiverId,
            })
          )
            .then(function (result) {
              // Read result of the Cloud Function.
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      // } else {
      //   setOpenChatDialog(true);
      // }
    } else {
      // this.handleOpenSignUpAndSignIn();
    }
  };

  const messageHandle = () => {
    if (auth.uid) {
      // if (!this.state.threadId) {
      handleOpenBackDrop();
      getThreadIdForSubjectAndSeller({
        receiverId: product.sellerId,
        receiverName: product.seller.name ? product.seller.name : "",
        receiverPhoto: product.seller.photoUrl ? product.seller.photoUrl : "",
        senderId: auth.uid,
        senderName: profile.name ? profile.name : "",
        senderPhoto: profile.photoUrl ? profile.photoUrl : "",
        subjectId: product.id,
        subjectName: product.title ? product.title : "",
        subjectPhoto: product.imageUrl1 ? product.imageUrl1 : "",
      })
        .then((response) => {
          setThread(response);
          setOpenChatDialog(true);
          createAction({
            actionType: "SellerContactProduct",
            productId: product.id,
            productTitle: product.title,
            userId: auth.uid,
          });
          var loggingProduct = {
            title: product.title,
            category: product.category,
          };
          props.contactSellerLogging(loggingProduct);
          props.contactSellerFacebookPixel(loggingProduct);
          handleCloseBackDrop();
        })
        .catch((err) => {
          console.log(err);
        });
      // } else {
      //   this.setState({ openChatDialog: true });
      // }
    } else {
      // this.handleOpenSignUpAndSignIn();
    }
  };

  const handleClickFavorite = () => {
    var product = {
      productId: props.product.id,
      userId: props.auth.uid,
    };
    var updateViews = {
      favorites: props.allThisFavoriteProduct.length + 1,
    };
    const documentId = props.product.id;
    props.createFavoriteProduct(product);
    props.updateViewsOrFavorites({ updateViews, documentId });
    props.addWishListLogging(props.product);
  };

  const handleClickRemoveFavorite = () => {
    for (let i = 0; i < props.allThisFavoriteProduct.length; i++) {
      if (
        props.allThisFavoriteProduct[i].subjectId === props.product.id &&
        props.allThisFavoriteProduct[i].userId === props.auth.uid
      ) {
        var product = {
          subjectId: props.allThisFavoriteProduct[i].subjectId,
          favoriteProductId: props.allThisFavoriteProduct[i].id,
        };
        props.removeFavoriteProduct(product);
      }
    }
    var updateViews = {
      favorites: props.allThisFavoriteProduct.length - 1,
    };
    const documentId = props.product.id;
    props.updateViewsOrFavorites({ updateViews, documentId });
  };

  const onSwipeMove = (position, event) => {
    if (position.x < 0) {
      props.handleNextPage();
      setIsFirstTime(true);
    } else {
      props.handlePrevPage();
      setIsFirstTime(true);
    }
  };

  const handleProductViews = async () => {
    var viewers = [];
    var views = null;
    var profileId = null;
    var documentId = null;
    var updateViews = {};
    firebase
      .firestore()
      .collection("products_views")
      .doc(product.id)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          if (props.auth.uid) {
            if (snapshot.data().viewers) {
              var count = 0;
              for (let i = 0; i < snapshot.data().viewers.length; i++) {
                count++;
                if (snapshot.data().viewers[i] === props.auth.uid) {
                  setViews(snapshot.data().views);
                  // setFavorites(snapshot.data().favorites);
                  break;
                } else {
                  if (count === snapshot.data().viewers.length) {
                    viewers = snapshot.data().viewers;
                    views = snapshot.data().views;
                    profileId = props.auth.uid;
                    views += 1;
                    viewers.push(profileId);
                    setViews(views);
                    // setFavorites(snapshot.data().favorites);
                    updateViews = {
                      views: views,
                      viewers: viewers,
                    };
                    documentId = product.id;
                    props.updateViewsOrFavorites({
                      updateViews,
                      documentId,
                    });
                  }
                }
              }
            } else {
              var favorites = 0;
              viewers = [];
              views = snapshot.data().views + 1;
              profileId = props.auth.uid;
              viewers.push(profileId);
              setViews(views);
              // setFavorites(favorites);
              updateViews = {
                favorites: favorites,
                viewers: viewers,
                views: views,
              };
              documentId = product.id;
              props.addNewDocumentToViews({ updateViews, documentId });
            }
          } else {
            setViews(snapshot.data().views);
          }
        } else {
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (isFirstTime === true) handleProductViews();
  }, [props.product.id]);

  const getArrayIamgesUrlForEdit = (product) => {
    const arrayIamgesUrl = [];
    for (let i = 1; i <= 5; i++) {
      const imageUrlKey = "imageUrl" + i;
      if (product[imageUrlKey] !== "" && product[imageUrlKey]) {
        arrayIamgesUrl.push(product[imageUrlKey]);
      }
    }
    return arrayIamgesUrl;
  };

  function CheckFavorite(productId) {
    if (favoriteProducts) {
      if (favoriteProducts.length === 0) return false;
      else {
        for (let i = 0; i < favoriteProducts.length; i++) {
          if (
            productId === favoriteProducts[i].subjectId &&
            auth.uid === favoriteProducts[i].userId
          ) {
            return true;
          }
          // return false;
        }
        return false;
      }
    }
  }

  const shareButtons = (
    <div className={classes.flex} style={{ padding: "5px" }}>
      <FacebookShareButton
        url={constantsConfig.mainUrl + "product/" + product.id}
        className={classes.shareIcon}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        url={constantsConfig.mainUrl + "product/" + product.id}
        className={classes.shareIcon}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton
        url={constantsConfig.mainUrl + "product/" + product.id}
        className={classes.shareIcon}
      >
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <TelegramShareButton
        url={constantsConfig.mainUrl + "product/" + product.id}
        className={classes.shareIcon}
      >
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>

      <LinkedinShareButton
        url={constantsConfig.mainUrl + "product/" + product.id}
        className={classes.shareIcon}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <EmailShareButton
        url={constantsConfig.mainUrl + "product/" + product.id}
        className={classes.shareIcon}
      >
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </div>
  );

  const renderMakeOffer = (
    <Dialog
      open={openMakeOffer}
      onClose={handleCloseMakeOffer}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //style={{ maxWidth: "300px" }}
    >
      <Fragment>
        <div className={classes.flexOfferHeder}>
          <CloseIcon
            className={classes.xMakerOfferIcon}
            onClick={handleCloseMakeOffer}
          />
          <Typography variant="h5" className={classes.MakerOfferlabel}>
            {intl.formatMessage({
              id: "enter_your_offer",
            })}
          </Typography>
        </div>
        <div className="d-flex justify-content-between"></div>
        <DialogContent className={classes.updateContent}>
          <div>
            <Input
              autoFocus
              placeholder={intl.formatMessage({
                id: "tab_price",
              })}
              id="offerPrice"
              startAdornment={
                <InputAdornment position="start">
                  {getSymbolFromCurrency(props.product.currencyCode)}
                </InputAdornment>
              }
              defaultValue={product.price}
              className={classes.OfferText}
              type="number"
              min={0}
              onChange={handleChangeMakeOfferPrice}
              required
            />
          </div>
          {!openMakeOffer ? <CircularProgress /> : null}
          {makeOfferSpinner === false ? (
            <Button
              color="primary"
              className={classes.offerButton}
              variant="contained"
              onClick={(e) => {
                sendNotifyAction(e);
                handleStartMakeOfferSpinner();
              }}
            >
              {intl.formatMessage({
                id: "product_make_offer",
              })}
            </Button>
          ) : (
            <Button
              color="primary"
              className={classes.offerButton}
              variant="contained"
            >
              <CircularProgress
                size={25}
                className={classes.circularProgressMakeOfferButton}
              />
            </Button>
          )}
        </DialogContent>
      </Fragment>
    </Dialog>
  );
  const isFavorite = CheckFavorite(product.id);
  const arrayIamgesUrlForEdit = getArrayIamgesUrlForEdit(product);
  return (
    <React.Fragment>
      <Snackbar
        open={openAlreadyBoostSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseAlreadyBoostSnackBar}
      >
        <Alert onClose={handleCloseAlreadyBoostSnackBar} severity="info">
          {intl.formatMessage({
            id: "already_boosted",
          })}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.circularProgressBackdrop}
        open={openBackdrop}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SellProductDialog
          threads={threads}
          product={product}
          threadsSpinner={threadsSpinner}
          handleClose={handleCloseDeleteDialog}
        />
      </Dialog>
      <Dialog
        open={openEditDialog}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={"md"}
        className={classes.EditProductDialog}
      >
        <AddProdcut
          productInformation={{
            productsDetails: { ...product },
            currentImagesURL: arrayIamgesUrlForEdit,
            coverImageIndex: -1,
          }}
          handleClose={handleCloseEditDialog}
          allThisFavoriteProduct={allThisFavoriteProduct}
          type={"edit"}
        />
      </Dialog>
      <Dialog
        open={openChatDialog}
        onClose={() => {
          handleCloseChat();
          handleCloseBackDrop();
        }}
        fullWidth
      >
        <MessageComponent thread={thread} isDialog />
      </Dialog>
      <Dialog
        open={
          openBoostDialog &&
          boostedProductId !== product.id &&
          !snackBarBoostProduct
        }
        onClose={handleCloseBoostDialog}
        fullWidth
      >
        <div className={classes.containerBoostDialog}>
          <div className={classes.containerXIcon}>
            <CloseIcon
              className={classes.xIcon}
              onClick={handleCloseBoostDialog}
            />
          </div>
          <div className={classes.flexContentBetween}>
            <DialogTitle id="alert-dialog-title" variant="h5">
              {intl.formatMessage({
                id: "boost_this_product",
              })}
            </DialogTitle>
          </div>

          <BoostDialogContent product={product} />
        </div>
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.shareButtonsMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {shareButtons}
      </Menu>

      <Backdrop className={classes.backdrop} open={openMainBackdrop}>
        <div className={classes.topContainer}>
          <div>
            <CloseIcon
              className={classes.xIcon}
              onClick={() => {
                handleCloseMainBackDrop();
                handleShowSlideShow();
              }}
            />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginTop: "1em" }}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <VisibilityIcon className={classes.icons} />
                </Grid>
                <Grid item>
                  <Typography className={classes.viewsTypography}>
                    {views}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {!auth.uid ? (
                <FavoriteBorderIcon
                  // onClick={this.handleOpenSignUpAndSignIn}
                  className={classes.favoriteBorderIcon}
                />
              ) : (
                <div>
                  {props.favoriteProducts ? (
                    <div>
                      {!isFavorite ? (
                        <div>
                          {loadingFavorite === null ? (
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <FavoriteBorderIcon
                                  onClick={handleClickFavorite}
                                  className={classes.favoriteBorderIcon}
                                />
                              </Grid>
                              <Grid item>
                                {allThisFavoriteProduct ? (
                                  <Typography
                                    className={classes.viewsTypography}
                                  >
                                    {allThisFavoriteProduct.length}
                                  </Typography>
                                ) : null}
                              </Grid>
                            </Grid>
                          ) : (
                            <div>
                              <CircularProgress
                                size={20}
                                className={
                                  classes.circularProgressLoadingFavorite
                                }
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {loadingFavorite === null ? (
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <FavoriteIcon
                                  onClick={handleClickRemoveFavorite}
                                  className={classes.favoriteBorderIcon}
                                />
                              </Grid>
                              <Grid item>
                                {allThisFavoriteProduct ? (
                                  <Typography
                                    className={classes.viewsTypography}
                                  >
                                    {allThisFavoriteProduct.length}
                                  </Typography>
                                ) : null}
                              </Grid>
                            </Grid>
                          ) : (
                            <div>
                              <CircularProgress
                                size={20}
                                className={
                                  classes.circularProgressLoadingFavorite
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </Grid>
            <Grid item>
              <ShareIcon onClick={handleClick} className={classes.icons} />
            </Grid>
          </Grid>
        </div>
        {!matchesSM ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.imgContainer}>
              <img
                src={product.imageUrl1}
                alt="product"
                className={classes.productImage}
              />
            </div>
          </Grid>
        ) : (
          <Swipe onSwipeMove={onSwipeMove}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={classes.imgContainer}>
                <img
                  src={product.imageUrl1}
                  alt="product"
                  className={classes.productImage}
                />
              </div>
            </Grid>
          </Swipe>
        )}
        <div className={classes.bottomContainer}>
          {!matchesSM ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <ArrowBackIosIcon
                  fontSize="large"
                  className={classes.arrows}
                  onClick={() => {
                    props.handlePrevPage();
                    setIsFirstTime(true);
                  }}
                />
              </Grid>
              <Grid item>
                <ArrowForwardIosIcon
                  fontSize="large"
                  className={classes.arrows}
                  onClick={() => {
                    props.handleNextPage();
                    setIsFirstTime(true);
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          {product.distanceKm ? (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              className={classes.bottomGrid}
            >
              <Grid item>
                {product.distanceKm ? (
                  product.countryCode ? (
                    <Typography
                      variant={matchesSM ? "caption" : "h6"}
                      className={classes.typography}
                      align="center"
                    >
                      {product.countryCode === "US"
                        ? parseInt(product.distanceMile) + " miles"
                        : parseInt(product.distanceKm) + " km"}
                    </Typography>
                  ) : (
                    <Typography
                      variant={matchesSM ? "caption" : "h6"}
                      className={classes.typography}
                      align="center"
                    >
                      {product.bingoDealLocation.countryCode === "US"
                        ? parseInt(product.distanceMile) + " miles"
                        : parseInt(product.distanceKm) + " km"}
                    </Typography>
                  )
                ) : null}
              </Grid>
              <Grid item>
                <Typography
                  variant={matchesSM ? "caption" : "h6"}
                  className={classes.typography}
                  align="center"
                >
                  {product.currencyCode === "undefined" ||
                  product.currencyCode === null
                    ? product.price
                    : product.currencyCode +
                      " " +
                      convertPriceToString(product.price)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={matchesSM ? "caption" : "h6"}
                  className={classes.typography}
                  align="center"
                >
                  <ReactTimeAgo date={new Date(product.createdTimestamp)} />
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              className={classes.bottomGrid}
            >
              <Grid item>
                <Typography
                  variant={matchesSM ? "caption" : "h6"}
                  className={classes.typography}
                  align="center"
                >
                  {product.currencyCode === "undefined" ||
                  product.currencyCode === null
                    ? product.price
                    : product.currencyCode +
                      " " +
                      convertPriceToString(product.price)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={matchesSM ? "caption" : "h6"}
                  className={classes.typography}
                  align="center"
                >
                  <ReactTimeAgo date={new Date(product.createdTimestamp)} />
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant={matchesSM ? "caption" : "h6"}>
                {product.title}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  <LocationOnIcon
                    fontSize="small"
                    className={classes.locationIcon}
                  />
                </Typography>
                {product.state ? (
                  <Typography
                    variant={matchesSM ? "caption" : "body1"}
                    className={classes.location}
                  >
                    {(product.city ? product.city + ", " : "") + product.state}
                  </Typography>
                ) : (
                  <Typography
                    variant={matchesSM ? "caption" : "body1"}
                    className={classes.location}
                  >
                    {product.bingoDealLocation &&
                      (product.bingoDealLocation.city
                        ? product.bingoDealLocation.city + ", "
                        : "") + product.bingoDealLocation.state}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {auth.uid === product.sellerId ? (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item>
                {boostedProduct &&
                !product.isBoosted &&
                boostedProduct.length === 0 ? (
                  <Button
                    variant="contained"
                    className={classes.buttons}
                    startIcon={<FaRocket size={matchesSM ? 10 : null} />}
                    onClick={handleOpenBoostDialog}
                  >
                    {intl.formatMessage({
                      id: "boost",
                    })}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttons}
                    startIcon={<FaRocket />}
                    onClick={handleOpenAlreadyBoostSnackBar}
                  >
                    {intl.formatMessage({
                      id: "boost",
                    })}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.buttons}
                  onClick={handleOpenEditDialog}
                >
                  {intl.formatMessage({
                    id: "edit",
                  })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.buttons}
                  onClick={handleOpenDeleteDialog}
                >
                  {intl.formatMessage({
                    id: "mark_as_sold",
                  })}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={messageHandle}
                  className={classes.buttons}
                >
                  {intl.formatMessage({
                    id: "contact_seller",
                  })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  component={Link}
                  to={"/product/" + product.id}
                  className={classes.buttons}
                >
                  {intl.formatMessage({
                    id: "view_details",
                  })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleOpenMakeOffer}
                  className={classes.buttons}
                >
                  {intl.formatMessage({
                    id: "product_make_offer",
                  })}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
        {renderMakeOffer}
      </Backdrop>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.firebase.profile,
    boostedProductId: state.usedProductReducer.boostedProductId,
    boostedProduct: state.firestore.ordered.boosted_products
      ? state.firestore.ordered.boosted_products
      : null,
    allThisFavoriteProduct: state.firestore.ordered.allThisFavoriteProduct,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    createFavoriteProduct: (product) =>
      dispatch(createFavoriteProduct(product)),
    removeFavoriteProduct: (product) =>
      dispatch(removeFavoriteProduct(product)),
    addWishListLogging: (product) => dispatch(addWishListLogging(product)),
    createAction: (actionDetails) => dispatch(createAction(actionDetails)),
    updateViewsOrFavorites: (data) => dispatch(updateViewsOrFavorites(data)),
    addNewDocumentToViews: (data) => dispatch(addNewDocumentToViews(data)),
    contactSellerLogging: (product) => dispatch(contactSellerLogging(product)),
    contactSellerFacebookPixel: (product) =>
      dispatch(contactSellerFacebookPixel(product)),
    makeOfferLogging: (product) => dispatch(makeOfferLogging(product)),
    makeOfferFacebookPixel: (product) =>
      dispatch(makeOfferFacebookPixel(product)),
    deleteFavoriteProduct: (productId) =>
      dispatch(deleteFavoriteProduct(productId)),
  };
};

export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
      if (props.product) {
        return [
          {
            collection: constantsConfig.collectionBoostedProducts,
            where: [["id", "==", props.product.id]],
            storeAs: constantsConfig.collectionBoostedProducts,
          },
        ];
      } else return [];
    }),
    firestoreConnect((props) => {
      if (props.product) {
        return [
          {
            collection: constantsConfig.collectionFavorites,
            where: ["subjectId", "==", props.product.id],
            storeAs: "allThisFavoriteProduct",
          },
        ];
      } else return [];
    })
  )(SlideShowProducts)
);
