import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import firebase from 'firebase/compat/app';
import ProductsGrid from '../products/ProductsGrid';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import DrawerAdmins from '../layout/DrawerAdmins';
const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  spinnerGrid: {
    flexGrow: 1,
    padding: '0px 20px 30px 20px'
  },
  mainContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.background,
    paddingTop: '10px'
  },
  gridCircularProgress: {
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.background
  },
  circularProgress: {
    color: theme.palette.primary.main
  }
});

class DashboardProductsAllCountries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      lastDoc: null,
      spinner: false
    };
  }
  componentDidMount() {
    firebase
      .firestore()
      .collection('products')
      .orderBy('createdTimestamp', 'desc')
      .limit(20)
      .get()
      .then((collections) => {
        const products = collections.docs.map((product) => product.data());
        const lastDoc = collections.docs[collections.docs.length - 1];
        this.setState({
          products: products,
          lastDoc: lastDoc
        });
      });
    window.addEventListener(
      'scroll',
      _.debounce(() => {
        const windowHeight =
          'innerHeight' in window
            ? window.innerHeight
            : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );
        const windowBottom = Math.round(windowHeight + window.pageYOffset);
        // Small hack for windows. It counts windowBottom in different way
        const difference = docHeight - windowBottom;
        const additional = difference >= 1 && difference <= 2 ? difference : 0;
        if (windowBottom + additional >= docHeight && this.state.lastDoc) {
          this.setState({
            spinner: true
          });
          this.handleFetchMore();
        }
      }, 1000)
    );
  }
  handleFetchMore = () => {
    if (this.state.lastDoc) {
      firebase
        .firestore()
        .collection('products')
        .orderBy('createdTimestamp', 'desc')
        .startAfter(this.state.lastDoc)
        .limit(20)
        .get()
        .then((collections) => {
          const products = collections.docs.map((product) => product.data());
          const lastDoc = collections.docs[collections.docs.length - 1];
          this.setState({
            products: [...this.state.products, ...products],
            lastDoc: lastDoc,
            spinner: false
          });
        });
    }
  };
  render() {
    const { classes, profile } = this.props;
    if (profile) {
      if (profile.isAdmin)
        return (
          <div className={classes.root}>
            <DrawerAdmins />
            <main className={classes.mainContent}>
              <ProductsGrid
                products={this.state.products}
                history={this.props.history}
              />
              {this.state.spinner === true ? (
                <Grid container className={classes.spinnerGrid} spacing={0}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={0}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </main>
          </div>
        );
      else return <Navigate to="/" />;
    } else
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.gridCircularProgress}
        >
          <Grid item xs={3}>
            <CircularProgress className={classes.circularProgress} />
          </Grid>
        </Grid>
      );
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    profile: state.firestore.ordered.users
      ? state.firestore.ordered.users[0]
      : null
  };
};

const mapDispatchToProps = (state, props) => {
  return {};
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardProductsAllCountries);
