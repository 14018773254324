// @flow
import React from "react";
import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { injectIntl } from "react-intl";
import firebase from "firebase/compat/app";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MultipleImageUpload from "./images/MultipleImageUpload";
import MapComponent from "../other/MapComponent";
import { createProduct, editProduct } from "../../store/product/ProductActions";
import { constantsConfig } from "../../config/ConstantsConfig";
import {
  postLogging,
  purchaseLogging,
} from "../../store/analyticsLogging/GoogleLogging";
import {
  purchaseFacebookPixel,
  productPostFacebookPixel,
} from "../../store/analyticsLogging/FacebookPixel";
import { isEmptyOrSpaces } from "../../functions/Functions";
import AddOtherModel from "./AddOtherModel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function translateConditionStatus(condition, intl) {
  if (condition === "Other (see description)")
    return intl.formatMessage({
      id: "condition_other",
    });
  if (condition === "For Parts")
    return intl.formatMessage({
      id: "condition_for_parts",
    });
  if (condition === "Used (normal wear)")
    return intl.formatMessage({
      id: "condition_used",
    });
  if (condition === "New (never used)")
    return intl.formatMessage({
      id: "condition_new",
    });
}

function translateTransmission(transmission, intl) {
  if (transmission === "Automatic")
    return intl.formatMessage({
      id: "automatic_transmission",
    });
  if (transmission === "Manual")
    return intl.formatMessage({
      id: "manual_transmission",
    });
}

function translateBodyStyles(bodyStyle, intl) {
  if (bodyStyle === "Convertible")
    return intl.formatMessage({
      id: "convertible",
    });
  if (bodyStyle === "Coupe")
    return intl.formatMessage({
      id: "coupe",
    });
  if (bodyStyle === "Truck")
    return intl.formatMessage({
      id: "truck",
    });
  if (bodyStyle === "Wagon & Hatchback")
    return intl.formatMessage({
      id: "wagon_hatchback",
    });
  if (bodyStyle === "Van & Minivan")
    return intl.formatMessage({
      id: "van_minivan",
    });
  if (bodyStyle === "Sedan")
    return intl.formatMessage({
      id: "sedan",
    });
  if (bodyStyle === "SUV & Crossover")
    return intl.formatMessage({
      id: "suv_crossover",
    });
  if (bodyStyle === "Other")
    return intl.formatMessage({
      id: "product_other",
    });
}

function translateFuelTypes(fuelType, intl) {
  if (fuelType === "Diesel")
    return intl.formatMessage({
      id: "fuel_diesel",
    });
  if (fuelType === "Electric")
    return intl.formatMessage({
      id: "fuel_electric",
    });
  if (fuelType === "Gasoline")
    return intl.formatMessage({
      id: "fuel_gasoline",
    });
  if (fuelType === "Flex")
    return intl.formatMessage({
      id: "fuel_flex",
    });
  if (fuelType === "Hybrid")
    return intl.formatMessage({
      id: "fuel_hybrid",
    });
  if (fuelType === "Other")
    return intl.formatMessage({
      id: "product_other",
    });
  if (fuelType === "Petrol")
    return intl.formatMessage({
      id: "fuel_petrol",
    });
  if (fuelType === "Plug-in hybrid")
    return intl.formatMessage({
      id: "fuel_plugin_hybrid",
    });
}

function translateColors(color, intl) {
  if (color === "Black")
    return intl.formatMessage({
      id: "black_color",
    });
  if (color === "Blue")
    return intl.formatMessage({
      id: "blue_color",
    });
  if (color === "Brown")
    return intl.formatMessage({
      id: "brown_color",
    });
  if (color === "Gold")
    return intl.formatMessage({
      id: "gold_color",
    });
  if (color === "Green")
    return intl.formatMessage({
      id: "green_color",
    });
  if (color === "Grey")
    return intl.formatMessage({
      id: "grey_color",
    });
  if (color === "Pink")
    return intl.formatMessage({
      id: "pink_color",
    });
  if (color === "Purple")
    return intl.formatMessage({
      id: "purple_color",
    });
  if (color === "Red")
    return intl.formatMessage({
      id: "red_color",
    });
  if (color === "Silver")
    return intl.formatMessage({
      id: "silver_color",
    });
  if (color === "Orange")
    return intl.formatMessage({
      id: "orange_color",
    });
  if (color === "White")
    return intl.formatMessage({
      id: "white_color",
    });
  if (color === "Yellow")
    return intl.formatMessage({
      id: "yellow_color",
    });
  if (color === "Charcol")
    return intl.formatMessage({
      id: "charcol_color",
    });
  if (color === "Off White")
    return intl.formatMessage({
      id: "off_white_color",
    });
  if (color === "Tan")
    return intl.formatMessage({
      id: "tan_color",
    });
  if (color === "Beige")
    return intl.formatMessage({
      id: "beige_color",
    });
  if (color === "Burgundy")
    return intl.formatMessage({
      id: "burgundy_color",
    });
  if (color === "Turquoise")
    return intl.formatMessage({
      id: "turquoise_color",
    });
}

function translateTitleStatus(title, intl) {
  if (title === "Clean")
    return intl.formatMessage({
      id: "title_status_clean",
    });
  if (title === "Rebuilt")
    return intl.formatMessage({
      id: "title_status_rebuilt",
    });
  if (title === "Salvage")
    return intl.formatMessage({
      id: "title_status_salvage",
    });
  if (title === "Missing")
    return intl.formatMessage({
      id: "title_status_missing",
    });
  if (title === "Lien")
    return intl.formatMessage({
      id: "title_status_lien",
    });
}

function translateDescriptionStatus(description, intl) {
  if (description === "Most vehicles that haven't been in accident")
    return intl.formatMessage({
      id: "clean_description",
    });
  if (description === "A salvaged car that has been rebuilt")
    return intl.formatMessage({
      id: "rebuilt_description",
    });
  if (description === "Is considered totalled after an accident")
    return intl.formatMessage({
      id: "salvage_description",
    });
  if (description === "Will need a new DMV issues title")
    return intl.formatMessage({
      id: "missing_description",
    });
  if (description === "Owned by a bank or court settlement")
    return intl.formatMessage({
      id: "lien_description",
    });
}

function translateDrivetrains(drivetrain, intl) {
  if (drivetrain === "AWD/4WD")
    return intl.formatMessage({
      id: "awd_4wd",
    });
  if (drivetrain === "FWD")
    return intl.formatMessage({
      id: "fwd",
    });
  if (drivetrain === "RWD")
    return intl.formatMessage({
      id: "rwd",
    });
}

function test(state) {
  var vehicleMetaData =
    state.vehicleMake +
    " " +
    state.vehicleModel +
    " " +
    state.vehicleYear +
    " " +
    state.vehicleBody +
    " " +
    state.vehicleExteriorColor;

  for (let i = 0; i < 5; i++) {
    if (i === 0 && state.vehicleDrivetrain) {
      vehicleMetaData = vehicleMetaData + " " + state.vehicleDrivetrain;
    }
    if (i === 1 && state.vehicleTransmission) {
      vehicleMetaData = vehicleMetaData + " " + state.vehicleTransmission;
    }
    if (i === 2 && state.vehicleInteriorColor) {
      vehicleMetaData = vehicleMetaData + " " + state.vehicleInteriorColor;
    }
    if (i === 3 && state.vehicleFuelType) {
      vehicleMetaData = vehicleMetaData + " " + state.vehicleFuelType;
    }
    if (i === 4 && state.vehicleTitleStatus) {
      vehicleMetaData = vehicleMetaData + " " + state.vehicleTitleStatus;
    }
  }
  return vehicleMetaData;
}

const styles = (theme) => ({
  xIcon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    color: theme.palette.primary.secondaryText,
    marginInlineEnd: "10px",
  },
  layout: {
    width: "auto",
    marginTop: "35px",
    marginInlineStart: theme.spacing(2),
    marginInlineEnd: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    marginTop: "1em",
  },
  textField: {
    width: "100%",
  },
  errImageMessageTypography: {
    color: theme.palette.primary.errColor,
  },
  buttonProgress: {
    color: theme.palette.primary.icons,
  },
  postButton: {
    ...theme.typography.normalButton,
  },
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
});

class AddVehicle extends Component {
  constructor(props) {
    super(props);
    var years = [];
    for (let i = 2022; i >= 1901; i--) {
      years.push(i);
    }
    if (props.type === "add") {
      this.state = {
        ...props.locationConfig,
        isFirstTime: false,
        isOpenSnackbar: false,
        isVehicleYearNull: false,
        isVehicleMakeNull: false,
        isVehicleModelNull: false,
        isVehicleBodyStyleNull: false,
        isVehicleMileageNull: false,
        isVehicleExteriorColor: false,
        isPriceNull: false,
        isDescriptionNull: false,
        isImageUrlNull: false,
        makeFromOthers: false,
        modelFromOthers: false,
        spinner: false,
        disabled: false,
        currency: "EUR",
        currencyCode: props.currencyCode,
        currentImagesURL: [],
        coverImageIndex: -1,
        tags: [],
        onTagsProcess: 0,
        imageUrl1: null,
        imageUrl2: null,
        imageUrl3: null,
        imageUrl4: null,
        imageUrl5: null,
        years: years,
        vehicleModels: null,
        defaultConditionStatus: "Used (normal wear)",
        condition: 2,
        conditionStatus: "Used (normal wear)",
        transmission: ["Automatic", "Manual"],
        bodyStyles: [
          "Convertible",
          "Coupe",
          "Truck",
          "Wagon & Hatchback",
          "Van & Minivan",
          "Sedan",
          "SUV & Crossover",
          "Other",
        ],
        fuelTypes: [
          "Diesel",
          "Electric",
          "Gasoline",
          "Flex",
          "Hybrid",
          "Other",
          "Petrol",
          "Plug-in hybrid",
        ],
        colors: [
          "Black",
          "Blue",
          "Brown",
          "Gold",
          "Green",
          "Grey",
          "Pink",
          "Purple",
          "Red",
          "Silver",
          "Orange",
          "White",
          "Yellow",
          "Charcol",
          "Off White",
          "Tan",
          "Beige",
          "Burgundy",
          "Turquoise",
        ],
        titleStatus: [
          {
            title: "Clean",
            description: "Most vehicles that haven't been in accident",
          },
          {
            title: "Rebuilt",
            description: "A salvaged car that has been rebuilt",
          },
          {
            title: "Salvage",
            description: "Is considered totalled after an accident",
          },
          {
            title: "Missing",
            description: "Will need a new DMV issues title",
          },
          {
            title: "Lien",
            description: "Owned by a bank or court settlement",
          },
        ],
        driveTrains: ["AWD/4WD", "FWD", "RWD"],
        numberOfOwners: ["1", "2", "3+", "Not Sure"],
        conditionsStatusArray: [
          "Other (see description)",
          "For Parts",
          "Used (normal wear)",
          "New (never used)",
        ],
        vehicleMileageUnit: props.profile
          ? props.profile.countryCode
            ? props.profile.countryCode === "US"
              ? "Miles"
              : "KM"
            : "Miles"
          : "Miles",
        vehicleYear: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleTransmission: "",
        vehicleBody: "",
        vehicleFuelType: "",
        vehicleExteriorColor: "",
        vehicleInteriorColor: "",
        vehicleTitleStatus: "",
        vehicleMileage: null,
        vehicleDrivetrain: "",
        vehicleNumberOfOwners: "",
        vehicleMoneyOwed: "",
        vehicleVin: "",
        description: "",
        vehicleOtherMake: "",
        price: 1,
        vehicleLocation: null,
        vehicleMakeId: null,
        vehicleModelId: null,
        isVehicleOtherMake: false,
        openOthersDialog: false,
      };
    } else {
      this.state = {
        ...props.locationConfig,
        isFirstTime: false,
        isOpenSnackbar: false,
        isVehicleYearNull: false,
        isVehicleMakeNull: false,
        isVehicleModelNull: false,
        isVehicleBodyStyleNull: false,
        isVehicleMileageNull: false,
        isVehicleExteriorColor: false,
        isPriceNull: false,
        isDescriptionNull: false,
        isImageUrlNull: false,
        spinner: false,
        disabled: false,
        currency: "EUR",
        allThisFavoriteProduct: props.allThisFavoriteProduct,
        currencyCode: props.currencyCode,
        currentImagesURL: props.currentImagesURL,
        coverImageIndex: props.coverImageIndex,
        tags: props.productData.tags ? props.productData.tags : [],
        onTagsProcess: 0,
        imageUrl1: props.productData.imageUrl1
          ? props.productData.imageUrl1
          : null,
        imageUrl2: props.productData.imageUrl2
          ? props.productData.imageUrl2
          : null,
        imageUrl3: props.productData.imageUrl3
          ? props.productData.imageUrl3
          : null,
        imageUrl4: props.productData.imageUrl4
          ? props.productData.imageUrl4
          : null,
        imageUrl5: props.productData.imageUrl5
          ? props.productData.imageUrl5
          : null,
        price: props.productData.price ? props.productData.price : 1,
        productId: props.productData.id,
        makeFromOthers:
          props.productData.vehicleMakeId !== constantsConfig.vehicleOtherId
            ? false
            : true,
        years: years,
        vehicleModels: props.productData.vehicleModels
          ? props.productData.vehicleModels
          : null,
        defaultConditionStatus: "Used (normal wear)",
        condition: props.productData.condition
          ? props.productData.condition
          : null,
        conditionStatus: props.productData.conditionStatus
          ? props.productData.conditionStatus
          : null,
        transmission: ["Automatic", "Manual"],
        bodyStyles: [
          "Convertible",
          "Coupe",
          "Truck",
          "Wagon & Hatchback",
          "Van & Minivan",
          "Sedan",
          "SUV & Crossover",
          "Other",
        ],
        fuelTypes: [
          "Diesel",
          "Electric",
          "Gasoline",
          "Flex",
          "Hybrid",
          "Other",
          "Petrol",
          "Plug-in hybrid",
        ],
        colors: [
          "Black",
          "Blue",
          "Brown",
          "Gold",
          "Green",
          "Grey",
          "Pink",
          "Purple",
          "Red",
          "Silver",
          "Orange",
          "White",
          "Yellow",
          "Charcol",
          "Off White",
          "Tan",
          "Beige",
          "Burgundy",
          "Turquoise",
        ],
        titleStatus: [
          {
            title: "Clean",
            description: "Most vehicles that haven't been in accident",
          },
          {
            title: "Rebuilt",
            description: "A salvaged car that has been rebuilt",
          },
          {
            title: "Salvage",
            description: "Is considered totalled after an accident",
          },
          {
            title: "Missing",
            description: "Will need a new DMV issues title",
          },
          {
            title: "Lien",
            description: "Owned by a bank or court settlement",
          },
        ],
        driveTrains: ["AWD/4WD", "FWD", "RWD"],
        numberOfOwners: ["1", "2", "3+", "Not Sure"],
        conditionsStatusArray: [
          "Other (see description)",
          "For Parts",
          "Used (normal wear)",
          "New (never used)",
        ],
        vehicleMileageUnit: props.productData.vehicleMileageUnit
          ? props.productData.vehicleMileageUnit
          : null,
        vehicleYear: props.productData.vehicleYear
          ? props.productData.vehicleYear
          : "",
        vehicleMake:
          props.productData.vehicleMakeId === constantsConfig.vehicleOtherId
            ? "other"
            : props.productData.vehicleMake
            ? props.productData.vehicleMake
            : "",
        vehicleMakeId: props.productData.vehicleMakeId
          ? props.productData.vehicleMakeId
          : null,
        vehicleModel: props.productData.vehicleModel
          ? props.productData.vehicleModel
          : "",
        vehicleModelId: props.productData.vehicleModelId
          ? props.productData.vehicleModelId
          : null,
        vehicleTransmission: props.productData.vehicleTransmission
          ? props.productData.vehicleTransmission
          : "",
        vehicleBody: props.productData.vehicleBody
          ? props.productData.vehicleBody
          : "",
        vehicleFuelType: props.productData.vehicleFuelType
          ? props.productData.vehicleFuelType
          : "",
        vehicleExteriorColor: props.productData.vehicleExteriorColor
          ? props.productData.vehicleExteriorColor
          : "",
        vehicleInteriorColor: props.productData.vehicleInteriorColor
          ? props.productData.vehicleInteriorColor
          : "",
        vehicleTitleStatus: props.productData.vehicleTitleStatus
          ? props.productData.vehicleTitleStatus
          : "",
        vehicleMileage: props.productData.vehicleMileage
          ? props.productData.vehicleMileage
          : null,
        vehicleDrivetrain: props.productData.vehicleDrivetrain
          ? props.productData.vehicleDrivetrain
          : "",
        vehicleNumberOfOwners: props.productData.vehicleNumberOfOwners
          ? props.productData.vehicleNumberOfOwners
          : "",
        vehicleMoneyOwed: props.productData.vehicleMoneyOwed,
        vehicleVin: props.productData.vehicleVin
          ? props.productData.vehicleVin
          : "",
        description: props.productData.description
          ? props.productData.description
          : "",
        vehicleOtherMake:
          props.productData.vehicleMakeId === constantsConfig.vehicleOtherId
            ? props.productData.vehicleMake
            : "",
        modelFromOthers: props.productData.vehicleModelId ? false : true,
        isVehicleOtherMake: false,
        openOthersDialog: false,
        vehicleLocation: {
          city: props.productData.city ? props.productData.city : null,
          country: props.productData.country ? props.productData.country : null,
          countryCode: props.productData.countryCode
            ? props.productData.countryCode
            : null,
          currency: props.productData.currencyCode
            ? props.productData.currencyCode
            : null,
          latitude: props.productData.latitude
            ? props.productData.latitude
            : null,
          longitude: props.productData.longitude
            ? props.productData.longitude
            : null,
          readableLocation: props.productData.readableLocation
            ? props.productData.readableLocation
            : null,
          state: props.productData.state ? props.productData.state : null,
          zip: props.productData.zip ? props.productData.zip : null,
        },
      };
    }
  }

  componentDidMount() {
    if (this.props.type === "edit") {
      this.handleGetModels(this.props.productData.vehicleMakeId);
    }
  }

  handleGetModels = async (vehicleId) => {
    const query = firebase
      .firestore()
      .collection("vehicles_models")
      .doc(vehicleId)
      .collection("models");

    const snapshot = await query.get();
    const model = snapshot.docs.map((doc) => doc.data());
    this.setState({
      spinner: false,
      vehicleModels: model,
    });
  };

  changeCoverIndex = (value) => {
    this.setState({
      coverImageIndex: 0,
    });

    var imageUrl1 = this.state.imageUrl1;
    var currentImage = this.state.currentImagesURL[value];
    var currentImage1 = this.state.currentImagesURL[0];
    var currentImagesURL = this.state.currentImagesURL;

    switch (value) {
      case 0:
        break;
      case 1:
        var imageUrl2 = this.state.imageUrl2;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          imageUrl2: imageUrl1,
          imageUrl1: imageUrl2,
        });

        break;
      case 2:
        var imageUrl3 = this.state.imageUrl3;

        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          imageUrl3: imageUrl1,
          imageUrl1: imageUrl3,
        });
        break;
      case 3:
        var imageUrl4 = this.state.imageUrl4;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;
        this.setState({
          imageUrl4: imageUrl1,
          imageUrl1: imageUrl4,
        });
        break;
      case 4:
        var imageUrl5 = this.state.imageUrl5;
        currentImagesURL[0] = currentImage;
        currentImagesURL[value] = currentImage1;

        this.setState({
          imageUrl5: imageUrl1,
          imageUrl1: imageUrl5,
        });
        break;
      default:
        break;
    }
  };

  handleChange = (event, type) => {
    const digits_only = (string) =>
      [...string].every((c) => "0123456789".includes(c));
    if (type === "vehicleYear")
      this.setState({
        vehicleYear: event.target.value,
        isVehicleYearNull: false,
      });
    if (type === "vehicleMake") {
      for (let i = 0; i < this.props.vehiclesMake.length; i++) {
        if (this.props.vehiclesMake[i].make === event.target.value) {
          if (
            this.props.vehiclesMake[i].makeId === constantsConfig.vehicleOtherId
          ) {
            this.setState({
              vehicleMake: this.props.vehiclesMake[i].make,
              vehicleMakeId: this.props.vehiclesMake[i].makeId,
              vehicleModel: "",
              vehicleModelId: null,
              makeFromOthers: true,
              isVehicleMakeNull: false,
              modelFromOthers: false,
            });
          } else {
            this.setState({
              vehicleMake: this.props.vehiclesMake[i].make,
              vehicleMakeId: this.props.vehiclesMake[i].makeId,
              vehicleModel: "",
              vehicleModelId: null,
              spinner: true,
              makeFromOthers: false,
              isVehicleMakeNull: false,
              modelFromOthers: false,
            });
            const vehicleId = this.props.vehiclesMake[i].makeId;
            this.handleGetModels(vehicleId);
            break;
          }
        }
      }
    }
    if (type === "vehicleOtherMake")
      this.setState({
        isVehicleOtherMake: false,
        vehicleOtherMake: event.target.value,
      });
    if (type === "vehicleModel") {
      if (this.state.makeFromOthers) {
        this.setState({
          model: event.target.value,
          vehicleModel: event.target.value,
          isVehicleModelNull: false,
        });
      } else {
        for (let i = 0; i < this.state.vehicleModels.length; i++) {
          if (this.state.vehicleModels[i].model === event.target.value) {
            this.setState({
              model: this.state.vehicleModels[i].model,
              vehicleModel: this.state.vehicleModels[i].model,
              vehicleModelId: this.state.vehicleModels[i].modelId,
              isVehicleModelNull: false,
            });
            break;
          }
        }
      }
    }
    if (type === "vehicleTransmission")
      this.setState({
        vehicleTransmission: event.target.value,
      });
    if (type === "vehicleBody")
      this.setState({
        vehicleBody: event.target.value,
        isVehicleBodyStyleNull: false,
      });
    if (type === "vehicleFuelType")
      this.setState({
        vehicleFuelType: event.target.value,
      });
    if (type === "vehicleExteriorColor")
      this.setState({
        vehicleExteriorColor: event.target.value,
        isVehicleExteriorColor: false,
      });
    if (type === "vehicleInteriorColor")
      this.setState({
        vehicleInteriorColor: event.target.value,
      });
    if (type === "vehicleTitleStatus")
      this.setState({
        vehicleTitleStatus: event.target.value,
      });
    if (type === "vehicleMileage") {
      if (!digits_only(event.target.value))
        this.setState({
          vehicleMileage: event.target.value,
          isVehicleMileageNull: true,
        });
      else
        this.setState({
          vehicleMileage: event.target.value,
          isVehicleMileageNull: false,
        });
    }
    if (type === "vehicleDrivetrain")
      this.setState({
        vehicleDrivetrain: event.target.value,
      });
    if (type === "vehicleNumberOfOwners")
      this.setState({
        vehicleNumberOfOwners: event.target.value,
      });
    if (type === "vehicleMoneyOwed")
      this.setState({
        vehicleMoneyOwed: event.target.value,
      });

    if (type === "description")
      this.setState({
        description: event.target.value,
        isDescriptionNull: false,
      });
    if (type === "price") {
      if (
        parseInt(event.target.value) === 0 ||
        !digits_only(event.target.value)
      ) {
        this.setState({
          price: event.target.value,
          isPriceNull: true,
        });
      } else {
        this.setState({
          price: event.target.value,
          isPriceNull: false,
        });
      }
    }
    if (type === "vehicleVin") {
      this.setState({
        vehicleVin: event.target.value,
      });
    }
  };

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "images":
        switch (value) {
          case 0:
            this.setState({
              imageUrl1: "",
            });
            for (let i = 2; i <= 5; i++) {
              if (i === 2) {
                if (this.state.imageUrl2 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl2,
                    imageUrl2: "",
                  });
                  break;
                }
              } else if (i === 3) {
                if (this.state.imageUrl3 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl3,
                    imageUrl3: "",
                  });
                  break;
                }
              } else if (i === 4) {
                if (this.state.imageUrl4 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl4,
                    imageUrl4: "",
                  });
                  break;
                }
              } else {
                if (this.state.imageUrl5 !== "") {
                  this.setState({
                    imageUrl1: this.state.imageUrl5,
                    imageUrl5: "",
                  });
                  break;
                }
              }
            }
            break;
          case 1:
            this.setState({
              imageUrl2: "",
            });
            break;
          case 2:
            this.setState({
              imageUrl3: "",
            });
            break;
          case 3:
            this.setState({
              imageUrl4: "",
            });
            break;
          case 4:
            this.setState({
              imageUrl5: "",
            });
            break;
          default:
            break;
        }
        this.state.currentImagesURL.splice(value, 1);

        break;
      case "image":
        switch (value.index) {
          case 0:
            this.setState({
              imageUrl1: value.url,
              isImageUrlNull: false,
            });
            break;
          case 1:
            this.setState({
              imageUrl2: value.url,
            });
            break;
          case 2:
            this.setState({
              imageUrl3: value.url,
            });
            break;
          case 3:
            this.setState({
              imageUrl4: value.url,
            });
            break;
          case 4:
            this.setState({
              imageUrl5: value.url,
            });
            break;
          default:
            break;
        }

        break;
      case "coverImageIndex":
        this.changeCoverIndex(value);
        break;
      case "map":
        this.setState({
          vehicleLocation: value,
        });
        break;

      //default value...
      default:
        break;
    }
  };

  handleClickOthersModel = () => {
    this.setState({
      vehicleModel: "",
      vehicleModelId: null,
      modelFromOthers: true,
    });
  };

  handleCloseOthersDialog = () => {
    this.setState({
      openOthersDialog: false,
    });
  };

  handleChangeOthersModel = (event) => {
    this.setState({
      vehicleModel: event.target.value,
    });
    if (!isEmptyOrSpaces(event.target.value))
      this.setState({
        isVehicleModelNull: false,
      });
  };

  handleCloseSnackbar = () => {
    this.setState({
      isOpenSnackbar: false,
    });
  };

  onHandleChangeImage = (type, value) => {
    this.handleChangeValue(type, value);
  };

  onHandleChangeCondition = (event, value) => {
    this.setState({
      condition: value,
      conditionStatus: this.state.conditionsStatusArray[value],
    });
  };

  handleSubmit = () => {
    if (
      !this.state.vehicleYear ||
      !this.state.vehicleMake ||
      (this.state.vehicleMakeId === constantsConfig.vehicleOtherId &&
        isEmptyOrSpaces(this.state.vehicleOtherMake) === true) ||
      isEmptyOrSpaces(this.state.vehicleModel) === true ||
      !this.state.vehicleBody ||
      !this.state.vehicleMileage ||
      !this.state.vehicleExteriorColor ||
      !this.state.imageUrl1 ||
      !this.state.price ||
      (this.state.price && parseInt(this.state.price) <= 0)
    ) {
      this.setState({
        isOpenSnackbar: true,
      });
      if (!this.state.vehicleYear) {
        this.setState({
          isVehicleYearNull: true,
        });
      }
      if (!this.state.vehicleMake) {
        this.setState({
          isVehicleMakeNull: true,
        });
      }
      if (!this.state.vehicleOtherMake)
        this.setState({
          isVehicleOtherMake: true,
        });
      if (isEmptyOrSpaces(this.state.vehicleModel) === true) {
        this.setState({
          isVehicleModelNull: true,
        });
      }
      if (!this.state.vehicleBody) {
        this.setState({
          isVehicleBodyStyleNull: true,
        });
      }
      if (!this.state.vehicleMileage) {
        this.setState({
          isVehicleMileageNull: true,
        });
      }
      if (!this.state.vehicleExteriorColor) {
        this.setState({
          isVehicleExteriorColor: true,
        });
      }
      if (!this.state.imageUrl1) {
        this.setState({
          isImageUrlNull: true,
        });
      }
      if (
        !this.state.price ||
        (this.state.price && parseInt(this.state.price) <= 0)
      ) {
        this.setState({
          isPriceNull: true,
        });
      }
    } else {
      this.setState({
        disabled: true,
      });
      const vehicleMetaData = test(this.state);
      this.setState({
        vehicleMetaData: vehicleMetaData,
      });
      var tags = [];
      tags.push(this.state.vehicleYear.toString());
      tags.push(
        this.state.vehicleMakeId !== constantsConfig.vehicleOtherId
          ? this.state.vehicleMake.toString()
          : this.state.vehicleOtherMake.toString()
      );
      tags.push(this.state.vehicleModel.toString());
      tags.push(this.state.vehicleBody.toString());
      tags.push(this.state.vehicleExteriorColor.toString());
      if (this.state.vehicleDrivetrain)
        tags.push(this.state.vehicleDrivetrain.toString());
      if (this.state.vehicleTransmission)
        tags.push(this.state.vehicleTransmission.toString());
      if (this.state.vehicleInteriorColor)
        tags.push(this.state.vehicleInteriorColor.toString());
      if (this.state.vehicleFuelType)
        tags.push(this.state.vehicleFuelType.toString());
      if (this.state.vehicleTitleStatus)
        tags.push(this.state.vehicleTitleStatus.toString());
      if (this.state.vehicleVin) tags.push(this.state.vehicleVin.toString());
      var loggingProduct = {
        title:
          (this.state.vehicleMakeId !== constantsConfig.vehicleOtherId
            ? this.state.vehicleMake
            : this.state.vehicleOtherMake) +
          " " +
          this.state.vehicleModel +
          " " +
          this.state.vehicleYear,
        category: this.props.vehiclesCategory,
      };
      if (this.props.type === "add") {
        const updateVehicle = {
          imageUrl1: this.state.imageUrl1,
          imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
          imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
          imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
          imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
          vehicleYear: this.state.vehicleYear,
          vehicleMake:
            this.state.vehicleMakeId !== constantsConfig.vehicleOtherId
              ? this.state.vehicleMake
              : this.state.vehicleOtherMake,
          vehicleMakeId: this.state.vehicleMakeId,
          vehicleMetaData: vehicleMetaData,
          vehicleModel: this.state.vehicleModel,
          vehicleModelId: this.state.vehicleModelId,
          vehicleBody: this.state.vehicleBody ? this.state.vehicleBody : null,
          vehicleExteriorColor: this.state.vehicleExteriorColor
            ? this.state.vehicleExteriorColor
            : null,
          vehicleMileage: parseInt(this.state.vehicleMileage),
          vehicleMileageUnit:
            this.state.vehicleLocation.countryCode === "US" ? "Miles" : "KM",
          price: parseInt(this.state.price),
          formattedPrice: this.state.price.toString(),
          title:
            (this.state.vehicleMakeId !== constantsConfig.vehicleOtherId
              ? this.state.vehicleMake
              : this.state.vehicleOtherMake) +
            " " +
            this.state.vehicleModel +
            " " +
            this.state.vehicleYear,
          condition: this.state.condition,
          conditionStatus: this.state.conditionStatus,
          rating: 3.5,
          tags: tags,
          vehicleDrivetrain: this.state.vehicleDrivetrain
            ? this.state.vehicleDrivetrain
            : null,
          vehicleTransmission: this.state.vehicleTransmission
            ? this.state.vehicleTransmission
            : null,
          vehicleInteriorColor: this.state.vehicleInteriorColor
            ? this.state.vehicleInteriorColor
            : null,
          vehicleFuelType: this.state.vehicleFuelType
            ? this.state.vehicleFuelType
            : null,
          vehicleTitleStatus: this.state.vehicleTitleStatus
            ? this.state.vehicleTitleStatus
            : null,
          vehicleMoneyOwed:
            this.state.vehicleMoneyOwed === "Yes"
              ? true
              : this.state.vehicleMoneyOwed === "No"
              ? false
              : null,
          vehicleNumberOfOwners: this.state.vehicleNumberOfOwners
            ? this.state.vehicleNumberOfOwners.toString()
            : null,
          vehicleVin: this.state.vehicleVin
            ? this.state.vehicleVin.toString()
            : null,
          description: !isEmptyOrSpaces(this.state.description)
            ? this.state.description
            : null,
          category: this.props.vehiclesCategory,
          categoryId: this.props.vehiclesCategory.categoryId,
          isSoldOnBingoDeal: false,
          isFoodItem: false,
          isBoosted: false,
          currencyCode: this.state.currencyCode,
          impressions: 0,
          clicks: 0,
          boostTimestamp: 0,
          isActive: true,
          isUsedProduct: true,
          platform: "web",
          firmOnPrice: false,
          seller: this.props.profile,
          sellerId: this.props.profile.id,
          ...this.state.vehicleLocation,
        };
        this.props.createProduct(updateVehicle);
        this.props.postLogging(loggingProduct);
        this.props.purchaseLogging(updateVehicle);
        this.props.purchaseFacebookPixel(loggingProduct);
        this.props.productPostFacebookPixel(loggingProduct);
      } else {
        const updateVehicle = {
          imageUrl1: this.state.imageUrl1,
          imageUrl2: this.state.imageUrl2 ? this.state.imageUrl2 : null,
          imageUrl3: this.state.imageUrl3 ? this.state.imageUrl3 : null,
          imageUrl4: this.state.imageUrl4 ? this.state.imageUrl4 : null,
          imageUrl5: this.state.imageUrl5 ? this.state.imageUrl5 : null,
          vehicleYear: this.state.vehicleYear,
          vehicleMake:
            this.state.vehicleMakeId !== constantsConfig.vehicleOtherId
              ? this.state.vehicleMake
              : this.state.vehicleOtherMake,
          vehicleMakeId: this.state.vehicleMakeId,
          id: this.state.productId,
          vehicleMetaData: vehicleMetaData,
          vehicleModel: this.state.vehicleModel,
          vehicleModelId: this.state.vehicleModelId,
          vehicleBody: this.state.vehicleBody ? this.state.vehicleBody : null,
          vehicleExteriorColor: this.state.vehicleExteriorColor
            ? this.state.vehicleExteriorColor
            : null,
          vehicleMileage: parseInt(this.state.vehicleMileage),
          vehicleMileageUnit:
            this.state.vehicleLocation.countryCode === "US" ? "Miles" : "KM",
          price: parseInt(this.state.price),
          formattedPrice: this.state.price.toString(),
          title:
            (this.state.vehicleMakeId !== constantsConfig.vehicleOtherId
              ? this.state.vehicleMake
              : this.state.vehicleOtherMake) +
            " " +
            this.state.vehicleModel +
            " " +
            this.state.vehicleYear,
          condition: this.state.condition,
          conditionStatus: this.state.conditionStatus,
          rating: 3.5,
          tags: tags,
          vehicleDrivetrain: this.state.vehicleDrivetrain
            ? this.state.vehicleDrivetrain
            : null,
          vehicleTransmission: this.state.vehicleTransmission
            ? this.state.vehicleTransmission
            : null,
          vehicleInteriorColor: this.state.vehicleInteriorColor
            ? this.state.vehicleInteriorColor
            : null,
          vehicleFuelType: this.state.vehicleFuelType
            ? this.state.vehicleFuelType
            : null,
          vehicleTitleStatus: this.state.vehicleTitleStatus
            ? this.state.vehicleTitleStatus
            : null,
          vehicleMoneyOwed:
            this.state.vehicleMoneyOwed === "Yes"
              ? true
              : this.state.vehicleMoneyOwed === "No"
              ? false
              : null,
          vehicleNumberOfOwners: this.state.vehicleNumberOfOwners
            ? this.state.vehicleNumberOfOwners.toString()
            : null,
          vehicleVin: this.state.vehicleVin
            ? this.state.vehicleVin.toString()
            : null,
          description: !isEmptyOrSpaces(this.state.description)
            ? this.state.description
            : null,
          category: this.props.vehiclesCategory,
          categoryId: this.props.vehiclesCategory.categoryId,
          isSoldOnBingoDeal: false,
          isFoodItem: false,
          isBoosted: false,
          currencyCode: this.state.currencyCode,
          impressions: 0,
          clicks: 0,
          boostTimestamp: 0,
          isActive: true,
          isUsedProduct: true,
          platform: "web",
          firmOnPrice: false,
          seller: this.props.profile,
          sellerId: this.props.profile.id,
          ...this.state.vehicleLocation,
        };
        this.props.editProduct(updateVehicle);
      }
    }
  };

  render() {
    const { classes, vehiclesMake, intl, handleClose, usedProductReducer } =
      this.props;
    const conditionTranslated = translateConditionStatus(
      this.state.conditionStatus,
      intl
    );
    if (usedProductReducer.productState === "CREATE_PRODUCT") {
      if (this.props.type === "add")
        window.location.href =
          constantsConfig.mainUrl +
          "product/" +
          usedProductReducer.productId +
          "/Open/BoostProduct";
      else
        window.location.href =
          constantsConfig.mainUrl + "product/" + this.state.productId;
    }
    return (
      <React.Fragment>
        <Dialog
          onClose={this.handleCloseOthersDialog}
          aria-labelledby="simple-dialog-title"
          fullWidth
          open={this.state.openOthersDialog}
        >
          <AddOtherModel
            model={this.state.vehicleModel}
            handleChangeOthersModel={this.handleChangeOthersModel}
            handleCloseOthersDialog={this.handleCloseOthersDialog}
          />
        </Dialog>
        <Snackbar
          open={this.state.isOpenSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert onClose={this.handleCloseSnackbar} severity="error">
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: "fill_required_fields",
              })}
            </Typography>
          </Alert>
        </Snackbar>
        <div className={classes.dialogsBackground}>
          <CloseIcon className={classes.xIcon} onClick={handleClose} />
        </div>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ color: (theme) => theme.palette.primary.main }}
          className={classes.dialogsBackground}
        >
          {this.props.type === "add"
            ? intl.formatMessage({
                id: "add_vehicle",
              })
            : intl.formatMessage({
                id: "edit_vehicle",
              })}
        </Typography>
        <DialogContent className={classes.dialogsBackground}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              <MultipleImageUpload
                handleRemoveTags={this.handleRemoveTags}
                onHandleChangeImage={this.onHandleChangeImage}
                currentImagesURL={this.state.currentImagesURL}
                coverImageIndex={this.state.coverImageIndex}
              />
              {this.state.isImageUrlNull ? (
                <Typography
                  variant="subtitle1"
                  className={classes.errImageMessageTypography}
                >
                  {intl.formatMessage({
                    id: "upload_a_photo",
                  })}
                </Typography>
              ) : null}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isVehicleYearNull ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_year_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleYear}
                  onChange={(e) => this.handleChange(e, "vehicleYear")}
                >
                  {this.state.years.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        <Typography variant="body1">{year}</Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                <TextField
                  error
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_year_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleYear}
                  onChange={(e) => this.handleChange(e, "vehicleYear")}
                >
                  {this.state.years.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        <Typography variant="body1">{year}</Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isVehicleMakeNull ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_make_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleMake}
                  onChange={(e) => this.handleChange(e, "vehicleMake")}
                >
                  {vehiclesMake ? (
                    vehiclesMake.map((vehicle) => {
                      return (
                        <MenuItem key={vehicle.makeId} value={vehicle.make}>
                          <Typography variant="body1">
                            {vehicle.make}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                </TextField>
              ) : (
                <TextField
                  error
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_make_title",
                  })}
                  value={this.state.vehicleMake}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "vehicleMake")}
                >
                  {vehiclesMake &&
                    vehiclesMake.map((vehicle) => {
                      return (
                        <MenuItem key={vehicle.makeId} value={vehicle.make}>
                          <Typography variant="body1">
                            {vehicle.make}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </Grid>
            {this.state.makeFromOthers ? (
              <Grid item className={classes.gridItem}>
                {!this.state.isVehicleOtherMake ? (
                  <TextField
                    id="vehicleOtherMake"
                    label="Other Make"
                    variant="standard"
                    className={classes.textField}
                    value={this.state.vehicleOtherMake}
                    onChange={(e) => this.handleChange(e, "vehicleOtherMake")}
                  />
                ) : (
                  <TextField
                    error
                    id="vehicleOtherMake"
                    label="Other Make"
                    variant="standard"
                    className={classes.textField}
                    value={this.state.vehicleOtherMake}
                    onChange={(e) => this.handleChange(e, "vehicleOtherMake")}
                  />
                )}
              </Grid>
            ) : null}
            <Grid item className={classes.gridItem}>
              {this.state.spinner === true ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_model_title",
                  })}
                  value={this.state.vehicleModel}
                  className={classes.textField}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Grid>
                </TextField>
              ) : (
                <div>
                  {this.state.vehicleModels ||
                  this.state.makeFromOthers ||
                  this.state.modelFromOthers ? (
                    <div>
                      {!this.state.isVehicleModelNull ? (
                        <div>
                          {this.state.makeFromOthers ||
                          this.state.modelFromOthers ? (
                            <TextField
                              id="vehicleModel"
                              variant="standard"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              className={classes.textField}
                              value={this.state.vehicleModel}
                              onChange={(e) =>
                                this.state.makeFromOthers
                                  ? this.handleChange(e, "vehicleModel")
                                  : this.handleChangeOthersModel(e)
                              }
                            />
                          ) : (
                            <TextField
                              select
                              required
                              variant="standard"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              className={classes.textField}
                              value={this.state.vehicleModel}
                              onChange={(e) =>
                                this.handleChange(e, "vehicleModel")
                              }
                            >
                              {this.state.vehicleModels &&
                                this.state.vehicleModels.map((model) => {
                                  return (
                                    <MenuItem
                                      key={model.modelId}
                                      value={model.model}
                                    >
                                      <Typography variant="body1">
                                        {model.model}
                                      </Typography>
                                    </MenuItem>
                                  );
                                })}
                              {/* <MenuItem
                                value={'Others'}
                                onClick={this.handleClickOthersModel}
                              >
                                <Typography variant="body1">Others</Typography>
                              </MenuItem> */}
                            </TextField>
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.state.makeFromOthers ||
                          this.state.modelFromOthers ? (
                            <TextField
                              error
                              id="vehicleModel"
                              variant="standard"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              className={classes.textField}
                              value={this.state.vehicleModel}
                              onChange={(e) =>
                                this.state.makeFromOthers
                                  ? this.handleChange(e, "vehicleModel")
                                  : this.handleChangeOthersModel(e)
                              }
                            />
                          ) : (
                            <TextField
                              error
                              select
                              required
                              variant="standard"
                              label={intl.formatMessage({
                                id: "dialog_model_title",
                              })}
                              className={classes.textField}
                              value={this.state.vehicleModel}
                              onChange={(e) =>
                                this.handleChange(e, "vehicleModel")
                              }
                            >
                              {this.state.vehicleModels &&
                                this.state.vehicleModels.map((model) => {
                                  return (
                                    <MenuItem
                                      key={model.modelId}
                                      value={model.model}
                                    >
                                      <Typography variant="body1">
                                        {model.model}
                                      </Typography>
                                    </MenuItem>
                                  );
                                })}
                              {/* <MenuItem
                                value={'Others'}
                                onClick={this.handleClickOthersModel}
                              >
                                <Typography variant="body1">Others</Typography>
                              </MenuItem> */}
                            </TextField>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {!this.state.isVehicleModelNull ? (
                        <TextField
                          select
                          required
                          variant="standard"
                          label={intl.formatMessage({
                            id: "dialog_model_title",
                          })}
                          value={this.state.vehicleModel}
                          className={classes.textField}
                        >
                          <Typography align="center" variant="body1">
                            {intl.formatMessage({
                              id: "select_model_car",
                            })}
                          </Typography>
                        </TextField>
                      ) : (
                        <TextField
                          error
                          select
                          required
                          variant="standard"
                          label={intl.formatMessage({
                            id: "dialog_model_title",
                          })}
                          value={this.state.vehicleModel}
                          className={classes.textField}
                        >
                          <Typography align="center" variant="body1">
                            {intl.formatMessage({
                              id: "select_model_car",
                            })}
                          </Typography>
                        </TextField>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "dialog_transmission_title",
                })}
                className={classes.textField}
                value={this.state.vehicleTransmission}
                onChange={(e) => this.handleChange(e, "vehicleTransmission")}
              >
                {this.state.transmission &&
                  this.state.transmission.map((transmission) => {
                    const transmissionTranslated = translateTransmission(
                      transmission,
                      intl
                    );
                    return (
                      <MenuItem key={transmission} value={transmission}>
                        <Typography variant="body1">
                          {transmissionTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isVehicleBodyStyleNull ? (
                <TextField
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_body_style_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleBody}
                  onChange={(e) => this.handleChange(e, "vehicleBody")}
                >
                  {this.state.bodyStyles &&
                    this.state.bodyStyles.map((style) => {
                      const styleTranslated = translateBodyStyles(style, intl);
                      return (
                        <MenuItem key={style} value={style}>
                          <Typography variant="body1">
                            {styleTranslated}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </TextField>
              ) : (
                <TextField
                  error
                  select
                  required
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_body_style_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleBody}
                  onChange={(e) => this.handleChange(e, "vehicleBody")}
                >
                  {this.state.bodyStyles &&
                    this.state.bodyStyles.map((style) => {
                      const styleTranslated = translateBodyStyles(style, intl);
                      return (
                        <MenuItem key={style} value={style}>
                          <Typography variant="body1">
                            {styleTranslated}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="standard-select-currency"
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "dialog_fuel_type_title",
                })}
                className={classes.textField}
                value={this.state.vehicleFuelType}
                onChange={(e) => this.handleChange(e, "vehicleFuelType")}
              >
                {this.state.fuelTypes &&
                  this.state.fuelTypes.map((fuelType) => {
                    const fuelTypeTranslated = translateFuelTypes(
                      fuelType,
                      intl
                    );
                    return (
                      <MenuItem key={fuelType} value={fuelType}>
                        <Typography variant="body1">
                          {fuelTypeTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isVehicleExteriorColor ? (
                <TextField
                  required
                  id="standard-select-currency"
                  select
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_exterior_color_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleExteriorColor}
                  onChange={(e) => this.handleChange(e, "vehicleExteriorColor")}
                >
                  {this.state.colors &&
                    this.state.colors.map((color) => {
                      const colorTranslated = translateColors(color, intl);
                      return (
                        <MenuItem key={color} value={color}>
                          <Typography variant="body1">
                            {colorTranslated}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </TextField>
              ) : (
                <TextField
                  select
                  required
                  error
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_exterior_color_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleExteriorColor}
                  onChange={(e) => this.handleChange(e, "vehicleExteriorColor")}
                >
                  {this.state.colors &&
                    this.state.colors.map((color) => {
                      const colorTranslated = translateColors(color, intl);
                      return (
                        <MenuItem key={color} value={color}>
                          <Typography variant="body1">
                            {colorTranslated}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="standard-select-currency"
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "dialog_interior_color_title",
                })}
                className={classes.textField}
                value={this.state.vehicleInteriorColor}
                onChange={(e) => this.handleChange(e, "vehicleInteriorColor")}
              >
                {this.state.colors &&
                  this.state.colors.map((color) => {
                    const colorTranslated = translateColors(color, intl);
                    return (
                      <MenuItem key={color} value={color}>
                        <Typography variant="body1">
                          {colorTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="standard-select-currency"
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "title_status",
                })}
                className={classes.textField}
                value={this.state.vehicleTitleStatus}
                onChange={(e) => this.handleChange(e, "vehicleTitleStatus")}
              >
                {this.state.titleStatus &&
                  this.state.titleStatus.map((titleStatus) => {
                    const titleStatusTranslated = translateTitleStatus(
                      titleStatus.title,
                      intl
                    );
                    const descriptionStatusTranslated =
                      translateDescriptionStatus(titleStatus.description, intl);
                    return (
                      <MenuItem
                        key={titleStatus.title}
                        value={titleStatus.title}
                      >
                        <List>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {titleStatusTranslated}
                                </Typography>
                              }
                              secondary={descriptionStatusTranslated}
                            />
                          </ListItem>
                        </List>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isVehicleMileageNull ? (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "vehicleMileage")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "mileage",
                      }) + " *"}
                    </InputAdornment>
                  }
                  defaultValue={this.state.vehicleMileage}
                  type="number"
                  min={1}
                  required
                />
              ) : (
                <Input
                  autoFocus
                  name={intl.formatMessage({
                    id: "mileage",
                  })}
                  placeholder={intl.formatMessage({
                    id: "mileage",
                  })}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "vehicleMileage")}
                  startAdornment={
                    <InputAdornment position="start">
                      {intl.formatMessage({
                        id: "mileage",
                      }) + " *"}
                    </InputAdornment>
                  }
                  defaultValue={this.state.vehicleMileage}
                  type="number"
                  min={1}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "dialog_drivetrain_title",
                })}
                className={classes.textField}
                value={this.state.vehicleDrivetrain}
                onChange={(e) => this.handleChange(e, "vehicleDrivetrain")}
              >
                {this.state.driveTrains &&
                  this.state.driveTrains.map((driveTrain) => {
                    const driveTrainTranslated = translateDrivetrains(
                      driveTrain,
                      intl
                    );
                    return (
                      <MenuItem key={driveTrain} value={driveTrain}>
                        <Typography variant="body1">
                          {driveTrainTranslated}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                select
                variant="standard"
                label={intl.formatMessage({
                  id: "dialog_owners_num_title",
                })}
                className={classes.textField}
                value={this.state.vehicleNumberOfOwners}
                onChange={(e) => this.handleChange(e, "vehicleNumberOfOwners")}
              >
                {this.state.numberOfOwners &&
                  this.state.numberOfOwners.map((numOwners) => {
                    return (
                      <MenuItem key={numOwners} value={numOwners}>
                        <Typography variant="body1">
                          {numOwners === "Not Sure"
                            ? intl.formatMessage({
                                id: "not_sure",
                              })
                            : numOwners}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item className={classes.gridItem}>
              {this.props.type === "add" ? (
                <TextField
                  select
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_vehicle_owed_money_title",
                  })}
                  className={classes.textField}
                  value={this.state.vehicleMoneyOwed}
                  onChange={(e) => this.handleChange(e, "vehicleMoneyOwed")}
                >
                  <MenuItem value="Yes">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "yes",
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="No">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "no",
                      })}
                    </Typography>
                  </MenuItem>
                </TextField>
              ) : (
                <TextField
                  select
                  variant="standard"
                  label={intl.formatMessage({
                    id: "dialog_vehicle_owed_money_title",
                  })}
                  className={classes.textField}
                  defaultValue={
                    this.state.vehicleMoneyOwed === true
                      ? "Yes"
                      : this.state.vehicleMoneyOwed === null
                      ? ""
                      : "No"
                  }
                  onChange={(e) => this.handleChange(e, "vehicleMoneyOwed")}
                >
                  <MenuItem value="Yes">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "yes",
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="No">
                    <Typography variant="body1">
                      {intl.formatMessage({
                        id: "no",
                      })}
                    </Typography>
                  </MenuItem>
                </TextField>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="vehicleVin"
                variant="standard"
                label={intl.formatMessage({
                  id: "vehicle_vin",
                })}
                className={classes.textField}
                value={this.state.vehicleVin}
                onChange={(e) => this.handleChange(e, "vehicleVin")}
              />
            </Grid>
            <Grid item container className={classes.gridItem}>
              <Grid item xs={12} sm={6}>
                <Slider
                  defaultValue={2}
                  aria-labelledby="discrete-slider"
                  step={1}
                  marks
                  min={0}
                  max={3}
                  onChange={this.onHandleChangeCondition}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ marginInlineStart: "5%" }}
                  variant="subtitle2"
                >
                  {conditionTranslated}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TextField
                id="description"
                label={intl.formatMessage({
                  id: "product_description",
                })}
                multiline
                rows={4}
                className={classes.textField}
                onChange={(e) => this.handleChange(e, "description")}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              {!this.state.isPriceNull ? (
                <Input
                  autoFocus
                  placeholder={intl.formatMessage({
                    id: "tab_price",
                  })}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "price")}
                  startAdornment={
                    <InputAdornment position="start">
                      {this.state.currencyCode}
                    </InputAdornment>
                  }
                  defaultValue={this.state.price}
                  type="number"
                  min={1}
                  required
                  error={this.state.errorInPrice}
                />
              ) : (
                <Input
                  autoFocus
                  placeholder={intl.formatMessage({
                    id: "tab_price",
                  })}
                  className={classes.textField}
                  onChange={(e) => this.handleChange(e, "price")}
                  startAdornment={
                    <InputAdornment position="start">
                      {this.state.currencyCode}
                    </InputAdornment>
                  }
                  defaultValue={this.state.price}
                  type="number"
                  min={0}
                  required
                  error={true}
                />
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <MapComponent
                height="200px"
                zoom={15}
                onHandleChangeValue={this.handleChangeValue}
                addProductData={
                  this.props.type === "add"
                    ? this.props.locationConfig
                    : this.state.vehicleLocation
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogsBackground}>
          {!this.state.disabled ? (
            <Button
              className={classes.postButton}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              {this.props.type === "add"
                ? intl.formatMessage({
                    id: "product_post",
                  })
                : intl.formatMessage({
                    id: "edit",
                  })}
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    usedProductReducer: state.ProductReducer,
    vehiclesMake: state.firestore.ordered.vehicles_models,
    vehiclesCategory: state.firestore.ordered.vehiclesCategory
      ? state.firestore.ordered.vehiclesCategory[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
    editProduct: (product) => dispatch(editProduct(product)),
    postLogging: (product) => dispatch(postLogging(product)),
    purchaseLogging: (product) => dispatch(purchaseLogging(product)),
    purchaseFacebookPixel: (product) =>
      dispatch(purchaseFacebookPixel(product)),
    productPostFacebookPixel: (product) =>
      dispatch(productPostFacebookPixel(product)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
      return [
        {
          collection: constantsConfig.collectionVehiclesModels,
          orderBy: ["make", "asc"],
        },
      ];
    }),
    firestoreConnect((props, state) => {
      return [
        {
          collection: constantsConfig.collectionCategories,
          where: ["categoryRanking", "==", 1000],
          storeAs: "vehiclesCategory",
        },
      ];
    })
  )(AddVehicle)
);
