// @flow
export var productInformationJson = {
  title: "",
  description: "",
  price: 1.0,
  formattedPrice: "1",
  currencyCode: "",
  rating: 3.5,
  createdTimestamp: "",
  name: "",
  readableLocation: null,
  city: "",
  state: "",
  country: "",
  countryCode: "",
  zip: "",
  latitude: 0,
  longitude: 0,
  category: {
    categoryId: "",
    categoryTitle: null,
    categoryImage: "",
    categoryRanking: "",
  },
  categoryId: "",
  imageUrl1: "",
  imageUrl2: "",
  imageUrl3: "",
  imageUrl4: "",
  imageUrl5: "",
  firmOnPrice: false,
  condition: 1,
  conditionStatus: "For Parts",
  isSoldOnBingoDeal: false,
  isFoodItem: false,
  isBoosted: false,
  impressions: 0,
  clicks: 0,
  boostTimestamp: 0,
  isActive: true,
  isUsedProduct: true,
  platform: "web",
};
