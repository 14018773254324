//@flow
import React from 'react';
import UsePersistedState from './UsePersistedState';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import Strings from './Strings';
import { IntlProvider } from 'react-intl';
import { constantsConfig } from '../config/ConstantsConfig';
export const colors = {
  light: {
    primary: '#2196F3',
    primaryLight: '#BBDEFB',
    primaryDark: '#1976D2',
    accent: '#8BC34A',
    primaryText: '#212121',
    secondaryText: '#757575',
    icons: '#FFFFFF',
    background: '#FFFFFF',
    transparentBackground: 'rgba(0, 0, 0,0.5)',
    price: '#FFFFFF',
    circleMapColor: '#FF0000',
    errColor: 'red',
    slideShowBackground: '#303030',
    blackColor: 'black'
  },
  dark: {
    primary: '#757575',
    primaryLight: '#FFFFFF',
    primaryDark: '#212121',
    accent: '#c3c3c3',
    primaryText: '#FFFFFF',
    secondaryText: '#757575',
    icons: '#212121',
    background: '#212121',
    transparentBackground: 'rgba(0, 0, 0,0.5)',
    price: '#FFFFFF',
    circleMapColor: '#FF0000',
    errColor: 'red',
    slideShowBackground: '#303030',
    blackColor: 'black'
  }
};

const rtlDirection = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: colors.light.primary,
      light: colors.light.primaryLight,
      dark: colors.light.primaryDark,
      accent: colors.light.accent,
      primaryText: colors.light.primaryText,
      secondaryText: colors.light.secondaryText,
      icons: colors.light.icons,
      background: colors.light.background,
      transparentBackground: colors.light.transparentBackground,
      price: colors.light.price,
      circleMapColor: colors.light.circleMapColor,
      errColor: colors.light.errColor,
      slideShowBackground: colors.light.slideShowBackground,
      blackColor: colors.light.blackColor
    },
    mode: 'light'
  },
  typography: {
    tab: {
      fontFamily: 'Quicksand',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '0.8rem'
    },
    h3: {
      fontFamily: 'Quicksand',
      fontSize: '2.2rem',
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: 'Quicksand',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h5: {
      fontFamily: 'Quicksand',
      fontSize: '1.3rem',
      fontWeight: 'bold'
    },
    h6: {
      fontFamily: 'Quicksand',
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.57
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.875rem',
      fontFamily: 'Quicksand'
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.5
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.5rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.66
    },
    normalButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none'
    },
    usernameButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadAppButton: {
      border: 'none',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    addProductButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    didNotSellOnListedButton: {
      borderStyle: 'solid',
      borderWidth: 'medium',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadDialogButtons: {
      fontFamily: 'Quicksand',
      fontSize: '0.7rem',
      fontWeight: 'bold',
      border: '0.1rem solid',
      borderRadius: '1.5rem'
    }
  }
});
const rtlDirectionDark = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: colors.dark.primary,
      light: colors.dark.primaryLight,
      dark: colors.dark.primaryDark,
      primaryText: colors.dark.primaryText,
      secondaryText: colors.dark.secondaryText,
      accent: colors.dark.accent,
      icons: colors.dark.icons,
      background: colors.dark.background,
      transparentBackground: colors.dark.transparentBackground,
      price: colors.dark.price,
      circleMapColor: colors.dark.circleMapColor,
      errColor: colors.dark.errColor,
      slideShowBackground: colors.dark.slideShowBackground,
      blackColor: colors.dark.blackColor
    },
    mode: 'dark'
  },
  typography: {
    tab: {
      fontFamily: 'Quicksand',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '0.8rem'
    },
    h3: {
      fontFamily: 'Quicksand',
      fontSize: '2.2rem',
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: 'Quicksand',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h5: {
      fontFamily: 'Quicksand',
      fontSize: '1.3rem',
      fontWeight: 'bold'
    },
    h6: {
      fontFamily: 'Quicksand',
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.57
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.875rem',
      fontFamily: 'Quicksand'
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.5
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.5rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.66
    },
    normalButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none'
    },
    usernameButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadAppButton: {
      border: 'none',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    addProductButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    didNotSellOnListedButton: {
      borderStyle: 'solid',
      borderWidth: 'medium',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadDialogButtons: {
      fontFamily: 'Quicksand',
      fontSize: '0.7rem',
      fontWeight: 'bold',
      border: '0.1rem solid',
      borderRadius: '1.5rem'
    }
  }
});

export const ltrDirection = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: colors.light.primary,
      light: colors.light.primaryLight,
      dark: colors.light.primaryDark,
      primaryText: colors.light.primaryText,
      secondaryText: colors.light.secondaryText,
      accent: colors.light.accent,
      icons: colors.light.icons,
      background: colors.light.background,
      transparentBackground: colors.light.transparentBackground,
      price: colors.light.price,
      circleMapColor: colors.light.circleMapColor,
      errColor: colors.light.errColor,
      slideShowBackground: colors.light.slideShowBackground,
      blackColor: colors.light.blackColor
    },
    mode: 'light'
  },
  typography: {
    tab: {
      fontFamily: 'Quicksand',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '0.8rem'
    },
    h3: {
      fontFamily: 'Quicksand',
      fontSize: '2.2rem',
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: 'Quicksand',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h5: {
      fontFamily: 'Quicksand',
      fontSize: '1.3rem',
      fontWeight: 'bold'
    },
    h6: {
      fontFamily: 'Quicksand',
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.57
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.875rem',
      fontFamily: 'Quicksand'
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.5
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.5rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.66
    },
    normalButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none'
    },
    usernameButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadAppButton: {
      border: 'none',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    addProductButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    didNotSellOnListedButton: {
      borderStyle: 'solid',
      borderWidth: 'medium',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadDialogButtons: {
      fontFamily: 'Quicksand',
      fontSize: '0.7rem',
      fontWeight: 'bold',
      border: '0.1rem solid',
      borderRadius: '1.5rem'
    }
  }
});
const ltrDirectionDark = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: colors.dark.primary,
      light: colors.dark.primaryLight,
      dark: colors.dark.primaryDark,
      primaryText: colors.dark.primaryText,
      secondaryText: colors.dark.secondaryText,
      accent: colors.dark.accent,
      icons: colors.dark.icons,
      background: colors.dark.background,
      transparentBackground: colors.dark.transparentBackground,
      price: colors.dark.price,
      circleMapColor: colors.dark.circleMapColor,
      errColor: colors.dark.errColor,
      slideShowBackground: colors.dark.slideShowBackground,
      blackColor: colors.dark.blackColor
    },
    mode: 'dark'
  },
  typography: {
    tab: {
      fontFamily: 'Quicksand',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '0.8rem'
    },
    h3: {
      fontFamily: 'Quicksand',
      fontSize: '2.2rem',
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: 'Quicksand',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h5: {
      fontFamily: 'Quicksand',
      fontSize: '1.3rem',
      fontWeight: 'bold'
    },
    h6: {
      fontFamily: 'Quicksand',
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.57
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.875rem',
      fontFamily: 'Quicksand'
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.5
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.5rem',
      fontFamily: 'Quicksand',
      lineHeight: 1.66
    },
    normalButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none'
    },
    usernameButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadAppButton: {
      border: 'none',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    addProductButton: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    didNotSellOnListedButton: {
      borderStyle: 'solid',
      borderWidth: 'medium',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '1.5rem'
    },
    downloadDialogButtons: {
      fontFamily: 'Quicksand',
      fontSize: '0.7rem',
      fontWeight: 'bold',
      border: '0.1rem solid',
      borderRadius: '1.5rem'
    }
  }
});
export var globalLanguage = null;
export var globalDirection = null;
export const ThemeContext = React.createContext();
export const ThemeProviderApp = (props) => {
  const [theme, setTheme] = UsePersistedState('theme', 'light');
  const [appLanguage, setLanguage] = UsePersistedState('appLanguage', {
    locale: 'en',
    language: 'en',
    direction: 'ltr'
  });
  const [isOpenOnBoardingScreens, setIsOpenOnBoardingScreens] =
    UsePersistedState('isOpenOnBoardingScreens', true);

  const updateTheme = (newTheme) => {
    setTheme(newTheme);
  };
  const updateAppLanguage = (newAppLanguage) => {
    setLanguage(newAppLanguage);
    document
      .getElementsByTagName('html')[0]
      .setAttribute(
        'dir',
        appLanguage.language === 'ar' || appLanguage.language === 'ur-PK'
          ? 'rtl'
          : 'ltr'
      );
  };
  const context = React.useMemo(
    () => ({ appLanguage, updateAppLanguage }),
    [appLanguage, updateAppLanguage]
  );
  const memoTheme = React.useMemo(
    () => ({ theme, updateTheme }),
    [theme, updateTheme]
  );
  const memoOnBoardingScreens = React.useMemo(
    () => ({ isOpenOnBoardingScreens, setIsOpenOnBoardingScreens }),
    [isOpenOnBoardingScreens, setIsOpenOnBoardingScreens]
  );
  const appSettings = { context, memoTheme, memoOnBoardingScreens };
  globalLanguage = appLanguage.language;
  globalDirection = appLanguage.direction;
  const selectedDirection =
    appLanguage.language === 'ar' || appLanguage.language === 'ur-PK'
      ? theme === 'dark'
        ? rtlDirectionDark
        : rtlDirection
      : theme === 'dark'
      ? ltrDirectionDark
      : ltrDirection;
  document
    .getElementsByTagName('html')[0]
    .setAttribute('dir', appLanguage.direction);

  return (
    <IntlProvider
      locale={appLanguage.locale}
      messages={Strings[appLanguage.locale]}
    >
      <ThemeContext.Provider value={appSettings}>
        <MuiThemeProvider theme={selectedDirection} {...props}>
          <ThemeProvider theme={selectedDirection} {...props} />
        </MuiThemeProvider>
      </ThemeContext.Provider>
    </IntlProvider>
  );
};

export const useTheme = () => {
  const themeContext = React.useContext(ThemeContext);
  if (!themeContext) {
    throw new Error(
      "Use language can't be used witout outside of a ThemeProvider"
    );
  }
  const { theme, updateTheme } = themeContext.memoTheme;
  var setTheme = (newTheme) => {
    if (newTheme) {
      if (newTheme === 'light') {
        updateTheme('light');
      } else {
        updateTheme('dark');
      }
    }
  };
  return [theme, setTheme];
};

export const useLanguage = () => {
  const themeContext = React.useContext(ThemeContext);
  if (!themeContext) {
    throw new Error(
      "Use language can't be used witout outside of a ThemeProvider"
    );
  }
  const { appLanguage, updateAppLanguage } = themeContext.context;
  var setLanguage = (newLanguage) => {
    if (newLanguage) {
      if (newLanguage === constantsConfig.languagesSymbols.arabic) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.arabic,
          language: constantsConfig.languagesSymbols.arabic,
          direction: 'rtl'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.spanish) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.spanish,
          language: constantsConfig.languagesSymbols.spanish,
          direction: 'ltr'
        });
      } else if (
        newLanguage === constantsConfig.languagesSymbols.chineseSimplified
      ) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.chineseSimplified,
          language: constantsConfig.languagesSymbols.chineseSimplified,
          direction: 'ltr'
        });
      } else if (
        newLanguage === constantsConfig.languagesSymbols.chineseTraditional
      ) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.chineseTraditional,
          language: constantsConfig.languagesSymbols.chineseTraditional,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.czech) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.czech,
          language: constantsConfig.languagesSymbols.czech,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.danish) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.danish,
          language: constantsConfig.languagesSymbols.danish,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.dutch) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.dutch,
          language: constantsConfig.languagesSymbols.dutch,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.finnish) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.finnish,
          language: constantsConfig.languagesSymbols.finnish,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.french) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.french,
          language: constantsConfig.languagesSymbols.french,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.german) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.german,
          language: constantsConfig.languagesSymbols.german,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.greek) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.greek,
          language: constantsConfig.languagesSymbols.greek,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.hungarian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.hungarian,
          language: constantsConfig.languagesSymbols.hungarian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.indonesian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.indonesian,
          language: constantsConfig.languagesSymbols.indonesian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.italian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.italian,
          language: constantsConfig.languagesSymbols.italian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.japanese) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.japanese,
          language: constantsConfig.languagesSymbols.japanese,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.korean) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.korean,
          language: constantsConfig.languagesSymbols.korean,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.norwegian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.norwegian,
          language: constantsConfig.languagesSymbols.norwegian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.polish) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.polish,
          language: constantsConfig.languagesSymbols.polish,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.portuguese) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.portuguese,
          language: constantsConfig.languagesSymbols.portuguese,
          direction: 'ltr'
        });
      } else if (
        newLanguage === constantsConfig.languagesSymbols.portugueseBrazilian
      ) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.portugueseBrazilian,
          language: constantsConfig.languagesSymbols.portugueseBrazilian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.romanian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.romanian,
          language: constantsConfig.languagesSymbols.romanian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.russian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.russian,
          language: constantsConfig.languagesSymbols.russian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.swedish) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.swedish,
          language: constantsConfig.languagesSymbols.swedish,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.turkish) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.turkish,
          language: constantsConfig.languagesSymbols.turkish,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.ukrainian) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.ukrainian,
          language: constantsConfig.languagesSymbols.ukrainian,
          direction: 'ltr'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.urdu) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.urdu,
          language: constantsConfig.languagesSymbols.urdu,
          direction: 'rtl'
        });
      } else if (newLanguage === constantsConfig.languagesSymbols.vietnamese) {
        updateAppLanguage({
          locale: constantsConfig.languagesSymbols.vietnamese,
          language: constantsConfig.languagesSymbols.vietnamese,
          direction: 'ltr'
        });
      } else {
        updateAppLanguage({
          locale: 'en',
          language: 'en',
          direction: 'ltr'
        });
      }
    }
  };
  return [appLanguage.language, setLanguage];
};

export const useOnBoardingScreens = () => {
  const appSettings = React.useContext(ThemeContext);
  if (!appSettings) {
    throw new Error(
      "Use language can't be used witout outside of a ThemeProvider"
    );
  }
  const { isOpenOnBoardingScreens, setIsOpenOnBoardingScreens } =
    appSettings.memoOnBoardingScreens;
  var setIsOpenOnBoardingScreensStatus = (newIsOpenOnBoardingScreensStatus) => {
    if (newIsOpenOnBoardingScreensStatus === false) {
      setIsOpenOnBoardingScreens(false);
    } else if (newIsOpenOnBoardingScreensStatus === true) {
      setIsOpenOnBoardingScreens(true);
    }
  };
  return [isOpenOnBoardingScreens, setIsOpenOnBoardingScreensStatus];
};
