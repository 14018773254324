// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { injectIntl } from 'react-intl';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '5px 0px 5px 5px',
    marginTop: '100px',
    backgroundColor: theme.palette.primary.background
  },
  nested: {
    paddingInlineStart: theme.spacing(4)
  },
  listItemIcon: {
    color: theme.palette.primary.main
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: theme.palette.primary.background
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: '0 !important',
    backgroundColor: theme.palette.primary.background
  },
  list: {
    marginTop: '150px'
  },
  toolbar: theme.mixins.toolbar,
  icons: {
    fontSize: 20,
    marginInlineStart: '10px'
  },
  listDrawer: {
    marginTop: '180px'
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const DrawerSellPage = ({ intl, ...props }) => {
  const classes = useStyles();
  return (
    <div /* className={classes.drawer} aria-label="mailbox folders"*/>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.root}>
            <Link className={classes.link} to="/sellpageproducts/null">
              <ListItem button key={'Show Products'}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ViewModuleIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText>
                    {intl.formatMessage({
                      id: 'my_used_products'
                    })}
                  </ListItemText>
                </ListItemIcon>
              </ListItem>
            </Link>
            {/*<Link className={classes.link} to="/sellpageshops">
              <ListItem button key={"My Shops"}>
                <ListItemIcon className={classes.listItemIcon}>
                  <StorefrontIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText className={classes.listItemText}>
                    {intl.formatMessage({
                      id: "my_shops",
                    })}
                  </ListItemText>
                </ListItemIcon>
              </ListItem>
                  </Link>*/}

            {/* <Link className={classes.link} to="/sellpageboosted">
              <ListItem button key={"Boost product"}>
                <ListItemIcon className={classes.listItemIcon}>
                  <FlashOnIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                  <ListItemText className={classes.listItemText}>
                    {intl.formatMessage({
                      id: "my_boosted_products",
                    })}
                  </ListItemText>
                </ListItemIcon>
              </ListItem>
            </Link> */}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          open
        >
          <div className={classes.listDrawer}>
            <List>
              <Link className={classes.link} to="/sellpageproducts">
                <ListItem button key={'Show Products'}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ViewModuleIcon className={classes.icons} />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: 'my_used_products'
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link>
              {/*<Link className={classes.link} to="/sellpageshops">
                <ListItem button key={"Shop Details"}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <StorefrontIcon className={classes.icons} />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: "my_shops",
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
                    </Link>*/}
              {/* <Link className={classes.link} to="/sellpageboosted">
                <ListItem button key={"Boost product"}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <FlashOnIcon className={classes.icons} />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText>
                      {intl.formatMessage({
                        id: "my_boosted_products",
                      })}
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </Link> */}
            </List>
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth
  };
};

export default injectIntl(connect(mapStateToProps, null)(DrawerSellPage));
