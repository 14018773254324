import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import PlanNameDialog from "./PlanNameDialog";
import MeetingPlanDialog from "./MeetingPlanDialog";
import MapComponent from "../other/MapComponent";
import MeetingPlanMap from "./MeetingPlanMap";
import { deleteMessage } from "../../store/messages/NewMessagesAction";
import { firebaseConfig } from "../../config/FirebaseConfig";
import ConfirmDeleteMessageDialog from "./ConfirmDeleteMessageDialog";

let options = {
  weekday: "long",
  month: "long",
  day: "numeric",
};

const ITEM_HEIGHT = 48;

const styles = (theme) => ({
  dialogsBackground: {
    backgroundColor: theme.palette.primary.background,
  },
  gridItem: {
    width: "100%",
    marginTop: "1em",
  },
  icons: {
    color: theme.palette.primary.accent,
  },
});

class EditMeetingPlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      planLocation: null,
      openBackdrop: false,
      openDeleteMessageDialog: false,
      openMeetingPlanDialog: false,
      openPlanNameDialog: false,
      openLocationDialog: false,
    };
  }

  AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap //google map component
        google={this.props.google}
        defaultZoom={12}
        defaultCenter={{
          lat: this.props.message.planLocation.latitude + 0.01,
          lng: this.props.message.planLocation.longitude + 0.01,
        }}
        center={{
          lat: this.props.message.planLocation.latitude + 0.01,
          lng: this.props.message.planLocation.longitude + 0.01,
        }}
      >
        <Marker //marker can drop it
          google={this.props.google}
          //set position of marker
          position={{
            lat: this.props.message.planLocation.latitude,
            lng: this.props.message.planLocation.longitude,
          }}
        />
      </GoogleMap>
    ))
  );

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loadingDeleteMessage === "done") {
      this.props.handleCloseEditMeetingPlan();
      this.handleCloseConfirmDeleteMessageDialog();
    }
  }

  handleClickMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleOpenMeetingPlanDialog = () => {
    this.setState({
      openMeetingPlanDialog: true,
    });
  };
  handleCloseMeetingPlanDialog = () => {
    this.setState({
      openMeetingPlanDialog: false,
    });
  };

  handleOpenPlanNameDialog = () => {
    this.setState({
      openPlanNameDialog: true,
    });
  };
  handleClosePlanNameDialog = () => {
    this.setState({
      openPlanNameDialog: false,
    });
  };

  handleOpenLocationDialog = () => {
    this.setState({
      openLocationDialog: true,
    });
  };
  handleCloseLocationDialog = () => {
    this.setState({
      openLocationDialog: false,
    });
  };

  handleOpenConfirmDeleteMessageDialog = () => {
    this.setState({
      openDeleteMessageDialog: true,
    });
  };
  handleCloseConfirmDeleteMessageDialog = () => {
    this.setState({
      openDeleteMessageDialog: false,
    });
  };

  handleDeleteMessage = () => {
    this.props.deleteMessage();
  };

  handleChangeValue = (whatChange, value) => {
    switch (whatChange) {
      case "map":
        this.setState({
          planLocation: value,
        });
        break;

      //default value...
      default:
        break;
    }
  };

  render() {
    let AsyncMap = this.AsyncMap;
    const { classes, auth, message, intl } = this.props;
    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.openDeleteMessageDialog}
          // onClose={this.handleCloseConfirmDeleteMessageDialog}
        >
          <ConfirmDeleteMessageDialog
            messageId={this.props.message.id}
            threadId={this.props.thread.threadId}
            handleCloseEditMeetingPlan={this.props.handleCloseEditMeetingPlan}
            handleCloseConfirmDeleteMessageDialog={
              this.handleCloseConfirmDeleteMessageDialog
            }
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openLocationDialog}
        >
          <MeetingPlanMap
            message={this.props.message}
            thread={this.props.thread}
            handleCloseLocationDialog={this.handleCloseLocationDialog}
            handleChangeValue={this.handleChangeValue}
            locationConfig={this.props.locationConfig}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openMeetingPlanDialog}
        >
          <MeetingPlanDialog
            thread={this.props.thread}
            message={this.props.message}
            handleCloseMeetingPlan={this.handleCloseMeetingPlanDialog}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openPlanNameDialog}
        >
          <PlanNameDialog
            // onSendMeetingPlan={this.onSendMeetingPlan}
            thread={this.props.thread}
            message={this.props.message}
            handleClosePlanNameDialog={this.handleClosePlanNameDialog}
          />
        </Dialog>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem onClick={this.handleOpenConfirmDeleteMessageDialog}>
            <IconButton>
              <DeleteIcon
                sx={{ color: (theme) => theme.palette.primary.accent }}
              />
            </IconButton>
            {intl.formatMessage({
              id: "delete",
            })}
          </MenuItem>
        </Menu>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.handleCloseEditMeetingPlan}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              {this.props.message.planName && this.props.message.planName !== ""
                ? this.props.message.planName
                : intl.formatMessage({
                    id: "plan_name",
                  })}
            </Typography>
            {auth.uid === message.userId ? (
              <Button autoFocus color="inherit" onClick={this.handleClickMenu}>
                <MoreVertIcon />
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
        <List>
          <ListItem
            button
            onClick={
              auth.uid === message.userId
                ? this.handleOpenMeetingPlanDialog
                : null
            }
          >
            <ListItemAvatar>
              <AccessTimeIcon className={classes.icons} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h6" className={classes.listItemText}>
                  {new Intl.DateTimeFormat("en-US", options)
                    .format(this.props.message.planDate)
                    .toString() +
                    " - " +
                    new Intl.DateTimeFormat("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(this.props.message.planTime)}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={
              auth.uid === message.userId ? this.handleOpenPlanNameDialog : null
            }
          >
            <ListItemAvatar>
              <ModeEditIcon className={classes.icons} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h6" className={classes.listItemText}>
                  {this.props.message.planName &&
                  this.props.message.planName !== ""
                    ? this.props.message.planName
                    : intl.formatMessage({
                        id: "plan_name",
                      })}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={
              auth.uid === message.userId ? this.handleOpenLocationDialog : null
            }
          >
            <ListItemAvatar>
              <LocationOnIcon className={classes.icons} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h6" className={classes.listItemText}>
                  {this.props.message.planLocation
                    ? (this.props.message.planLocation.city
                        ? this.props.message.planLocation.city + ", "
                        : "") + this.props.message.planLocation.state
                    : intl.formatMessage({
                        id: "location",
                      })}
                </Typography>
              }
            />
          </ListItem>
          {this.props.message.planLocation ? (
            <div
              style={{
                padding: 20,
              }}
            >
              <AsyncMap //call asyncMap to render map
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  firebaseConfig.apiKey +
                  "&libraries=places"
                }
                loadingElement={<CircularProgress />}
                containerElement={<div style={{ height: "400px" }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          ) : null}
          <Divider />
          <ListItem button>
            <ListItemAvatar>
              <NotificationsIcon className={classes.icons} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h6" className={classes.listItemText}>
                  {intl.formatMessage({
                    id: "plan_reminder_message",
                  })}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            //   onClick={(e) => {
            //     handleCloseNewListingDialog();
            //     handleOpenAddMobileDialog();
            //   }}
          >
            <Typography variant="h6">
              {intl.formatMessage({
                id: "planners_title",
              })}
            </Typography>
          </ListItem>
          <ListItem>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item>
                <Avatar
                  alt="Sender photo"
                  src={this.props.thread.senderPhoto}
                  sx={{ width: 56, height: 56 }}
                />
                <Typography align="center">
                  {this.props.thread.senderName}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  alt="Receiver photo"
                  src={this.props.thread.receiverPhoto}
                  sx={{ width: 56, height: 56 }}
                />
                <Typography align="center">
                  {this.props.thread.receiverName}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingDeleteMessage: state.messages.loadingDeleteMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteMessage: (info) => dispatch(deleteMessage(info)),
  };
};

export default injectIntl(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(EditMeetingPlanDetails)
);
