// @flow
const initState = {
  productState: null,
  deleted: false,
  boostedProductId: null,
  unBoostedProductId: null,
  snackBarBoostProduct: false,
  category: '',
  loading: null,
  deleteProductSpinner: null,
  createProductSpinner: null,
  createModelSpinner: null,
  productsInformation: {
    startAtProduct: null,
    products: null,
    isLoading: false,
    firstTime: true,
    isLoadingMore: false
  }
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_PRODUCT':
      return { ...state, productState: 'CREATE_PRODUCT', productId: action.id };

    case 'CREATE_PRODUC_ERROR':
      return state;

    case 'EDIT_PRODUCT':
      return {
        ...state,
        productState: 'CREATE_PRODUCT',
        productId: action.id
      };
    case 'EDIT_PRODUCT_ERROR':
      return state;
    case 'EDIT_FAVORITE_PRODUCT':
      return {
        ...state,
        productState: 'EDIT_FAVORITE_PRODUCT',
        productId: action.id
      };
    case 'EDIT_FAVORITE_PRODUCT_ERROR':
      return state;
    case 'EDIT_PRODUCT_CATEGORY':
      return {
        ...state,
        productState: 'CREATE_PRODUCT',
        productId: action.id,
        category: action.category
      };
    case 'EDIT_PRODUCT_CATEGORY_ERROR':
      return state;
    case 'LOADING_FAVORITE_PRODUCT':
      return {
        ...state,
        loading: 'loading'
      };
    case 'DISABLE_LOADING_FAVORITE_PRODUCT':
      return {
        ...state,
        loading: null
      };
    case 'SNACKBAR_BOOST_PRODUCT':
      return {
        ...state,
        snackBarBoostProduct: 'loading'
      };
    case 'DISABLE_SNACKBAR_BOOST_PRODUCT':
      return {
        ...state,
        snackBarBoostProduct: null
      };
    case 'DELETE_PRODUCT':
      initState.deleted = true;
      initState.productState = 'DELETE_PRODUCT';
      return state;
    case 'DELETE_PRODUCT_ERROR':
      return state;
    case 'RESET_PRODUCT':
      return {
        ...state,
        productState: null,
        deleted: false,
        boostedProductId: null,
        unBoostedProductId: null
      };

    case 'DELETE_PRODUCT_SPINNER': {
      return {
        ...state,
        deleteProductSpinner: 'loading'
      };
    }

    case 'DELETE_PRODUCT_BY_ADMIN': {
      return {
        ...state,
        productState: 'DELETE_PRODUCT_BY_ADMIN',
        deleteProductSpinner: 'success',
        deleted: true
      };
    }

    case 'DELETE_BLOCKED_PRODUCT_BY_ADMIN': {
      return {
        ...state,
        productState: 'DELETE_BLOCKED_PRODUCT_BY_ADMIN',
        deleted: true
      };
    }

    case 'DELETE_PRODUCT_BY_ADMIN_ERROR':
      return {
        ...state,
        productState: 'DELETE_PRODUCT_BY_ADMIN_ERROR',
        deleteProductSpinner: 'error',
        deleted: false
      };
    case 'DELETE_BLOCKED_PRODUCT_BY_ADMIN_ERROR':
      return {
        ...state,
        productState: 'DELETE_BLOCKED_PRODUCT_BY_ADMIN_ERROR',
        deleted: false
      };

    case 'CREATE_BLOCKED_PRODUCT':
      return state;
    case 'CREATE_BLOCKED_PRODUCT_ERROR':
      return state;
    case 'CREATE_FAVORITE_PRODUCT':
      return state;
    case 'CREATE_FAVORITE_PRODUCT_ERROR':
      return state;
    case 'DELETE_FAVORITE_PRODUCT':
      return state;
    case 'DELETE_FAVORITE_PRODUCT_ERROR':
      return state;
    case 'CREATE_MODEL':
      return { ...state, createModelSpinner: 'done' };
    case 'CREATE_MODEL_ERROR':
      return { ...state, createModelSpinner: null };
    case 'CREATE_PRODUCT_SPINNER':
      return { ...state, createProductSpinner: 'loading' };
    case 'CREATE_UnBLOCKED_PRODUCT':
      return { ...state, createProductSpinner: 'success' };
    case 'CREATE_UnBLOCKED_PRODUCT_ERROR':
      return { ...state, createProductSpinner: 'error' };
    case 'BOOSTED_PRODUCT':
      return { ...state, boostedProductId: action.productId };
    case 'BOOSTED_PRODUCT_ERROR':
      return { ...state, boostedProductId: null };

    case 'UNBOOSTED_PRODUCT':
      return { ...state, unBoostedProductId: action.productId };
    case 'UNBOOSTED_PRODUCT_ERROR':
      return { ...state, unBoostedProductId: null };
    case 'UPDATE_VIEWS':
      return state;
    case 'UPDATE_VIEWS_ERROR':
      return state;
    case 'ADD_VIEWS_DOCUMENT':
      return state;
    case 'ADD_VIEWS_DOCUMENT_ERROR':
      return state;
    case 'GET_LOCATION_PRODUCTS_SUCSESS':
      return { ...state, productsInformation: action.productsInformation };
    case 'LOADING_GET_PRODUCTS':
      return {
        ...state,
        productsInformation: { ...state.productsInformation, isLoading: true }
      };
    case 'LOADING_MORE_GET_PRODUCTS':
      return {
        ...state,
        productsInformation: {
          ...state.productsInformation,
          isLoadingMore: true
        }
      };
    case 'LOADING_CREATE_MODEL':
      return {
        ...state,
        createModelSpinner: 'loading'
      };
    case 'RESET_LOCATION_PRODUCTS': {
      return {
        ...state,
        productsInformation: { ...state.productsInformation, isLoading: true }
      };
    }
    case 'RESET_PRODUCTS_INFORMATION': {
      return {
        ...state,
        productsInformation: {
          startAtProduct: null,
          products: null,
          isLoading: false,
          firstTime: true,
          isLoadingMore: false
        }
      };
    }

    default:
      return state;
  }
};

export default productReducer;
