// @flow

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export function impressionProduct(product) {
  var firestore = firebase.firestore();
  const error = 'Document does not exist!';
  var sfDocRef = firestore
    .collection('boost_transactions')
    .doc(product.lastBoostTransactionId);

  firestore
    .runTransaction(function (transaction) {
      return transaction.get(sfDocRef).then(function (sfDoc) {
        if (!sfDoc.exists) {
          throw error;
        }

        var newViews = sfDoc.data().impressions + 1;
        var newCumulativeImpressions = sfDoc.data().cumulativeImpressions + 1;
        transaction.update(sfDocRef, {
          impressions: newViews,
          cumulativeImpressions: newCumulativeImpressions
        });
        return newViews;
      });
    })
    .then(function () {})
    .catch(function () {});
}
export function clickProduct(product) {
  var firestore = firebase.firestore();
  const error = 'Document does not exist!';
  var sfDocRef = firestore
    .collection('boost_transactions')
    .doc(product.lastBoostTransactionId);

  firestore
    .runTransaction(function (transaction) {
      return transaction.get(sfDocRef).then(function (sfDoc) {
        if (!sfDoc.exists) {
          throw error;
        }

        var newCilcks = sfDoc.data().clicks + 1;
        var newCumulativeClicks = sfDoc.data().cumulativeClicks + 1;
        transaction.update(sfDocRef, {
          clicks: newCilcks,
          cumulativeClicks: newCumulativeClicks
        });
        return newCilcks;
      });
    })
    .then(function () {})
    .catch(function (err) {
      console.log(err);
    });
}
