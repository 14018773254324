// @flow
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import {
  Grid,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Fab,
  Hidden,
  Typography,
} from "@mui/material";
import { constantsConfig } from "../../config/ConstantsConfig";
import { makeStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import AddProduct from "./AddProduct";
import { productInformationJson } from "./InitProductInforamtion";
import AddIcon from "@mui/icons-material/Add";
import * as rdd from "react-device-detect";
import RemoveAllProducts from "./RemoveAllProducts";
import ProductsGrid from "./ProductsGrid";
import CreateNewListing from "./CreateNewListing";
import AddHome from "./AddHome";
import AddVehicle from "./AddVehicle";
import AddYardSaleEvent from "./AddYardSaleEvent";
import AddMobile from "./AddMobile";
import AddTablet from "./AddTablet";

const useStyles = makeStyles((theme) => ({
  noProducts: {
    marginTop: "-30px",
    color: theme.palette.primary.main,
  },
  noProductsMobile: {
    color: theme.palette.primary.main,
  },
  dialog: {
    "& + .pac-container": {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  imgSell: {
    maxHeight: "50%",
    maxWidth: "50%",
  },
  imgSellMobile: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  addProduct: {
    ...theme.typography.addProductButton,
    marginInlineStart: "25px",
    marginTop: "10px",
    backgroundColor: theme.palette.primary.accent,
    color: theme.palette.primary.icons,
    fontSize: "1rem",
    cursor: "pointer",
    position: "relative",
    padding: "7px",
    letterSpacing: "1px",
    minWidth: "1.8cm",
    maxWidth: "5cm",
    "&:hover": {
      backgroundColor: theme.palette.primary.accent,
      color: theme.palette.primary.icons,
    },
  },
  addProductMobile: {
    ...theme.typography.addProductButton,
    marginInlineStart: "25px",
    marginTop: "15px",
    backgroundColor: theme.palette.primary.accent,
    color: theme.palette.primary.icons,
    fontSize: "0.75rem",
    cursor: "pointer",
    position: "relative",
    padding: "7px",
    letterSpacing: "1px",
    minWidth: "1.8cm",
    maxWidth: "5cm",
    "&:hover": {
      backgroundColor: theme.palette.primary.accent,
      color: theme.palette.primary.icons,
    },
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    zIndex: 2,
  },
  gridOfSpinner: {
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.background,
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingTop: "10px",
  },
  flexContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  cardGrid: {
    /*   paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),*/
    padding: theme.spacing(6),
    paddingTop: theme.spacing(1),
  },
  cardGridMobile: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  containerGridMobile: {
    flexGrow: 1,
    marginTop: "20px",
    backgroundColor: theme.palette.primary.background,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.background,
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
    zIndex: 1,
  },
}));

function BoostedProductsDialogContant(props) {
  const { products, locationConfig, profile, intl } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenNewListing, setIsOpenNewListing] = React.useState(false);
  const [isOpenAddVehicle, setIsOpenAddVehicle] = React.useState(false);
  const [isOpenAddHome, setIsOpenAddHome] = React.useState(false);
  const [isOpenAddYardSaleEvent, setIsOpenAddYardSaleEvent] =
    React.useState(false);
  const [isOpenAddMobile, setIsOpenAddMobile] = React.useState(false);
  const [isOpenAddTablet, setIsOpenAddTablet] = React.useState(false);
  const [isOpenRemoveAllProducts, setIsOpenRemoveAllProducts] =
    React.useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpenNewListingDialog = () => {
    setIsOpenNewListing(true);
  };
  const handleCloseNewListingDialog = () => {
    setIsOpenNewListing(false);
  };
  const handleOpenAddHomeDialog = () => {
    setIsOpenAddHome(true);
  };
  const handleCloseAddHomeDialog = () => {
    setIsOpenAddHome(false);
  };
  const handleOpenAddVehicleDialog = () => {
    setIsOpenAddVehicle(true);
  };
  const handleCloseAddVehicleDialog = () => {
    setIsOpenAddVehicle(false);
  };
  const handleOpenAddYardSaleEventDialog = () => {
    setIsOpenAddYardSaleEvent(true);
  };
  const handleCloseAddYardSaleEventDialog = () => {
    setIsOpenAddYardSaleEvent(false);
  };
  const handleOpenAddMobileDialog = () => {
    setIsOpenAddMobile(true);
  };
  const handleCloseAddMobileDialog = () => {
    setIsOpenAddMobile(false);
  };
  const handleOpenAddTabletDialog = () => {
    setIsOpenAddTablet(true);
  };
  const handleCloseAddTabletDialog = () => {
    setIsOpenAddTablet(false);
  };
  const handleOpenRemoveAllProducts = () => {
    setIsOpenRemoveAllProducts(true);
  };
  const handleCloseRemoveAllProducts = () => {
    setIsOpenRemoveAllProducts(false);
  };
  if (products) {
    return (
      <div>
        <Dialog
          onClose={handleCloseNewListingDialog}
          aria-labelledby="simple-dialog-title"
          open={isOpenNewListing}
        >
          <CreateNewListing
            handleCloseNewListingDialog={handleCloseNewListingDialog}
            handleOpenAddProduct={handleOpen}
            handleOpenAddVehicleDialog={handleOpenAddVehicleDialog}
            handleOpenAddHomeDialog={handleOpenAddHomeDialog}
            handleOpenAddYardSaleEventDialog={handleOpenAddYardSaleEventDialog}
            handleOpenAddMobileDialog={handleOpenAddMobileDialog}
            handleOpenAddTabletDialog={handleOpenAddTabletDialog}
          />
        </Dialog>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={isOpen}
          className={classes.dialog}
        >
          <AddProduct
            productInformation={productInformationJson}
            handleClose={handleClose}
            type={"add"}
          />
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={isOpenAddVehicle}
          className={classes.dialog}
        >
          <AddVehicle
            handleClose={handleCloseAddVehicleDialog}
            profile={profile}
            currencyCode={
              locationConfig
                ? locationConfig.currency
                  ? locationConfig.currency
                  : null
                : null
            }
            locationConfig={locationConfig}
            type={"add"}
          />
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={isOpenAddHome}
          className={classes.dialog}
        >
          <AddHome
            handleClose={handleCloseAddHomeDialog}
            profile={profile}
            currencyCode={
              locationConfig
                ? locationConfig.currency
                  ? locationConfig.currency
                  : null
                : null
            }
            locationConfig={locationConfig}
            type={"add"}
          />
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={isOpenAddYardSaleEvent}
          onClose={handleCloseAddYardSaleEventDialog}
          className={classes.dialog}
        >
          <AddYardSaleEvent
            handleClose={handleCloseAddYardSaleEventDialog}
            profile={profile}
            currencyCode={
              locationConfig
                ? locationConfig.currency
                  ? locationConfig.currency
                  : null
                : null
            }
            locationConfig={locationConfig}
            type={"add"}
          />
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={isOpenAddMobile}
          onClose={handleCloseAddMobileDialog}
          className={classes.dialog}
        >
          <AddMobile
            handleClose={handleCloseAddMobileDialog}
            profile={profile}
            currencyCode={
              locationConfig
                ? locationConfig.currency
                  ? locationConfig.currency
                  : null
                : null
            }
            locationConfig={locationConfig}
            type={"add"}
          />
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"md"}
          open={isOpenAddTablet}
          onClose={handleCloseAddTabletDialog}
          className={classes.dialog}
        >
          <AddTablet
            handleClose={handleCloseAddTabletDialog}
            profile={profile}
            currencyCode={
              locationConfig
                ? locationConfig.currency
                  ? locationConfig.currency
                  : null
                : null
            }
            locationConfig={locationConfig}
            type={"add"}
          />
        </Dialog>
        <Dialog
          onClose={handleCloseRemoveAllProducts}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth={"xs"}
          open={isOpenRemoveAllProducts}
        >
          <RemoveAllProducts
            products={products}
            handleCloseRemoveAllProducts={handleCloseRemoveAllProducts}
          />
        </Dialog>
        {/* {products.length !== 0 ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={handleOpenRemoveAllProducts}
          >
            {intl.formatMessage({
              id: "products_word.remove_all_products",
            })}
          </Button>
        ) : null} */}
        <div>
          {products.length !== 0 ? (
            <div style={{ padding: 0 }}>
              <ProductsGrid
                products={products}
                router={props.router}
                removeAllProducts={true}
                handleOpenRemoveAllProducts={handleOpenRemoveAllProducts}
              />
            </div>
          ) : (
            <main className={classes.content}>
              <Container
                className={
                  !rdd.isMobile ? classes.cardGrid : classes.cardGridMobile
                }
              >
                <div>
                  <Hidden smDown implementation="css">
                    <div className={classes.flexContentCenter}>
                      <img
                        src={constantsConfig.imgSell}
                        className={classes.imgSell}
                        alt=""
                      />
                    </div>
                    <div className={classes.flexContentCenter}>
                      <Typography variant="h5" className={classes.noProducts}>
                        {intl.formatMessage({
                          id: "no_products_for_sale",
                        })}
                      </Typography>
                    </div>
                    <div className={classes.flexContentCenter}>
                      <Button
                        className={classes.addProduct}
                        onClick={handleOpenNewListingDialog}
                      >
                        {intl.formatMessage({
                          id: "add_product",
                        })}
                      </Button>
                    </div>
                  </Hidden>
                  <Hidden smUp implementation="css">
                    <Grid style={{ height: "75vh" }}>
                      <div className={classes.flexContentCenter}>
                        <img
                          src={constantsConfig.imgSell}
                          className={classes.imgSellMobile}
                          alt=""
                        />
                      </div>
                      <div className={classes.flexContentCenter}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          className={classes.noProductsMobile}
                        >
                          {intl.formatMessage({
                            id: "no_products_for_sale",
                          })}
                        </Typography>
                      </div>
                      <div className={classes.flexContentCenter}>
                        <Button
                          className={classes.addProductMobile}
                          onClick={handleOpenNewListingDialog}
                        >
                          {intl.formatMessage({
                            id: "add_product",
                          })}
                        </Button>
                      </div>
                    </Grid>
                  </Hidden>
                </div>
              </Container>
            </main>
          )}
        </div>

        <Fab
          aria-label="add"
          className={classes.fab}
          color="primary"
          onClick={handleOpenNewListingDialog}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  } else
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.gridOfSpinner}
      >
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>{" "}
      </Grid>
    );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    firebaseAuth: state.firebase.auth,
    locationConfig: state.locationReducer,
    profile: state.firestore.ordered.profile
      ? state.firestore.ordered.profile[0]
      : null,
    products: state.firestore.ordered.ownerProducts,
  };
};

export default injectIntl(
  compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
      if (props.ownerProductsId)
        return [
          {
            collection: constantsConfig.collectionUsers,
            where: ["id", "==", props.ownerProductsId],
            storeAs: "profile",
          },
        ];
      else return [];
    }),
    firestoreConnect((props) => {
      return [
        {
          collection: constantsConfig.subCollectionShops,
          where: [["sellerId", "==", props.ownerProductsId]],
          orderBy: ["lastModifiedTimestamp", "desc"],
          storeAs: "ownerProducts",
        },
      ];
    })
  )(BoostedProductsDialogContant)
);
